import { takeLatest, put, call, all } from "redux-saga/effects";
import Api from "../../../services/api";
import actions from "./actions";

const api = new Api();

const fetchAssignCourse = takeLatest(actions.FETCH_ASSIGN_COURSE, function* (action) {
  try {
    const response = yield call(
      api.get,
      `api/assignCourse/${action.id}`
    );
    yield put({
      type: actions.FETCH_ASSIGN_COURSE_SUCCESS,
      payload: response,
    });

    yield call(action.resolve, "success");
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

const fetchAssignCourses = takeLatest(actions.FETCH_ASSIGN_COURSES, function* (action) {
  try {
    const response = yield call(api.get, "api/assignCourses");
    yield put({
      type: actions.FETCH_ASSIGN_COURSES_SUCCESS,
      payload: response,
    });
    yield call(action.resolve, "success");
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

const addAssignCourses = takeLatest(actions.SAVE_ASSIGN_COURSE, function* (action) {
  try {
    const response = yield call(api.post, "api/assignCourse/add", action.data, null);
    action.data.id = response.id;
    yield put({
      type: actions.SAVE_ASSIGN_COURSE_SUCCESS,
      payload: response,
    });

    yield call(action.resolve, "success");
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

const editAssignCourses = takeLatest(actions.EDIT_ASSIGN_COURSE, function* (action) {
  try {
    const response = yield call(
      api.put,
      `api/assignCourse`,
      action.data,
      null
    );
    yield call(action.resolve, "success");
    yield put({
      type: actions.EDIT_ASSIGN_COURSE_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

const deleteAssignCourses = takeLatest(actions.DELETE_ASSIGN_COURSE, function* (action) {
  try {
    const response = yield call(
      api.delete,
      `api/assignCourse/${action.assignId}`
    );
    yield put({
      type: actions.DELETE_ASSIGN_COURSE_SUCCESS,
      response: response,
    });

    yield call(action.resolve, "success");
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

export default function* saga() {
  yield all([
    fetchAssignCourse,
    fetchAssignCourses,
    addAssignCourses,
    deleteAssignCourses,
    editAssignCourses,
  ]);
}
