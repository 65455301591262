const actions = {
  FETCH_STUDENTS: "FETCH_STUDENTS",
  FETCH_STUDENTS_SUCCESS: "FETCH_STUDENTS_SUCCESS",
  SAVE_STUDENT: "SAVE_STUDENT",
  SAVE_STUDENT_SUCCESS: "SAVE_STUDENT_SUCCESS",
  UPLOAD_STUDENT: "UPLOAD_STUDENT",
  UPLOAD_STUDENT_SUCCESS: "UPLOAD_STUDENT_SUCCESS",
  EDIT_STUDENT: "EDIT_STUDENT",
  EDIT_STUDENT_SUCCESS: "EDIT_STUDENT_SUCCESS",
  DELETE_STUDENT: "DELETE_STUDENT",
  DELETE_STUDENT_SUCCESS: "DELETE_STUDENT_SUCCESS",
  FETCH_GROUPS: "FETCH_GROUPS",
  FETCH_GROUPS_SUCCESS: "FETCH_GROUPS_SUCCESS",
  SAVE_GROUP: "SAVE_GROUP",
  SAVE_GROUP_SUCCESS: "SAVE_GROUP_SUCCESS",
  EDIT_GROUP: "EDIT_GROUP",
  EDIT_GROUP_SUCCESS: "EDIT_GROUP_SUCCESS",
  DELETE_GROUP: "DELETE_GROUP",
  DELETE_GROUP_SUCCESS: "DELETE_GROUP_SUCCESS",
  FETCH_STUDENTS_BY_COURSE: "FETCH_STUDENTS_BY_COURSE",
  FETCH_STUDENTS_BY_COURSE_SUCCESS: "FETCH_STUDENTS_BY_COURSE_SUCCESS",

  fetchStudents: (resolve, reject) => ({
    type: actions.FETCH_STUDENTS,
    resolve,
    reject,
  }),

  saveStudent: (data, event, resolve, reject) => ({
    type: actions.SAVE_STUDENT,
    data,
    event,
    resolve,
    reject,
  }),

  uploadStudent: (data, resolve, reject) => ({
    type: actions.UPLOAD_STUDENT,
    data,
    resolve,
    reject,
  }),

  editStudent: (userId, data, event, resolve, reject) => ({
    type: actions.EDIT_STUDENT,
    userId,
    data,
    event,
    resolve,
    reject,
  }),
  deleteStudent: (userId, resolve, reject) => ({
    type: actions.DELETE_STUDENT,
    userId: userId,
    resolve: resolve,
    reject: reject,
  }),

  fetchGroups: (resolve, reject) => ({
    type: actions.FETCH_GROUPS,
    resolve,
    reject,
  }),

  saveGroup: (data, event, resolve, reject) => ({
    type: actions.SAVE_GROUP,
    data,
    event,
    resolve,
    reject,
  }),

  editGroup: (groupId, data, event, resolve, reject) => ({
    type: actions.EDIT_GROUP,
    groupId,
    data,
    event,
    resolve,
    reject,
  }),

  deleteGroup: (groupId, resolve, reject) => ({
    type: actions.DELETE_GROUP,
    groupId: groupId,
    resolve: resolve,
    reject: reject,
  }),
  fetchStudentsByCourse: (courseId, resolve, reject) => ({
    type: actions.FETCH_STUDENTS_BY_COURSE,
    courseId,
    resolve,
    reject,
  }),
};

export default actions;
