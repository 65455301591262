import React from "react";
import { message } from "antd";
import { CloudDownloadOutlined } from "@ant-design/icons";
import axios from "axios";
import AButton from "../Button";
import { tokens } from "../../utils/Constants";

const FileDownload = ({ url, fileName, style, icon, displayFileName }) => {
  const handleDownload = () => {
    try {
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", fileName || "file");
      link.target = "_blank"; // Open the link in a new tab to trigger the download
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error creating or downloading the file:", error);
      message.error("Failed to download the file");
    }
  };

  return (
    <AButton
      type="primary"
      style={{ backgroundColor: tokens.COLOR_SUCCESS, ...style }}
      onClick={handleDownload}
      icon={icon || <CloudDownloadOutlined />}
    >
      Download {displayFileName ? fileName : ""}
    </AButton>
  );
  //   ) : (
  //     <a href={url} target="_blank" rel="noopener noreferrer">
  //       <AButton
  //         type="primary"
  //         style={{ backgroundColor: tokens.COLOR_SUCCESS, ...style }}
  //         icon={icon || <DownloadOutlined />}
  //       >
  //         Download
  //       </AButton>
  //     </a>
  //   );
};

export default FileDownload;
