import EvaluationCriteriaSaga from "./sagas";
import EvaluationCriteriaReducer from "./reducers";
import evaluationCriteriaActions from "./actions";

export const evaluationCriteriaReducer = {
  evaluationCriteriaReducer: EvaluationCriteriaReducer,
};

export const evaluationCriteriaSaga = [EvaluationCriteriaSaga()];

export { evaluationCriteriaActions };
