import React from "react";
import Icon from "@ant-design/icons";

const pendingIcon = () => {
  return (
    <svg
      width="17"
      height="16"
      viewBox="0 0 17 16"
      fill="currentColor"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.82461 9.59998C4.82461 9.26861 5.09324 8.99998 5.42461 8.99998H5.43261C5.76398 8.99998 6.03261 9.26861 6.03261 9.59998V9.60798C6.03261 9.93935 5.76398 10.208 5.43261 10.208H5.42461C5.09324 10.208 4.82461 9.93935 4.82461 9.60798V9.59998Z"
        fill="currentColor"
      />
      <path
        d="M5.42461 10.6C5.09324 10.6 4.82461 10.8686 4.82461 11.2V11.208C4.82461 11.5393 5.09324 11.808 5.42461 11.808H5.43261C5.76398 11.808 6.03261 11.5393 6.03261 11.208V11.2C6.03261 10.8686 5.76398 10.6 5.43261 10.6H5.42461Z"
        fill="currentColor"
      />
      <path
        d="M6.42461 9.59998C6.42461 9.26861 6.69324 8.99998 7.02461 8.99998H7.03261C7.36398 8.99998 7.63261 9.26861 7.63261 9.59998V9.60798C7.63261 9.93935 7.36398 10.208 7.03261 10.208H7.02461C6.69324 10.208 6.42461 9.93935 6.42461 9.60798V9.59998Z"
        fill="currentColor"
      />
      <path
        d="M7.02461 10.6C6.69324 10.6 6.42461 10.8686 6.42461 11.2V11.208C6.42461 11.5393 6.69324 11.808 7.02461 11.808H7.03261C7.36398 11.808 7.63261 11.5393 7.63261 11.208V11.2C7.63261 10.8686 7.36398 10.6 7.03261 10.6H7.02461Z"
        fill="currentColor"
      />
      <path
        d="M8.02461 7.99998C8.02461 7.66861 8.29324 7.39998 8.62461 7.39998H8.63261C8.96398 7.39998 9.23261 7.66861 9.23261 7.99998V8.00798C9.23261 8.33935 8.96398 8.60798 8.63261 8.60798H8.62461C8.29324 8.60798 8.02461 8.33935 8.02461 8.00798V7.99998Z"
        fill="currentColor"
      />
      <path
        d="M8.62461 8.99998C8.29324 8.99998 8.02461 9.26861 8.02461 9.59998V9.60798C8.02461 9.93935 8.29324 10.208 8.62461 10.208H8.63261C8.96398 10.208 9.23261 9.93935 9.23261 9.60798V9.59998C9.23261 9.26861 8.96398 8.99998 8.63261 8.99998H8.62461Z"
        fill="currentColor"
      />
      <path
        d="M8.02461 11.2C8.02461 10.8686 8.29324 10.6 8.62461 10.6H8.63261C8.96398 10.6 9.23261 10.8686 9.23261 11.2V11.208C9.23261 11.5393 8.96398 11.808 8.63261 11.808H8.62461C8.29324 11.808 8.02461 11.5393 8.02461 11.208V11.2Z"
        fill="currentColor"
      />
      <path
        d="M10.2246 7.39998C9.89324 7.39998 9.62461 7.66861 9.62461 7.99998V8.00798C9.62461 8.33935 9.89324 8.60798 10.2246 8.60798H10.2326C10.564 8.60798 10.8326 8.33935 10.8326 8.00798V7.99998C10.8326 7.66861 10.564 7.39998 10.2326 7.39998H10.2246Z"
        fill="currentColor"
      />
      <path
        d="M9.62461 9.59998C9.62461 9.26861 9.89324 8.99998 10.2246 8.99998H10.2326C10.564 8.99998 10.8326 9.26861 10.8326 9.59998V9.60798C10.8326 9.93935 10.564 10.208 10.2326 10.208H10.2246C9.89324 10.208 9.62461 9.93935 9.62461 9.60798V9.59998Z"
        fill="currentColor"
      />
      <path
        d="M10.2246 10.6C9.89324 10.6 9.62461 10.8686 9.62461 11.2V11.208C9.62461 11.5393 9.89324 11.808 10.2246 11.808H10.2326C10.564 11.808 10.8326 11.5393 10.8326 11.208V11.2C10.8326 10.8686 10.564 10.6 10.2326 10.6H10.2246Z"
        fill="currentColor"
      />
      <path
        d="M11.2246 7.99998C11.2246 7.66861 11.4932 7.39998 11.8246 7.39998H11.8326C12.164 7.39998 12.4326 7.66861 12.4326 7.99998V8.00798C12.4326 8.33935 12.164 8.60798 11.8326 8.60798H11.8246C11.4932 8.60798 11.2246 8.33935 11.2246 8.00798V7.99998Z"
        fill="currentColor"
      />
      <path
        d="M11.8246 8.99998C11.4932 8.99998 11.2246 9.26861 11.2246 9.59998V9.60798C11.2246 9.93935 11.4932 10.208 11.8246 10.208H11.8326C12.164 10.208 12.4326 9.93935 12.4326 9.60798V9.59998C12.4326 9.26861 12.164 8.99998 11.8326 8.99998H11.8246Z"
        fill="currentColor"
      />
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M5.22461 1.59998C5.55598 1.59998 5.82461 1.8686 5.82461 2.19998V3.19998H11.4246V2.19998C11.4246 1.8686 11.6932 1.59998 12.0246 1.59998C12.356 1.59998 12.6246 1.8686 12.6246 2.19998V3.19998H12.8246C14.0396 3.19998 15.0246 4.18495 15.0246 5.39998V12.2C15.0246 13.415 14.0396 14.4 12.8246 14.4H4.42461C3.20958 14.4 2.22461 13.415 2.22461 12.2V5.39998C2.22461 4.18495 3.20958 3.19998 4.42461 3.19998H4.62461V2.19998C4.62461 1.8686 4.89324 1.59998 5.22461 1.59998ZM4.42461 5.99998C3.87232 5.99998 3.42461 6.44769 3.42461 6.99998V12.2C3.42461 12.7523 3.87232 13.2 4.42461 13.2H12.8246C13.3769 13.2 13.8246 12.7523 13.8246 12.2V6.99998C13.8246 6.44769 13.3769 5.99998 12.8246 5.99998H4.42461Z"
        fill="currentColor"
      />
    </svg>
  );
};

export const PendingIcon = (props) => {
  return <Icon component={pendingIcon} {...props} />;
};
