import { takeLatest, put, call, all } from "redux-saga/effects";
import Api from "../../../services/api";
import actions from "./actions";

const api = new Api();

const createTimeSlot = takeLatest(
  actions.CREATE_TIMESLOT,
  function* (action) {
    try {
      const response = yield call(api.post, `api/timeSlot`, action.data, null);
      yield put({
        type: actions.CREATE_TIMESLOT_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield call(action.reject, "reject");
    }
  }
);

const fetchTimeSlots = takeLatest(
  actions.FETCH_TIMESLOTS,
  function* (action) {
    try {
      const response = yield call(api.get, "api/timeSlot", false);
      yield put({
        type: actions.FETCH_TIMESLOTS_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield call(action.reject, "reject");
    }
  }
);

const updateTimeSlot = takeLatest(
  actions.UPDATE_TIMESLOT,
  function* (action) {
    try {
      const response = yield call(
        api.put,
        `api/timeSlot/${action.data.id}`,
        action.data
      );
      yield put({
        type: actions.UPDATE_TIMESLOT_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield call(action.reject, "reject");
    }
  }
);

const deleteTimeSlot = takeLatest(
  actions.DELETE_TIMESLOT,
  function* (action) {
    try {
      const response = yield call(api.delete, `api/timeSlot/${action.id}`);
      yield put({
        type: actions.DELETE_TIMESLOT_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield call(action.reject, "reject");
    }
  }
);

export default function* saga() {
  yield all([createTimeSlot, fetchTimeSlots, updateTimeSlot, deleteTimeSlot]);
};
