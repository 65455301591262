import React, { useState } from "react";
import Loader from "../../../../../Components/Loader";
import AText from "../../../../../Components/Text";
import { tokens } from "../../../../../utils/Constants";
import {
  formatDeadline,
  getEvaluationID,
  getEvaluationName,
  hasPermission,
} from "../../../../../utils/Helper";
import ATag from "../../../../../Components/Tag";
import AButton from "../../../../../Components/Button";

import { Col, Divider, Form, Row, Space, message } from "antd";
import ATable from "../../../../../Components/Table";
import AModal from "../../../../../Components/Model";
import useToken from "antd/es/theme/useToken";
import ATitle from "../../../../../Components/Title";
import AInput from "../../../../../Components/Input";
import QuillEditor from "../../../../../Components/Editor";
import actions from "../../../store/actions";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router";
import FileDownload from "../../../../../Components/FileDownload";

const InstructorDetails = (evaluation) => {
  const location = useLocation();
  const evaluationId = getEvaluationID(location);
  const evaluationName = getEvaluationName(location);

  const [openModel, setOpenModel] = useState(false);
  const [editorContent, setEditorContent] = useState("");
  const [mediaUrl, setMediaUrl] = useState("");
  const [mediaId, setMediaId] = useState();
  const [selectedId, setSelectedId] = useState();

  const { recordId } = useParams();
  const dispatch = useDispatch();
  const courseId = localStorage.getItem("selectedCourse");

  const token = useToken();
  const [form] = Form.useForm();
  const evaluationData = evaluation && evaluation.evaluation;

  const handleEditorChange = (content) => {
    setEditorContent(content);
  };
  const handleSave = (values) => {
    new Promise((resolve, reject) => {
      dispatch(
        actions.updateEvaluationAnswerMarks(
          selectedId,
          evaluationId,
          courseId,
          values,
          resolve,
          reject
        )
      );
    })
      .then(() => {
        message.success(`${evaluationName} marks updated Sucessfully`);
        form.resetFields();
        setOpenModel(false);
        // navigate("/evaluationCriteria");
      })
      .catch(() => {
        message.error(`${evaluationName} marks updates fails`);
      })
      .finally(() => {});
  };

  const columns = [
    {
      title: "S.N.",
      render: (_, __, index) => <span>{index + 1}</span>,
    },
    {
      title: "Student Name",
      render: (_, record) => (record.student.first_name + " " +record.student.last_name ),
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text) => <div dangerouslySetInnerHTML={{ __html: text }} />,

    },
    {
      title: "Submission Date",
      render: (_, record) => formatDeadline(record.submittedOn).formattedDate1,
    },
    {
      title: "Marks",
      render: (_, record) => (record.marks ? record.marks : "Not Reviewed"),
    },
    {
      title: "Status",
      key: "status",
      render: (value) => {
        return value.marks ? (
          <ATag type="active"> Reviewed</ATag>
        ) : (
          <ATag type="pending"> Not Reviewed</ATag>
        );
      },
    },
    {
      title: "Attachment",
      key: "studentFile",
      render: (value) => {
        return value.file ? (
          <FileDownload
          displayFileName={true}
          url={value.file.media_url}
          fileName={value.file.name}
          style={{
            color: tokens.COLOR_PRIMARY,
            background: tokens.BG_LIGHT,
            border: `1px solid ${tokens.COLOR_PRIMARY}`,
          }}
        />
          ) : (
         <div style={{display:'flex', justifyContent:'center'}}>-</div>
        );
      },
    },
    //for review
    {
      // title: hasPermission("review_evaluation") ? "Action" : null,
      title: "Action",
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {/* {hasPermission("review_evaluation") && ( */}
          <AButton
            type="primary"
            size="middle"
            style={{
              color: tokens.BG_LIGHT,
              backgroundColor: record.marks ? token.COLOR_SECONDARY : tokens.COLOR_SUCCESS,
            }}
            onClick={(e) => {
              e.stopPropagation();
              setSelectedId(record.id);
              setOpenModel(true);
            }}
          >
            {record.marks ? 'Update Marks' : 'Review' }
          </AButton>
          {/* )} */}
        </div>
      ),
    },
  ];

  const getMaximunMarks = (totalPoints,minimunNumber) => {
    return totalPoints/minimunNumber;
  }
  if (!evaluation) {
    return <Loader />;
  }
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontWeight: 500,
            // flexDirection: "row",
          }}
        >
          <div>
            <AText
              style={{
                fontSize: "16px",
                fontWeight: 600,
                margin: "0 10px 0 0",
                color: tokens.COLOR_NEUTRAL_N600,
              }}
            >
              {evaluationName} Title :
            </AText>
            <AText
              content={evaluationData.title}
              style={{
                color: tokens.COLOR_NEUTRAL_N600,
                fontWeight: 500,
                fontSize: "16px",
              }}
            />
          </div>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            fontWeight: 500,
            marginTop: "2%",
            marginBottom: "4%",
          }}
        >
          <AText
            style={{
              fontSize: "16px",
              fontWeight: 600,
              margin: "0 10px 0 0",
              color: tokens.COLOR_NEUTRAL_N600,
            }}
          >
            {evaluationName} Description :
          </AText>
          <AText
            content={evaluationData.title}
            style={{
              color: tokens.COLOR_NEUTRAL_N600,
              fontWeight: 500,
              fontSize: "16px",
            }}
          />
        </div>
      </div>

      <Divider />
      <AText
        content={`Submitted ${evaluationName}`}
        style={{
          color: tokens.COLOR_NEUTRAL_N600,
          fontWeight: 600,
          fontSize: "16px",
        }}
      />

      <div>
        {evaluationData.evaluationAnswer && (
          <ATable
            columns={columns}
            dataSource={evaluationData.evaluationAnswer}
            onRow={(record, rowIndex) => {
              return {
                onClick: ({ event }) => {
                  // setSelectedId(record.id);
                  // setOpenModel(true);
                }, // click row
                onDoubleClick: (event) => {
                  // setSelectedId(record.id);
                  // setOpenModel(true);
                }, // double click row
              };
            }}
          />
        )}
      </div>
      {/* model to submit evaluation*/}
      <AModal
        open={openModel}
        closable={false}
        footer={null}
        className="css-dev-only-do-not-override-1r3vfrt-last"
      >
        <ATitle content={`Review ${evaluationName}`} level={4} />
        <div style={{ display: "flex", flexDirection: "column" }}>
          <AText
            style={{
              fontSize: "16px",
              fontWeight: 600,
              marginBottom: "20px",
              color: tokens.COLOR_NEUTRAL_N600,
            }}
          >
            {` Title : ${evaluationData.title}`}
          </AText>
          <AText
            style={{
              fontSize: "16px",
              fontWeight: 600,
              marginBottom: "10px",
              color: tokens.COLOR_NEUTRAL_N600,
            }}
          >
            {` Description :`}
          </AText>
          <QuillEditor
            style={{
              fontSize: tokens.FONT_FAMILY,
            }}
            value={evaluationData.description}
            className="no-toolbar"
            onChange={() => {}}
            readOnly={true}
          />
        </div>

        <Form
          size="large"
          form={form}
          name="add/editForm"
          onFinish={(values) => {
            values.evaluationAnswerId = selectedId;
            handleSave(values);
          }}
          layout="vertical"
          scrollToFirstError
          title="Submit Marks"
          style={{ width: "100%", padding: "2%" }}
          initialValues={{ marks: null }}
        >
          {/* <Form.Item
            name="marks"
            label={
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                  lineHeight: "19.6px",
                }}
              >
                Marks
              </p>
            }
            rules={[
              {
                required: true,
                message: "Please provide marks!",
              },
              {
                pattern: /^[0-9]*$/,
                message: "Please enter a valid number.",
              },
            ]}
          >
            <AInput placeholder={`Enter Marks`} size="large" />{" "}
          </Form.Item> */}
          <Form.Item
            name="marks"
            label={
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                  lineHeight: "19.6px",
                }}
              >
                {`Marks`}
              </p>
            }
            rules={[
              {
                required: true,
                message: `Please enter the Marks!`,
              },
              {
                validator: (_, value) => {
                  const topMarks = getMaximunMarks(
                    evaluationData.evaluationCriteria.totalPoints,
                    evaluationData.evaluationCriteria.minimunNumber
                  );
                  if (value >= 0 && value <= topMarks) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    `Marks must be between 0 - ${topMarks}`
                  );
                },
              },
            ]}
          >
            {/* <ASelect
                customized={false}
                placeholder="Choose Evaluation Criteria"
                // mode="multiple"
              >
                {evaluationCriteria &&
                  evaluationCriteria.map((element) => (
                    <Select.Option value={element.id}>
                      {element.name}
                    </Select.Option>
                  ))}
              </ASelect>{" "} */}
            <AInput placeholder={`Enter Marks`} size="large" />

          </Form.Item>
          <AText>
            {`[ Note : Marks must be between 0 - ${getMaximunMarks(evaluationData.evaluationCriteria.totalPoints, evaluationData.evaluationCriteria.minimunNumber)} ]`}
          </AText>
          <Form.Item style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <AButton
                type="default"
                size="large"
                onClick={() => setOpenModel(false)}
                style={{
                  backgroundColor: tokens.COLOR_LIGHTBLACK_L50,
                }}
              >
                Cancel
              </AButton>
              <AButton
                type="primary"
                htmlType="submit"
                size="large"
                style={{
                  color: tokens.BG_LIGHT,
                  backgroundColor: tokens.COLOR_PRIMARY,
                  padding: "0 30px",
                }}
              >
                {"Submit"}
              </AButton>
            </div>
          </Form.Item>
        </Form>
      </AModal>
    </div>
  );
};

export default InstructorDetails;
