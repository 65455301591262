import React, { useState } from "react";
import { message, Row, Upload } from "antd";
// import "index.css";
import { LoadingOutlined, PlusOutlined, LinkOutlined } from "@ant-design/icons";
import { tokens } from "../../utils/Constants";

const { Dragger } = Upload;

const beforeUpload = (file) => {
  const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
  if (!isJpgOrPng) {
    message.error("You can only upload JPG/PNG file!");
  }
  const isLt2M = file.size / 1024 / 1024 < 2;
  if (!isLt2M) {
    message.error("Image must be smaller than 2MB!");
  }
  return isJpgOrPng && isLt2M;
};

const authToken = `Bearer ${localStorage.getItem("token")}`;

const Uploads = ({
  isDragger = false,
  customContent,
  customUploadIcon,
  customUploadText,
  customUploadHint,
  setMediaUrl,
  setMediaId,
  existingImageUrl,
  id,
  onUploadSuccess,
}) => {
  const [loading, setLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState();
  const [mediaName, setMediaName] = useState();

  const handleChange = (info) => {
    if (info.file.status === "uploading") {
      setLoading(true);
      return;
    }
    if (info.file.status === "done") {
      setLoading(false);
      setImageUrl(info.file.response.media_url);
      setMediaUrl(info.file.response.media_url);
      setMediaId(info.file.response.id);
      setMediaName(info.file.response.name);
      if (onUploadSuccess) {
        onUploadSuccess(info.file.response.id);
      }
    }
  };

  const uploadButton = (
    <div>
      {loading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );

  if (isDragger) {
    const draggerProps = {
      name: "uploadfile",
      multiple: false,
      headers: {
        Authorization: authToken,
      },
      action: process.env.REACT_APP_API_BASE_URL + "/api/media/uploadFile",
      onChange: handleChange,
      onDrop(e) {
        console.log("Dropped files", e.dataTransfer.files);
      },
      progress: {
        strokeColor: {
          "0%": tokens.COLOR_PRIMARY,
          "100%": tokens.COLOR_PRIMARY,
        },
        strokeWidth: 6,
        format: (percent) => percent && `${parseFloat(percent.toFixed(0))}%`,
      },
    };

    return (
      <Dragger {...draggerProps} style={{ width: "40vh" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {[customUploadIcon, customContent]}
          {customUploadText}
          {customUploadHint}
        </div>
      </Dragger>
    );
  }

  const uploadProps = {
    name: "uploadfile",
    listType: "picture-card",
    className: "avatar-uploader",
    showUploadList: false,
    action: process.env.REACT_APP_API_BASE_URL + "/api/media/uploadFile",
    onChange: handleChange,
    headers: {
      Authorization: authToken,
    },
  };

  return (
    <Upload {...uploadProps} id={id}>
      {imageUrl || existingImageUrl ? (
        <div>
          <img
            src={imageUrl ? imageUrl : existingImageUrl ? existingImageUrl : ""}
            alt="Uploaded"
            style={{ width: "100px" }}
          />
          <Row>
            {imageUrl && <LinkOutlined />}
            <p style={{ fontSize: "14px", margin: "0 0 0 10px" }}>
              {mediaName}
            </p>
          </Row>
        </div>
      ) : (
        uploadButton
      )}
    </Upload>
  );
};

export default Uploads;
