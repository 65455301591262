import React from 'react';
import { Card, List, Row, Col } from 'antd';
import { tokens } from '../../utils/Constants';
import AText from '../Text';
import { DeleteOutlined } from '@ant-design/icons';
import AButton from '../Button/index';
import { useNavigate } from 'react-router';

function ExamListCard({ exam, openPopUp, selectItemId }) {
  const navigate = useNavigate();
  return (
    <Card
      style={{
        width: '100%',
        margin: '10px',
        background: tokens.BG_LIGHT,
        boxShadow: '3px 2px 18px -4px rgba(0,0,0,0.61)',
        border: '1px solid #d9d9d9',
        borderRadius: '5px',
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <div
        style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <AText
          content={exam.class}
          ellipsis
          strong
          style={{
            fontSize: '20px',
            fontWeight: 600,
            color: tokens.COLOR_NEUTRAL_N600,
            marginTop: '10px',
            marginBottom: '10px',
          }}
        />
        <div style={{ flex: 1, overflow: 'hidden' }}>
          <List
            dataSource={exam && exam.exam}
            renderItem={(examName, index) => (
              <List.Item
                style={{
                  border: '1px solid #d9d9d9',
                  borderRadius: '5px',
                  marginBottom: '10px',
                  padding: '10px',
                  cursor: 'pointer',
                }}
                onClick={() => navigate(`/exam/schedule/${exam.exam[index].id}`)}
              >
                <Row
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%',
                  }}
                >
                  <Col style={{ flex: '80%' }}>
                    <AText
                      content={examName.name}
                      ellipsis
                      strong
                      style={{
                        fontSize: '15px',
                        color: tokens.COLOR_LIGHTBLACK_L500,
                      }}
                    />
                  </Col>
                  <Col style={{ flex: '10%' }}>
                    <AButton
                      type='primary'
                      size='small'
                      icon={<DeleteOutlined />}
                      style={{
                        color: tokens.COLOR_DANGER,
                        backgroundColor: tokens.BG_LIGHT,
                        border: `1px solid ${tokens.COLOR_DANGER}`,
                      }}
                      onClick={(e) => {
                        e.stopPropagation();
                        openPopUp(true);
                        selectItemId(examName.id);
                      }}
                    />
                  </Col>
                </Row>
              </List.Item>
            )}
          />
        </div>
      </div>
    </Card>
  );
}

export default ExamListCard;
