import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import Loader from "../../../Components/Loader";
import AddEditBatch from "../Components/AddEditBatch";
import actions from "../store/actions";
function EditBatch() {
  const [saving, setSaving] = useState(false);

  const { id } = useParams();
  const dispatch = useDispatch();

  let batch = useSelector(({ batchesReducer }) => batchesReducer.get("batch"));

  useEffect(() => {
    new Promise((resolve, reject) => {
      dispatch(actions.fetchBatch(parseInt(id), resolve, reject));
    })
      .then((response) => {})
      .finally(() => {});
  }, [dispatch]);

  let navigate = useNavigate();

  const transformData = (data) => {
    const transformedData = [];

    for (const programId in data) {
      if (data[programId]) {
        transformedData.push({
          program: programId,
          semesters: data[programId],
        });
      }
    }

    return transformedData;
  };
  const handleEdit = (value, batchData) => {
    setSaving(true);

    const transformedValue = transformData(value);

    const data = {
      batchData,
      batchSemesterData: transformedValue,
    };

    new Promise((resolve, reject) => {
      dispatch(
        actions.editBatches(batch.batch.id, data, "ADD", resolve, reject)
      );
    })
      .then(() => {
        message.success("Batch edited Sucessfully");
        navigate(`/session`);
      })
      .catch(() => {
        message.error("Batch edit failed");
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const handleBatchDelete = (id) => {
    new Promise((resolve, reject) => {
      dispatch(actions.deleteBatches(id, resolve, reject));
    })
      .then(() => {
        navigate("/session");
        message.success("College delete Success");
      })
      .catch(() => {
        message.error("College delete fails ");
      })
      .finally(() => {});
  };

  if (!batch || saving) {
    return <Loader />;
  }
  return (
    <div>
      <AddEditBatch
        handleSave={handleEdit}
        batch={batch}
        handleDelete={handleBatchDelete}
      />
    </div>
  );
}

export default EditBatch;
