import React, { useEffect, useState } from "react";
import ATable from "../../Components/Table";
import AButton from "../../Components/Button/index";
import {
  SearchOutlined,
  PlusOutlined,
  WarningFilled,
  FilterOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import { tokens } from "../../utils/Constants";
import { hasPermission } from "../../utils/Helper";
import { Drawer, Space, message, theme } from "antd";
import AInput from "../../Components/Input";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import actions from "./store/actions";
import AModal from "../../Components/Model";
import ATitle from "../../Components/Title";

function College() {
  const [openModel, setOpenModel] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { useToken } = theme;
  const { token } = useToken();
  const [searchTerm, setSearchTerm] = useState("");

  const handleCollegeDelete = (id) => {
    new Promise((resolve, reject) => {
      dispatch(actions.deleteColleges(id, resolve, reject));
    })
      .then(() => {
        setOpenModel(false);
        message.success("College delete Success");
      })
      .catch(() => {
        message.error("College delete fails ");
      })
      .finally(() => {});
  };

  let colleges = useSelector(({ collegesReducer }) =>
    collegesReducer.get("colleges")
  );

  const filteredColleges = colleges && colleges.filter(college => {
    return Object.keys(college).some(key => {
      if (key !== 'id') {
        const value = college[key];
        return value && value.toString().toLowerCase().includes(searchTerm.toLowerCase());
      }
      return false;
    });
  });

  useEffect(() => {
    new Promise((resolve, reject) => {
      dispatch(actions.fetchColleges(resolve, reject));
    }).finally(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, openModel]);

  const columns = [
    {
      title: "S.N.",
      render: (_, __, index) => <span>{index + 1}</span>,
    },
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Contact Number",
      dataIndex: "contactNo",
      key: "contactNo",
    },
    {
      title: "City",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "State",
      dataIndex: "state",
      key: "state",
    },
    // {
    //   title: "Country",
    //   dataIndex: "country",
    //   key: "country",
    // },
    {
      title: "Action",
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            // justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <AButton
            type="primary"
            size="middle"
            style={{
              color: tokens.BG_LIGHT,
              backgroundColor: tokens.COLOR_SUCCESS,
            }}
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/college/edit/${record.id}`);
            }}
          >
            Edit
          </AButton>
          <AButton
            type="default"
            size="middle"
            style={{
              color: tokens.COLOR_DANGER,
              borderColor: tokens.COLOR_DANGER,
              margin: "10px",
            }}
            onClick={(e) => {
              e.stopPropagation();
              setOpenModel(true);
              setSelectedId(record.id);
            }}
          >
            Delete
          </AButton>
        </div>
      ),
    },
  ];

  const [openDrawer, setOpenDrawer] = useState(false);
  const showDrawer = () => {
    setOpenDrawer(true);
  };

  const onClose = () => {
    setOpenDrawer(false);
  };

  return (
    <div>
      <Drawer
        title="Filter"
        placement={"right"}
        onClose={onClose}
        open={openDrawer}
      ></Drawer>
      <Space
        style={{
          width: "100%",
          justifyContent: "flex-end",
          marginBottom: "30px",
        }}
      >
        <AInput
          suffix={<SearchOutlined />}
          placeholder="Search"
          name="search"
          size="large"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <AButton
          type="primary"
          icon={<FilterOutlined />}
          size="large"
          onClick={showDrawer}
          style={{ backgroundColor: tokens.COLOR_LIGHT_ORANGE }}
        >
          Filter
        </AButton>
      </Space>
      {hasPermission("add_college") && (
        <Space
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "30px",
          }}
        >
          <Link to={"/college/add"}>
            <AButton
              type="primary"
              icon={<PlusOutlined />}
              size="large"
              style={{ backgroundColor: tokens.COLOR_DARK_ORANGE }}
            >
              Add New College
            </AButton>
          </Link>
        </Space>
      )}

      {filteredColleges && filteredColleges.length === 0 && searchTerm == "" ? (
        <Space
          style={{
            minWidth: "100%",
            minHeight: "80vh",
            justifyContent: "center",
            fontFamily: tokens.SECONDARY_FONT_FAMILY,
          }}
          align="center"
        >
          <Space direction="vertical" align="center">
            <ClockCircleOutlined style={{ fontSize: "24pt" }} />
            <ATitle level={4} content="You don't have any College yet" />
            <div
              style={{
                border: `1px dashed ${tokens.COLOR_PRIMARY}`,
                padding: "30px",
                borderRadius: "5px",
              }}
            >
              <AButton
                type="default"
                icon={<PlusOutlined />}
                style={{
                  color: tokens.COLOR_PRIMARY,
                  borderColor: tokens.COLOR_PRIMARY,
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/college/add`);
                }}
              >
                Add New College
              </AButton>
            </div>
          </Space>
        </Space>
      ) : (
        <ATable
          columns={columns}
          dataSource={filteredColleges}
          onRow={(record, rowIndex) => {
            return {
              onClick: ({ event }) => {
                navigate(`/college/view/${record.id}`);
              }, // click row
              onDoubleClick: (event) => {
                navigate(`/college/view/${record.id}`);
              }, // double click row
            };
          }}
        />
      )}

      <AModal
        style={{
          padding: "50px",
        }}
        open={openModel}
        closable={false}
        footer={null}
        className="css-dev-only-do-not-override-1r3vfrt-last"
      >
        <Space
          align="center"
          direction="vertical"
          style={{
            display: "flex",
            alignContent: "center",
          }}
        >
          <Space direction="vertical" size="small" align="center">
            <WarningFilled
              style={{
                fontSize: "65px",
                color: token.colorError,
              }}
            />
            <ATitle content="Are you sure ?" level={4} />
            <p
              style={{
                textAlign: "center",
                width: "100%",
                marginTop: "-5px",
              }}
            >
              Deleting a college will delete all the users of college. This
              process cannot be undo.
            </p>
          </Space>
          <Space>
            <AButton
              type="default"
              size="small"
              onClick={() => setOpenModel(false)}
            >
              Cancel
            </AButton>
            <AButton
              type="primary"
              size="small"
              onClick={() => handleCollegeDelete(selectedId)}
              mode="danger"
            >
              Delete
            </AButton>
          </Space>
        </Space>
      </AModal>
    </div>
  );
}

export default College;
