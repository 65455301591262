import React, { useState } from "react";
import { useNavigate } from "react-router";
import AddEditStudent from "../../../Components/AddStudent";
import { useDispatch } from "react-redux";
import actions from "../store/actions";
import { message } from "antd";
function AddTeacher() {
  const [saving, setSaving] = useState(false);
  const dispatch = useDispatch();

  let navigate = useNavigate();
  const handleSave = (value) => {
    setSaving(true);
    const data = {
      ...value,
    };
    if (data.middle_name === undefined) {
      data.middle_name = "";
    }
    new Promise((resolve, reject) => {
      dispatch(actions.saveInstructor(data, "ADD", resolve, reject));
    })
      .then(() => {
        message.success("Instructor added Sucessfully");
        navigate("/instructor");
      })
      .catch(() => {
        message.error("Instructor add failed");
      })
      .finally(() => {
        setSaving(false);
      });
  };
  return (
    <div>
      <AddEditStudent
        loading={saving}
        handleSave={handleSave}
        isStudent={false}
        content={"Add Teacher"}
      />
    </div>
  );
}

export default AddTeacher;
