import { takeLatest, put, call, all } from "redux-saga/effects";
import Api from "../../../services/api";
import actions from "./actions";
import { act } from "react-dom/test-utils";

const api = new Api();

const fetchColleges = takeLatest(actions.FETCH_COLLEGES, function* (action) {
  try {
    const response = yield call(api.get, "api/college");
    yield put({
      type: actions.FETCH_COLLEGES_SUCCESS,
      payload: response,
    });
    yield call(action.resolve, "success");
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

const addColleges = takeLatest(actions.SAVE_COLLEGES, function* (action) {
  try {
    const response = yield call(api.post, "api/college/add", action.data, null);
    action.data.id = response.id;
    yield put({
      type: actions.SAVE_COLLEGES_SUCCESS,
      payload: response,
    });

    yield call(action.resolve, "success");
  } catch (error) {
    yield call(action.reject, error);
  }
});

const editColleges = takeLatest(actions.EDIT_COLLEGES, function* (action) {
  try {
    const response = yield call(
      api.put,
      `api/college/${action.collegeId}`,
      action.data,
      null
    );
    yield call(action.resolve, "success");
    yield put({
      type: actions.EDIT_COLLEGES_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

const deleteColleges = takeLatest(actions.DELETE_COLLEGES, function* (action) {
  try {
    const responseId = yield call(
      api.delete,
      `api/college/${action.collegeId}`
    );
    yield put({
      type: actions.DELETE_COLLEGES_SUCCESS,
      id: action.id,
    });

    yield call(action.resolve, responseId);
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

const fetchCollegeAdmins = takeLatest(
  actions.FETCH_COLLEGE_ADMIN,
  function* (action) {
    try {
      const response = yield call(
        api.get,
        `api/college/admin/${action.collegeId}`
      );
      yield put({
        type: actions.FETCH_COLLEGE_ADMIN_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, "success");
    } catch (error) {
      yield call(action.reject, "failed");
    }
  }
);

const addCollegeAdmin = takeLatest(
  actions.SAVE_COLLEGE_ADMIN,
  function* (action) {
    try {
      const response = yield call(
        api.post,
        "api/college/admin/add",
        action.data,
        null
      );
      action.data.id = response.id;
      yield put({
        type: actions.SAVE_COLLEGE_ADMIN_SUCCESS,
        payload: response,
      });

      yield call(action.resolve, "success");
    } catch (error) {
      yield call(action.reject, "failed");
    }
  }
);

const deleteCollegeAdmin = takeLatest(
  actions.DELETE_COLLEGE_ADMIN,
  function* (action) {
    try {
      const responseId = yield call(
        api.delete,
        `api/college/admin/${action.id}`
      );
      yield put({
        type: actions.DELETE_COLLEGE_ADMIN_SUCCESS,
        payload: responseId,
      });

      yield call(action.resolve, responseId);
    } catch (error) {
      yield call(action.reject, "failed");
    }
  }
);

export default function* saga() {
  yield all([
    fetchColleges,
    addColleges,
    deleteColleges,
    editColleges,
    fetchCollegeAdmins,
    addCollegeAdmin,
    deleteCollegeAdmin,
  ]);
}
