import React, { useEffect, useState } from "react";
import {
  formatDeadline,
  getEvaluationName,
  hasPermission,
  getEvaluationID,
} from "../../../utils/Helper";
import AButton from "../../../Components/Button";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";
import { Drawer, Progress, Select, Space, message, theme } from "antd";
import { tokens } from "../../../utils/Constants";
import actions from "../store/actions";
import {
  SearchOutlined,
  PlusOutlined,
  WarningFilled,
  FilterOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import AInput from "../../../Components/Input";
import { Link } from "react-router-dom";
import ATitle from "../../../Components/Title";
import ATable from "../../../Components/Table";
import AModal from "../../../Components/Model";
import Loader from "../../../Components/Loader";
import coursesAction from "../../Course/store/actions";
import ASelect from "../../../Components/Select";
import ATag from "../../../Components/Tag";
import AText from "../../../Components/Text";

function Evaluation() {
  const location = useLocation();

  const evaluationId = getEvaluationID(location);
  const evaluationName = getEvaluationName(location);

  const [openModel, setOpenModel] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");
  const [courseId, setCourseId] = useState("");

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { useToken } = theme;
  const { token } = useToken();
  let evaluations = useSelector(({ evaluationCriteriaReducer }) =>
    evaluationCriteriaReducer.get("evaluations")
  );
  let evaluationsByStudent = useSelector(({ evaluationCriteriaReducer }) =>
    evaluationCriteriaReducer.get("evaluationsByStudent")
  );
  let coursesList = useSelector(({ coursesReducer }) =>
    hasPermission("view_course_as_instructor")
      ? coursesReducer.get("coursesByInstructor")
      : hasPermission("view_course_as_student")
      ? coursesReducer.get("coursesByStudent")
      : null
  );

  const filteredEvaluations =
    evaluations &&
    evaluations.filter((data) => {
      return Object.keys(data).some((key) => {
        if (key !== "id") {
          const value = data[key];
          return (
            value &&
            value.toString().toLowerCase().includes(searchTerm.toLowerCase())
          );
        }
        return false;
      });
    });

  const filteredEvaluationsByStudents =
    evaluationsByStudent &&
    evaluationsByStudent.filter((data) => {
      return Object.keys(data).some((key) => {
        if (key !== "id") {
          const value = data[key];
          return (
            value &&
            value.toString().toLowerCase().includes(searchTerm.toLowerCase())
          );
        }
        return false;
      });
    });

  // useEffect(() => {
  //   hasPermission("view_course_as_instructor") &&
  //     new Promise((resolve, reject) => {
  //       dispatch(coursesAction.fetchCoursesByInstructor(resolve, reject));
  //     }).finally(() => {});

  //   hasPermission("view_course_as_instructor") &&
  //     courseId &&
  //     new Promise((resolve, reject) => {
  //       dispatch(
  //         actions.fetchEvaluationByType(evaluationId, courseId, resolve, reject)
  //       );
  //     }).finally(() => {});

  //   //for student
  //   hasPermission("view_course_as_student") &&
  //     new Promise((resolve, reject) => {
  //       dispatch(coursesAction.fetchCoursesByStudent(resolve, reject));
  //     }).finally(() => {});

  //   hasPermission("view_course_as_student") &&
  //     courseId &&
  //     new Promise((resolve, reject) => {
  //       dispatch(
  //         actions.fetchEvaluationByStudent(
  //           evaluationId,
  //           courseId,
  //           resolve,
  //           reject
  //         )
  //       );
  //     }).finally(() => {}); // evaluationByStudent
  // }, [evaluationId, courseId]);

  useEffect(() => {
    const fetchCoursesAndEvaluations = async () => {
       if (hasPermission("view_course_as_instructor")) {
         await dispatch(coursesAction.fetchCoursesByInstructor());
         if (courseId) {
           await dispatch(actions.fetchEvaluationByType(evaluationId, courseId));
         }
       }
   
       if (hasPermission("view_course_as_student")) {
         await dispatch(coursesAction.fetchCoursesByStudent());
         if (courseId) {
           await dispatch(actions.fetchEvaluationByStudent(evaluationId, courseId));
         }
       }
    };
   
    fetchCoursesAndEvaluations().catch(error => {
       console.error("Failed to fetch courses or evaluations:", error);
    });
   }, [evaluationId, courseId, dispatch]);
   
   
  useEffect(() => {
    if (coursesList && coursesList.length > 0) {
      !courseId && onChange(coursesList[0].id);
    }
  },[]);

  const handleEvaluationDelete = (id) => {
    new Promise((resolve, reject) => {
      dispatch(
        actions.deleteEvaluation(id, evaluationId, courseId, resolve, reject)
      );
    })
      .then(() => {
        setOpenModel(false);
        message.success(evaluationName + " delete Success");
      })
      .catch(() => {
        message.error(evaluationName + " delete fails ");
      })
      .finally(() => {
        setOpenModel(false);
      });
  };

  const columns = [
    {
      title: "S.N.",
      render: (_, __, index) => <span>{index + 1}</span>,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text) => <div dangerouslySetInnerHTML={{ __html: text }} />,
    },
    {
      title: "Course",
      render: (_, record) => record.course.name,
    },
    {
      title: "Students",
      render: (_, record) => (
        <ul>
          {record.students.map((student) => (
            <li
              key={student.id}
            >{`${student.first_name} ${student.last_name}`}</li>
          ))}
        </ul>
      ),
    },

    {
      title: "Deadline",
      render: (_, record) => formatDeadline(record.deadline).formattedDate1,
    },
    {
      title: `${evaluationName} Completion Rate`,
      render: (_, record) => (<Progress percent={record.summary.rate} size="small" strokeColor={tokens.COLOR_SECONDARY}/>),
    },
    {
      title:
        hasPermission("edit_evaluation") || hasPermission("delete_evaluation")
          ? "Action"
          : null,
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {hasPermission("edit_evaluation") && (
            <AButton
              type="primary"
              size="middle"
              style={{
                color: tokens.BG_LIGHT,
                backgroundColor: tokens.COLOR_SUCCESS,
              }}
              onClick={(e) => {
                e.stopPropagation();
                navigate(
                  `/evaluation/edit/${record.id}/${evaluationId}/${evaluationName}`
                );
              }}
            >
              Edit
            </AButton>
          )}
          {hasPermission("delete_evaluation") && (
            <AButton
              type="default"
              size="middle"
              style={{
                color: tokens.COLOR_DANGER,
                borderColor: tokens.COLOR_DANGER,
                margin: "10px",
              }}
              onClick={(e) => {
                e.stopPropagation();
                setOpenModel(true);
                setSelectedId(record.id);
              }}
            >
              Delete
            </AButton>
          )}
        </div>
      ),
    },
  ];

  const columnsForStudents = [
    {
      title: "S.N.",
      render: (_, __, index) => <span>{index + 1}</span>,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Status",
      key: "status",
      render: (value) => {
        return value.status === 1 ? (
          <ATag type="active"> Submitted</ATag>
        ) : (
          <ATag type="pending"> Not Submitted</ATag>
        );
      },
    },
  ];
  const [openDrawer, setOpenDrawer] = useState(false);
  const showDrawer = () => {
    setOpenDrawer(true);
  };

  const onChange = (courseId) => {
    localStorage.setItem("selectedCourse", courseId);
    setCourseId(courseId);
    // new Promise((resolve, reject) => {
    //   dispatch(actions.updateCourseIdInRedux(courseId, resolve, reject));
    // }).finally(() => {});
  };
  if (coursesList && coursesList.length <= 0) {
    return (
      <div>
        <Space
          style={{
            minWidth: "100%",
            minHeight: "80vh",
            justifyContent: "center",
            fontFamily: tokens.SECONDARY_FONT_FAMILY,
          }}
          align="center"
        >
          <Space direction="vertical" align="center">
            <ClockCircleOutlined style={{ fontSize: "24pt" }} />
            <ATitle level={4} content={`No any Course Assigned for you yet`} />
          </Space>
        </Space>
      </div>
    );
  } else if (evaluations || evaluationsByStudent) {
    return (
      <div>
        <Space
          style={{
            display: "flex",
            justifyContent: "flex-end",
            marginBottom: "2vh",
          }}
        >
          <div
            style={{
              position: "relative",
            }}
          >
            <AText
              style={{
                position: "absolute",
                top: "-12px",
                left: "22px",
                backgroundColor: "#fff",
                padding: "0 4px",
                color: tokens.COLOR_PRIMARY,
                zIndex: 1,
              }}
            >
              Select Course
            </AText>
            <ASelect
              customized={false}
              placeholder="Choose Course"
              size="large"
              defaultValue={coursesList && coursesList[0] && coursesList[0].id}
              onChange={onChange}
              style={{ marginLeft: 6, border: null }}
            >
              {coursesList &&
                coursesList.map((element) => (
                  <Select.Option value={element.id} key={element.id}>
                    {element.name}
                  </Select.Option>
                ))}
            </ASelect>
          </div>
        </Space>


        <Space
          style={{
            width: "100%",
            justifyContent: "flex-end",
            marginBottom: "30px",
          }}
        >
          <AInput
            suffix={<SearchOutlined />}
            placeholder="Search"
            name="search"
            size="large"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <AButton
            type="primary"
            icon={<FilterOutlined />}
            size="large"
            onClick={showDrawer}
            style={{ backgroundColor: tokens.COLOR_LIGHT_ORANGE }}
          >
            Filter
          </AButton>
        </Space>
        {hasPermission("add_evaluation") && (
          <Space
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "30px",
            }}
          >
            <Link to={`/evaluation/add/${evaluationId}/${evaluationName}`}>
              <AButton
                type="primary"
                icon={<PlusOutlined />}
                size="large"
                style={{ backgroundColor: tokens.COLOR_DARK_ORANGE }}
              >
                Add New {evaluationName}
              </AButton>
            </Link>
          </Space>
        )}

        {filteredEvaluations &&
        filteredEvaluations.length === 0 &&
        searchTerm == "" ? (
          <Space
            style={{
              minWidth: "100%",
              minHeight: "80vh",
              justifyContent: "center",
              fontFamily: tokens.SECONDARY_FONT_FAMILY,
            }}
            align="center"
          >
            <Space direction="vertical" align="center">
              <ClockCircleOutlined style={{ fontSize: "24pt" }} />
              <ATitle
                level={4}
                content={`You don't have any ${evaluationName} yet`}
              />
              {hasPermission("add_evaluation") && (
                <div
                  style={{
                    border: `1px dashed ${tokens.COLOR_PRIMARY}`,
                    padding: "30px",
                    borderRadius: "5px",
                  }}
                >
                  <AButton
                    type="default"
                    icon={<PlusOutlined />}
                    style={{
                      color: tokens.COLOR_PRIMARY,
                      borderColor: tokens.COLOR_PRIMARY,
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(
                        `/evaluation/add/${evaluationId}/${evaluationName}`
                      );
                    }}
                  >
                    {`Add New ${evaluationName}`}
                  </AButton>
                </div>
              )}
            </Space>
          </Space>
        ) : hasPermission("view_course_as_instructor") ? (
          <ATable
            columns={columns}
            dataSource={filteredEvaluations}
            onRow={(record, rowIndex) => {
              return {
                onClick: ({ event }) => {
                  navigate(
                    `/evaluation/details/${record.id}/${evaluationId}/${evaluationName}`
                  );
                }, // click row
                onDoubleClick: (event) => {
                  navigate(
                    `/evaluation/details/${record.id}/${evaluationId}/${evaluationName}`
                  );
                }, // double click row
              };
            }}
          />
        ) : hasPermission("view_course_as_student") &&
          (evaluationsByStudent && evaluationsByStudent.length > 0) ? (
          <ATable
            columns={columnsForStudents}
            dataSource={filteredEvaluationsByStudents}
            onRow={(record, rowIndex) => {
              return {
                onClick: ({ event }) => {
                  navigate(
                    `/evaluation/details/${record.id}/${evaluationId}/${evaluationName}`
                  );
                }, // click row
                onDoubleClick: (event) => {
                  navigate(
                    `/evaluation/details/${record.id}/${evaluationId}/${evaluationName}`
                  );
                }, // double click row
              };
            }}
          />
        ) : (
          <Space
            style={{
              minWidth: "100%",
              minHeight: "80vh",
              justifyContent: "center",
              fontFamily: tokens.SECONDARY_FONT_FAMILY,
            }}
            align="center"
          >
            <Space direction="vertical" align="center">
              <ClockCircleOutlined style={{ fontSize: "24pt" }} />
              <ATitle
                level={4}
                content={`You don't have any ${evaluationName} yet`}
              />
              {hasPermission("add_evaluation") && (
                <div
                  style={{
                    border: `1px dashed ${tokens.COLOR_PRIMARY}`,
                    padding: "30px",
                    borderRadius: "5px",
                  }}
                >
                  <AButton
                    type="default"
                    icon={<PlusOutlined />}
                    style={{
                      color: tokens.COLOR_PRIMARY,
                      borderColor: tokens.COLOR_PRIMARY,
                    }}
                    onClick={(e) => {
                      e.stopPropagation();
                      navigate(
                        `/evaluation/add/${evaluationId}/${evaluationName}`
                      );
                    }}
                  >
                    {`Add New ${evaluationName}`}
                  </AButton>
                </div>
              )}
            </Space>
          </Space>
        )}

        <AModal
          style={{
            padding: "50px",
          }}
          open={openModel}
          closable={false}
          footer={null}
          className="css-dev-only-do-not-override-1r3vfrt-last"
        >
          <Space
            align="center"
            direction="vertical"
            style={{
              display: "flex",
              alignContent: "center",
            }}
          >
            <Space direction="vertical" size="small" align="center">
              <WarningFilled
                style={{
                  fontSize: "65px",
                  color: token.colorError,
                }}
              />
              <ATitle content="Are you sure ?" level={4} />
              <p
                style={{
                  textAlign: "center",
                  width: "100%",
                  marginTop: "-5px",
                }}
              >
                Deleting a {evaluationName} will delete all the submitted
                answers . This process cannot be undo.
              </p>
            </Space>
            <Space>
              <AButton
                type="default"
                size="small"
                onClick={() => setOpenModel(false)}
              >
                Cancel
              </AButton>
              <AButton
                type="primary"
                size="small"
                onClick={() => handleEvaluationDelete(selectedId)}
                mode="danger"
              >
                Delete
              </AButton>
            </Space>
          </Space>
        </AModal>
      </div>
    );
  } else {
    return <Loader />;
  }
}
export default Evaluation;
