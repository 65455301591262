const actions = {
  FETCH_TIMESLOTS: "FETCH_TIMESLOTS",
  FETCH_TIMESLOTS_SUCCESS: "FETCH_TIMESLOTS_SUCCESS",
  CREATE_TIMESLOT: "CREATE_TIMESLOT",
  CREATE_TIMESLOT_SUCCESS: "CREATE_TIMESLOT_SUCCESS",
  UPDATE_TIMESLOT: "UPDATE_TIMESLOT",
  UPDATE_TIMESLOT_SUCCESS: "UPDATE_TIMESLOT_SUCCESS",
  DELETE_TIMESLOT: "DELETE_TIMESLOT",
  DELETE_TIMESLOT_SUCCESS: "DELETE_TIMESLOT_SUCCESS",


  fetchTimeSlots: (resolve, reject) => ({
    type: actions.FETCH_TIMESLOTS,
    resolve,
    reject,
  }),

  createTimeSlot: (data, resolve, reject) => ({
    type: actions.CREATE_TIMESLOT,
    data,
    resolve,
    reject,
  }),

  updateTimeSlot: (id, data, resolve, reject) => ({
    type: actions.UPDATE_TIMESLOT,
    id,
    data,
    resolve,
    reject,
  }),

  deleteTimeSlot: (id, resolve, reject) => ({
    type: actions.DELETE_TIMESLOT,
    id,
    resolve,
    reject,
  }),
};

export default actions;
