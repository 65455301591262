const actions = {
  FETCH_PROGRAMS: "FETCH_PROGRAMS",
  FETCH_PROGRAMS_SUCCESS: "FETCH_PROGRAMS_SUCCESS",
  SAVE_PROGRAM: "SAVE_PROGRAM",
  SAVE_PROGRAM_SUCCESS: "SAVE_PROGRAM_SUCCESS",
  EDIT_PROGRAM: "EDIT_PROGRAM",
  EDIT_PROGRAM_SUCCESS: "EDIT_PROGRAM_SUCCESS",
  DELETE_PROGRAM: "DELETE_PROGRAM",
  DELETE_PROGRAM_SUCCESS: "DELETE_PROGRAM_SUCCESS",

  FETCH_SEMESTERS: "FETCH_SEMESTERS",
  FETCH_SEMESTERS_SUCCESS: "FETCH_SEMESTERS_SUCCESS",
  SAVE_SEMESTER: "SAVE_SEMESTER",
  SAVE_SEMESTER_SUCCESS: "SAVE_SEMESTER_SUCCESS",
  EDIT_SEMESTER: "EDIT_SEMESTER",
  EDIT_SEMESTER_SUCCESS: "EDIT_SEMESTER_SUCCESS",
  DELETE_SEMESTER: "DELETE_SEMESTER",
  DELETE_SEMESTER_SUCCESS: "DELETE_SEMESTER_SUCCESS",

  fetchPrograms: (resolve, reject) => ({
    type: actions.FETCH_PROGRAMS,
    resolve,
    reject,
  }),

  saveProgram: (data, event, resolve, reject) => ({
    type: actions.SAVE_PROGRAM,
    data,
    event,
    resolve,
    reject,
  }),

  editProgram: (programId, data, event, resolve, reject) => ({
    type: actions.EDIT_PROGRAM,
    programId,
    data,
    event,
    resolve,
    reject,
  }),
  deleteProgram: (programId, resolve, reject) => ({
    type: actions.DELETE_PROGRAM,
    programId: programId,
    resolve: resolve,
    reject: reject,
  }),

  fetchSemesters: (programId, resolve, reject) => ({
    type: actions.FETCH_SEMESTERS,
    programId: programId,
    resolve,
    reject,
  }),

  saveSemester: (data, event, resolve, reject) => ({
    type: actions.SAVE_SEMESTER,
    data,
    event,
    resolve,
    reject,
  }),

  editSemester: (semesterID, data, event, resolve, reject) => ({
    type: actions.EDIT_SEMESTER,
    semesterID,
    data,
    event,
    resolve,
    reject,
  }),
  deleteSemester: (semesterID, resolve, reject) => ({
    type: actions.DELETE_SEMESTER,
    semesterID: semesterID,
    resolve: resolve,
    reject: reject,
  }),
};

export default actions;
