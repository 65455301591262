// Define constants here

// import { SelectProps } from "antd";
// import { StringObject } from "./interface/Utils.interface";

/* 
Themeing variables
*/
export const tokens = {
  HEADING_1: "20",
  HEADING_2: "18",
  HEADING_3: "16",
  LINEHEIGHT_H1: "22.4",
  LINEHEIGHT_H2: "21",
  LINEHEIGHT_H3: "19.6",
  PARAGRAPH: "14",
  FONT_FAMILY: "'Poppins', sans-serif",
  THEME_COLOR_HEADING_DISPLAY: "#5E5873",
  BORDER: " 1px solid #EDEDED",
  CARD_BOX_SHADOW: "0px 4px 24px 0px #0000000F",
  COLOR_SUCCESS: "#6EB869",
  COLOR_PENDING: "#F4C249",
  BG_LIGHT: "#FFFFFF",
  COLOR_USERNAME: "#6E6B7B",
  // COLOR_PRIMARY: "#6C559E",
  COLOR_PRIMARY: "#8E5808",
  COLOR_PRIMARY_P50: "#f0eef5",
  COLOR_PRIMARY_P75: "#c3b9d7",
  COLOR_PRIMARY_P100: "#aa9cc7",
  COLOR_PRIMARY_P200: "#8572ae",
  COLOR_PRIMARY_P300: "#6c559e",
  COLOR_PRIMARY_P400: "#4c3b6f",
  COLOR_PRIMARY_P500: "#423460",
  COLOR_SECONDARY: "#f4c249",
  COLOR_SECONDARY_S50: "#fef9ed",
  COLOR_SECONDARY_S75: "#fae6b4",
  COLOR_SECONDARY_S100: "#f9dc95",
  COLOR_SECONDARY_S200: "#f6cc68",
  COLOR_SECONDARY_S300: "#f4c249",
  COLOR_SECONDARY_S400: "#ab8833",
  COLOR_SECONDARY_S500: "#95762d",
  COLOR_GREEN: "#437040",
  COLOR_GREEN_G50: "#f1f8f0",
  COLOR_GREEN_G75: "#c4e2c2",
  COLOR_GREEN_G100: "#87c483",
  COLOR_GREEN_G200: "#87c483",
  COLOR_GREEN_G300: "#6eb869",
  COLOR_GREEN_G400: "#4d814a",
  COLOR_GREEN_G500: "#437040",
  COLOR_DANGER: "#921000",
  COLOR_DANGER_D50: "#fae8e6",
  COLOR_DANGER_D75: "#eca096",
  COLOR_DANGER_D100: "#e4786b",
  COLOR_DANGER_D200: "#d93e2b",
  COLOR_DANGER_D300: "#d11700",
  COLOR_DANGER_D400: "#921000",
  COLOR_DANGER_D500: "#7f0e00",
  COLOR_LIGHTBLACK: "#2b2b2c",
  COLOR_LIGHTBLACK_L50: "#ececec",
  COLOR_LIGHTBLACK_L75: "#b0b0b0",
  COLOR_LIGHTBLACK_L100: "#8f8f90",
  COLOR_LIGHTBLACK_L200: "#5f5f60",
  COLOR_LIGHTBLACK_L300: "#3e3e3f",
  COLOR_LIGHTBLACK_L400: "#2b2b2c",
  COLOR_LIGHTBLACK_L500: "#262626",
  COLOR_NEUTRAL: "#565656",
  COLOR_NEUTRAL_N0: "#f0eef5",
  COLOR_NEUTRAL_N10: "#fbfbfb",
  COLOR_NEUTRAL_N20: "#f6f6f6",
  COLOR_NEUTRAL_N30: "#ededed",
  COLOR_NEUTRAL_N40: "#e2e2e3",
  COLOR_NEUTRAL_N50: "#c8c8c8",
  COLOR_NEUTRAL_N60: "#bbbbbb",
  COLOR_NEUTRAL_N70: "#b0b0b0",
  COLOR_NEUTRAL_N80: "#a3a3a3",
  COLOR_NEUTRAL_N90: "#959596",
  COLOR_NEUTRAL_N100: "#888889",
  COLOR_NEUTRAL_N200: "#7b7b7c",
  COLOR_NEUTRAL_N300: "#6e6e6e",
  COLOR_NEUTRAL_N400: "#636364",
  COLOR_NEUTRAL_N500: "#565656",
  COLOR_NEUTRAL_N600: "#4b4b4b",
  COLOR_NEUTRAL_N700: "#3b3b3c",
  COLOR_NEUTRAL_N800: "#2e2e2f",
  COLOR_NEUTRAL_N900: "#232324",
  COLOR_DARK_ORANGE: "#8E5808",
  COLOR_LIGHT_ORANGE: "#F3AE48",
  TABLE_BOX_SADOW: "box-shadow: 0px 4px 5px 0px #4C3B6F1A",
  COLOR_SIDEBAR: "#488DF3",
};

// Role constants
export const roleNames = {
  SUPER_ADMIN: "Super Admin",
  ADMIN: "admin",
  INSTRUCTOR: "instructor",
  PARENT: "parent",
  CHILD: "child",
};

export const days = [
  { label: "Sunday", value: "sun" },
  { label: "Monday", value: "mon" },
  { label: "Tuesday", value: "tue" },
  { label: "Wednesday", value: "wed" },
  { label: "Thursday", value: "thu" },
  { label: "Friday", value: "fri" },
  { label: "Saturday", value: "sat" },
];

export const daysLabel = ["", "Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

export const monthsLabel = [
  "",
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "Jun",
  "Jul",
  "Aug",
  "Sep",
  "Oct",
  "Nov",
  "Dec",
];
export const stateName = [
  {
    id: 1,
    value: "Koshi",
  },
  {
    id: 2,
    value: "Madhesh",
  },
  {
    id: 3,
    value: "Bagmati",
  },
  {
    id: 4,
    value: "Gandaki",
  },
  {
    id: 5,
    value: "Lumbini",
  },
  {
    id: 6,
    value: "Karnali",
  },
  {
    id: 7,
    value: "Sudurpashchim",
  },
];

export const Roles = {
  ROLE_SUPER_ADMIN: "Super Admin",
  ROLE_ADMIN: "Admin Role",
  ROLE_TEACHER: "ROLE_TEACHER",
  ROLE_STUDENT: "ROLE_STUDENT",
};
