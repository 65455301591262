import SuperAdminsSaga from "./sagas";
import SuperAdminsReducer from "./reducers";
import superadminsActions from "./actions";

export const superadminsReducer = {
  superadminsReducer: SuperAdminsReducer,
};

export const superadminsSaga = [SuperAdminsSaga()];

export { superadminsActions };
