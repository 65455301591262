import React, { useEffect, useState } from "react";
import ViewTeacherStudent from "../../../Components/ViewInstructorStudent";
import AddEditStudent from "../../../Components/AddStudent";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import actions from "../store/actions";
import progamActions from "../../Program/store/actions";
import { message } from "antd";
import Loader from "../../../Components/Loader";
function EditStudent() {
  const handleSave = {};
  const data = {
    id: 1,
    firstName: "Yugesh",
    middleName: "",
    lastName: "Marahatta",
    contactNumber: "89749832",
    email: "yugeshmarahatta@gmail.com",
    gender: "Male",
    address1: "Lainchoue",
    city: "Kathmandu",
    state: "Bagmati",
    qualification: "Msc IT",
  };
  const { id } = useParams();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [saving, setSaving] = useState(false);

  const student = useSelector(({ studentsReducer }) => {
    const students = studentsReducer.get("students");
    return students.find((student) => {
      return student.id === parseInt(id);
    });
  });

  useEffect(() => {
    if (!student) {
      new Promise((resolve, reject) => {
        dispatch(actions.fetchStudents(resolve, reject));
      })
        .then((response) => {})
        .finally(() => {});
    }
  }, [student]);

  const handleEdit = (value) => {
    setSaving(true);
    const data = {
      ...value,
    };

    new Promise((resolve, reject) => {
      dispatch(
        actions.editStudent(parseInt(id), data, "EDIT", resolve, reject)
      );
    })
      .then(() => {
        message.success("Student edited Sucessfully");
        navigate(`/student/details/${id}`);
      })
      .catch(() => {
        message.error("Student edit failed");
      })
      .finally(() => {
        setSaving(false);
      });
  };
  if (student) {
    return (
      <AddEditStudent
        loading={saving}
        handleSave={handleEdit}
        content={"Edit Student"}
        data={student}
      />
    );
  } else {
    return <Loader />;
  }
}
export default EditStudent;
