import { Col, Form, Row, Space, message } from "antd";
import AddEditRole from "../../../Components/AddRole";
import React, { useState } from "react";
import AInput from "../../../Components/Input";
import AButton from "../../../Components/Button";
import { useNavigate } from "react-router";
import { tokens } from "../../../utils/Constants";
import { useDispatch } from "react-redux";
import actions from "../store/actions";
import { validateRoleCode } from "../../../utils/Helper";
function AddRole() {
  const [saving, setSaving] = useState(false);

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [roleCode, setRoleCode] = useState("");

  const handleSave = (values) => {
    setSaving(true);
    const data = {
      ...values,
    };
    new Promise((resolve, reject) => {
      dispatch(actions.saveRoles(data, "ADD", resolve, reject));
    })
      .then(() => {
        message.success("Role add successfully");
        navigate("/role");
      })
      .catch((error) => {
        message.error("Role already exist or something wrong !!!");
      })
      .finally(() => {
        setSaving(false);
      });
  };

  return (
    <div>
      <AddEditRole handleSave={handleSave} />
    </div>
  );
}
export default AddRole;
