import { Col, Form, Row, Select, Space, message } from "antd";
import AInput from "../../../Components/Input";
import AButton from "../../../Components/Button";
import { tokens } from "../../../utils/Constants";

import ASelect from "../../../Components/Select";
import { useState, useEffect } from "react";
import QuillEditor from "../../../Components/Editor";
import React from "react";
import { useNavigate } from "react-router";
// import "../../pages/Program/index.css";
import { batch, useDispatch, useSelector } from "react-redux";
import batchActions from "../../Batch/store/actions";
import actions from "../store/actions";

const AddEditNotice = ({
  handleSave,
  notice,
  handleDelete,
  saving = false,
  deleting = false,
}) => {
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [mediaUrl, setMediaUrl] = useState("");
  const [coursePhotoUrl, setCoursePhotoUrl] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [editorContent, setEditorContent] = useState("");
  const dispatch = useDispatch();

  const handleEditorChange = (content) => {};

  const handleProgramDelete = (id) => {
    new Promise((resolve, reject) => {
      dispatch(actions.deleteNotice(id, resolve, reject));
    })
      .then(() => {
        message.success("Notice delete Success");
        navigate("/notice");
      })
      .catch(() => {
        message.error("Notice delete fails ");
      })
      .finally(() => {});
  };

  let batches = useSelector(({ batchesReducer }) =>
    batchesReducer.get("batches")
  );

  useEffect(() => {
    setLoading(true);
    new Promise((resolve, reject) => {
      dispatch(batchActions.fetchBatches(resolve, reject));
    }).finally(() => {
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  return (
    <div style={{ padding: "1%" }}>
      {/* <ATitle
        content={notice ? "Edit Program" : "Add Program "}
        style={{ fontSize: "18px" }}
      /> */}
      <Form
        size="large"
        form={form}
        name="add/editForm"
        onFinish={(values) => {
          values.file = mediaUrl || coursePhotoUrl;
          handleSave(values);
        }}
        layout="vertical"
        scrollToFirstError
        title="Add/EditProgram"
        initialValues={
          notice
            ? {
                title: notice.title,
                description: notice.description,
                batch_id: notice ? notice.batch.id : null,
              }
            : {}
        }
      >
        <Row gutter={20}>
          <Col xs={24} md={10}>
            <Form.Item
              name="title"
              label={
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "19.6px",
                  }}
                >
                  Notice Title
                </p>
              }
              rules={[
                {
                  required: true,
                  message: "Please input the Notice Title!",
                },
              ]}
            >
              <AInput placeholder={"Enter Notice Title"} size="large" />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col xs={24} md={24}>
            <Form.Item
              name="description"
              label={
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "19.6px",
                  }}
                >
                  Notice Description
                </p>
              }
              rules={[
                {
                  required: true,
                  message: "Please input the Description!",
                },
              ]}
            >
              <QuillEditor
                className="toolbar"
                style={{ fontSize: tokens.FONT_FAMILY }}
                value={editorContent}
                onChange={handleEditorChange}
              />
            </Form.Item>
          </Col>
        </Row>

        <Row>
          <Col xs={24} md={10}>
            <Form.Item
              name="batch_id"
              label={
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "19.6px",
                  }}
                >
                  Batch
                </p>
              }
              rules={[
                {
                  required: true,
                  message: "Please select at least one batch",
                },
              ]}
            >
              <ASelect
                customized={false}
                placeholder="Choose Batch"
                mode="multiple"
              >
                {batches &&
                  batches.map((element) => (
                    <Select.Option value={element.id}>
                      {element.name}
                    </Select.Option>
                  ))}
              </ASelect>
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          <div
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "space-between",
            }}
          >
            {notice ? (
              <AButton
                type="default"
                size="middle"
                style={{
                  color: tokens.COLOR_DANGER,
                  borderColor: tokens.COLOR_DANGER,
                  margin: "10px",
                }}
                onClick={(e) => {
                  handleDelete(notice.id);
                }}
              >
                Delete
              </AButton>
            ) : (
              <AButton
                type="default"
                size="large"
                onClick={() => navigate("/notice")}
                style={{
                  backgroundColor: tokens.COLOR_LIGHTBLACK_L50,
                }}
              >
                Cancel
              </AButton>
            )}

            <AButton
              type="primary"
              htmlType="submit"
              style={{
                color: tokens.BG_LIGHT,
                backgroundColor: tokens.COLOR_LIGHT_ORANGE,
              }}
            >
              {notice ? "Update Notice" : "Add Notice"}
            </AButton>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddEditNotice;
