import AssignTimeSlotsSaga from "./sagas";
import AssignTimeSlotsReducer from "./reducers";
import assignTimeSlotsActions from "./actions";

export const assignTimeSlotsReducer = {
  assignTimeSlotsReducer: AssignTimeSlotsReducer,
};

export const assignTimeSlotsSaga = [AssignTimeSlotsSaga()];

export { assignTimeSlotsActions };
