import ExamsSaga from "./sagas";
import ExamsReducer from "./reducers";
import examsActions from "./actions";

export const examsReducer = {
  examsReducer: ExamsReducer,
};

export const examsSaga = [ExamsSaga()];

export { examsActions };
