import actions from "./actions";
import { fromJS } from "immutable";
import { combineReducers } from "redux";

const initialState = fromJS({
  instructors: [],
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_INSTRUCTORS_SUCCESS:
      return state.set("instructors", action.payload);

    case actions.SAVE_INSTRUCTOR_SUCCESS:
      return state.set("instructors", action.payload);

    case actions.UPLOAD_INSTRUCTOR_SUCCESS:
      return state.set("instructors", action.payload);

    case actions.EDIT_INSTRUCTOR_SUCCESS:
      return state.set("instructors", action.payload);

    case actions.DELETE_INSTRUCTOR_SUCCESS:
      return state.set("instructors", action.payload);

    default:
      return state;
  }
};

export default reducer;
