import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import AddEditProgram from "../../../Components/AddEditProgram/index";
import { useDispatch, useSelector } from "react-redux";
import actions from "../store/actions";
import { message } from "antd";
import Loader from "../../../Components/Loader";
import PageNotFound from "../../PageNotFound/index";

function ViewNotice() {
  const [saving, setSaving] = useState(false);

  const { id } = useParams();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  let notice = useSelector(({ noticeReducer }) => noticeReducer.get("notice"));
  useEffect(() => {
    if (!notice) {
      new Promise((resolve, reject) => {
        dispatch(actions.fetchNotice(parseInt(id), resolve, reject));
      }).finally(() => {});
    }
  }, [notice]);

  const handleEdit = (value) => {
    setSaving(true);
    const data = {
      ...value,
    };

    new Promise((resolve, reject) => {
      dispatch(actions.editNotice(parseInt(id), data, "EDIT", resolve, reject));
    })
      .then(() => {
        message.success("Program edited Sucessfully");
        navigate(`/notice`);
      })
      .catch(() => {
        message.error("Program edit failed");
      })
      .finally(() => {
        setSaving(false);
      });
  };

  // if (!notice) {
  //   <Loader />;
  // }
  return (
    <div>
      {/* {notice ? <AddEditNotice handleSave={handleEdit} /> : <Loader />} */}
      <PageNotFound />
    </div>
  );
}

export default ViewNotice;
