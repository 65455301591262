const actions = {
  FETCH_EXAMS: "FETCH_EXAMS",
  FETCH_EXAMS_SUCCESS: "FETCH_EXAMS_SUCCESS",
  FETCH_EXAM_SUBJECT_BY_CLASS: "FETCH_EXAM_SUBJECT_BY_CLASS",
  FETCH_EXAMS_SUBJECT_BY_CLASS_SUCCESS: "FETCH_EXAMS_SUBJECT_BY_CLASS_SUCCESS",
  SAVE_EXAMS: "SAVE_EXAMS",
  SAVE_EXAMS_SUCCESS: "SAVE_EXAMS_SUCCESS",
  EDIT_EXAMS: "EDIT_EXAMS",
  EDIT_EXAMS_SUCCESS: "EDIT_EXAMS_SUCCESS",
  DELETE_EXAMS: "DELETE_EXAMS",
  DELETE_EXAMS_SUCCESS: "DELETE_EXAMS_SUCCESS",

  fetchExams: (resolve, reject) => ({
    type: actions.FETCH_EXAMS,
    resolve,
    reject,
  }),

  fetchExamSubjectByClass: (classId, resolve, reject) => ({
    type: actions.FETCH_EXAM_SUBJECT_BY_CLASS,
    classId,
    resolve,
    reject,
  }),

  saveExams: (data, event, resolve, reject) => ({
    type: actions.SAVE_EXAMS,
    data,
    event,
    resolve,
    reject,
  }),

  editExams: (examId, data, event, resolve, reject) => ({
    type: actions.EDIT_EXAMS,
    examId,
    data,
    event,
    resolve,
    reject,
  }),
  deleteExams: (examId, resolve, reject) => ({
    type: actions.DELETE_EXAMS,
    examId: examId,
    resolve: resolve,
    reject: reject,
  }),
};

export default actions;
