import { takeLatest, put, call, all } from "redux-saga/effects";
import Api from "../../../services/api";
import actions from "./actions";

const api = new Api();

const fetchRoles = takeLatest(actions.FETCH_ROLES, function* (action) {
  try {
    const response = yield call(api.get, "api/roles");
    yield put({
      type: actions.FETCH_ROLES_SUCCESS,
      payload: response,
    });
    yield call(action.resolve, "success");
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

const addRoles = takeLatest(actions.SAVE_ROLES, function* (action) {
  try {
    const response = yield call(api.put, "api/role/add", action.data, null);
    action.data.id = response.id;
    yield put({
      type: actions.SAVE_ROLES_SUCCESS,
      payload: response,
    });

    yield call(action.resolve, "success");
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

const editRoles = takeLatest(actions.EDIT_ROLES, function* (action) {
  try {
    const response = yield call(
      api.put,
      `api/role/${action.roleId}`,
      action.data,
      null
    );
    yield call(action.resolve, "success");
    yield put({
      type: actions.EDIT_ROLES_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

const editPermissions = takeLatest(actions.EDIT_PERMISSIONS, function* (action) {
  try {
    const response = yield call(
      api.put,
      `api/permission/${action.roleId}`,
      action.data,
      null
    );
    yield call(action.resolve, "success");
    yield put({
      type: actions.EDIT_PERMISSIONS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

const deleteRoles = takeLatest(actions.DELETE_ROLES, function* (action) {
  try {
    const responseId = yield call(
      api.delete,
      `api/role/${action.roleId}`
    );
    yield put({
      type: actions.DELETE_ROLES_SUCCESS,
      id: action.id,
    });

    yield call(action.resolve, responseId);
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

const fetchPermissions = takeLatest(actions.FETCH_PERMISSIONS, function* (action) {
  try {
    const response = yield call(api.get, "api/permissions");
    yield put({
      type: actions.FETCH_PERMISSIONS_SUCCESS,
      payload: response,
    });
    yield call(action.resolve, "success");
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

const fetchRolePermissions = takeLatest(actions.FETCH_ROLE_PERMISSIONS, function* (action) {
  try {
    const response = yield call(api.get, "api/role/permission");
    yield put({
      type: actions.FETCH_ROLE_PERMISSIONS_SUCCESS,
      payload: response,
    });
    yield call(action.resolve, "success");
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

export default function* saga() {
  yield all([
    fetchRoles,
    addRoles,
    deleteRoles,
    editRoles,
    fetchPermissions,
    editPermissions,
    fetchRolePermissions,
  ]);
}
