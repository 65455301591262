import { Input } from "antd";
import React from "react";
function AInput({ type, placeholder, bordered, customized, ...others }) {
  const commonStyle = {
    borderBottom: "1px solid black",
    borderRadius: 0,
    fontSize: "12px",
  };

  const inputComponent =
    type === "password" ? (
      <Input.Password
        placeholder={placeholder}
        bordered={bordered}
        {...others}
        style={customized ? commonStyle : undefined}
      />
    ) : (
      <Input
        placeholder={placeholder}
        bordered={bordered}
        {...others}
        style={customized ? commonStyle : { fontSize: "14px" }}
      />
    );

  return inputComponent;
}

export default AInput;
