const actions = {
  FETCH_INSTRUCTORS: "FETCH_INSTRUCTORS",
  FETCH_INSTRUCTORS_SUCCESS: "FETCH_INSTRUCTORS_SUCCESS",
  SAVE_INSTRUCTOR: "SAVE_INSTRUCTOR",
  SAVE_INSTRUCTOR_SUCCESS: "SAVE_INSTRUCTOR_SUCCESS",
  UPLOAD_INSTRUCTOR: "UPLOAD_INSTRUCTOR",
  UPLOAD_INSTRUCTOR_SUCCESS: "UPLOAD_INSTRUCTOR_SUCCESS",
  EDIT_INSTRUCTOR: "EDIT_INSTRUCTOR",
  EDIT_INSTRUCTOR_SUCCESS: "EDIT_INSTRUCTOR_SUCCESS",
  DELETE_INSTRUCTOR: "DELETE_INSTRUCTOR",
  DELETE_INSTRUCTOR_SUCCESS: "DELETE_INSTRUCTOR_SUCCESS",

  fetchInstructors: (resolve, reject) => ({
    type: actions.FETCH_INSTRUCTORS,
    resolve,
    reject,
  }),

  saveInstructor: (data, event, resolve, reject) => ({
    type: actions.SAVE_INSTRUCTOR,
    data,
    event,
    resolve,
    reject,
  }),

  uploadInstructor: (data, resolve, reject) => ({
    type: actions.UPLOAD_INSTRUCTOR,
    data,
    resolve,
    reject,
  }),

  editInstructor: (userId, data, event, resolve, reject) => ({
    type: actions.EDIT_INSTRUCTOR,
    userId,
    data,
    event,
    resolve,
    reject,
  }),
  deleteInstructor: (userId, resolve, reject) => ({
    type: actions.DELETE_INSTRUCTOR,
    userId: userId,
    resolve: resolve,
    reject: reject,
  }),
};

export default actions;
