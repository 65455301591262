const actions = {
    FETCH_ASSIGN_COURSE: "FETCH_ASSIGN_COURSE",
    FETCH_ASSIGN_COURSE_SUCCESS: "FETCH_ASSIGN_COURSE_SUCCESS",
    FETCH_ASSIGN_COURSES: "FETCH_ASSIGN_COURSES",
    FETCH_ASSIGN_COURSES_SUCCESS: "FETCH_ASSIGN_COURSES_SUCCESS",
    SAVE_ASSIGN_COURSE: "SAVE_ASSIGN_COURSE",
    SAVE_ASSIGN_COURSE_SUCCESS: "SAVE_ASSIGN_COURSE_SUCCESS",
    EDIT_ASSIGN_COURSE: "EDIT_ASSIGN_COURSE",
    EDIT_ASSIGN_COURSE_SUCCESS: "EDIT_ASSIGN_COURSE_SUCCESS",
    DELETE_ASSIGN_COURSE: "DELETE_ASSIGN_COURSE",
    DELETE_ASSIGN_COURSE_SUCCESS: "DELETE_ASSIGN_COURSE_SUCCESS",
  
    fetchAssignCourses: (resolve, reject) => ({
      type: actions.FETCH_ASSIGN_COURSES,
      resolve,
      reject,
    }),

    fetchAssignCourse: (id, resolve, reject) => ({
      type: actions.FETCH_ASSIGN_COURSE,
      id,
      resolve,
      reject,
    }),
  
    saveAssignCourse: (data, event, resolve, reject) => ({
      type: actions.SAVE_ASSIGN_COURSE,
      data,
      event,
      resolve,
      reject,
    }),
  
    editAssignCourse: (data, resolve, reject) => ({
      type: actions.EDIT_ASSIGN_COURSE,
      data,
      resolve,
      reject,
    }),
    deleteAssignCourse: (assignId, resolve, reject) => ({
      type: actions.DELETE_ASSIGN_COURSE,
      assignId,
      resolve,
      reject,
    }),
  
  };
  
  export default actions;
  