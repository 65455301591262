import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import AddEditCourse from "../../../Components/AddEditCourse";
import { useDispatch, useSelector } from "react-redux";
import actions from "../store/actions";
import { message } from "antd";
import Loader from "../../../Components/Loader";

function EditCourse() {

  const { id } = useParams();
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [semesterId, setSemesterId] = useState();

  const course = useSelector(({ coursesReducer }) => {
    const courses = coursesReducer.get("courses");
    return courses.find((course) => {
      return course.id == parseInt(id);
    });
  });

  useEffect(() => {
    if (!course) {
      new Promise((resolve, reject) => {
        dispatch(actions.fetchCourses(resolve, reject));
      })
        .then((response) => {})
        .finally(() => {});
    }
  }, [course]);

  let navigate = useNavigate();

  const handleEdit = (value) => {
    setSaving(true);

    const data = {
      ...value,
    };

    new Promise((resolve, reject) => {
      dispatch(actions.editCourses(course.id, data, "ADD", resolve, reject));
    })
      .then(() => {
        message.success("Course edited Sucessfully");
        setUpdateSuccess(true);
        navigate(semesterId ? `/semester/details/${semesterId}`: `/course/details/${id}`);
      })
      .catch(() => {
        message.error("Course edit failed");
      })
      .finally(() => {
        setSaving(false);
        setSemesterId();
      });
  };

  if (!course || saving) {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Loader />
      </div>
    );
  }
  return (
    <div>
      <AddEditCourse
        handleSave={handleEdit}
        course={course}
        handlePath={setSemesterId}
      />
    </div>
  );
}

export default EditCourse;
