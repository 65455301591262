import React from "react";
import { message } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import axios from "axios";
import AButton from "../Button";
import { tokens } from "../../utils/Constants";


const FileDownload = ({
  url,
  fileName,
  style,
  icon,
  type = "s3",
}) => {
  const handleDownload = async () => {
    try {
      const response = await axios.get(url, {
        responseType: "blob",
      });

      const downloadUrl = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", fileName || "file");
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
      window.URL.revokeObjectURL(downloadUrl);
    } catch (error) {
      message.error("Failed to download the file");
    }
  };

  return type !== "stripe_invoice" ? (
    <AButton
      type="primary"
      style={{ backgroundColor: tokens.COLOR_SUCCESS, ...style }}
      onClick={handleDownload}
      icon={icon}
    >
      Download
    </AButton>
  ) : (
    <a href={url} target="_blank" rel="noopener noreferrer">
      <AButton
        type="primary"
        style={{ backgroundColor: tokens.COLOR_SUCCESS, ...style }}
        icon={icon}
      >
        Download
      </AButton>
    </a>
  );
};

export default FileDownload;
