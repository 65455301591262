import { Col, Form, Row, Space, message } from "antd";
import React, { useState } from "react";
import AInput from "../../../Components/Input";
import AButton from "../../../Components/Button";
import { useNavigate } from "react-router";
import { tokens } from "../../../utils/Constants";
import { useDispatch } from "react-redux";
import actions from "../store/actions";
import { validateEmail } from "../../../utils/Helper";
function AddSuperAdmin() {
  const [saving, setSaving] = useState(false);

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSave = (values) => {
    setSaving(true);
    const data = {
      ...values,
    };
    new Promise((resolve, reject) => {
      dispatch(actions.saveSuperAdmin(data, "ADD", resolve, reject));
    })
      .then(() => {
        message.success("Super Admin added Sucessfully");
        navigate("/superadmin");
      })
      .catch(() => {
        message.error("Super Admin add failed");
      })
      .finally(() => {
        setSaving(false);
      });
  };

  return (
    <Space>
      <Form
        size="large"
        form={form}
        name="add/editForm"
        onFinish={(values) => {
          handleSave(values);
        }}
        layout="vertical"
        scrollToFirstError
        title="Add Super Admin"
        style={{ width: "80vw" }}
      >
        <Row gutter={20}>
          <Col span={8}>
            <Form.Item
              name="email"
              label={
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "19.6px",
                  }}
                >
                  Email
                </p>
              }
              rules={[
                {
                  required: true,
                  message: "Please input the Email!",
                  validateTrigger: "onFinish",
                },
                {
                  validator: validateEmail,
                },
              ]}
            >
              <AInput placeholder={"Enter Email"} size="large" />
            </Form.Item>
          </Col>

          <Col span={8}></Col>
        </Row>
        <Row>
          <Col span={24}></Col>
        </Row>

        <Form.Item>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20vh",
            }}
          >
            <AButton
              type="default"
              size="middle"
              onClick={() => navigate("/superadmin")}
              style={{
                backgroundColor: tokens.COLOR_LIGHTBLACK_L50,
              }}
            >
              Cancel
            </AButton>
            <AButton
              type="primary"
              htmlType="submit"
              style={{
                color: tokens.BG_LIGHT,
                backgroundColor: tokens.COLOR_LIGHT_ORANGE,
              }}
              loader={saving}
            >
              Create Admin
            </AButton>
          </div>
        </Form.Item>
      </Form>
    </Space>
  );
}
export default AddSuperAdmin;
