import { all } from "redux-saga/effects";
import { collegesSaga } from "../pages/College/store";
import { superadminsSaga } from "../pages/Superadmin/store";
import { semestersSaga } from "../pages/Semester/store";
import { programsSaga } from "../pages/Program/store";
import { instructorsSaga } from "../pages/Instructor/store";
import { studentsSaga } from "../pages/Students/store";
import { batchesSaga } from "../pages/Batch/store";
import { rolesSaga } from "../pages/Roles/store";
import { coursesSaga } from "../pages/Course/store";
import { noticeSaga } from "../pages/Notice/store";
import { assignCoursesSaga } from "../pages/AssignCourse/store";
import { timeSlotSaga } from "../pages/TimeSlot/store";
import { assignTimeSlotsSaga } from "../pages/AssignTimeSlot/store";
import { examsSaga } from "../pages/Exam/store";

import { evaluationCriteriaSaga } from "../pages/EvaluationCriteria/store";
import { batchSessionSaga } from "../pages/ManageBatch/store";
export default function* rootSaga() {
  yield all([
    ...collegesSaga,
    ...superadminsSaga,
    ...semestersSaga,
    ...programsSaga,
    ...instructorsSaga,
    ...studentsSaga,
    ...batchesSaga,
    ...rolesSaga,
    ...coursesSaga,
    ...noticeSaga,
    ...assignCoursesSaga,
    ...evaluationCriteriaSaga,
    ...timeSlotSaga,
    ...assignTimeSlotsSaga,
    ...examsSaga,
    ...batchSessionSaga,
  ]);
}
