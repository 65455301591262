import { takeLatest, put, call, all } from "redux-saga/effects";
import Api from "../../../services/api";
import actions from "./actions";
import { act } from "react-dom/test-utils";

const api = new Api();

const fetchBatchSession = takeLatest(
  actions.FETCH_BATCH_SESSION,
  function* (action) {
    try {
      const isActive = action.isActive;
      const response = yield call(
        api.get,
        `api/batchSession?isActive=${isActive}`
      );
      yield put({
        type: actions.FETCH_BATCH_SESSION_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield call(action.reject, "failed");
    }
  }
);

const fetchBatchInfo = takeLatest(actions.FETCH_BATCH_INFO, function* (action) {
  try {
    const response = yield call(
      api.get,
      `api/batchSessionByBatch/${action.batchId}`
    );
    yield put({
      type: actions.FETCH_BATCH_INFO_SUCCESS,
      payload: response,
    });
    yield call(action.resolve, response);
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

const updateBatch = takeLatest(actions.UPDATE_BATCH, function* (action) {
  try {
    const response = yield call(
      api.post,
      "api/batchSession",
      action.data,
      null
    );
    action.data.id = response.id;
    yield put({
      type: actions.UPDATE_BATCH_SUCCESS,
      payload: response,
    });

    yield call(action.resolve, response);
  } catch (error) {
    yield call(action.reject, error);
  }
});

const fetchBatchDetails = takeLatest(
  actions.FETCH_BATCH_DETAILS,
  function* (action) {
    try {
      const response = yield call(api.get, `api/group/${action.id}`);
      yield put({
        type: actions.FETCH_BATCH_DETAILS_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, response);
    } catch (error) {
      yield call(action.reject, "failed");
    }
  }
);

const endBatch = takeLatest(actions.END_BATCH, function* (action) {
  try {
    const response = yield call(
      api.delete,
      `api/disableAllActiveSemesterClassByBatch/${action.batchId}`
    );
    yield put({
      type: actions.END_BATCH_SUCCESS,
      payload: response,
    });
    yield call(action.resolve, response);
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

export default function* saga() {
  yield all([
    fetchBatchSession,
    fetchBatchInfo,
    updateBatch,
    fetchBatchDetails,
    endBatch,
  ]);
}
