import React, { useEffect, useState } from "react";
import {
  SearchOutlined,
  FilterOutlined,
  PlusOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import { Col, Drawer, Row, Space, message } from "antd";
import AInput from "../../Components/Input";
import AButton from "../../Components/Button/index";
import { Link, useNavigate } from "react-router-dom";
import { tokens } from "../../utils/Constants";
import ATitle from "../../Components/Title";
import ProgramCard from "../../Components/ProgramCard/index";
import ATable from "../../Components/Table";
import { useDispatch, useSelector } from "react-redux";
import actions from "./store/actions";
import Loader from "../../Components/Loader/index";
import { is } from "immutable";

function Notice() {
  const [isLoading, setLoading] = useState(false);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const showDrawer = () => {
    setOpenDrawer(true);
  };

  const onClose = () => {
    setOpenDrawer(false);
  };

  const handleNoticeDelete = (id) => {
    new Promise((resolve, reject) => {
      dispatch(actions.deleteNotice(id, resolve, reject));
    })
      .then(() => {
        setOpenModel(false);
        message.success("Notice delete Success");
      })
      .catch(() => {
        message.error("Notice delete fails ");
      })
      .finally(() => {});
  };

  let notices = useSelector(({ noticeReducer }) =>
    noticeReducer.get("notices")
  );

  useEffect(() => {
    setLoading(true);
    new Promise((resolve, reject) => {
      dispatch(actions.fetchNotiecs(resolve, reject));
    }).finally(() => {
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, openModel]);

  const filteredNotices =
    notices &&
    notices.filter((notice) => {
      return Object.keys(notice).some((key) => {
        if (key !== "id") {
          const value = notice[key];
          return (
            value &&
            value.toString().toLowerCase().includes(searchTerm.toLowerCase())
          );
        }
        return false;
      });
    });

  const columns = [
    {
      title: "S.N.",
      render: (_, __, index) => <span>{index + 1}</span>,
    },
    {
      title: "Notice Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Notice Description",
      dataIndex: "description",
      key: "description",
      render: (text) => <div dangerouslySetInnerHTML={{ __html: text }} />,
    },
    {
      title: "Batch",
      key: "batch.id",
      render: (_, record) => `${record.batch.batchName}`,
    },
    {
      title: "Action",
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            // justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <AButton
            type="primary"
            size="middle"
            style={{
              color: tokens.BG_LIGHT,
              backgroundColor: tokens.COLOR_SUCCESS,
            }}
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/notice/edit/${record.id}`);
            }}
          >
            Edit
          </AButton>
          <AButton
            type="default"
            size="middle"
            loader={openModel}
            style={{
              color: tokens.COLOR_DANGER,
              borderColor: tokens.COLOR_DANGER,
              margin: "10px",
            }}
            onClick={(e) => {
              e.stopPropagation();
              setOpenModel(true);
              handleNoticeDelete(record.id);
            }}
          >
            Delete
          </AButton>
        </div>
      ),
    },
  ];

  if (isLoading) {
    return <Loader />;
  }
  return (
    <div>
      <Drawer
        title="Filter"
        placement={"right"}
        onClose={onClose}
        open={openDrawer}
      ></Drawer>
      <Space
        style={{
          width: "100%",
          justifyContent: "flex-end",
          marginBottom: "30px",
        }}
      >
        <AInput
          suffix={<SearchOutlined />}
          placeholder="Search"
          name="search"
          size="large"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <AButton
          type="primary"
          icon={<FilterOutlined />}
          size="large"
          onClick={showDrawer}
          style={{ backgroundColor: tokens.COLOR_LIGHT_ORANGE }}
        >
          Filter
        </AButton>
      </Space>

      <Space
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "30px",
        }}
      >
        <Link to={"/notice/add"}>
          <AButton
            type="primary"
            icon={<PlusOutlined />}
            size="large"
            style={{ backgroundColor: tokens.COLOR_DARK_ORANGE }}
          >
            Add New Notice
          </AButton>
        </Link>
      </Space>

      {filteredNotices && filteredNotices.length === 0 && searchTerm == "" ? (
        <Space
          style={{
            minWidth: "100%",
            minHeight: "80vh",
            justifyContent: "center",
            fontFamily: tokens.SECONDARY_FONT_FAMILY,
          }}
          align="center"
        >
          <Space direction="vertical" align="center">
            <ClockCircleOutlined style={{ fontSize: "24pt" }} />
            <ATitle level={4} content="You don't have any Notice yet" />
            <div
              style={{
                border: `1px dashed ${tokens.COLOR_PRIMARY}`,
                padding: "30px",
                borderRadius: "5px",
              }}
            >
              <AButton
                type="default"
                icon={<PlusOutlined />}
                style={{
                  color: tokens.COLOR_PRIMARY,
                  borderColor: tokens.COLOR_PRIMARY,
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/notice/add`);
                }}
              >
                Add New Notice
              </AButton>
            </div>
          </Space>
        </Space>
      ) : (
        <ATable
          columns={columns}
          dataSource={filteredNotices}
          onRow={(record, rowIndex) => {
            return {
              onClick: ({ event }) => {}, // click row
              onDoubleClick: (event) => {}, // double click row
            };
          }}
        />
      )}
    </div>
  );
}
export default Notice;
