import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import AddEditProgram from "../../../Components/AddEditProgram/index";
import { useDispatch, useSelector } from "react-redux";
import actions from "../store/actions";
import { message } from "antd";
import Loader from "../../../Components/Loader";

function EditProgram() {
  const [saving, setSaving] = useState(false);

  const { id } = useParams();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const program = useSelector(({ programsReducer }) => {
    const programs = programsReducer.get("programs");
    if (programs) {
      return programs.find((program) => {
        return program.id === parseInt(id);
      });
    }
  });

  useEffect(() => {
    if (!program) {
      new Promise((resolve, reject) => {
        dispatch(actions.fetchPrograms(resolve, reject));
      })
        .then((response) => {})
        .finally(() => {});
    }
  }, [program]);

  useEffect(() => {
    new Promise((resolve, reject) => {
      dispatch(actions.fetchPrograms(resolve, reject));
    }).finally(() => {});

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleEdit = (value) => {
    setSaving(true);
    const data = {
      ...value,
    };

    new Promise((resolve, reject) => {
      dispatch(actions.editProgram(program.id, data, "EDIT", resolve, reject));
    })
      .then(() => {
        message.success("Program edited Sucessfully");
        navigate(`/program`);
      })
      .catch(() => {
        message.error("Program edit failed");
      })
      .finally(() => {
        setSaving(false);
      });
  };

  if (!program) {
    <Loader />;
  }
  return (
    <div>
      {program ? (
        <AddEditProgram handleSave={handleEdit} program={program} />
      ) : (
        <Loader />
      )}
    </div>
  );
}

export default EditProgram;
