import { Col, Form, Row, Select, Space, message } from "antd";
import ATitle from "../Title";
import AInput from "../Input";
import AButton from "../Button/index";
import { tokens } from "../../utils/Constants";
import ASelect from "../Select/index";
import { useState, useEffect } from "react";
import QuillEditor from "../Editor/index";
import Uploads from "../Uploads/index";
import React from "react";
import { useNavigate } from "react-router";
import { useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../pages/Program/store/actions";

const AddEditCourse = ({ handleSave, course, handlePath }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [mediaUrl, setMediaUrl] = useState("");
  const [syllabusUrl, setSyllabusUrl] = useState("");
  const [coursePhotoUrl, setCoursePhotoUrl] = useState(null);
  const [programId, setProgramId] = useState(course ? course.programId : "");
  const [semesterId, setSemesterId] = useState(
    course ? course.semesterClassId : ""
  );
  const [mediaId, setMediaId] = useState();
  const [syllabusMediaId, setSyllabusMediaId] = useState();

  const [editorContent, setEditorContent] = useState(
    course ? course.description : ""
  );

  let [searchParams] = useSearchParams();
  let defaultProgram = searchParams.get("program");
  let defaultSemester = searchParams.get("semester");
  let defaultSemesterId = searchParams.get("semesterId");
  if (defaultSemesterId) {
    handlePath(defaultSemesterId);
  }

  let programs = useSelector(({ programsReducer }) =>
    programsReducer.get("programs")
  );

  useEffect(() => {
    new Promise((resolve, reject) => {
      dispatch(actions.fetchPrograms(resolve, reject));
    }).finally(() => { });
  }, [dispatch]);

  useEffect(() => {
    programId &&
      new Promise((resolve, reject) => {
        dispatch(actions.fetchSemesters(programId, resolve, reject));
      }).finally(() => { });
  }, [dispatch, programId]);

  let semesters = useSelector(({ programsReducer }) =>
    programsReducer.get("semesters")
  );

  const handleProgramChange = (value) => {
    setProgramId(value);
    form.resetFields(["course_class"]);
    form.setFieldsValue({
      course_class: undefined,
    });
  };

  const handleEditorChange = (content) => {
    setEditorContent(content);
  };

  const handleUploadSuccess = (mediaId) => {
    setMediaId(mediaId);
  };

  const handleSyllabusUploadSuccess = (syllabusId) => {
    setSyllabusMediaId(syllabusId);
  };

  return (
    <div style={{ padding: "1%" }}>
      <ATitle
        content={course ? "Edit Course" : "Add Course"}
        style={{ fontSize: "18px" }}
      />
      <Form
        size="large"
        form={form}
        name="add/editForm"
        onFinish={(values) => {
          values.description = editorContent;
          values.course_class = semesterId;
          values.media_id = mediaId;
          values.course_class = defaultSemesterId
            ? defaultSemesterId
            : semesterId;
          values.syllabus_id = syllabusMediaId;
          handleSave(values);
        }}
        layout="vertical"
        scrollToFirstError
        title="Add/EditCourse"
        initialValues={{
          course_name: course ? course.name : "",
          course_code: course ? course.code : "",
          credit_hour: course ? course.creditHour : "",
          course_category: course ? course.category : "",
          course_program: defaultProgram || (course ? course.program : ""),
          course_class: defaultSemester || (course ? course.class : ""),
          description : course ? course.description : ""
        }}
      >
        <Row gutter={20}>
          <Col xs={24} md={8}>
            <Form.Item
              name="course_name"
              label={<p style={{ fontSize: "16px" }}>Course Name</p>}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Please input the Course Name!",
                },
              ]}
            >
              <AInput placeholder={"Enter Course Name"} size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name="course_code"
              label={<p style={{ fontSize: "16px" }}>Course Code</p>}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Please input the Course Code!",
                },
              ]}
            >
              <AInput placeholder={"Enter Course Code"} size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name="credit_hour"
              label={<p style={{ fontSize: "16px" }}>Credit Hour</p>}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Please input the Credit Hour!",
                },
                {
                  pattern: /^[0-9]+$/,
                  message: "Please enter a valid number for Credit Hour!",
                },
              ]}
            >
              <AInput placeholder={"Enter course Credit Hour"} size="large" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col xs={24} md={8}>
            <Form.Item
              name="course_category"
              label={<p style={{ fontSize: "16px" }}>Course Category</p>}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Please input the Course Category!",
                },
              ]}
            >
              <ASelect customized={false} defaultValue="Choose Category">
                <Select.Option value="Mandatory">Mandatory</Select.Option>
                <Select.Option value="Elective">Elective</Select.Option>
              </ASelect>
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name="course_program"
              label={<p style={{ fontSize: "16px" }}>Program</p>}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Please select the program",
                },
              ]}
            >
              <ASelect
                customized={false}
                defaultValue="Choose Program"
                value={programId}
                onChange={handleProgramChange}
                disabled={!!defaultProgram}
              >
                {programs &&
                  programs.map((element) => (
                    <Select.Option value={element.id}>
                      {element.name}
                    </Select.Option>
                  ))}
              </ASelect>
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name="course_class"
              label={<p style={{ fontSize: "16px" }}>Class</p>}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Please select the class",
                },
              ]}
            >
              <ASelect
                customized={false}
                defaultValue="Choose Class/Semester"
                value={semesterId}
                onChange={(value) => setSemesterId(value)}
                disabled={!!defaultSemester}
              >
                {semesters &&
                  semesters.map((element) => (
                    <Select.Option value={element.id}>
                      {element.name}
                    </Select.Option>
                  ))}
              </ASelect>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={24}>
            <Form.Item
              name="description"
              label={<p style={{ fontSize: "16px" }}>Description</p>}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Please input the Description!",
                },
              ]}
            >
              <QuillEditor
                className="toolbar"
                style={{ fontSize: tokens.FONT_FAMILY }}
                value={editorContent}
                onChange={handleEditorChange}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={24}>
            <Form.Item name="course_photo" label="Choose Course Photo">
              <Uploads
                setMediaUrl={setCoursePhotoUrl}
                setMediaId={setMediaId}
                imagesUrl={coursePhotoUrl}
                id="uploadButton"
                onUploadSuccess={handleUploadSuccess}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={24}>
            <Form.Item name="course_syllabus" label="Choose Course syllabus">
              <Uploads
                isDragger={true}
                setMediaUrl={setSyllabusUrl}
                setMediaId={setSyllabusMediaId}
                customUploadText="Upload Syllabus"
                imagesUrl={mediaUrl}
                id="uploadSyllabusButton"
                onUploadSuccess={handleSyllabusUploadSuccess}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Form.Item style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <AButton
                type="default"
                size="middle"
                onClick={() => navigate("/course")}
                style={{
                  backgroundColor: tokens.COLOR_LIGHTBLACK_L50,
                }}
              >
                Cancel
              </AButton>
              <AButton
                type="primary"
                htmlType="submit"
                size="large"
                style={{
                  color: tokens.BG_LIGHT,
                  backgroundColor: tokens.COLOR_PRIMARY,
                  padding: "0 30px",
                }}
              >
                {course ? "Update Course" : "Create Course"}
              </AButton>
            </div>
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
};

export default AddEditCourse;
