import React, { useState } from "react";
import LoginForm from "../../Components/Login";
import { useNavigate } from "react-router-dom";
import { message } from "antd";
// import { useHistory } from 'react-router-dom';

import axios from "axios";

function Login() {
  const API_URL = process.env.REACT_APP_API_BASE_URL;
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  const onFinish = async (values) => {
    const { email, password } = values;
    // localStorage.setItem(
    //   "token",
    //   "eyJhbGciOiJIUzI1NiJ9.eyJpZCI6MSwiYWxnIjoiSFMyNTYiLCJ0eXAiOiJKV1QiLCJ1c2VyIjp7InJvbGVzIjpbeyJyb2xuYW1lIjoiYWRtaW4iLCJwZXJtaXNzaW9ucyI6WyJjb3Vyc2UgdmlldyIsImNvdXJzZSBhZGQiLCJjb3Vyc2UgZWRpdCIsImNvdXJzZSBkZWxldGUiXX1dfX0.d_UP4iTn-AXku364vdJBox1SPZXW6p6ANsgRAqyhyj0"
    // );
    // navigate("/");

    //Login API call here
    let response;
    try {
      setLoading(true);
      response = await axios.post(API_URL + "/login", {
        email,
        password,
      });
      if (response.data.token) {
        setLoading(false);
        //first clear all localstorage data
        localStorage.clear();
        
        // Store the token in localStorage
        localStorage.setItem("token", response.data.token);
        // localStorage.setItem("permission", response.data.user.permissions);
        // localStorage.setItem("role", response.data.user.rolename);
        navigate("/");
      } else {
        setLoading(false);
        message.error(response.data.error);
      }
      setLoading(false);
      // Perform any additional actions or redirect the user
      // after successful login
    } catch (error) {
      message.error(response.data.error);
      setLoading(false);
    }
  };

  const onFinishFailed = (errorInfo) => {
    message.error("Failed:", errorInfo);
    setLoading(false);
  };
  return (
    <div>
      <LoginForm
        onFinish={onFinish}
        onFinishFailed={onFinishFailed}
        loading={loading}
      />
    </div>
  );
}

export default Login;
