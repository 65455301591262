import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import AddEditCollege from "../../../Components/AddCollege";
import { useDispatch, useSelector } from "react-redux";
import actions from "../store/actions";
import { message } from "antd";
import Loader from "../../../Components/Loader";
function EditCollege() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  // const [college, setCollege] = useState(null);

  const college = useSelector(({ collegesReducer }) => {
    const colleges = collegesReducer.get("colleges");
    return colleges.find((college) => {
      return college.id === parseInt(id);
    });
  });

  useEffect(() => {
    if (!college) {
      new Promise((resolve, reject) => {
        dispatch(actions.fetchColleges(resolve, reject));
      })
        .then((response) => {})
        .finally(() => {});
    }
  }, [college]);

  let navigate = useNavigate();

  const handleEdit = (value) => {
    setSaving(true);
    const data = {
      ...value,
    };

    new Promise((resolve, reject) => {
      dispatch(actions.editColleges(college.id, data, "ADD", resolve, reject));
    })
      .then(() => {
        message.success("College edited Sucessfully");
        setUpdateSuccess(true);
        navigate(`/college/view/${id}`);
      })
      .catch(() => {
        message.error("College edit failed");
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const handleCollegeDelete = (id) => {
    new Promise((resolve, reject) => {
      dispatch(actions.deleteColleges(id, resolve, reject));
    })
      .then(() => {
        navigate("/college");
        message.success("College delete Success");
      })
      .catch(() => {
        message.error("College delete fails ");
      })
      .finally(() => {});
  };

  if (!college || saving) {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Loader />
      </div>
    );
  }
  return (
    <div>
      <AddEditCollege
        handleSave={handleEdit}
        college={college}
        handleDelete={handleCollegeDelete}
      />
    </div>
  );
}

export default EditCollege;
