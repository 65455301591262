import actions from "./actions";
import { fromJS } from "immutable";

const initialState = fromJS({
  assignTimeSlots: [],
});

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case actions.FETCH_ASSIGN_TIMESLOTS_SUCCESS:
      return state.set("assignTimeSlots", action.payload);

    case actions.SAVE_ASSIGN_TIMESLOT_SUCCESS:
      return state.set("assignTimeSlots", action.payload);

    default:
      return state;
  }
};

export default reducer;
