import AssignCoursesSaga from "./sagas";
import AssignCoursesReducer from "./reducers";
import assignCoursesActions from "./actions";

export const assignCoursesReducer = {
  assignCoursesReducer: AssignCoursesReducer,
};

export const assignCoursesSaga = [AssignCoursesSaga()];

export { assignCoursesActions };
