import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import actions from "../store/actions";
import { message } from "antd";
import AddEditBatch from "../Components/AddEditBatch";
import BatchDetails from "../Components/BatchDetails";
import Loader from "../../../Components/Loader";
function ViewBatch() {
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  let navigate = useNavigate();

  const { id } = useParams();

  let batch = useSelector(({ batchesReducer }) => batchesReducer.get("batch"));

  useEffect(() => {
    new Promise((resolve, reject) => {
      dispatch(actions.fetchBatch(parseInt(id), resolve, reject));
    })
      .then((response) => {})
      .finally(() => {});
  }, [dispatch]);

  const transformData = (data) => {
    const transformedData = [];

    for (const programId in data) {
      if (data[programId]) {
        transformedData.push({
          program: programId,
          semesters: data[programId],
        });
      }
    }

    return transformedData;
  };

  const handleBatchDelete = (id) => {
    new Promise((resolve, reject) => {
      dispatch(actions.deleteBatches(id, resolve, reject));
    })
      .then(() => {
        navigate("/session");
        message.success("Session delete Success");
      })
      .catch(() => {
        message.error("Session delete fails ");
      })
      .finally(() => {});
  };

  if (!batch) {
    return <Loader />;
  }
  return (
    <div>
      {/* <AddEditBatch batch={batch} /> */}
      <BatchDetails batch={batch} handleDelete={handleBatchDelete} />
    </div>
  );
}

export default ViewBatch;
