import React, { useState } from "react";
import { useNavigate } from "react-router";
import AddEditCollege from "../../../Components/AddCollege";
import { useDispatch } from "react-redux";
import actions from "../store/actions";
import { message } from "antd";
function AddCollege() {
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);

  let navigate = useNavigate();
  const handleSave = (value) => {
    setSaving(true);
    const data = {
      ...value,
    };
    new Promise((resolve, reject) => {
      dispatch(actions.saveColleges(data, "ADD", resolve, reject));
    })
      .then(() => {
        message.success("College added Sucessfully");
        setUpdateSuccess(true);
        navigate("/college");
      })
      .catch((error) => {
        message.error(error.response ? error.response.data.error : "College add failed, Please try again");
      })
      .finally(() => {
        setSaving(false);
      });
  };
  return (
    <div>
      <AddEditCollege handleSave={handleSave} loading={saving}/>
    </div>
  );
}

export default AddCollege;
