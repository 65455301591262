import React, { useEffect, useState } from "react";
import AddEditEvaluationForm from "../components/AddEditEvaluationForm";
import actions from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { getEvaluationID, getEvaluationName } from "../../../../utils/Helper";
import { message } from "antd";
import { useLocation, useNavigate, useParams } from "react-router";
import courseAction from "../../../Course/store/actions";
import Loader from "../../../../Components/Loader";

function EditEvaluation() {
  const [loading, setLoading] = useState();

  const location = useLocation();
  const evaluationId = getEvaluationID(location);
  const evaluationName = getEvaluationName(location);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { recordId } = useParams();
  const courseId = localStorage.getItem("selectedCourse");

  const evaluation = useSelector(({ evaluationCriteriaReducer }) => {
    const evaluations = evaluationCriteriaReducer.get("evaluations");
    return (
      evaluations &&
      evaluations.find((evaluation) => {
        return evaluation.id === parseInt(recordId);
      })
    );
  });

  const handleSave = (values) => {
    setLoading(true);
    new Promise((resolve, reject) => {
      dispatch(
        actions.updateEvaluation(
          recordId,
          evaluationId,
          courseId,
          values,
          "EDIT",
          resolve,
          reject
        )
      );
    })
      .then(() => {
        message.success(evaluationName + " added Sucessfully");
        navigate(`/evaluation/${evaluationId}/${evaluationName}`);
      })
      .catch(() => {
        message.error(evaluationName + " add failed");
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const handleEvaluationDelete = (id) => {
    new Promise((resolve, reject) => {
      dispatch(
        actions.deleteEvaluation(id, evaluationId, courseId, resolve, reject)
      );
    })
      .then(() => {
        message.success(evaluationName + " delete Success");
        navigate(`/evaluation/${evaluationId}/${evaluationName}`);
      })
      .catch(() => {
        message.error(evaluationName + " delete fails ");
      })
      .finally(() => {});
  };

  useEffect(() => {
    courseId &&
      new Promise((resolve, reject) => {
        dispatch(
          actions.fetchEvaluationByType(evaluationId, courseId, resolve, reject)
        );
      }).finally(() => {});
  }, [dispatch]);

  if (!evaluation) {
    return <Loader />;
  }
  return (
    <AddEditEvaluationForm
      loading={loading}
      evaluationData={evaluation}
      handleSave={handleSave}
      handleDelete={handleEvaluationDelete}
    />
  );
}

export default EditEvaluation;
