import React, { useEffect, useState } from "react";
import ViewTeacherStudent from "../../../Components/ViewInstructorStudent";
import AddEditStudent from "../../../Components/AddStudent";
import actions from "../store/actions";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import Loader from "../../../Components/Loader";
function EditInstructor() {
  const [saving, setSaving] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const instructor = useSelector(({ instructorsReducer }) => {
    const instructors = instructorsReducer.get("instructors");
    return instructors.find((instructor) => {
      return instructor.id === parseInt(id);
    });
  });

  useEffect(() => {
    if (!instructor) {
      new Promise((resolve, reject) => {
        dispatch(actions.fetchInstructors(resolve, reject));
      })
        .then((response) => {})
        .finally(() => {});
    }
  }, [instructor]);

  const handleEdit = (value) => {
    setSaving(true);
    const data = {
      ...value,
    };

    new Promise((resolve, reject) => {
      dispatch(
        actions.editInstructor(parseInt(id), data, "EDIT", resolve, reject)
      );
    })
      .then(() => {
        message.success("Instructor edited Sucessfully");
        navigate(`/instructor/details/${id}`);
      })
      .catch(() => {
        message.error("Instructor edit failed");
      })
      .finally(() => {
        setSaving(false);
      });
  };
  if (instructor) {
    return (
      <AddEditStudent
        loading={saving}
        handleSave={handleEdit}
        isStudent={false}
        data={instructor}
      />
    );
  } else {
    return <Loader />;
  }
}
export default EditInstructor;
