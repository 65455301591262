import { takeLatest, put, call, all } from "redux-saga/effects";
import Api from "../../../services/api";
import actions from "./actions";
import { act } from "react-dom/test-utils";

const api = new Api();

const fetchStudents = takeLatest(actions.FETCH_STUDENTS, function* (action) {
  try {
    const response = yield call(api.get, "api/student");
    yield put({
      type: actions.FETCH_STUDENTS_SUCCESS,
      payload: response,
    });
    yield call(action.resolve, "success");
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

const addStudent = takeLatest(actions.SAVE_STUDENT, function* (action) {
  try {
    const response = yield call(api.post, "api/student", action.data, null);
    action.data.id = response.id;
    yield put({
      type: actions.SAVE_STUDENT_SUCCESS,
      payload: response,
    });

    yield call(action.resolve, "success");
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

const uploadStudent = takeLatest(actions.UPLOAD_STUDENT, function* (action) {
  try {
    const response = yield call(
      api.post,
      "api/upload-students",
      action.data,
      null
    );
    yield put({
      type: actions.UPLOAD_STUDENT_SUCCESS,
      payload: response,
    });
    yield call(action.resolve, "success");
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

const editStudent = takeLatest(actions.EDIT_STUDENT, function* (action) {
  try {
    const response = yield call(
      api.put,
      `api/student/${action.userId}`,
      action.data,
      null
    );
    yield call(action.resolve, "success");
    yield put({
      type: actions.EDIT_STUDENT_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

const deleteStudent = takeLatest(actions.DELETE_STUDENT, function* (action) {
  try {
    const responseId = yield call(api.delete, `api/student/${action.userId}`);
    yield put({
      type: actions.DELETE_STUDENT_SUCCESS,
      id: action.id,
    });

    yield call(action.resolve, responseId);
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

const fetchGroups = takeLatest(actions.FETCH_GROUPS, function* (action) {
  try {
    const response = yield call(api.get, "api/group");
    yield put({
      type: actions.FETCH_GROUPS_SUCCESS,
      payload: response,
    });
    yield call(action.resolve, "success");
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

const addGroup = takeLatest(actions.SAVE_GROUP, function* (action) {
  try {
    const response = yield call(api.post, "api/group", action.data, null);
    action.data.id = response.id;
    yield put({
      type: actions.SAVE_GROUP_SUCCESS,
      payload: response,
    });

    yield call(action.resolve, "success");
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

const editGroup = takeLatest(actions.EDIT_GROUP, function* (action) {
  try {
    const response = yield call(
      api.put,
      `api/group/${action.groupId}`,
      action.data,
      null
    );
    yield call(action.resolve, "success");
    yield put({
      type: actions.EDIT_GROUP_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

const deleteGroup = takeLatest(actions.DELETE_GROUP, function* (action) {
  try {
    const responseId = yield call(api.delete, `api/group/${action.groupId}`);
    yield put({
      type: actions.DELETE_GROUP_SUCCESS,
      id: action.id,
    });

    yield call(action.resolve, responseId);
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

const fetchStudentsByCourse = takeLatest(
  actions.FETCH_STUDENTS_BY_COURSE,
  function* (action) {
    try {
      const response = yield call(
        api.get,
        `api/studentByCourse/${action.courseId}`
      );
      yield put({
        type: actions.FETCH_STUDENTS_BY_COURSE_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, "success");
    } catch (error) {
      yield call(action.reject, "failed");
    }
  }
);

export default function* saga() {
  yield all([
    fetchStudents,
    addStudent,
    uploadStudent,
    editStudent,
    deleteStudent,
    fetchGroups,
    addGroup,
    editGroup,
    deleteGroup,
    fetchStudentsByCourse,
  ]);
}
