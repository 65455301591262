import { Col, Form, Row, Select, Space, Tooltip, message } from "antd";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import actions from "../../ManageBatch/store/actions";
import AInput from "../../../Components/Input";
import ASelect from "../../../Components/Select";
import programActions from "../../Program/store/actions";
import Loader from "../../../Components/Loader";
import AButton from "../../../Components/Button";
import { tokens } from "../../../utils/Constants";
import batchActions from "../../Batch/store/actions";

const ManageBatchRelation  = () => {
    const [loading, setLoading] = useState(false)
    const [saving, setSaving] = useState(false)
    const [error, setError] = useState(false)

    const { id } = useParams();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const navigate = useNavigate();

    useEffect(() => {
        const getBatchDetails =  async () => {
            setLoading(true)
            new Promise((resolve, reject) => {
                dispatch(actions.fetchBatchDetails(id, resolve, reject));
            })
            .then(async (result) => {
                setLoading(false);
                fetchSemesterYear(result[0].program.id)
            })
            .catch((error) => {
              message.error(error.response ? error.response.data.error : "Error fetching batch details, Please try again!");
            })
            .finally(() => {
                setLoading(false);
            });
        }
        const fetchSemesterYear =  async (programID) => {
            setLoading(true);

            new Promise((resolve, reject) => {
                dispatch(programActions.fetchSemesters(programID, resolve, reject));
              })
                .then(async (result) => {
                    setLoading(false);
                })
                .catch((error) => {
                  message.error(error.response ? error.response.data.error : "Error fetching program details, Please try again!");
                })
                .finally(() => {
                    setLoading(false);
                });
        }

        const fetchBatches = () => {
            setLoading(true);
            new Promise((resolve, reject) => {
              dispatch(batchActions.fetchBatches(resolve, reject));
            }).finally(() => {
                setLoading(false);
            });
        }
        
        getBatchDetails();
        fetchBatches();

    },[id])

    const handleSave = (value) =>{
        setSaving(true)
        new Promise((resolve, reject) => {
            dispatch(actions.updateBatch(value, "ADD", resolve, reject));
          })
            .then(() => {
                message.success("Batch Updated Successfully!");
                setTimeout(() => {
                    navigate(`/groupDetails/${id}`);
                    window.location.reload();
                }, 1000);
            })
            .catch((error) => {
              message.error(error.response ? error.response.data.error : "Batch Update failed, Please try again");
            })
            .finally(() => {
              setSaving(false);
            });

    }

    const batchDetails = useSelector(({ batchSessionReducer }) =>
        batchSessionReducer.get("batch_details")
    );
    let semesters = useSelector(({ programsReducer }) =>
        programsReducer.get("semesters")
    );

    let sessions = useSelector(({ batchesReducer }) =>
        batchesReducer.get("batches")
    );


    const handleSessionChange = (session) => {
        const sessionData = findDataById(sessions,session)

        const programData = findDataById(sessionData.program,batchDetails[0].program.id )

        if(!programData){
            message.warning("Batch and Session doesn't matched , Please check once!")
            setError(true)
        }
        else{
            setError(false)
        }
    }

    const findDataById = (dataArray, id) => {
        // Loop through the dataArray
        for (let i = 0; i < dataArray.length; i++) {
          // If the current object's id matches the id we're looking for, return it
          if (dataArray[i].id === id) {
            return dataArray[i];
          }
        }
        // If no match is found, return null or handle it as needed
        return null;
      }

    if(!batchDetails || !semesters || !sessions) {
        return <Loader/>
    }

    return (
        <div>
            <Form
                size="large"
                form={form}
                name="managegroupForm"
                onFinish={(values) => {
                    handleSave(values)             
                }}
                layout="vertical"
                scrollToFirstError
                title="Add/Editbatch"
                initialValues={{batch : batchDetails[0].id, program:batchDetails[0].program.id, batchName : batchDetails[0].name, programName:batchDetails[0].program.name}}
            >
                <Row gutter={20} style={{ backgroundColor:"transparent"}}>
                <Col span={12}>
                    <Form.Item
                    name="batch"
                    label={
                        <p
                        style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            lineHeight: "19.6px",
                        }}
                        >
                        Batch Name
                        </p>
                    }
                    rules={[
                        {
                        required: true,
                        message: "Please input the Name!",
                        },
                    ]}
                    >
                    <ASelect
                        customized={false}
                        defaultValue="Choose Batch"
                        onChange={() => {}}
                        >
                            <Select.Option key={batchDetails[0].id} value={batchDetails[0].id} disabled={true}>
                                {batchDetails[0].name}
                            </Select.Option>
                    </ASelect>

                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                    name="program"
                    label={
                        <p
                        style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            lineHeight: "19.6px",
                        }}
                        >
                        Program Name
                        </p>
                    }
                    rules={[
                        {
                        required: true,
                        message: "Please input the Name!",
                        },
                    ]}
                    >
                    <ASelect
                        customized={false}
                        defaultValue="Choose Program"
                        onChange={() => {}}
                        >
                            <Select.Option key={batchDetails[0].program.id} value={batchDetails[0].program.id} disabled={true}>
                                {batchDetails[0].program.name}
                            </Select.Option>
                    </ASelect>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                    name="session"
                    label={
                        <p
                        style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            lineHeight: "19.6px",
                        }}
                        >
                        Session 
                        </p>
                    }
                    rules={[
                        {
                        required: true,
                        message: "Please choose a Session!",
                        },
                    ]}
                    >
                      <ASelect
                        customized={false}
                        defaultValue="Choose Session"
                        onChange={(e) => {handleSessionChange(e)}}
                        >
                            {sessions && sessions.map((session) => (
                            <Select.Option key={session.id} value={session.id}>
                                {session.name}
                            </Select.Option>
                            ))}
                        </ASelect>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                    name="semester"
                    label={
                        <p
                        style={{
                            fontSize: "14px",
                            fontWeight: "600",
                            lineHeight: "19.6px",
                        }}
                        >
                        Semester/Year 
                        </p>
                    }
                    rules={[
                        {
                        required: true,
                        message: "Please choose a Semester/ year!",
                        },
                    ]}
                    >
                      <ASelect
                        customized={false}
                        defaultValue="Choose Class/Semester"
                        onChange={() => {}}
                        >
                            {semesters && semesters.map((semester) => (
                            <Select.Option key={semester.id} value={semester.id}>
                                {semester.name}
                            </Select.Option>
                            ))}
                        </ASelect>
                    </Form.Item>
                </Col>

                </Row>
                <Row>
                <Form.Item>
                <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                        marginTop:20,
                    }}
                >
                        
                        <AButton
                        type="default"
                        size="large"
                        style={{
                            backgroundColor: tokens.COLOR_LIGHTBLACK_L50,
                            padding: "0 30px",
                            marginRight:20
                        }}
                        onClick={(e) => navigate(`/groupDetails/${id}`)}
                        loading={loading}
                        >
                        {"Cancel"}
                        </AButton>

                        <Tooltip title={error && "Batch and Session doesn't matched , Please check once!"}>
                        <AButton
                        type="primary"
                        htmlType="submit"
                        size="large"
                        style={{
                            color: tokens.BG_LIGHT,
                            backgroundColor: tokens.COLOR_LIGHT_ORANGE,
                            padding: "0 30px",
                        }}
                        loading={loading}
                        disabled={error}
                        >
                            {"Save"}
                        </AButton>
                        </Tooltip>
                        </div>
                    </Form.Item>
                </Row>
            </Form>
        </div>
    )
}

export default ManageBatchRelation