import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import AddEditAssignCourse from "../../../Components/AddEditAssignCourse";
import { useDispatch, useSelector } from "react-redux";
import actions from "../store/actions";
import { message } from "antd";
import Loader from "../../../Components/Loader";

function EditAssignCourse() {

  const { id } = useParams();
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);

  const assignCourse = useSelector(({ assignCoursesReducer }) => {
    const assignCourses = assignCoursesReducer.get("assignCourses");
    return assignCourses.find((assignCourse) => {
      return assignCourse.id == parseInt(id);
    });
  });

  useEffect(() => {
    if (!assignCourse) {
      new Promise((resolve, reject) => {
        dispatch(actions.fetchAssignCourses(resolve, reject));
      })
        .then((response) => {})
        .finally(() => {});
    }
  }, [assignCourse, id]);

  let navigate = useNavigate();

  const handleEdit = (value) => {

    const data = {
      course_id: assignCourse.course_id,
      previous_assign_course: id,
      new_instructor: value.course_instructor,
    };

    new Promise((resolve, reject) => {
      dispatch(actions.editAssignCourse(data, resolve, reject));
    })
      .then(() => {
        message.success("Change instructor successfully");
        navigate("/assignInstructor");
      })
      .catch(() => {
        message.error("Change instructor failed");
      })
      .finally(() => {
        setSaving(false);
      });
  };

  if (saving && !assignCourse) {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Loader />
      </div>
    );
  }
  return (
    <div>
      <AddEditAssignCourse
        handleSave={handleEdit}
        assignCourse={assignCourse}
      />
    </div>
  );
}

export default EditAssignCourse;
