const actions = {
  FETCH_COLLEGES: "FETCH_COLLEGES",
  FETCH_COLLEGES_SUCCESS: "FETCH_COLLEGES_SUCCESS",
  SAVE_COLLEGES: "SAVE_COLLEGES",
  SAVE_COLLEGES_SUCCESS: "SAVE_COLLEGES_SUCCESS",
  EDIT_COLLEGES: "EDIT_COLLEGES",
  EDIT_COLLEGES_SUCCESS: "EDIT_COLLEGES_SUCCESS",
  DELETE_COLLEGES: "DELETE_COLLEGES",
  DELETE_COLLEGES_SUCCESS: "DELETE_COLLEGES_SUCCESS",
  FETCH_COLLEGE_ADMIN: "FETCH_COLLEGE_ADMIN",
  FETCH_COLLEGE_ADMIN_SUCCESS: "FETCH_COLLEGE_ADMIN_SUCCESS",
  SAVE_COLLEGE_ADMIN: "SAVE_COLLEGE_ADMIN",
  SAVE_COLLEGE_ADMIN_SUCCESS: "SAVE_COLLEGE_ADMIN_SUCCESS",
  DELETE_COLLEGE_ADMIN: "DELETE_COLLEGE_ADMIN",
  DELETE_COLLEGE_ADMIN_SUCCESS: "DELETE_COLLEGE_ADMIN_SUCCESS",

  fetchColleges: (resolve, reject) => ({
    type: actions.FETCH_COLLEGES,
    resolve,
    reject,
  }),

  saveColleges: (data, event, resolve, reject) => ({
    type: actions.SAVE_COLLEGES,
    data,
    event,
    resolve,
    reject,
  }),

  editColleges: (collegeId, data, event, resolve, reject) => ({
    type: actions.EDIT_COLLEGES,
    collegeId,
    data,
    event,
    resolve,
    reject,
  }),
  deleteColleges: (collegeId, resolve, reject) => ({
    type: actions.DELETE_COLLEGES,
    collegeId: collegeId,
    resolve: resolve,
    reject: reject,
  }),

  fetchCollegeAdmins: (collegeId, resolve, reject) => ({
    type: actions.FETCH_COLLEGE_ADMIN,
    collegeId: collegeId,
    resolve,
    reject,
  }),

  saveCollegeAdmin: (data, event, resolve, reject) => ({
    type: actions.SAVE_COLLEGE_ADMIN,
    data,
    event,
    resolve,
    reject,
  }),

  deleteCollegeAdmin: (id, resolve, reject) => ({
    type: actions.DELETE_COLLEGE_ADMIN,
    id,
    resolve,
    reject,
  }),
};

export default actions;
