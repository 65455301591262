import React, { useEffect } from "react";
import ThemeConfigProvider from "./theme/ThemeConfigProvider";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
  useLocation,
  BrowserRouter,
} from "react-router-dom";
import Loader from "./Components/Loader/index";
import PublicRoutes from "./routes/publicroutes/index.jsx";
import RestrictedRoutes from "./routes/restrictedRoutes/index.jsx";
import MainLayout from "./layout/mainLayout/index";
import PermittedRoutes from "./routes/permittedRoutes/index.jsx";
import jwt_decode from "jwt-decode";
import { useHistory } from "react-router-dom";
import "./App.css";

function AppRoutes() {
  const navigate = useNavigate();
  const locaiton = useLocation();

  const hasTokenExpired = (token) => {
    if (token) {
      const decodedToken = jwt_decode(token || "");
      const expireTime = decodedToken.exp * 1000;
      const currentTime = Date.now();
      const isExpired = expireTime < currentTime;
      return isExpired;
    }
  };

  const token = localStorage.getItem("token");

  const isTokenExpired = hasTokenExpired(token);
  const loading = false;
  useEffect(() => {
    if (token && !isTokenExpired) {
      if (locaiton.pathname === "/") {
        navigate("/dashboard", { replace: false });
      } else navigate(locaiton.pathname, { replace: false });
    } else {
      if (locaiton.pathname === "/") {
        navigate("/login", { replace: false });
      } else navigate(locaiton.pathname, { replace: false });
    }
  }, [token]);

  if (loading) {
    return <Loader />;
  }
  if (!token || isTokenExpired) {
    return (
      <Routes>
        {PublicRoutes.map((route) => (
          <Route path={route.path} element={route.Component} />
        ))}
        {/* <Route path = '/login' element = {<Login/>}/> */}
      </Routes>
    );
  }
  return (
    // <Routes>
    //   {RestrictedRoutes.map((route)=>
    //   <Route path={route.path} element = {route.Component}/>)}
    // </Routes>
    <MainLayout>
      <Routes>
        {RestrictedRoutes.map((route) => {
          return (
            <Route
              path={route.path}
              element={
                <PermittedRoutes
                  permission={route.permission}
                  Component={route.Component}
                />
              }
            />
          );
        })}
      </Routes>
    </MainLayout>
  );
}

function App() {
  return (
    <ThemeConfigProvider>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </ThemeConfigProvider>
  );
}

export default App;
