import actions from "./actions";
import { fromJS } from "immutable";
import { combineReducers } from "redux";

const initialState = fromJS({
  roles: [],
  permissions: [],
  rolePermissions: [],
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_ROLES_SUCCESS:
      return state.set("roles", action.payload);

    case actions.SAVE_ROLES_SUCCESS:
      return state.set("roles", action.payload);

    case actions.EDIT_ROLES_SUCCESS:
      return state.set("roles", action.payload);

    case actions.EDIT_PERMISSIONS_SUCCESS:
      return state.set("roles", action.payload);

    case actions.DELETE_ROLES_SUCCESS:
      return state.set("roles", action.payload);

    case actions.FETCH_PERMISSIONS_SUCCESS:
      return state.set("permissions", action.payload);

    case actions.FETCH_ROLE_PERMISSIONS_SUCCESS:
      return state.set("rolePermissions", action.payload);

    default:
      return state;
  }
};

export default reducer;
