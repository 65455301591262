import React, { useState } from "react";
import { useNavigate } from "react-router";
import AddEditStudent from "../../../Components/AddStudent";
import { useDispatch } from "react-redux";
import actions from "../store/actions";
import { message } from "antd";
function AddStudent() {
  const [saving, setSaving] = useState(false);
  const dispatch = useDispatch();

  let navigate = useNavigate();
  const handleSave = (value) => {
    setSaving(true);
    const data = {
      ...value,
    };
    new Promise((resolve, reject) => {
      dispatch(actions.saveStudent(data, "ADD", resolve, reject));
    })
      .then(() => {
        message.success("Student added Sucessfully");
        navigate("/student");
      })
      .catch(() => {
        message.error("Student add failed");
      })
      .finally(() => {
        setSaving(false);
      });
  };
  return (
    <div>
      <AddEditStudent loading={saving} handleSave={handleSave} content={"Add Student"} />
    </div>
  );
}

export default AddStudent;
