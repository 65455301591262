import React, { useState } from "react";
import Loader from "../../../../../Components/Loader";
import AText from "../../../../../Components/Text";
import { tokens } from "../../../../../utils/Constants";
import {
  formatDeadline,
  getEvaluationID,
  getEvaluationName,
} from "../../../../../utils/Helper";
import ATag from "../../../../../Components/Tag";
import AButton from "../../../../../Components/Button";

import { Col, Divider, Form, Row, Space, message } from "antd";
import {
  SearchOutlined,
  PlusOutlined,
  WarningFilled,
  FilterOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import ATable from "../../../../../Components/Table";
import AModal from "../../../../../Components/Model";
import useToken from "antd/es/theme/useToken";
import ATitle from "../../../../../Components/Title";
import { useForm } from "antd/es/form/Form";
import AInput from "../../../../../Components/Input";
import QuillEditor from "../../../../../Components/Editor";
import Uploads from "../../../../../Components/Uploads";
import actions from "../../../store/actions";
import { useDispatch } from "react-redux";
import { useLocation, useParams } from "react-router";
import FileDownload from "../../../../../Components/FileDownload";

const ChildDetails = (evaluation) => {
  const location = useLocation();
  const evaluationId = getEvaluationID(location);
  const evaluationName = getEvaluationName(location);

  const [openModel, setOpenModel] = useState(false);
  const [editorContent, setEditorContent] = useState("");
  const [mediaUrl, setMediaUrl] = useState("");
  const [mediaId, setMediaId] = useState();
  const [selectedAnswer, setSelectedAnswer] = useState();
  const { recordId } = useParams();
  const dispatch = useDispatch();
  const courseId = localStorage.getItem("selectedCourse");

  const token = useToken();
  const [form] = Form.useForm();
  const evaluationData = evaluation && evaluation.evaluation;

  const handleEditorChange = (content) => {
    setEditorContent(content);
  };
  const handleSave = (values) => {
    new Promise((resolve, reject) => {
      dispatch(
        actions.submitEvaluationByStudent(
          evaluationId,
          courseId,
          values,
          resolve,
          reject
        )
      );
    })
      .then(() => {
        message.success(`${evaluationName} submitted Sucessfully`);
        form.resetFields();
        setSelectedAnswer(null);
        setOpenModel(false);
        // navigate("/evaluationCriteria");
      })
      .catch(() => {
        message.error(`${evaluationName} submission fails`);
      })
      .finally(() => {});
  };

  const hanldeEdit = (values) => {
    new Promise((resolve, reject) => {
      dispatch(
        actions.updateEvaluationByStudent(
          selectedAnswer.id,
          evaluationId,
          courseId,
          values,
          resolve,
          reject
        )
      );
    })
      .then(() => {
        message.success(`${evaluationName} submitted Sucessfully`);
        form.resetFields();
        setSelectedAnswer(null);
        setOpenModel(false);
        // navigate("/evaluationCriteria");
      })
      .catch(() => {
        message.error(`${evaluationName} submission fails`);
      })
      .finally(() => {});
  };

  const handleAnswerEdit = (id) => {
    const result =
      evaluationData.evaluationAnswer &&
      evaluationData.evaluationAnswer.find((item) => item.id === id);
    setSelectedAnswer(result);
  };
  const columns = [
    {
      title: "S.N.",
      render: (_, __, index) => <span>{index + 1}</span>,
    },
    {
      title: "Title",
      dataIndex: "title",
      key: "title",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
      render: (text) => <div dangerouslySetInnerHTML={{ __html: text }} />,
    },
    {
      title: "Submission Date",
      render: (_, record) => formatDeadline(record.submittedOn).formattedDate1,
    },
    {
      title: "Marks",
      dataIndex: "marks",
      key: "marks",       },
    {
      title: "Attachment123",
      key: "studentFile",
      render: (value) => {
        return value.studentFile ? (
          <FileDownload
          displayFileName={true}
          url={value.studentFile.media_url}
          fileName={value.studentFile.name}
          style={{
            color: tokens.COLOR_PRIMARY,
            background: tokens.BG_LIGHT,
            border: `1px solid ${tokens.COLOR_PRIMARY}`,
          }}
        />
          ) : (
         <div style={{display:'flex', justifyContent:'center'}}>-</div>
        );
      },
    },
    {
      title: "Action",
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {record.marks ? 
          <AButton
            type="primary"
            size="middle"
            style={{
              color: tokens.BG_LIGHT,
              backgroundColor: tokens.COLOR_SUCCESS,
            }}
            onClick={(e) => {
              e.stopPropagation();
              setOpenModel(true);
              handleAnswerEdit(record.id);
            }}
            disabled
          >
            Edit
          </AButton>
          :          
          <AButton
          type="primary"
          size="middle"
          style={{
            color: tokens.BG_LIGHT,
            backgroundColor: tokens.COLOR_SUCCESS,
          }}
          onClick={(e) => {
            e.stopPropagation();
            setOpenModel(true);
            handleAnswerEdit(record.id);
          }}
        >
          Edit
        </AButton>
        }
        </div>
      ),
    },
  ];

  if (!evaluation) {
    return <Loader />;
  }
  return (
    <div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontWeight: 500,
            // flexDirection: "row",
          }}
        >
          <div>
            <AText
              style={{
                fontSize: "16px",
                fontWeight: 600,
                margin: "0 10px 0 0",
                color: tokens.COLOR_NEUTRAL_N600,
              }}
            >
              {evaluationName} Title :
            </AText>
            <AText
              content={evaluationData.title}
              style={{
                color: tokens.COLOR_NEUTRAL_N600,
                fontWeight: 500,
                fontSize: "16px",
              }}
            />
          </div>
          {evaluationData.status === 1 ? (
            <ATag type="active"> Submitted</ATag>
          ) : (
            <ATag type="pending"> Not Submitted</ATag>
          )}
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            fontWeight: 500,
            marginTop: "2%",
            marginBottom: "4%",
          }}
        >
          <div>
            <AText
              style={{
                fontSize: "16px",
                fontWeight: 600,
                margin: "0 10px 0 0",
                color: tokens.COLOR_NEUTRAL_N600,
              }}
            >
              {evaluationName} Description :
            </AText>
            <AText
              content={evaluationData.title}
              style={{
                color: tokens.COLOR_NEUTRAL_N600,
                fontWeight: 500,
                fontSize: "16px",
              }}
            />
          </div>
          {evaluationData && evaluationData.instructorFile.media_url && (
            <FileDownload
              url={evaluationData.instructorFile.media_url}
              fileName={evaluationData.instructorFile.name}
              style={{
                color: tokens.COLOR_PRIMARY,
                background: tokens.BG_LIGHT,
                border: `1px solid ${tokens.COLOR_PRIMARY}`,
                marginTop: "10px",
              }}
              displayFileName={true}
            />
          )}
        </div>
      </div>

      {evaluationData.status === 0 && (
        <Space
          style={{
            display: "flex",
            justifyContent: "flex-start",
            marginBottom: "30px",
          }}
        >
          <AButton
            type="primary"
            icon={<PlusOutlined />}
            size="large"
            style={{ backgroundColor: tokens.COLOR_DARK_ORANGE }}
            onClick={() => setOpenModel(true)}
          >
            Submit {evaluationName}
          </AButton>
        </Space>
      )}
      <Divider />
      <AText
        content={`Submitted ${evaluationName}`}
        style={{
          color: tokens.COLOR_NEUTRAL_N600,
          fontWeight: 600,
          fontSize: "16px",
        }}
      />

      <div>
        {evaluationData.evaluationAnswer && (
          <ATable
            columns={columns}
            dataSource={evaluationData.evaluationAnswer}
          />
        )}
      </div>
      {/* model to submit evaluation*/}
      <AModal
        open={openModel}
        closable={false}
        footer={null}
        className="css-dev-only-do-not-override-1r3vfrt-last"
      >
        <ATitle content={`Submit ${evaluationName}`} level={4} />
        <AText
          style={{
            fontSize: "16px",
            fontWeight: 600,
            marginBottom: "20px",
            color: tokens.COLOR_NEUTRAL_N600,
          }}
        >
          {` Title : ${evaluationData.title}`}
        </AText>
        <Form
          size="large"
          form={form}
          name="add/editForm"
          onFinish={(values) => {
            values.title = evaluationData.title;
            values.evaluation_id = recordId;
            values.description = editorContent
              ? editorContent
              : selectedAnswer.description && selectedAnswer.description;
            values.studentFile = selectedAnswer ? (mediaId ? mediaId : (selectedAnswer.studentFile.id && selectedAnswer.studentFile.id) ) : mediaId;
            selectedAnswer ? hanldeEdit(values) : handleSave(values);
          }}
          layout="vertical"
          scrollToFirstError
          title="Submit Evaluation"
          style={{ width: "100%", padding: "2%" }}
          initialValues={
            selectedAnswer
              ? {
                  description: selectedAnswer.description,
                }
              : {}
          }
        >
          <Form.Item
            name="description"
            label={
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                  lineHeight: "19.6px",
                }}
              >
                {`${evaluationName} Description`}
              </p>
            }
            rules={[
              {
                required: true,
                message: `Please enter the ${evaluationName} Title!`,
              },
            ]}
          >
            <QuillEditor
              className="toolbar"
              style={{
                fontSize: tokens.FONT_FAMILY,
                marginLeft: "-10px",
              }}
              value={editorContent}
              onChange={handleEditorChange}
            />
          </Form.Item>
          <Form.Item
            name="studentFile"
            label={
              <p
                style={{
                  fontSize: "14px",
                  fontWeight: "600",
                  lineHeight: "19.6px",
                }}
              >
                Attcahment
              </p>
            }
            // rules={[
            //   {
            //     required: true,
            //     message: "Please select a photo!",
            //   },
            // ]}
          >
            <Uploads
              setMediaUrl={setMediaUrl}
              setMediaId={setMediaId}
              id="uploadButton"
              isDragger={true}
              customUploadIcon={<PlusOutlined />}
              customContent={"Click to Upload file or Drop file here"}
            />
            {selectedAnswer && selectedAnswer.studentFile.media_url && (
              <FileDownload
                url={selectedAnswer.studentFile.media_url}
                fileName={selectedAnswer.studentFile.name}
                style={{
                  color: tokens.COLOR_PRIMARY,
                  background: tokens.BG_LIGHT,
                  border: `1px solid ${tokens.COLOR_PRIMARY}`,
                  marginTop: "10px",
                }}
                displayFileName={true}
              />
            )}
          </Form.Item>
          <Form.Item style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <AButton
                type="default"
                size="large"
                onClick={() => {
                  setOpenModel(false);
                  setSelectedAnswer(null);
                }}
                style={{
                  backgroundColor: tokens.COLOR_LIGHTBLACK_L50,
                }}
              >
                Cancel
              </AButton>
              <AButton
                type="primary"
                htmlType="submit"
                size="large"
                style={{
                  color: tokens.BG_LIGHT,
                  backgroundColor: tokens.COLOR_PRIMARY,
                  padding: "0 30px",
                }}
              >
                {selectedAnswer ? "Update" : "Submit"}
              </AButton>
            </div>
          </Form.Item>
        </Form>
      </AModal>
    </div>
  );
};

export default ChildDetails;
