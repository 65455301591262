import React, { useEffect, useState } from "react";
import { getEvaluationID, getEvaluationName } from "../../../../utils/Helper";
import { Col, DatePicker, Form, Row, Select } from "antd";
import { useLocation, useNavigate } from "react-router";
import AInput from "../../../../Components/Input";
import actions from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import ASelect from "../../../../Components/Select";
import QuillEditor from "../../../../Components/Editor";
import { tokens } from "../../../../utils/Constants";
import studentActions from "../../../Students/store/actions";
import Loader from "../../../../Components/Loader/index";
import AButton from "../../../../Components/Button";
import { EditIcon } from "../../../../assets/icons/EditIcon";
import { DeleteIcon } from "../../../../assets/icons/DeleteIcon";
import dayjs from "dayjs";
import Uploads from "../../../../Components/Uploads";
import { PlusOutlined } from "@ant-design/icons";
import FileDownload from "../../../../Components/FileDownload";
import { AText } from "../../../../Components";
const AddEditEvaluationForm = ({
  handleSave,
  evaluationData,
  handleDelete,
  loading,
}) => {
  const location = useLocation();
  const evaluationId = getEvaluationID(location);
  const evaluationName = getEvaluationName(location);
  const [mediaUrl, setMediaUrl] = useState("");
  const [mediaId, setMediaId] = useState();

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  const [editorContent, setEditorContent] = useState(
    // data ? data.description : ""
    ""
  );
  const [selectedDate, setSelectedDate] = useState();
  // data ? data.deadline : ""

  const courseId = localStorage.getItem("selectedCourse");

  let evaluationCriteria = useSelector(({ evaluationCriteriaReducer }) =>
    evaluationCriteriaReducer.get("evaluationCriterias")
  );

  const handleEditorChange = (content) => {
    setEditorContent(content);
  };

  let students = useSelector(({ studentsReducer }) =>
    studentsReducer.get("studentsByCourse")
  );

  const onChange = (date, dateString) => {
    setSelectedDate(date);
  };

  useEffect(() => {
    new Promise((resolve, reject) => {
      dispatch(actions.fetchevaluationCriterias(resolve, reject));
    }).finally(() => { });

    new Promise((resolve, reject) => {
      dispatch(studentActions.fetchStudentsByCourse(courseId, resolve, reject));
    }).finally(() => { });
  }, [dispatch]);

  useEffect(() => {
    if (evaluationData && evaluationData.students) {
      form.setFieldsValue({
        students: evaluationData.students.map((student) => student.id),
      });
    }
    if (evaluationData && evaluationData.deadline) {
      form.setFieldsValue({
        deadline: dayjs(evaluationData.deadline.date),
      });
    }
  }, [evaluationCriteria, form]);

  if (!students) {
    return <Loader />;
  }
  return (
    <div style={{ padding: "1%" }}>
      <Form
        size="large"
        form={form}
        name="add/editForm"
        onFinish={(values) => {
          values.course_id = courseId;
          values.evaluation_criteria_id = parseInt(evaluationId);
          values.description = editorContent
            ? editorContent
            : evaluationData && evaluationData.description;
          values.deadline = selectedDate
            ? selectedDate
            : evaluationData && evaluationData.deadline.date;
          values.instructorFile = mediaId
            ? mediaId
            : evaluationData && evaluationData.instructorFile.id;
          handleSave(values);
        }}
        layout="vertical"
        scrollToFirstError
        title="Add/EditevaluationCriteria"
        initialValues={
          evaluationData
            ? {
              title: evaluationData.title,
              description: evaluationData.description,
              minimum_number: evaluationCriteria.minimum_number,
              students: evaluationData ? evaluationData.students : [],
            }
            : {}
        }
      >
        <Row gutter={20}>
          <Col span={16}>
            <Form.Item
              name="title"
              label={
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "19.6px",
                  }}
                >
                  {`${evaluationName} Title`}
                </p>
              }
              rules={[
                {
                  required: true,
                  message: `Please enter the ${evaluationName} Title!`,
                },
              ]}
            >
              {/* <ASelect
                customized={false}
                placeholder="Choose Evaluation Criteria"
                // mode="multiple"
              >
                {evaluationCriteria &&
                  evaluationCriteria.map((element) => (
                    <Select.Option value={element.id}>
                      {element.name}
                    </Select.Option>
                  ))}
              </ASelect>{" "} */}
              <AInput
                placeholder={`Enter ${evaluationName} Title`}
                size="large"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={24}>
            <Form.Item
              name="description"
              label={
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "19.6px",
                  }}
                >
                  {`${evaluationName} Description`}
                </p>
              }
              rules={[
                {
                  required: true,
                  message: "Please enter the Title!",
                },
              ]}
            >
              <QuillEditor
                className="toolbar"
                style={{
                  fontSize: tokens.FONT_FAMILY,
                  marginLeft: "-10px",
                }}
                value={editorContent}
                onChange={handleEditorChange}
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={16}>
            <Form.Item
              name="students"
              label={
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "19.6px",
                  }}
                >
                  Students
                </p>
              }
              rules={[
                {
                  required: true,
                  message: "Please select atlease one Student!",
                },
              ]}
            >
              <ASelect
                customized={false}
                placeholder="Choose Students"
                mode="multiple"
              >
                {students &&
                  students.map((element) => (
                    <Select.Option value={element.id}>
                      {element.first_name +
                        " " +
                        element.middle_name +
                        " " +
                        element.last_name}
                    </Select.Option>
                  ))}
              </ASelect>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="deadline"
              label={
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "19.6px",
                  }}
                >
                  Deadline
                </p>
              }
              rules={[
                {
                  required: true,
                  message: "Please select the Deadline!",
                },
              ]}
            >
              <DatePicker
                onChange={onChange}
                style={{ width: "100%" }}
                placeholder="Select Deadline"
              />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item
          name="instructorFile"
          label={
            <p
              style={{
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "19.6px",
              }}
            >
              Attcahment
            </p>
          }
        // rules={[
        //   {
        //     required: true,
        //     message: "Please select a photo!",
        //   },
        // ]}
        >
          <Uploads
            setMediaUrl={setMediaUrl}
            setMediaId={setMediaId}
            id="uploadButton"
            isDragger={true}
            customUploadIcon={<PlusOutlined />}
            customContent={"Click to Upload file or Drop file here"}
          />
          {evaluationData && evaluationData.instructorFile && (
            <FileDownload
              url={evaluationData.instructorFile.media_url}
              fileName={evaluationData.instructorFile.name}
              style={{
                color: tokens.COLOR_PRIMARY,
                background: tokens.BG_LIGHT,
                border: `1px solid ${tokens.COLOR_PRIMARY}`,
                marginTop: "10px",
              }}
              displayFileName={true}
            />
          )}
        </Form.Item>
        {/*buttom*/}
        <Form.Item>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: 30,
            }}
          >
            {evaluationData ? (
              <AButton
                type="default"
                size="large"
                onClick={() => handleDelete(evaluationData.id)}
                style={{
                  color: tokens.COLOR_DANGER,
                  borderColor: tokens.COLOR_DANGER,
                }}
                icon={<DeleteIcon />}
              >
                Delete
              </AButton>
            ) : (
              <AButton
                type="default"
                size="middle"
                onClick={() =>
                  navigate(`/evaluation/${evaluationId}/${evaluationName}`)
                }
                style={{
                  backgroundColor: tokens.COLOR_LIGHTBLACK_L50,
                }}
              >
                Cancel
              </AButton>
            )}

            <AButton
              type="primary"
              htmlType="submit"
              size="large"
              style={{
                color: tokens.BG_LIGHT,
                backgroundColor: tokens.COLOR_LIGHT_ORANGE,
                padding: "0 30px",
              }}
              loading={loading}
              icon={evaluationData ? <EditIcon /> : null}
            >
              {evaluationData ? "Update Evaluation" : "Create Evaluation"}
            </AButton>
          </div>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddEditEvaluationForm;
