import CollegesSaga from "./sagas";
import CollegesReducer from "./reducers";
import collegesActions from "./actions";

export const collegesReducer = {
  collegesReducer: CollegesReducer,
};

export const collegesSaga = [CollegesSaga()];

export { collegesActions };
