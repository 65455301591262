import actions from "./actions";
import { fromJS } from "immutable";
import { combineReducers } from "redux";

const initialState = fromJS({
  assignCourses: [],
});

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case actions.FETCH_ASSIGN_COURSE_SUCCESS:
      return state.set("assignCourses", action.payload);

    case actions.FETCH_ASSIGN_COURSES_SUCCESS:
      return state.set("assignCourses", action.payload);

    case actions.SAVE_ASSIGN_COURSE_SUCCESS:
      return state.set("assignCourses", action.payload);

    case actions.EDIT_ASSIGN_COURSE_SUCCESS:
      return state.set("assignCourses", action.payload);

    case actions.DELETE_ASSIGN_COURSE_SUCCESS:
      return state.set("assignCourses", action.payload);    

    default:
      return state;
  }
};

export default reducer;
