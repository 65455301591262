import React, { useEffect, useState } from "react";
import AddEditEvaluationCriteria from "../Components/AddEditForm";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Components/Loader";
import actions from "../store/actions";
import { message } from "antd";
import { useNavigate, useParams } from "react-router";
function EditEvaluationCriteria() {
  const [saving, setSaving] = useState(false);

  const { id } = useParams();

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const evaluationCriteria = useSelector(({ evaluationCriteriaReducer }) => {
    const evaluationCriterias = evaluationCriteriaReducer.get(
      "evaluationCriterias"
    );
    return evaluationCriterias.find((evaluationCriteria) => {
      return evaluationCriteria.id === parseInt(id);
    });
  });

  useEffect(() => {
    new Promise((resolve, reject) => {
      dispatch(actions.fetchevaluationCriterias(resolve, reject));
    }).finally(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleSave = (values) => {
    setSaving(true);
    new Promise((resolve, reject) => {
      dispatch(
        actions.editevaluationCriterias(
          parseInt(id),
          values,
          "EDIT",
          resolve,
          reject
        )
      );
    })
      .then(() => {
        message.success("Evaluation Criteria updated Sucessfully");
        navigate("/evaluationCriteria");
      })
      .catch(() => {
        message.error("Evaluation Criteria update failed");
      })
      .finally(() => {
        setSaving(false);
      });
  };
  const handleDelete = (evaluationCriteriaId) => {
    new Promise((resolve, reject) => {
      dispatch(
        actions.deleteevaluationCriterias(evaluationCriteriaId, resolve, reject)
      );
    })
      .then(() => {
        message.success("Evaluation Criteria deleted Sucessfully");
        navigate("/evaluationCriteria");
      })
      .catch(() => {
        message.error("Evaluation Criteria delete failed");
      })
      .finally(() => {});
  };
  if (saving || !evaluationCriteria) {
    return <Loader />;
  }
  return (
    <AddEditEvaluationCriteria
      handleSave={handleSave}
      evaluationCriteria={evaluationCriteria}
      handleDelete={handleDelete}
    />
  );
}
export default EditEvaluationCriteria;
