import ProgramsSaga from "./sagas";
import ProgramsReducer from "./reducers";
import programsActions from "./actions";

export const programsReducer = {
  programsReducer: ProgramsReducer,
};

export const programsSaga = [ProgramsSaga()];

export { programsActions };
