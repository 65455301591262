import React, { useState } from "react";
import AddEditEvaluationForm from "../components/AddEditEvaluationForm";
import actions from "../../store/actions";
import { useDispatch, useSelector } from "react-redux";
import { getEvaluationID, getEvaluationName } from "../../../../utils/Helper";
import { message } from "antd";
import { useLocation, useNavigate } from "react-router";

function AddEvaluation() {
  const [loading, setLoading] = useState();

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const location = useLocation();
  const evaluationId = getEvaluationID(location);
  const evaluationName = getEvaluationName(location);

  const courseId = localStorage.getItem("selectedCourse");

  const handleSave = (values) => {
    setLoading(true);
    new Promise((resolve, reject) => {
      dispatch(
        actions.saveEvaluation(
          evaluationId,
          courseId,
          values,
          "ADD",
          resolve,
          reject
        )
      );
    })
      .then(() => {
        message.success(evaluationName + " added Sucessfully");
        navigate(`/evaluation/${evaluationId}/${evaluationName}`);
      })
      .catch(() => {
        message.error(evaluationName + " add failed");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return <AddEditEvaluationForm handleSave={handleSave} loading={loading} />;
}
export default AddEvaluation;
