import { Form, message } from "antd";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import AddEditRole from "../../../Components/AddRole";
import Loader from "../../../Components/Loader";
import actions from "../store/actions";

function EditRole() {
  const { id } = useParams();
  const [saving, setSaving] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dispatch = useDispatch();

  //get all roles list
  const role = useSelector(({ rolesReducer }) => {
    const roles = rolesReducer.get("roles");
    return roles.find((role) => {
      return role.id === parseInt(id);
    });
  });

  useEffect(() => {
    if(!role) {
      new Promise((resolve, reject) => {
        dispatch(actions.fetchRolePermissions(resolve, reject));
      })
      .then((response) => {})
      .finally(() => {});
    }
  }, [role]);

  const handleEdit = (value) => {
    setSaving(true);
    const data = {
      ...value,
    };

    new Promise((resolve, reject) => {
      dispatch(actions.editRoles(role.id, data, "ADD", resolve, reject));
    })
      .then(() => {
        message.success("Role edited Sucessfully");
        setUpdateSuccess(true);
        navigate('/role');
      })
      .catch(() => {
        message.error("Role edit failed");
      })
      .finally(() => {
        setSaving(false);
      });
  };

  if (!role || saving) {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Loader />
      </div>
    );
  }

  return (
    <div>
      <AddEditRole handleSave={handleEdit} role={role} />
    </div>
  );
}
export default EditRole;
