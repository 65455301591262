import { Col, Form, Row, Select, Space, message } from "antd";
import ATitle from "../Title";
import AInput from "../Input";
import AButton from "../Button/index";
import { UploadOutlined } from "@ant-design/icons";
import { tokens } from "../../utils/Constants";
import ASelect from "../Select/index";
import { useState, useEffect } from "react";
import QuillEditor from "../Editor/index";
import Uploads from "../Uploads/index";
import React from "react";
import { useNavigate } from "react-router";
import "../../pages/Program/index.css";
import { useDispatch } from "react-redux";
import actions from "../../pages/Program/store/actions";
const AddEditProgram = ({
  handleSave,
  program,
  handleDelete,
  saving,
  deleting = false,
}) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [mediaUrl, setMediaUrl] = useState("");
  const [coursePhotoUrl, setCoursePhotoUrl] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [editorContent, setEditorContent] = useState(program ? program.description : "");
  const dispatch = useDispatch();
  useEffect(() => {}, [program]);
  const handleEditorChange = (content) => {
    setEditorContent(content);
  };

  const handleProgramDelete = (id) => {
    new Promise((resolve, reject) => {
      dispatch(actions.deleteProgram(id, resolve, reject));
    })
      .then(() => {
        message.success("Program delete Success");
        navigate("/program");
      })
      .catch(() => {
        message.error("Program delete fails ");
      })
      .finally(() => {});
  };

  return (
    <div style={{ padding: "1%" }}>
      {/* <ATitle
        content={program ? "Edit Program" : "Add Program "}
        style={{ fontSize: "18px" }}
      /> */}
      <Form
        size="large"
        form={form}
        name="add/editForm"
        onFinish={(values) => {
          values.description = editorContent;
          values.course_photo = mediaUrl || coursePhotoUrl;
          handleSave(values);
        }}
        layout="vertical"
        scrollToFirstError
        title="Add/EditProgram"
        initialValues={
          program
            ? {
                name: program.name,
                programType: program.programType,
                programCode: program.programCode,
                programDescription: program.programDescription,
              }
            : {}
        }
      >
        <Row gutter={20}>
          <Col xs={24} md={8}>
            <Form.Item
              name="name"
              label={
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "19.6px",
                  }}
                >
                  Program Name
                </p>
              }
              rules={[
                {
                  required: true,
                  message: "Please input the Program Name!",
                },
              ]}
            >
              <AInput placeholder={"Enter Program Name"} size="large" />
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name="programType"
              label={
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "19.6px",
                  }}
                >
                  Program Type
                </p>
              }
              rules={[
                {
                  required: true,
                  message: "Please input the Program Category!",
                },
              ]}
            >
              <ASelect customized={false} defaultValue="Select a Type">
                <Select.Option value="Semester">Semester</Select.Option>
                <Select.Option value="Year">Year</Select.Option>
              </ASelect>
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name="programCode"
              label={
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "19.6px",
                  }}
                >
                  Program Code
                </p>
              }
              rules={[
                {
                  required: true,
                  message: "Please input the Program Code!",
                },
              ]}
            >
              <AInput placeholder={"Enter Program Code"} size="large" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col xs={24} md={24}>
            <Form.Item
              name="programDescription"
              label={
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "19.6px",
                  }}
                >
                  Program Description
                </p>
              }
              rules={[
                {
                  required: true,
                  message: "Please input the Description!",
                },
              ]}
            >
              <QuillEditor
                className="toolbar"
                style={{ fontSize: tokens.FONT_FAMILY }}
                value={editorContent}
                onChange={handleEditorChange}
              />
            </Form.Item>
          </Col>
        </Row>

        <Form.Item>
          {/* <div
            style={{
              display: "flex",
              justifyContent: "space-between",
            }}
          > */}
          <div
            style={{
              position: "fixed",
              bottom: 0,
              width: "78vw",
              padding: "1vh",
              display: "flex",
              justifyContent: "space-between",
              marginBottom: "5vh",
            }}
          >
            {program ? (
              <AButton
                type="default"
                size="middle"
                style={{
                  color: tokens.COLOR_DANGER,
                  borderColor: tokens.COLOR_DANGER,
                  margin: "10px",
                }}
                onClick={(e) => {
                  handleProgramDelete(program.id);
                }}
              >
                Delete
              </AButton>
            ) : (
              <AButton
                type="default"
                size="middle"
                onClick={() => navigate("/program")}
                style={{
                  backgroundColor: tokens.COLOR_LIGHTBLACK_L50,
                }}
              >
                Cancel
              </AButton>
            )}

            <AButton
              type="primary"
              htmlType="submit"
              style={{
                color: tokens.BG_LIGHT,
                backgroundColor: tokens.COLOR_LIGHT_ORANGE,
              }}
              loading={saving}
            >
              {program ? "Update Program" : "Create Program"}
            </AButton>
          </div>
        </Form.Item>
        {/* <Row style={{ display: "flex", justifyContent: "flex-end" }}>
          <Form.Item>
            <AButton
              type="primary"
              htmlType="submit"
              size="large"
              style={{
                color: tokens.BG_LIGHT,
                backgroundColor: tokens.COLOR_PRIMARY,
                padding: "0 30px",
              }}
            >
              {"Create"}
            </AButton>
          </Form.Item>
        </Row> */}
      </Form>
    </div>
  );
};

export default AddEditProgram;
