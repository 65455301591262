import React, { useEffect, useState } from "react";
import ATable from "../../Components/Table";
import AButton from "../../Components/Button/index";
import {
  SearchOutlined,
  PlusOutlined,
  WarningFilled,
  FilterOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import { tokens } from "../../utils/Constants";
import { Drawer, Space, message, theme } from "antd";
import AInput from "../../Components/Input";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AModal from "../../Components/Model";
import ATitle from "../../Components/Title";
import actions from "./store/actions";
import Loader from "../../Components/Loader";

function AssignCourse() {
  const [loading, setLoading] = useState(false);
  const [openModel, setOpenModel] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { useToken } = theme;
  const { token } = useToken();
  const [searchTerm, setSearchTerm] = useState("");

  const [openDrawer, setOpenDrawer] = useState(false);
  let assignCourses = useSelector(({ assignCoursesReducer }) =>
  assignCoursesReducer.get("assignCourses")
);
  const showDrawer = () => {
    setOpenDrawer(true);
  };

  const onClose = () => {
    setOpenDrawer(false);
  };

  useEffect(() => {
    setLoading(true);
    new Promise((resolve, reject) => {
      dispatch(actions.fetchAssignCourses(resolve, reject));
    }).finally(() => {
      setLoading(false);
    });
  }, [dispatch, openModel]);



  const filteredAssignCourses =
    assignCourses &&
    assignCourses.filter((batch) => {
      return Object.keys(batch).some((key) => {
        if (key !== "id") {
          const value = batch[key];
          return (
            value &&
            value.toString().toLowerCase().includes(searchTerm.toLowerCase())
          );
        }
        return false;
      });
    });

  const handleAssignCourseDelete = (id) => {
    new Promise((resolve, reject) => {
      dispatch(actions.deleteAssignCourse(id, resolve, reject));
    })
      .then(() => {
        navigate("/assignInstructor");
        message.success("Deleted the assign course record !");
      })
      .catch(() => {
        message.error("Failed to delete the assign course");
      })
      .finally(() => {
        setOpenModel(false);
      });
  };

  const columns = [
    {
      title: "S.N.",
      render: (_, __, index) => <span>{index + 1}</span>,
    },
    {
      title: "Course Name",
      dataIndex: "course_name",
      key: "course_name",
    },
    {
      title: "Instructor Name",
      dataIndex: "instructor_name",
      key: "instructor_name",
    },
    {
      title: "Program",
      dataIndex: "program_code",
      key: "program_code",
    },
    {
      title: "Semester/Class",
      dataIndex: "class",
      key: "class",
    },
    {
      title: "Action",
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <AButton
            type="primary"
            size="middle"
            style={{
              color: tokens.BG_LIGHT,
              backgroundColor: tokens.COLOR_SUCCESS,
            }}
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/assignInstructor/edit/${record.id}`);
            }}
          >
            Edit
          </AButton>
          <AButton
            type="default"
            size="middle"
            style={{
              color: tokens.COLOR_DANGER,
              borderColor: tokens.COLOR_DANGER,
              margin: "10px",
            }}
            onClick={(e) => {
              e.stopPropagation();
              setOpenModel(true);
              setSelectedId([record.id]);
            }}
          >
            Delete
          </AButton>
        </div>
      ),
    },
  ];

  if (loading) {
    return <Loader />;
  }
  return (
    <div>
      <Drawer
        title="Filter"
        placement={"right"}
        onClose={onClose}
        open={openDrawer}
      ></Drawer>
      <Space
        style={{
          width: "100%",
          justifyContent: "flex-end",
          marginBottom: "30px",
        }}
      >
        <AInput
          suffix={<SearchOutlined />}
          placeholder="Search"
          name="search"
          size="large"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <AButton
          type="primary"
          icon={<FilterOutlined />}
          size="large"
          onClick={showDrawer}
          style={{ backgroundColor: tokens.COLOR_LIGHT_ORANGE }}
        >
          Filter
        </AButton>
      </Space>

      <Space
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "30px",
        }}
      >
        <Link to={"/assignInstructor/add"}>
          <AButton
            type="primary"
            icon={<PlusOutlined />}
            size="large"
            style={{ backgroundColor: tokens.COLOR_DARK_ORANGE }}
          >
            Assign New Course
          </AButton>
        </Link>
      </Space>

      {assignCourses && assignCourses.length === 0 && searchTerm == "" ? (
        <Space
          style={{
            minWidth: "100%",
            minHeight: "80vh",
            justifyContent: "center",
            fontFamily: tokens.SECONDARY_FONT_FAMILY,
          }}
          align="center"
        >
          <Space direction="vertical" align="center">
            <ClockCircleOutlined style={{ fontSize: "24pt" }} />
            <ATitle
              level={4}
              content="You don't have any Course Assigned yet"
            />
            <div
              style={{
                border: `1px dashed ${tokens.COLOR_PRIMARY}`,
                padding: "30px",
                borderRadius: "5px",
              }}
            >
              <AButton
                type="default"
                icon={<PlusOutlined />}
                style={{
                  color: tokens.COLOR_PRIMARY,
                  borderColor: tokens.COLOR_PRIMARY,
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/assignInstructor/add`);
                }}
              >
                Assign New Course
              </AButton>
            </div>
          </Space>
        </Space>
      ) : (
        <ATable
          columns={columns}
          dataSource={filteredAssignCourses}
          onRow={(record, rowIndex) => {
            return {
              onDoubleClick: (event) => {
                navigate(`/assignInstructor/edit/${record.id}`);
              },
            };
          }}
        />
      )}

      <AModal
        style={{
          padding: "50px",
        }}
        open={openModel}
        closable={false}
        footer={null}
        className="css-dev-only-do-not-override-1r3vfrt-last"
      >
        <Space
          align="center"
          direction="vertical"
          style={{
            display: "flex",
            alignContent: "center",
          }}
        >
          <Space direction="vertical" size="small" align="center">
            <WarningFilled
              style={{
                fontSize: "65px",
                color: token.colorError,
              }}
            />
            <ATitle content="Are you sure ?" level={4} />
            <p
              style={{
                textAlign: "center",
                width: "100%",
                marginTop: "-5px",
              }}
            >
              Deleting a Assign Course will delete all assign instructor and
              course relation. This process cannot be undo.
            </p>
          </Space>
          <Space>
            <AButton
              type="default"
              size="small"
              onClick={() => setOpenModel(false)}
            >
              Cancel
            </AButton>
            <AButton
              type="primary"
              size="small"
              onClick={() => handleAssignCourseDelete(selectedId)}
              mode="danger"
            >
              Delete
            </AButton>
          </Space>
        </Space>
      </AModal>
    </div>
  );
}

export default AssignCourse;
