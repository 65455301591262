import { Card, Checkbox, Col, Form, Row, Select, Space } from "antd";

import { useState, useEffect } from "react";
import React from "react";
import { useNavigate } from "react-router";
import { DeleteIcon } from "../../../assets/icons/DeleteIcon";
import { EditIcon } from "../../../assets/icons/EditIcon";
import { tokens } from "../../../utils/Constants";
import AButton from "../../../Components/Button";
import AInput from "../../../Components/Input";
import QuillEditor from "../../../Components/Editor";
import { useDispatch, useSelector } from "react-redux";
import programActions from "../../Program/store/actions";
import ASelect from "../../../Components/Select";
import Loader from "../../../Components/Loader";
import actions from "../store/actions";
import axios from "axios";
import AText from "../../../Components/Text";
import ATitle from "../../../Components/Title";
const AddEditBatch = ({
  handleSave,
  batch,
  handleDelete,
  saving = false,
  deleting = false,
  isStudent = true,
}) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [mediaUrl, setMediaUrl] = useState("");
  const [coursePhotoUrl, setCoursePhotoUrl] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [currentState, setCurrentState] = useState(1);
  const [semesterData, setSemesterData] = useState([]);
  const [batchdata, setBatchData] = useState();
  const [initialSemesters, setSemesters] = useState([]);
  const [initialProrgams, setPrograms] = useState();

  useEffect(() => {
    // Reset the form fields
    form.resetFields();
  }, [initialProrgams]);

  const [editorContent, setEditorContent] = useState(
    batch ? batch.description : ""
  );
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedTempCountry, setSelectedTempCountry] = useState("");

  const handleEditorChange = (content) => {
    setEditorContent(content);
  };

  const handleCountryChange = (value) => {
    setSelectedCountry(value);
  };
  const handleTempCountryChange = (value) => {
    setSelectedTempCountry(value);
  };

  const dispatch = useDispatch();
  let programs = useSelector(({ programsReducer }) =>
    programsReducer.get("programs")
  );

  useEffect(() => {
    new Promise((resolve, reject) => {
      dispatch(programActions.fetchPrograms(resolve, reject));
    }).finally(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleNext = async (value) => {
    setLoading(true);
    const data = {
      ...value,
    };
    setBatchData(data);
    try {
      const response = await axios.post(
        process.env.REACT_APP_API_BASE_URL + "/api/semestersByProgram",
        data,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      setSemesterData(response.data); // Assuming the API response is in JSON format
      setCurrentState(2);
    } catch (error) {
      console.error("Error fetching semester data:", error);
      // Handle the error here
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (batch && batch.batchSemester.length > 0) {
      let programIds = batch.batchSemester.map((item) => item.program.id);
      let uniqueProgramIds = [...new Set(programIds)];

      setPrograms(uniqueProgramIds);
      let initialVals = {};

      // Iterate through batchSemester and set initial values for each program
      batch.batchSemester.forEach((item) => {
        const programId = item.program.id;
        const semesterId = item.semester.id;

        if (!initialVals[programId]) {
          initialVals[programId] = [];
        }

        initialVals[programId].push(semesterId);
      });
      setSemesters(initialVals);
    }
  }, [batch && batch.batchSemester]);

  if (!programs) {
    <Loader />;
  }
  if (batch) {
    if (!initialProrgams) {
      return <Loader />;
    }
  }
  return (
    <div style={{ padding: "1%" }}>
      {currentState === 1 && (
        <div>
          <Form
            size="large"
            form={form}
            name="add/editForm"
            onFinish={(values) => {
              handleNext(values);
            }}
            layout="vertical"
            scrollToFirstError
            title="Add/Editbatch"
            initialValues={
              batch
                ? {
                    name: batch.batch.name,
                    description: batch.batch.description,
                    program: initialProrgams && initialProrgams,
                  }
                : {}
            }
          >
            <Row gutter={20}>
              <Col span={12}>
                <Form.Item
                  name="name"
                  label={
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "19.6px",
                      }}
                    >
                      Batch Name
                    </p>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Please input the Name!",
                    },
                  ]}
                >
                  <AInput placeholder={"Enter Name"} size="large" />
                </Form.Item>
              </Col>
              {/* </Row>
        <Row> */}
              <Col xs={24} md={12}>
                <Form.Item
                  name="program"
                  label={
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "19.6px",
                      }}
                    >
                      Program
                    </p>
                  }
                  rules={
                    !initialProrgams && [
                      {
                        required: true,
                        message: "Please select a program",
                      },
                    ]
                  }
                >
                  {/* <ASelect customized={false} defaultValue="Choose Program">
                {programs &&
                  programs.map((element) => (
                    <Select.Option value={element.id}>
                      {element.name}
                    </Select.Option>
                  ))}
              </ASelect> */}
                  <ASelect
                    customized={false}
                    mode="multiple"
                    placeholder="Choose Program"
                  >
                    {programs &&
                      programs.map((element) => (
                        <Select.Option key={element.id} value={element.id}>
                          {element.name}
                        </Select.Option>
                      ))}
                  </ASelect>
                </Form.Item>
              </Col>
            </Row>

            <Row>
              <Col span={24}>
                <Form.Item
                  name="description"
                  label={
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "19.6px",
                      }}
                    >
                      Batch Description
                    </p>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Please input the Description!",
                    },
                  ]}
                >
                  <QuillEditor
                    className="toolbar"
                    style={{
                      fontSize: tokens.FONT_FAMILY,
                      marginLeft: "-10px",
                    }}
                    value={editorContent}
                    onChange={handleEditorChange}
                  />
                </Form.Item>
              </Col>
            </Row>

            {/*buttom*/}
            <Form.Item>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <AButton
                  type="default"
                  size="middle"
                  onClick={() => navigate("/batch")}
                  style={{
                    backgroundColor: tokens.COLOR_LIGHTBLACK_L50,
                  }}
                >
                  Cancel
                </AButton>
                <AButton
                  type="primary"
                  htmlType="submit"
                  size="large"
                  style={{
                    color: tokens.BG_LIGHT,
                    backgroundColor: tokens.COLOR_LIGHT_ORANGE,
                    padding: "0 30px",
                  }}
                  loading={isLoading}
                >
                  Next
                </AButton>
              </div>
            </Form.Item>
          </Form>
        </div>
      )}
      {/* second state */}
      {currentState === 2 && (
        <Space
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
          }}
        >
          <ATitle
            content={"Select Semesters|Years"}
            style={{ fontSize: "16px" }}
          />
          <Form
            onFinish={(values) => {
              handleSave(values, batchdata);
            }}
            layout="vertical"
            initialValues={initialSemesters}
            style={{ width: "100%" }}
          >
            <div
              style={{
                display: "flex",
                flexWrap: "wrap",
                width: "100%",
              }}
            >
              {semesterData.map((program) => (
                <Card
                  key={program.id}
                  style={{
                    marginBottom: "16px",
                    width: "300px",
                    marginRight: "16px",
                    flex: "1 1 240px",
                  }}
                >
                  <h3>{program.program_name}</h3>
                  <Form.Item
                    name={program.id}
                    rules={
                      program.semesters.length > 0 && [
                        {
                          required: true,
                          message: "Please choose at least one Semester!",
                        },
                      ]
                    }
                  >
                    {program.semesters.length > 0 ? (
                      <Checkbox.Group
                        style={{ display: "flex", flexDirection: "column" }}
                      >
                        {program.semesters.map((semester) => (
                          <Checkbox key={semester.id} value={semester.id}>
                            {semester.name}
                          </Checkbox>
                        ))}
                      </Checkbox.Group>
                    ) : (
                      "No Semester / Year "
                    )}
                  </Form.Item>
                </Card>
              ))}
            </div>

            <Form.Item
              style={{
                width: "100%",
                marginTop: "2vh",
              }}
            >
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                }}
              >
                <AButton
                  type="default"
                  size="middle"
                  onClick={() => setCurrentState(1)}
                  style={{
                    backgroundColor: tokens.COLOR_LIGHTBLACK_L50,
                  }}
                >
                  Previous
                </AButton>
                <AButton
                  type="primary"
                  htmlType="submit"
                  size="large"
                  style={{
                    color: tokens.BG_LIGHT,
                    backgroundColor: tokens.COLOR_LIGHT_ORANGE,
                    padding: "0 30px",
                  }}
                  loading={isLoading}
                >
                  {batch ? "Update" : "Create"}
                </AButton>{" "}
              </div>
            </Form.Item>
          </Form>
        </Space>
      )}
    </div>
  );
};

export default AddEditBatch;
