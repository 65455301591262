import { Form, Checkbox, Row, Col } from "antd";
// import { AButton, AInput, AText, ATitle } from "components";
import loginImage from "../../assets/images/login_image.png";
import logo from "../../assets/images/Logo.png";
import { Link } from "react-router-dom";
import { AxiosError } from "axios";
import React from "react";
import AButton from "../Button/index";
import AInput from "../Input";
import AText from "../Text";
import ATitle from "../Title";

function LoginForm({ onFinish, onFinishFailed, loading }) {
  return (
    <Row style={{ width: "100%", height: "100vh" }}>
      <Col
        md={12}
        style={{
          backgroundColor: "#F0EEF5",
          display: "flex",
          justifyContent: "center",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <img width={560} alt="login_image" height={400} src={loginImage} />
        <ATitle
          content="Efficiency for Successful Student Management"
          style={{
            fontSize: "32px",
            fontWeight: 600,
            textAlign: "center",
            width: "452px",
            lineHeight: "44.8px",
          }}
        />
        <AText
          content="Providing schools and universities with the tools they need to effectively manage student data, automate processes, and ensure academic success for every student"
          style={{
            fontSize: "16px",
            fontWeight: 400,
            textAlign: "center",
            width: "480px",
            lineHeight: "22.4px",
            gap: "40px",
          }}
        />
      </Col>
      <Col
        md={12}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "left",
          flexDirection: "column",
          padding: "0 10%",
        }}
      >
        <img
          src={logo}
          alt="logo"
          width={285}
          height={120}
          style={{ marginLeft: "10%" }}
        />

        <ATitle
          content="Sign in to your Account"
          level={5}
          style={{ fontSize: "32px", fontWeight: 600 }}
        />
        <Form
          name="basic"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          autoComplete="off"
          layout="vertical"
        >
          <Form.Item
            label="Username"
            name="email"
            rules={[{ required: true, message: "Please input your username!" }]}
          >
            <AInput bordered={true} placeholder="User Name" size="large" />
          </Form.Item>

          <Form.Item
            label="Password"
            name="password"
            rules={[{ required: true, message: "Please input your password!" }]}
          >
            <AInput
              bordered={true}
              type="password"
              placeholder="Password"
              size="large"
            />
          </Form.Item>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Form.Item name="remember" valuePropName="checked">
              <Checkbox>Remember me</Checkbox>
            </Form.Item>

            <Link to={"/forgotpassword"}>Forgot Password? </Link>
          </div>

          <Form.Item>
            <AButton
              fullwidth={true}
              type="primary"
              htmlType="submit"
              size="large"
              loading={loading}
            >
              Login
            </AButton>
          </Form.Item>
        </Form>
      </Col>
    </Row>
  );
}

export default LoginForm;
