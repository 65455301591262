import React from "react";
import Icon from "@ant-design/icons";

export const DeleteIcon = (props) => {
  const deleteSvg = () => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g id="heroicons-outline/trash">
        <path
          id="Vector"
          d="M13.512 8.25L13.1947 16.5M8.80529 16.5L8.48798 8.25M17.6253 5.30802C17.9388 5.35536 18.2512 5.40601 18.5625 5.45993M17.6253 5.30802L16.6465 18.0332C16.5638 19.1077 15.6678 19.9375 14.5901 19.9375H7.40994C6.33221 19.9375 5.43617 19.1077 5.35351 18.0332L4.37466 5.30802M17.6253 5.30802C16.5745 5.14932 15.5114 5.02778 14.4375 4.9448M3.4375 5.45993C3.74878 5.40601 4.06117 5.35536 4.37466 5.30802M4.37466 5.30802C5.42554 5.14932 6.48862 5.02778 7.5625 4.9448M14.4375 4.9448V4.105C14.4375 3.02392 13.6027 2.12138 12.5222 2.08681C12.0168 2.07065 11.5093 2.0625 11 2.0625C10.4907 2.0625 9.98321 2.07065 9.4778 2.08681C8.39727 2.12138 7.5625 3.02392 7.5625 4.105V4.9448M14.4375 4.9448C13.3032 4.85714 12.1568 4.8125 11 4.8125C9.8432 4.8125 8.69682 4.85714 7.5625 4.9448"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
  return <Icon component={deleteSvg} {...props} />;
};
