import React, { useState } from "react";
import { useNavigate } from "react-router";
import AddEditAssignCourse from "../../../Components/AddEditAssignCourse";
import actions from "../store/actions";
import { message } from "antd";
import { useDispatch } from "react-redux";

function AddAssignCourse() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);

  const handleSave = (value) => {
    setSaving(true);
    const data = {
      ...value,
    };

    new Promise((resolve, reject) => {
      dispatch(actions.saveAssignCourse(data, "ADD", resolve, reject));
    })
      .then(() => {
        message.success("Assign Course to Instructor Successfully");
        navigate("/assignInstructor");
      })
      .catch(() => {
        message.error("Assign Course Failed !");
      })
      .finally(() => {navigate("/assignInstructor");setSaving(false)});
  };
  return (
    <div>
      <AddEditAssignCourse handleSave={handleSave} saving={saving}/>
    </div>
  );
}

export default AddAssignCourse;
