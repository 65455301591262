import React, { useEffect, useState } from "react";
import ATable from "../../Components/Table";
import AButton from "../../Components/Button/index";
import {
  WarningFilled,
  PlusOutlined,
  SearchOutlined,
  FilterOutlined,
  UploadOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons";
import { tokens } from "../../utils/Constants";
import {
  Upload,
  Col,
  Drawer,
  Form,
  Row,
  Select,
  Space,
  message,
  Button,
} from "antd";
import AModal from "../../Components/Model";
import { Link, useNavigate } from "react-router-dom";
import ATitle from "../../Components/Title";
import { useDispatch, useSelector } from "react-redux";
import actions from "./store/actions";
import AInput from "../../Components/Input";
import { read, utils } from "xlsx";

function Instructor() {
  const [openModel, setOpenModel] = useState(false);
  const [openCSVModel, setOpenCSVModel] = useState(false);
  const [loadingInstructorDelete, setLoadingInstructorDelete] = useState(false);
  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [searchTerm, setSearchTerm] = useState("");
  const [saving, setSaving] = useState(false);
  const [clickAdd, setClickAdd] = useState(false);
  const [fileData, setFileData] = useState([]);
  const [file, setFile] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [openDrawer, setOpenDrawer] = useState(false);
  const showDrawer = () => {
    setOpenDrawer(true);
  };

  const onClose = () => {
    setOpenDrawer(false);
  };

  let instructors = useSelector(({ instructorsReducer }) =>
    instructorsReducer.get("instructors")
  );

  const filteredInstructors =
    instructors &&
    instructors.filter((instructor) => {
      return Object.keys(instructor).some((key) => {
        if (key !== "id") {
          const value = instructor[key];
          return (
            value &&
            value.toString().toLowerCase().includes(searchTerm.toLowerCase())
          );
        }
        return false;
      });
    });

  const expectedColumns = [
    "S.N.",
    "First Name",
    "Middle Name",
    "Last Name",
    "Email",
    "Address",
    "City",
    "State",
    "Contact Number",
    "Gender",
    "Qualification",
  ];

  const beforeUpload = (file) => {
    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      let data = utils.sheet_to_json(ws, { header: 1 });
      data = data.filter((item) => item.length > 0);

      const missingColumns = expectedColumns.filter(
        (col) => !data[0].includes(col)
      );

      if (missingColumns.length > 0) {
        message.error(
          `File is missing the following columns: ${missingColumns.join(
            ", "
          )}. Please verify and upload again.`
        );
        return false;
      }

      const emptyColumnRows = data.slice(1).filter((row) => {
        const middleNameIndex = expectedColumns.indexOf("Middle Name");
        const rowWithoutMiddleName = [
          ...row.slice(0, middleNameIndex),
          ...row.slice(middleNameIndex + 1),
        ];
        return rowWithoutMiddleName.some((col) => !col);
      });

      if (emptyColumnRows.length > 0) {
        const emptyColumnRowNumbers = emptyColumnRows.map((row) => row[0]);
        message.error(
          `Empty values found in S.N. : ${emptyColumnRowNumbers.join(
            ", "
          )}. Please fill in all columns values before uploading.`
        );
        return false; // Empty values found in other columns
      }

      const emailIndex = expectedColumns.indexOf("Email");

      const invalidEmails = data.slice(1).filter((row) => {
        const email = row[emailIndex];
        return !isValidEmail(email);
      });

      if (invalidEmails.length > 0) {
        const invalidEmailRowNumbers = invalidEmails.map((row) => row[0]);
        message.error(
          `Invalid email format found in S.N. : ${invalidEmailRowNumbers.join(
            ", "
          )}. Please provide valid email addresses.`
        );
        return false;
      }

      const contactIndex = expectedColumns.indexOf("Contact Number");

      const invalidContact = data.slice(1).filter((row) => {
        const contact = row[contactIndex];
        return !isValidContact(contact);
      });

      if (invalidContact.length > 0) {
        const invalidContactRowNumbers = invalidContact.map((row) => row[0]);
        message.error(
          `Invalid contact number format found in S.N. : ${invalidContactRowNumbers.join(
            ", "
          )}. Please provide valid contact number.`
        );
        return false;
      }

      const isValidGender = checkValidValues(
        data,
        ["Male", "Female", "Others"],
        "Gender"
      );

      if (!isValidGender) {
        return false;
      }

      const isValidState = checkValidValues(
        data,
        [
          "Koshi",
          "Madhesh",
          "Bagmati",
          "Gandaki",
          "Lumbini",
          "Karnali",
          "Sudurpashchim",
        ],
        "State"
      );

      if (!isValidState) {
        return false;
      }

      const jsonData = data.slice(1).map((row) => {
        const obj = {};
        expectedColumns.forEach((col, index) => {
          obj[col] = row[index];
        });
        return obj;
      });

      setFile(jsonData);
      setFileData(jsonData);
    };
    reader.readAsBinaryString(file);
    return false; // do not automatically upload file
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidContact = (contact) => {
    const contactRegex = /^(?:(98|97)\d{8}|0\d{8,10})$/;
    return contactRegex.test(contact);
  };

  const checkValidValues = (data, validValues, columnName) => {
    const columnIndex = expectedColumns.indexOf(columnName);
    const invalidRows = data.slice(1).filter((row, index) => {
      const value = row[columnIndex];
      return !validValues.includes(value);
    });

    if (invalidRows.length > 0) {
      const invalidRowNumbers = invalidRows.map((row) => row[0]);
      message.error(
        `Invalid ${columnName} values found in S.N. : ${invalidRowNumbers.join(
          ", "
        )}. Please use ${validValues
          .map((value) => `'${value}'`)
          .join(", ")} for the '${columnName}' column.`
      );
      return false; // Invalid values found
    }

    return true; // No invalid values found
  };

  const handleUpload = (values) => {
    setSaving(true);
    setClickAdd(false);
    const data = {
      ...values,
    };
    new Promise((resolve, reject) => {
      dispatch(actions.uploadInstructor(data, resolve, reject));
    })
      .then(() => {
        message.success("Instructors added successfully");
        navigate("/instructor");
      })
      .catch((error) => {
        message.error("Issue while adding the instructors !!!");
      })
      .finally(() => {
        setSaving(false);
        setOpenCSVModel(false);
      });
  };

  if (clickAdd) {
    handleUpload(fileData);
  }

  useEffect(() => {
    new Promise((resolve, reject) => {
      dispatch(actions.fetchInstructors(resolve, reject));
    }).finally(() => {});
  }, [dispatch, openModel]);

  const handleInstructorDelete = (id) => {
    setLoadingInstructorDelete(true);
    new Promise((resolve, reject) => {
      dispatch(actions.deleteInstructor(id, resolve, reject));
    })
      .then(() => {
        message.success("Instructor delete Success");
      })
      .catch(() => {
        message.error("Instructor delete fails ");
      })
      .finally(() => {
        setOpenModel(false);
        setLoadingInstructorDelete(false);
      });
  };
  const columns = [
    {
      title: "S.N.",
      render: (_, __, index) => <span>{index + 1}</span>,
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: "Middle Name",
      dataIndex: "middle_name",
      key: "middle_name",
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Contact Number",
      dataIndex: "contactNumber",
      key: "phone",
    },
    {
      title: "City ",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "Action",
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            // justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <AButton
            type="primary"
            size="middle"
            style={{
              color: tokens.BG_LIGHT,
              backgroundColor: tokens.COLOR_SUCCESS,
            }}
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/instructor/edit/${record.id}`);
            }}
          >
            Edit
          </AButton>
          <AButton
            type="default"
            size="middle"
            style={{
              color: tokens.COLOR_DANGER_D300,
              borderColor: tokens.COLOR_DANGER_D300,
              margin: "10px",
            }}
            onClick={(e) => {
              e.stopPropagation();
              setOpenModel(true);
              setSelectedId(record.id);
            }}
          >
            Delete
          </AButton>
        </div>
      ),
    },
  ];

  return (
    <div>
      <Drawer
        title="Filter"
        placement={"right"}
        onClose={onClose}
        open={openDrawer}
      ></Drawer>
      <Space
        style={{
          width: "100%",
          justifyContent: "flex-end",
          marginBottom: "30px",
        }}
      >
        <AInput
          suffix={<SearchOutlined />}
          placeholder="Search"
          name="search"
          size="large"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <AButton
          type="primary"
          icon={<FilterOutlined />}
          size="large"
          onClick={showDrawer}
          style={{ backgroundColor: tokens.COLOR_LIGHT_ORANGE }}
        >
          Filter
        </AButton>
      </Space>

      <Space
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "1.5em 0",
        }}
      >
        <Link to={"/instructor/add"}>
          <AButton
            type="primary"
            icon={<PlusOutlined />}
            size="large"
            style={{ backgroundColor: tokens.COLOR_SUCCESS }}
          >
            Add New Instructor
          </AButton>
        </Link>
        <AButton
          type="primary"
          icon={<UploadOutlined />}
          size="large"
          style={{ backgroundColor: tokens.COLOR_SUCCESS }}
          onClick={(e) => {
            e.stopPropagation();
            setOpenCSVModel(true);
          }}
        >
          Upload Instructor List
        </AButton>
      </Space>

      <ATable
        columns={columns}
        dataSource={filteredInstructors}
        onRow={(record, rowIndex) => {
          return {
            onClick: ({ event }) => {
              navigate(`/instructor/details/${record.id}`);
            }, // click row
            onDoubleClick: (event) => {
              navigate(`/instructor/details/${record.id}`);
            }, // double click row
          };
        }}
      />
      {/* model to delete the college instructor */}
      <AModal
        style={{
          padding: "50px",
        }}
        open={openModel}
        closable={false}
        footer={null}
        className="css-dev-only-do-not-override-1r3vfrt-last"
      >
        <Space
          align="center"
          direction="vertical"
          style={{
            display: "flex",
            alignContent: "center",
          }}
        >
          <Space direction="vertical" size="small" align="center">
            <WarningFilled
              style={{
                fontSize: "65px",
                color: tokens.colorError,
              }}
            />
            <ATitle content="Are you sure ?" level={4} />
            <p
              style={{
                textAlign: "center",
                width: "100%",
                marginTop: "-5px",
              }}
            >
              Deleting items from this directory cannot be undo.
            </p>
          </Space>
          <Space>
            <AButton
              type="default"
              size="small"
              onClick={() => setOpenModel(false)}
            >
              Cancel
            </AButton>
            <AButton
              type="primary"
              size="small"
              onClick={() => handleInstructorDelete(selectedId)}
              mode="danger"
              loading={loadingInstructorDelete}
            >
              Delete
            </AButton>
          </Space>
        </Space>
      </AModal>
      {/* Model for add CSV file for new instructor */}
      <AModal
        style={{
          padding: "50px",
        }}
        open={openCSVModel}
        closable={false}
        footer={null}
        className="css-dev-only-do-not-override-1r3vfrt-last"
      >
        <InfoCircleOutlined
          style={{
            position: "absolute",
            top: "20px",
            right: "20px",
            fontSize: "24px",
            color: "blue",
          }}
          onClick={(e) => {
            e.stopPropagation();
            setIsInfoModalVisible(true);
            setOpenCSVModel(false);
          }}
        />
        <Space
          align="center"
          direction="vertical"
          style={{
            display: "flex",
            alignContent: "center",
          }}
        >
          <Space direction="vertical" size="small" align="center">
            <PlusOutlined
              style={{
                fontSize: "35px",
                background: tokens.COLOR_SUCCESS,
                color: tokens.BG_LIGHT,
                borderRadius: 50,
                padding: 10,
              }}
            />

            <ATitle content="Upload Your Spreadsheet file" level={4} />
            <Upload
              name="file"
              accept=".xlsx, .xls, .csv"
              beforeUpload={beforeUpload}
              fileList={
                file
                  ? [{ uid: "-1", name: "File Uploaded", status: "done" }]
                  : []
              }
              onRemove={() => setFile(null)}
            >
              <Button icon={<UploadOutlined />}>Upload Spreadsheet</Button>
            </Upload>
          </Space>
          <Space>
            <AButton
              type="primary"
              size="small"
              mode="default"
              loading={saving}
              onClick={() => setClickAdd(true)}
            >
              Add Instructors
            </AButton>
            <AButton
              type="default"
              size="small"
              mode="danger"
              onClick={(e) => {
                e.stopPropagation();
                setFile(null);
                setFileData([]);
                setOpenCSVModel(false);
              }}
            >
              Cancel
            </AButton>
          </Space>
        </Space>
      </AModal>

      {/* model for info of spreadsheet columns details */}
      <AModal
        style={{
          padding: "5px",
        }}
        open={isInfoModalVisible}
        closable={false}
        footer={null}
        className="css-dev-only-do-not-override-1r3vfrt-last"
      >
        <Space
          align="center"
          direction="vertical"
          style={{
            display: "flex",
            alignContent: "center",
            padding: "20px",
          }}
        >
          <Space direction="vertical" size="small" align="center">
            <InfoCircleOutlined
              style={{
                fontSize: "35px",
                color: "blue",
                marginBottom: "10px",
              }}
            />
            <ATitle content="Info" level={4} />
            <p
              style={{
                textAlign: "justify",
                width: "100%",
              }}
            >
              Welcome to the instructor upload section! Here, you can
              effortlessly add multiple instructors by uploading a spreadsheet
              file. It's important to include specific columns in your file:
              <span style={{ fontWeight: 700 }}>
                S.N., First Name, Middle Name, Last Name, Email, Address, City,
                State, Contact Number, Gender, and Qualification.
              </span>
              <br />
              Kindly take note of the following important details:
              <ul>
                <li>
                  For the 'Gender' column, use 'Male', 'Female', or 'Others'.
                </li>
                <li>
                  For the 'State' column, use one of the following: 'Bagmati',
                  'Koshi', 'Madhesh', 'Gandaki', 'Lumbini', 'Karnali', or
                  'Sudurpashchim'.
                </li>
              </ul>
              Ensuring these details are present will help streamline the
              process. Thank you for your cooperation!
            </p>
          </Space>

          <Space>
            <AButton
              type="primary"
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                setOpenCSVModel(true);
                setIsInfoModalVisible(false);
              }}
            >
              Add Spreadsheet File
            </AButton>
            <AButton
              type="primary"
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                setIsInfoModalVisible(false);
              }}
              mode="danger"
            >
              Close
            </AButton>
          </Space>
        </Space>
      </AModal>
    </div>
  );
}

export default Instructor;
