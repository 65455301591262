import { Col, Form, Row, Space, message } from "antd";
import React, { useState } from "react";
import AInput from "../Input";
import AButton from "../Button/index";
import { useNavigate } from "react-router";
import { tokens } from "../../utils/Constants";
import { useDispatch } from "react-redux";
import { validateRoleCode } from "../../utils/Helper";

const AddEditRole = ({ handleSave, role, handleDelete }) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();

  return (
    <Space>
      <Form
        size="large"
        form={form}
        name="add/editForm"
        onFinish={(values) => {
          handleSave(values);
        }}
        layout="vertical"
        autoComplete="off"
        scrollToFirstError
        title={role ? "Edit Role" : "Add Role"}
        style={{ width: "80vw" }}
        initialValues={
          role
            ? {
                role_name: role.name,
                role_code: role.code,
              }
            : {}
        }
      >
        <Row gutter={20}>
          <Col span={8}>
            <Form.Item
              name="role_name"
              label={
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "19.6px",
                  }}
                >
                  Role Name
                </p>
              }
              rules={[
                {
                  required: true,
                  message: "Please input the role name!",
                },
              ]}
            >
              <AInput placeholder={"Enter Role Name"} size="large" />
            </Form.Item>
          </Col>
          <Col span={8}></Col>
        </Row>

        <Row gutter={20}>
          <Col span={8}>
            <Form.Item
              name="role_code"
              label={
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "19.6px",
                  }}
                >
                  Role Code
                </p>
              }
              rules={[
                {
                  required: true,
                  message: "Please input the role code!",
                  validateTrigger: "onFinish",
                },
                {
                  validator: validateRoleCode,
                },
              ]}
            >
              <AInput placeholder={"Enter Role Code"} size="large" />
            </Form.Item>
          </Col>
          <Col span={8}></Col>
        </Row>

        <Form.Item>
          <div
            style={{
              display: "flex",
              justifyContent: "space-evenly",
              marginTop: "20vh",
            }}
          >
            <AButton
              type="default"
              size="middle"
              onClick={() => navigate("/role")}
              style={{
                backgroundColor: tokens.COLOR_LIGHTBLACK_L50,
              }}
            >
              Cancel
            </AButton>
            {role ? (
              <AButton
                type="primary"
                htmlType="submit"
                style={{
                  color: tokens.BG_LIGHT,
                  backgroundColor: tokens.COLOR_LIGHT_ORANGE,
                }}
              >
                Update
              </AButton>
            ) : (
              <AButton
                type="primary"
                htmlType="submit"
                style={{
                  color: tokens.BG_LIGHT,
                  backgroundColor: tokens.COLOR_LIGHT_ORANGE,
                }}
              >
                Create Role
              </AButton>
            )}
          </div>
        </Form.Item>
      </Form>
    </Space>
  );
};
export default AddEditRole;
