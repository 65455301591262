import React, { useState, useEffect } from "react";
import { Space } from "antd";
import { AButton, AText, ATitle } from "../../Components";
import AddEditTimeSlot from "../../Components/AddEditTimeSlot";
import { PlusOutlined, ClockCircleOutlined } from "@ant-design/icons";
import { tokens } from "../../utils/Constants";
import { useDispatch, useSelector } from "react-redux";
import { hasPermission } from "../../utils/Helper";
import actions from "./store/actions";
import Loader from "../../Components/Loader";

function TimeSlot() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [content, setContent] = useState([]);

  const handleAdd = () => {
    const newContent = [...content, <AddEditTimeSlot />];
    setContent(newContent);
  };
  let timeSlots = useSelector(({ timeSlotReducer }) =>
    timeSlotReducer.get("timeSlots")
  );

  useEffect(() => {
    setIsLoading(true);
    new Promise((resolve, reject) => {
      dispatch(actions.fetchTimeSlots(resolve, reject));
    }).finally(() => {
      setIsLoading(false);
    });
  }, [dispatch]);

  useEffect(() => {
    if (timeSlots.length > 0) {
      const newContent = [];
      timeSlots.map((timeSlot) => {
        newContent.push(
          <AddEditTimeSlot initialValue={timeSlot} disabled={true} />
        );
      });
      setContent(newContent);
    } else {
      setContent([]);
    }
  }, [timeSlots]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <div>
      {content.length === 0 ? (
        <Space
          style={{
            minWidth: "100%",
            minHeight: "80vh",
            justifyContent: "center",
          }}
          align="center"
        >
          <Space direction="vertical" align="center">
            <ClockCircleOutlined style={{ fontSize: "24pt" }} />
            <ATitle level={4} content="You don't have any Class Time" />
            {hasPermission("add_time_slot") && (
              <AButton
                type="default"
                icon={<PlusOutlined />}
                style={{
                  color: tokens.COLOR_PRIMARY,
                  borderColor: tokens.COLOR_PRIMARY,
                }}
                onClick={handleAdd}
              >
                Add New Time Slot
              </AButton>
            )}
          </Space>
        </Space>
      ) : (
        <div
          style={{
            backgroundColor: tokens.BG_LIGHT,
            borderRadius: "10px",
            margin: "10px 0",
            padding: "20px",
          }}
        >
          <Space direction="vertical" style={{ width: "100%" }} size="large">
            <AText strong content="Add time" />
            {content}
            {hasPermission("add_time_slot") && (
              <AButton
                type="primary"
                onClick={handleAdd}
                htmlType="submit"
                icon={<PlusOutlined />}
              >
                Add
              </AButton>
            )}
          </Space>
        </div>
      )}
    </div>
  );
}

export default TimeSlot;
