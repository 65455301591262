import { takeLatest, put, call, all } from "redux-saga/effects";
import Api from "../../../services/api";
import actions from "./actions";
import { act } from "react-dom/test-utils";

const api = new Api();

const fetchPrograms = takeLatest(actions.FETCH_PROGRAMS, function* (action) {
  try {
    const response = yield call(api.get, "api/program");
    yield put({
      type: actions.FETCH_PROGRAMS_SUCCESS,
      payload: response,
    });
    yield call(action.resolve, "success");
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

const addProgram = takeLatest(actions.SAVE_PROGRAM, function* (action) {
  try {
    const response = yield call(api.post, "api/program", action.data, null);
    action.data.id = response.id;
    yield put({
      type: actions.SAVE_PROGRAM_SUCCESS,
      payload: response,
    });

    yield call(action.resolve, "success");
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

const editProgram = takeLatest(actions.EDIT_PROGRAM, function* (action) {
  try {
    const response = yield call(
      api.put,
      `api/program/${action.programId}`,
      action.data,
      null
    );
    yield call(action.resolve, "success");
    yield put({
      type: actions.EDIT_PROGRAM_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

const deleteProgram = takeLatest(actions.DELETE_PROGRAM, function* (action) {
  try {
    const responseId = yield call(
      api.delete,
      `api/program/${action.programId}`
    );
    yield put({
      type: actions.DELETE_PROGRAM_SUCCESS,
      id: action.id,
    });

    yield call(action.resolve, responseId);
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

const fetchSemesters = takeLatest(actions.FETCH_SEMESTERS, function* (action) {
  try {
    const response = yield call(
      api.get,
      `api/semester?program_id=${action.programId}`
    );
    yield put({
      type: actions.FETCH_SEMESTERS_SUCCESS,
      payload: response,
    });
    yield call(action.resolve, "success");
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

const saveSemester = takeLatest(actions.SAVE_SEMESTER, function* (action) {
  try {
    const response = yield call(api.post, "api/semester", action.data, null);
    action.data.id = response.id;
    yield put({
      type: actions.SAVE_SEMESTER_SUCCESS,
      payload: response,
    });

    yield call(action.resolve, "success");
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

const editSemester = takeLatest(actions.EDIT_SEMESTER, function* (action) {
  try {
    const response = yield call(
      api.put,
      `api/semester/${action.semesterID}`,
      action.data,
      null
    );
    yield call(action.resolve, "success");
    yield put({
      type: actions.EDIT_SEMESTER_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

const deleteSemester = takeLatest(actions.DELETE_SEMESTER, function* (action) {
  try {
    const responseId = yield call(
      api.delete,
      `api/semester/${action.semesterID}`
    );
    yield put({
      type: actions.DELETE_SEMESTER_SUCCESS,
      id: action.id,
    });

    yield call(action.resolve, responseId);
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

export default function* saga() {
  yield all([
    fetchPrograms,
    addProgram,
    editProgram,
    deleteProgram,
    fetchSemesters,
    saveSemester,
    editSemester,
    deleteSemester,
  ]);
}
