import { takeLatest, put, call, all } from "redux-saga/effects";
import Api from "../../../services/api";
import actions from "./actions";

const api = new Api();

const fetchSuperAdmins = takeLatest(
  actions.FETCH_SUPERADMINS,
  function* (action) {
    try {
      const response = yield call(api.get, "api/superadmin");
      yield put({
        type: actions.FETCH_SUPERADMINS_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, "success");
    } catch (error) {
      yield call(action.reject, "failed");
    }
  }
);

const addSuperAdmin = takeLatest(actions.SAVE_SUPERADMIN, function* (action) {
  try {
    const response = yield call(api.post, "api/superadmin", action.data, null);
    action.data.id = response.id;
    yield put({
      type: actions.SAVE_SUPERADMIN_SUCCESS,
      payload: response,
    });

    yield call(action.resolve, "success");
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

const deleteSuperAdmin = takeLatest(
  actions.DELETE_SUPERADMIN,
  function* (action) {
    try {
      const responseId = yield call(api.delete, `api/superadmin/${action.id}`);
      yield put({
        type: actions.DELETE_SUPERADMIN_SUCCESS,
        payload: responseId,
      });

      yield call(action.resolve, responseId);
    } catch (error) {
      yield call(action.reject, "failed");
    }
  }
);

export default function* saga() {
  yield all([fetchSuperAdmins, addSuperAdmin, deleteSuperAdmin]);
}
