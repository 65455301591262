import React, { useState } from "react";
import { useNavigate } from "react-router";
import AddEditProgram from "../../../Components/AddEditProgram/index";
import { useDispatch } from "react-redux";
import { message } from "antd";
import actions from "../store/actions";
import Loader from "../../../Components/Loader";
function AddProgram() {
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);

  let navigate = useNavigate();
  const handleSave = (value) => {
    setSaving(true);
    const data = {
      ...value,
    };
    new Promise((resolve, reject) => {
      dispatch(actions.saveProgram(data, "ADD", resolve, reject));
    })
      .then(() => {
        message.success("College added Sucessfully");
        setUpdateSuccess(true);
        navigate("/program");
      })
      .catch(() => {
        setSaving(false);
        message.error("College add failed");
      })
      .finally(() => {
        setSaving(false);
      });
  };
  if (saving) {
    <Loader />;
  }
  return (
    <div>
      <AddEditProgram handleSave={handleSave} saving={saving}/>
    </div>
  );
}

export default AddProgram;
