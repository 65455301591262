import { takeLatest, put, call, all } from "redux-saga/effects";
import Api from "../../../services/api";
import actions from "./actions";

const api = new Api();

const fetchAssignTimeSlots = takeLatest(actions.FETCH_ASSIGN_TIMESLOTS, function* (action) {
  try {
    const response = yield call(api.get, "api/assignTimeSlot");
    yield put({
      type: actions.FETCH_ASSIGN_TIMESLOTS_SUCCESS,
      payload: response,
    });
    yield call(action.resolve, "success");
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

const saveAssignTimeSlot = takeLatest(actions.SAVE_ASSIGN_TIMESLOT, function* (action) {
  try {
    const response = yield call(
      api.put,
      `api/assignTimeSlot/save`,
      action.data,
      null
    );
    yield call(action.resolve, "success");
    yield put({
      type: actions.SAVE_ASSIGN_TIMESLOT_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

export default function* saga() {
  yield all([
    fetchAssignTimeSlots,
    saveAssignTimeSlot,
  ]);
}
