import { takeLatest, put, call, all } from "redux-saga/effects";
import Api from "../../../services/api";
import actions from "./actions";
import { act } from "react-dom/test-utils";

const api = new Api();

const fetchNotices = takeLatest(actions.FETCH_NOTICES, function* (action) {
  try {
    const response = yield call(api.get, "api/notice");
    yield put({
      type: actions.FETCH_NOTICES_SUCCESS,
      payload: response,
    });
    yield call(action.resolve, "success");
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

const fetchNotice = takeLatest(actions.FETCH_NOTICE, function* (action) {
  try {
    const response = yield call(api.get, `api/notice/${action.noticeId}`);
    yield call(action.resolve, "success");
    yield put({
      type: actions.FETCH_NOTICE_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

const addNotice = takeLatest(actions.SAVE_NOTICE, function* (action) {
  try {
    const response = yield call(api.post, "api/notice", action.data, null);
    action.data.id = response.id;
    yield put({
      type: actions.SAVE_NOTICE_SUCCESS,
      payload: response,
    });

    yield call(action.resolve, "success");
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

const editNotice = takeLatest(actions.EDIT_NOTICE, function* (action) {
  try {
    const response = yield call(
      api.put,
      `api/notice/${action.noticeId}`,
      action.data,
      null
    );
    yield call(action.resolve, "success");
    yield put({
      type: actions.EDIT_NOTICE_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

const deleteNotice = takeLatest(actions.DELETE_NOTICE, function* (action) {
  try {
    const responseId = yield call(api.delete, `api/notice/${action.noticeId}`);
    yield put({
      type: actions.DELETE_NOTICE_SUCCESS,
      id: action.id,
    });

    yield call(action.resolve, responseId);
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

export default function* saga() {
  yield all([fetchNotices, fetchNotice, addNotice, editNotice, deleteNotice]);
}
