const actions = {
  FETCH_EVALUATION_CRITERIAS: "FETCH_EVALUATION_CRITERIAS",
  FETCH_EVALUATION_CRITERIAS_SUCCESS: "FETCH_EVALUATION_CRITERIAS_SUCCESS",
  SAVE_EVALUATION_CRITERIA: "SAVE_EVALUATION_CRITERIA",
  SAVE_EVALUATION_CRITERIA_SUCCESS: "SAVE_EVALUATION_CRITERIA_SUCCESS",
  EDIT_EVALUATION_CRITERIA: "EDIT_EVALUATION_CRITERIA",
  EDIT_EVALUATION_CRITERIA_SUCCESS: "EDIT_EVALUATION_CRITERIA_SUCCESS",
  DELETE_EVALUATION_CRITERIA: "DELETE_EVALUATION_CRITERIA",
  DELETE_EVALUATION_CRITERIA_SUCCESS: "DELETE_EVALUATION_CRITERIA_SUCCESS",
  FETCH_EVALUATION_BY_TYPE: "FETCH_EVALUATION_BY_TYPE",
  FETCH_EVALUATION_BY_TYPE_SUCCESS: "FETCH_EVALUATION_BY_TYPE_SUCCESS",
  UPDATE_COURSE_ID_IN_REDUX: "UPDATE_COURSE_ID_IN_REDUX",
  SAVE_EVALUATION: "SAVE_EVALUATION",
  SAVE_EVALUATION_SUCCESS: "SAVE_EVALUATION_SUCCESS",
  EDIT_EVALUATION: "EDIT_EVALUATION",
  EDIT_EVALUATION_SUCCESS: "EDIT_EVALUATION_SUCCESS",
  DELETE_EVALUATION: "DELETE_EVALUATION",
  DELETE_EVALUATION_SUCCESS: "DELETE_EVALUATION_SUCCESS",
  FETCH_EVALUATION_BY_STUDENT: "FETCH_EVALUATION_BY_STUDENT",
  FETCH_EVALUATION_BY_STUDENT_SUCCESS: "FETCH_EVALUATION_BY_STUDENT_SUCCESS",
  SUBMIT_EVALUATION_BY_STUDENT: "SUBMIT_EVALUATION_BY_STUDENT",
  SUBMIT_EVALUATION_BY_STUDENT_SUCCESS: "SUBMIT_EVALUATION_BY_STUDENT_SUCCESS",
  UPDATE_EVALUATION_ANSWER_MARKS: "UPDATE_EVALUATION_ANSWER_MARKS",
  UPDATE_EVALUATION_ANSWER_MARKS_SUCCESS:
    "UPDATE_EVALUATION_ANSWER_MARKS_SUCCESS",
  UPDATE_EVALUATION_BY_STUDENT: "UPDATE_EVALUATION_BY_STUDENT",
  UPDATE_EVALUATION_BY_STUDENT_SUCCESS: " UPDATE_EVALUATION_BY_STUDENT_SUCCESS",
  fetchevaluationCriterias: (resolve, reject) => ({
    type: actions.FETCH_EVALUATION_CRITERIAS,
    resolve,
    reject,
  }),

  saveevaluationCriterias: (data, event, resolve, reject) => ({
    type: actions.SAVE_EVALUATION_CRITERIA,
    data,
    event,
    resolve,
    reject,
  }),

  editevaluationCriterias: (
    evaluationCriteriaId,
    data,
    event,
    resolve,
    reject
  ) => ({
    type: actions.EDIT_EVALUATION_CRITERIA,
    evaluationCriteriaId,
    data,
    event,
    resolve,
    reject,
  }),
  deleteevaluationCriterias: (evaluationCriteriaId, resolve, reject) => ({
    type: actions.DELETE_EVALUATION_CRITERIA,
    evaluationCriteriaId: evaluationCriteriaId,
    resolve: resolve,
    reject: reject,
  }),
  fetchEvaluationByType: (evaluationCriteriaType, resolve, reject) => ({
    type: actions.FETCH_EVALUATION_BY_TYPE,
    evaluationCriteriaType,
    resolve,
    reject,
  }),

  fetchEvaluationByType: (
    evaluationCriteriaType,
    courseId,
    resolve,
    reject
  ) => ({
    type: actions.FETCH_EVALUATION_BY_TYPE,
    evaluationCriteriaType,
    courseId,
    resolve,
    reject,
  }),

  updateCourseIdInRedux: (courseId, resolve, reject) => ({
    type: actions.UPDATE_COURSE_ID_IN_REDUX,
    courseId,
    resolve,
    reject,
  }),

  saveEvaluation: (evaluationId, courseId, data, event, resolve, reject) => ({
    type: actions.SAVE_EVALUATION,
    evaluationId: evaluationId,
    courseId: courseId,
    data,
    event,
    resolve,
    reject,
  }),

  updateEvaluation: (
    id,
    evaluationId,
    courseId,
    data,
    event,
    resolve,
    reject
  ) => ({
    type: actions.EDIT_EVALUATION,
    id: id,
    evaluationId: evaluationId,
    courseId: courseId,
    data,
    event,
    resolve,
    reject,
  }),

  deleteEvaluation: (id, evaluationId, courseId, resolve, reject) => ({
    type: actions.DELETE_EVALUATION,
    id: id,
    evaluationId: evaluationId,
    courseId: courseId,
    resolve,
    reject,
  }),

  fetchEvaluationByStudent: (
    evaluationCriteriaType,
    courseId,
    resolve,
    reject
  ) => ({
    type: actions.FETCH_EVALUATION_BY_STUDENT,
    evaluationCriteriaType,
    courseId,
    resolve,
    reject,
  }),

  submitEvaluationByStudent: (
    evaluationCriteriaType,
    courseId,
    data,
    resolve,
    reject
  ) => ({
    type: actions.SUBMIT_EVALUATION_BY_STUDENT,
    evaluationCriteriaType,
    courseId,
    data,
    resolve,
    reject,
  }),

  updateEvaluationAnswerMarks: (
    evaluationAnswerID,
    evaluationCriteriaType,
    courseId,
    data,
    resolve,
    reject
  ) => ({
    type: actions.UPDATE_EVALUATION_ANSWER_MARKS,
    evaluationAnswerID,
    evaluationCriteriaType,
    courseId,
    data,
    resolve,
    reject,
  }),

  updateEvaluationByStudent: (
    evaluationAnswerId,
    evaluationCriteriaType,
    courseId,
    data,
    resolve,
    reject
  ) => ({
    type: actions.UPDATE_EVALUATION_BY_STUDENT,
    evaluationAnswerId,
    evaluationCriteriaType,
    courseId,
    data,
    resolve,
    reject,
  }),
};

export default actions;
