import { message } from "antd";
import axios from "axios";
import jwt_decode from "jwt-decode";

export const getFormattedFullName = () => {
  const token = localStorage.getItem("token");
  const decodedToken = jwt_decode(token || "");
  return `${decodedToken.first_name} ${decodedToken.last_name}`;
};

export const getFormattedRoleName = () => {
  const token = localStorage.getItem("token");
  const decodedToken = jwt_decode(token || "");
  return decodedToken.rolename[0];
};

export const getCollegeName = () => {
  const token = localStorage.getItem("token");
  const decodedToken = jwt_decode(token || "");
  return decodedToken.collegeDetails.college_name;
};

export const getOwnCollegeDetails = async () => {
  const token = localStorage.getItem("token");
  const decodedToken = jwt_decode(token || "");
  const response = await axios.get(
    process.env.REACT_APP_API_BASE_URL + `/api/college/${decodedToken.college}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  );
  return response.data;
};

export const validateEmail = (_, value) => {
  const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
  if (!emailRegex.test(value)) {
    return Promise.reject(new Error("Please input a valid email address!"));
  }
  return Promise.resolve();
};

export const validateRoleCode = (_, value) => {
  const codeRegex = /^ROLE_[A-Za-z_]+$/;
  if (!codeRegex.test(value)) {
    return Promise.reject(new Error("Role code must be start with 'ROLE_'"));
  }
  return Promise.resolve();
};

export const validateContactNumber = (_, value) => {
  const codeRegex = /^(?:(98|97)\d{8}|0\d{8,10})$/;
  if (!codeRegex.test(value)) {
    return Promise.reject(new Error("Invalid Contact Number"));
  }
  return Promise.resolve();
};

export const hasPermission = (permission) => {
  const decodedToken = jwt_decode(localStorage.getItem("token") || "");
  let permissions = decodedToken && decodedToken.permissions;
  permissions.push("*");

  return permissions.includes(permission);
};

export const format12Hrs = (hr, min) => {
  if (hr >= 0 && hr <= 24 && min >= 0 && min <= 60) {
    let AMorPM = "AM";
    if (hr >= 12) AMorPM = "PM";
    hr = hr % 12;
    return (
      (hr < 10 ? (hr === 0 ? 12 : hr.toString().padStart(2, "0")) : hr) +
      " : " +
      (min < 10 ? min.toString().padStart(2, "0") : min) +
      " " +
      AMorPM
    );
  }
};

export const formatTimeSlots = (timeSlots) => {
  return timeSlots.map((timeSlot) => {
    return {
      value: timeSlot.id,
      show:
        format12Hrs(
          parseInt(timeSlot.start_time.split(":")[0]),
          parseInt(timeSlot.start_time.split(":")[1])
        ) +
        " - " +
        format12Hrs(
          parseInt(timeSlot.end_time.split(":")[0]),
          parseInt(timeSlot.end_time.split(":")[1])
        ),
    };
  });
};

export const getEvaluationID = (location) => {
  const parts = location.pathname.split("/").filter(Boolean);

  const lastSlice = decodeURIComponent(parts.pop());
  const secondLastSlice = decodeURIComponent(parts.pop());
  return secondLastSlice;
};

export const getEvaluationName = (location) => {
  const parts = location.pathname.split("/").filter(Boolean);

  const lastSlice = decodeURIComponent(parts.pop());
  const secondLastSlice = decodeURIComponent(parts.pop());
  return lastSlice;
};

export const formatDeadline = (deadline) => {
  const date = new Date(deadline.date);
  const day = date.getDate();
  const month = date.toLocaleString("default", { month: "short" });
  const year = date.getFullYear();
  const dayOfWeek = date.toLocaleString("default", { weekday: "short" });

  const formattedDate1 = `${day} ${month} ${year} , ${dayOfWeek}`;
  const formattedDate2 = `${day}/${month}/${year}`;

  return {
    formattedDate1,
    formattedDate2,
  };
};

// export const filterData = (data, searchTerm) => {
//   // Convert searchTerm to lowercase for case-insensitive search
//   const term = searchTerm ? searchTerm.toLowerCase() : "";

//   // Helper function to recursively check if an object contains the searchTerm
//   const containsTerm = (obj) => {
//     if (!obj) return false; // Handle null or undefined objects

//     return Object.values(obj).some((val) => {
//       if (val === null || val === undefined) {
//         return false;
//       }
//       if (typeof val === "object") return containsTerm(val);
//       return typeof val === "string" && val.toLowerCase().includes(term);
//     });
//   };

//   // Functional filter using Array.prototype.filter
//   const filteredData = data.filter((item) => containsTerm(item));

//   return filteredData;
// };
export const filterData = (data, searchTerm) => {
  // Convert searchTerm to lowercase for case-insensitive search
  const term = searchTerm ? searchTerm.toLowerCase() : "";

  // Helper function to recursively check if an object or array contains the searchTerm
  const containsTerm = (obj) => {
    if (obj === null || obj === undefined) return false; // Handle null or undefined values
    if (Array.isArray(obj)) {
      return obj.some((val) => containsTerm(val)); // Recursively search each element in the array
    }
    if (typeof obj === "object") {
      return Object.values(obj).some((val) => containsTerm(val)); // Recursively search each value in the object
    }
    if (typeof obj === "string") {
      return obj.toLowerCase().includes(term); // Check if string contains searchTerm
    }
    return false; // For other types of values, return false
  };

  // Functional filter using Array.prototype.filter
  const filteredData = data.filter((item) => containsTerm(item));

  return filteredData;
};
export const convertToRoman = (num, lowercase) => {
  const romanNumeralMap = [
    { value: 1000, numeral: "M" },
    { value: 900, numeral: "CM" },
    { value: 500, numeral: "D" },
    { value: 400, numeral: "CD" },
    { value: 100, numeral: "C" },
    { value: 90, numeral: "XC" },
    { value: 50, numeral: "L" },
    { value: 40, numeral: "XL" },
    { value: 10, numeral: "X" },
    { value: 9, numeral: "IX" },
    { value: 5, numeral: "V" },
    { value: 4, numeral: "IV" },
    { value: 1, numeral: "I" },
  ];

  let result = "";

  for (let i = 0; i < romanNumeralMap.length; i++) {
    while (num >= romanNumeralMap[i].value) {
      result += romanNumeralMap[i].numeral;
      num -= romanNumeralMap[i].value;
    }
  }
  if (lowercase === 1) {
    return result.toLowerCase();
  }

  return result;
};
