const actions = {
  FETCH_NOTICES: "FETCH_NOTICES",
  FETCH_NOTICES_SUCCESS: "FETCH_NOTICES_SUCCESS",
  FETCH_NOTICE: "FETCH_NOTICE",
  FETCH_NOTICE_SUCCESS: "FETCH_NOTICE_SUCCESS",
  SAVE_NOTICE: "SAVE_NOTICE",
  SAVE_NOTICE_SUCCESS: "SAVE_NOTICE_SUCCESS",
  EDIT_NOTICE: "EDIT_NOTICE",
  EDIT_NOTICE_SUCCESS: "EDIT_NOTICE_SUCCESS",
  DELETE_NOTICE: "DELETE_NOTICE",
  DELETE_NOTICE_SUCCESS: "DELETE_NOTICE_SUCCESS",

  fetchNotiecs: (resolve, reject) => ({
    type: actions.FETCH_NOTICES,
    resolve,
    reject,
  }),

  fetchNotice: (noticeId, resolve, reject) => ({
    type: actions.FETCH_NOTICE,
    noticeId,
    resolve,
    reject,
  }),

  saveNotice: (data, event, resolve, reject) => ({
    type: actions.SAVE_NOTICE,
    data,
    event,
    resolve,
    reject,
  }),

  editNotice: (noticeId, data, event, resolve, reject) => ({
    type: actions.EDIT_NOTICE,
    noticeId,
    data,
    event,
    resolve,
    reject,
  }),
  deleteNotice: (noticeId, resolve, reject) => ({
    type: actions.DELETE_NOTICE,
    noticeId: noticeId,
    resolve: resolve,
    reject: reject,
  }),
};

export default actions;
