import { Card, Checkbox, Col, Form, Row, Select, Space } from "antd";

import { useState, useEffect } from "react";
import React from "react";
import { useNavigate, useParams } from "react-router";
import { DeleteIcon } from "../../../assets/icons/DeleteIcon";
import { EditIcon } from "../../../assets/icons/EditIcon";
import { tokens } from "../../../utils/Constants";
import AButton from "../../../Components/Button";
import AInput from "../../../Components/Input";
import QuillEditor from "../../../Components/Editor";
import { useDispatch, useSelector } from "react-redux";
import programActions from "../../Program/store/actions";
import ASelect from "../../../Components/Select";
import Loader from "../../../Components/Loader";
import AText from "../../../Components/Text";
import { RiseOutlined, FallOutlined } from "@ant-design/icons";
import ATitle from "../../../Components/Title";
const BatchDetails = ({ batch, handleDelete }) => {
  const [initialSemesters, setSemesters] = useState([]);
  const [initialProrgams, setPrograms] = useState();
  const [selectedSemesters, setSelectedSemesters] = useState([]);
  const { id } = useParams();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const dispatch = useDispatch();

  useEffect(() => {
    new Promise((resolve, reject) => {
      dispatch(programActions.fetchPrograms(resolve, reject));
    }).finally(() => {});
  }, [dispatch]);

  useEffect(() => {
    if (batch && batch.batchSemester.length > 0) {
      let programIds = batch.batchSemester.map((item) => item.program.id);
      let uniqueProgramIds = [...new Set(programIds)];

      setPrograms(uniqueProgramIds);
      let initialVals = {};

      // Iterate through batchSemester and set initial values for each program
      batch.batchSemester.forEach((item) => {
        const programId = item.program.id;
        const semesterId = item.semester.id;

        if (!initialVals[programId]) {
          initialVals[programId] = [];
        }

        initialVals[programId].push(semesterId);
      });
      setSemesters(initialVals);
    }
  }, [batch && batch.batchSemester]);
  //format the data
  const formattedData = batch.batchSemester.reduce((acc, item) => {
    const { id, program, semester, ...rest } = item;

    if (!acc[program.id]) {
      acc[program.id] = {
        program: {
          id: program.id,
          name: program.name,
        },
        semesters: [],
      };
    }

    acc[program.id].semesters.push({
      id: semester.id,
      name: semester.name,
      description: semester.description,
    });

    return acc;
  }, {});

  const result = Object.values(formattedData);

  useEffect(() => {
    if (batch && batch.batchSemester.length > 0) {
      const selectedSemesterIds = batch.batchSemester.map(
        (item) => item.semester.id
      );
      setSelectedSemesters(selectedSemesterIds);
    }
  }, [batch]);

  if (!result) {
    return <Loader />;
  }
  return (
    <div style={{ padding: "1%" }}>
      <ATitle content={`${batch.batch.name} Session`} />
      <div style={{ display: "flex", flexDirection: "row" }}>
        <QuillEditor
          value={batch.batch.description}
          readOnly={true}
          style={{
            fontSize: tokens.FONT_FAMILY,
            border: "none",
            padding: "0px",
          }}
          className="no-toolbar"
          modules={{ toolbar: false }}
        />
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          marginTop: "30px",
        }}
      >
        {/* <AButton
          type="primary"
          size="large"
          mode="danger"
          icon={<FallOutlined />}
        >
          Downgrade Batch
        </AButton>
        <AButton
          type="primary"
          size="large"
          mode="default"
          icon={<RiseOutlined />}
        >
          Upgrade Batch
        </AButton> */}
      </div>

      <Space
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <ATitle content={"Batch Details"} style={{ fontSize: "16px" }} />
        <Form
          layout="vertical"
          // initialValues={initialSemesters}
          disabled={true}
          style={{ width: "100%" }}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              width: "100%",
            }}
          >
            {result.map((program) => (
              <Card
                key={program.id}
                style={{
                  marginBottom: "16px",
                  width: "300px",
                  marginRight: "16px",
                  flex: "1 1 240px",
                }}
              >
                <h3>{program.program.name}</h3>
                <Form.Item name={program.id}>
                  <Checkbox.Group
                    value={selectedSemesters}
                    style={{ display: "flex", flexDirection: "column" }}
                  >
                    {program.semesters.map((semester) => (
                      <Checkbox key={semester.id} value={semester.id}>
                        {semester.name}
                      </Checkbox>
                    ))}
                  </Checkbox.Group>
                </Form.Item>
              </Card>
            ))}
          </div>
        </Form>
      </Space>

      <Form.Item
        style={{
          width: "100%",
          marginTop: "2vh",
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <AButton
            type="primary"
            size="large"
            // onClick={() => handleBatchDelete(selectedId)}
            onClick={(values) => {
              handleDelete(id);
            }}
            mode="danger"
          >
            Delete
          </AButton>
          <AButton
            type="primary"
            size="large"
            style={{
              color: tokens.BG_LIGHT,
              backgroundColor: tokens.COLOR_LIGHT_ORANGE,
              padding: "0 30px",
            }}
            onClick={() => navigate(`/session/edit/${id}`)}
          >
            Edit
          </AButton>{" "}
        </div>
      </Form.Item>
    </div>
  );
};

export default BatchDetails;
