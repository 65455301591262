import actions from "./actions";
import { fromJS } from "immutable";
import { combineReducers } from "redux";

const initialState = fromJS({
  batches: [],
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_BATCHES_SUCCESS:
      return state.set("batches", action.payload);

    case actions.FETCH_BATCH_SUCCESS:
      return state.set("batch", action.payload);

    case actions.SAVE_BATCHES_SUCCESS:
      return state.set("batches", action.payload);

    case actions.EDIT_BATCHES_SUCCESS:
      return state.set("batches", action.payload);

    case actions.DELETE_BATCHES_SUCCESS:
      return state.set("batches", action.payload);

    default:
      return state;
  }
};

export default reducer;
