import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { Space, Input } from "antd";
import { AButton } from "../../../Components";
import { tokens } from "../../../utils/Constants";
import courseAction from "../../Course/store/actions";
import actions from "../store/actions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Components/Loader";
import ATable from "../../../Components/Table";
import { hasPermission } from "../../../utils/Helper";
import { DeleteOutlined, EditOutlined, BackwardOutlined } from "@ant-design/icons";
import { Form, DatePicker, TimePicker, InputNumber } from "antd";

function ExamSchedule() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { id } = useParams();
  const [openModel, setOpenModel] = useState(false);
  const [course, setCourses] = useState();
  const [editableRows, setEditableRows] = useState({});
  const [form] = Form.useForm();

  //get all college list
  const courses = useSelector(({ coursesReducer }) =>
    coursesReducer.get("coursesByClass")
  );
  const examSubjects = useSelector(({ examsReducer }) =>
    examsReducer.get("examSubjects")
  );

  useEffect(() => {
    new Promise((resolve, reject) => {
      dispatch(courseAction.fetchCoursesByClass(id, resolve, reject));
    }).finally(() => { });
    new Promise((resolve, reject) => {
      dispatch(actions.fetchExamSubjectByClass(id, resolve, reject));
    }).finally(() => { });
  }, [dispatch, id]);

  const onChange = (value, field, courseId) => {
    setCourses(prevCourses => {
      const updatedCourses = prevCourses.map(course => {
        if (course.id === courseId) {
          return {
            ...course,
            [field]: value,
          };
        }
        return course;
      });
      return updatedCourses;
    });
  };


  const handleEdit = (record) => {
    setEditableRows({ ...editableRows, [record.id]: true });
  };

  const columns = [
    {
      title: "S.N.",
      render: (_, __, index) => <span>{index + 1}</span>,
    },
    {
      title: "Subject",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Exam Date",
      dataIndex: "date",
      key: "date",
      editable: true,
      render: (_, record) => {
        return (
          <DatePicker
            onChange={(date, dateString) => onChange(date, dateString, record.courseId)}
            style={{ width: "70%" }}
            placeholder="Select Date"
            disabled={!editableRows[record.id]}
          />
        );
      },
    },
    {
      title: "Start Time",
      dataIndex: "startTime",
      key: "startTime",
      editable: true,
      render: (_, record) => {
        return (
          <Input
            type="time"
            style={{ width: "60%" }}
            onChange={(e) => onChange(e.target.value, 'startTime', record.courseId)}
            disabled={!editableRows[record.id]}
          />
        );
      },
    },
    {
      title: "End Time",
      dataIndex: "endTime",
      key: "endTime",
      editable: true,
      render: (_, record) => {
        return (
          <Input
            type="time"
            style={{ width: "60%" }}
            onChange={(e) => onChange(e.target.value, 'endTime', record.courseId)}
            disabled={!editableRows[record.id]}
          />
        );
      },
    },
    {
      title: "Pass Mark",
      dataIndex: "passMark",
      key: "passMark",
      editable: true,
      render: (_, record) => {
        return (
          <InputNumber min={1} disabled={!editableRows[record.id]} />
        );
      },
    },
    {
      title: "Full Mark",
      dataIndex: "fullMark",
      key: "fullMark",
      editable: true,
      render: (_, record) => {
        return (
          <InputNumber min={1} disabled={!editableRows[record.id]} />
        );
      },
    },
    {
      title: "Action",
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <AButton
            type="primary"
            size="middle"
            style={{
              color: tokens.BG_LIGHT,
              backgroundColor: tokens.COLOR_SUCCESS,
            }}
            onClick={() => handleEdit(record)}
          >
            Edit
          </AButton>
        </div>
      ),
    },
  ];

  if (!courses || !examSubjects) {
    return <Loader />;
  }

  return (
    <div>
      <ATable
        columns={columns}
        dataSource={courses}
        onRow={(record, rowIndex) => {
          return {
            onClick: ({ event }) => {
              // navigate(`/college/view/${record.id}`);
            }, // click row
            onDoubleClick: (event) => {
              // navigate(`/college/view/${record.id}`);
            }, // double click row
          };
        }}
      />
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "2%",
          alignItems: "center",
          marginTop: "15px"
        }}
      >
        {hasPermission("add_exam") && (
          <AButton
            type="default"
            style={{
              borderColor: tokens.COLOR_PRIMARY,
              color: tokens.COLOR_PRIMARY,
            }}
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/exam`);
            }}
          >
            <BackwardOutlined style={{ color: tokens.COLOR_PRIMARY, fontSize: "15px", alignContent: "center" }} /> Back
          </AButton>
        )}
        {hasPermission("add_exam") && (
          <AButton
            type="default"
            style={{
              borderColor: tokens.COLOR_DANGER_D300,
              color: tokens.COLOR_DANGER_D300,
            }}
            icon={<DeleteOutlined />}
          // onClick={handleDelete}
          >
            Save
          </AButton>
        )}
      </div>
    </div>
  );
}

export default ExamSchedule;
