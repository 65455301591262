import React from "react";

function Dashboard() {
  return (
    <div>
      <h1>Dashboard</h1>
      {/* Dashboard content */}
    </div>
  );
}

export default Dashboard;
