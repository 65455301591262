import { takeLatest, put, call, all } from "redux-saga/effects";
import Api from "../../../services/api";
import actions from "./actions";
import { act } from "react-dom/test-utils";

const api = new Api();

const fetchSemester = takeLatest(actions.FETCH_SEMESTER, function* (action){
  try {
    const response = yield call(api.get, `api/semester/${action.semesterId}`);
    yield put({
      type: actions.FETCH_SEMESTER_SUCCESS,
      payload: response,
    });
    yield call(action.resolve, "success");
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

export default function* saga() {
  yield all([
    fetchSemester,
  ]);
}
