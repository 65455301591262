import { Col, Form, Row, Select } from 'antd';
import ATitle from '../Title';
import AInput from '../Input';
import AButton from '../Button/index';
import { tokens } from '../../utils/Constants';
import ASelect from '../Select/index';
import { useState, useEffect } from 'react';
import React from 'react';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import actions from '../../pages/Program/store/actions';

const AddEditExam = ({ handleSave, exam }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [programId, setProgramId] = useState(exam ? exam.programId : '');
  const [semesterId, setSemesterId] = useState(exam ? exam.semesterClassId : '');

  let [searchParams] = useSearchParams();
  let defaultProgram = searchParams.get('program');
  let defaultSemester = searchParams.get('semester');
  let defaultSemesterId = searchParams.get('semesterId');

  let programs = useSelector(({ programsReducer }) => programsReducer.get('programs'));

  useEffect(() => {
    new Promise((resolve, reject) => {
      dispatch(actions.fetchPrograms(resolve, reject));
    }).finally(() => {});
  }, [dispatch]);

  useEffect(() => {
    programId &&
      new Promise((resolve, reject) => {
        dispatch(actions.fetchSemesters(programId, resolve, reject));
      }).finally(() => {});
  }, [dispatch, programId]);

  let semesters = useSelector(({ programsReducer }) => programsReducer.get('semesters'));

  const handleProgramChange = (value) => {
    setProgramId(value);
    form.resetFields(['exam_class']);
    form.setFieldsValue({
      exam_class: undefined,
    });
  };

  return (
    <div style={{ padding: '1%' }}>
      <ATitle content={exam ? 'Edit Exam' : 'Add Exam'} style={{ fontSize: '18px' }} />
      <Form
        size='large'
        form={form}
        name='add/editForm'
        onFinish={(values) => {
          values.exam_class = semesterId;
          values.exam_class = defaultSemesterId ? defaultSemesterId : semesterId;
          handleSave(values);
        }}
        layout='vertical'
        scrollToFirstError
        title='Add/EditExam'
        initialValues={{
          exam_name: exam ? exam.name : '',
          exam_program: defaultProgram || (exam ? exam.program : ''),
          exam_class: defaultSemester || (exam ? exam.class : ''),
        }}
      >
        <Row gutter={20}>
          <Col xs={24} md={8}>
            <Form.Item
              name='exam_name'
              label={<p style={{ fontSize: '16px' }}>Exam Name</p>}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: 'Please input the Exam Name!',
                },
              ]}
            >
              <AInput placeholder={'Enter Exam Name'} size='large' />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col xs={24} md={8}>
            <Form.Item
              name='exam_program'
              label={<p style={{ fontSize: '16px' }}>Program</p>}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: 'Please select the program',
                },
              ]}
            >
              <ASelect
                customized={false}
                defaultValue='Choose Program'
                value={programId}
                onChange={handleProgramChange}
                disabled={!!defaultProgram}
              >
                {programs &&
                  programs.map((element) => <Select.Option value={element.id}>{element.programCode}</Select.Option>)}
              </ASelect>
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name='exam_class'
              label={<p style={{ fontSize: '16px' }}>Class</p>}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: 'Please select the class',
                },
              ]}
            >
              <ASelect
                customized={false}
                defaultValue='Choose Class/Semester'
                value={semesterId}
                onChange={(value) => setSemesterId(value)}
                disabled={!!defaultSemester}
              >
                {semesters &&
                  semesters.map((element) => <Select.Option value={element.id}>{element.name}</Select.Option>)}
              </ASelect>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Form.Item style={{ width: '100%' }}>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <AButton
                type='default'
                size='middle'
                onClick={() => navigate('/exam')}
                style={{
                  backgroundColor: tokens.COLOR_LIGHTBLACK_L50,
                }}
              >
                Cancel
              </AButton>
              <AButton
                type='primary'
                htmlType='submit'
                size='large'
                style={{
                  color: tokens.BG_LIGHT,
                  backgroundColor: tokens.COLOR_PRIMARY,
                  padding: '0 30px',
                }}
              >
                {exam ? 'Update exam' : 'Create exam'}
              </AButton>
            </div>
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
};

export default AddEditExam;
