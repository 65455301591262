import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import actions from "./store/actions";
import Loader from "../../Components/Loader";
import AButton from "../../Components/Button";
import { EditIcon } from "../../assets/icons/EditIcon";
import { tokens } from "../../utils/Constants";
import ATitle from "../../Components/Title";
import ATable from "../../Components/Table";
import AInput from "../../Components/Input";
import {
    SearchOutlined,
    PlusOutlined,
    WarningFilled,
    FilterOutlined,
    ClockCircleOutlined,
    EnterOutlined,
    DoubleRightOutlined
  } from "@ant-design/icons";
  import { CheckCircleTwoTone, HeartTwoTone, SmileTwoTone,CloseCircleTwoTone } from '@ant-design/icons';

import { filterData } from "../../utils/Helper";
import { useNavigate } from "react-router";
import {Tooltip,Switch } from "antd";

const { Space, Drawer } = require("antd")


const ManageBatch = () => {
    const [loading, setLoading] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [dataType, setDataType] = useState(false);

    //for drawer
    const [openDrawer, setOpenDrawer] = useState(false);
    const showDrawer = () => {
        setOpenDrawer(true);
    };

    const onClose = () => {
        setOpenDrawer(false);
    };
    //

    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        // setLoading(true);
        new Promise((resolve, reject) => {
        dispatch(actions.fetchBatchSessions(dataType, resolve, reject));
        }).finally(() => {
        // setLoading(false);
        });
    }, [dataType]);

      //for grpup
    let batchSessions = useSelector(({ batchSessionReducer }) =>
        batchSessionReducer.get("batch_session")
    );

    const onChange = (checked) => {
      setDataType(checked)
    };

    const columns = [
        {
          title: "S.N.",
          render: (_, __, index) => <span>{index + 1}</span>,
        },
        {
          title: "Batch",
          key: "batch_name",
          render: (_, record) => record.batch.name,
        },
        {
          title: "Session",
          key: "session_name",
          render: (_, record) => record.session.name,
        },
        {
          title: "Program",
          key: "program_name",
          render: (_, record) => record.program.name,
        },
        {
          title: "Semester/Year",
          key: "semester_name",
          render: (_, record) => record.semester.name,
        },
        {
          title: "Active Status",
          key: "active_status",
          render: (_, record) => (<div style={{display:'flex', justifyContent:'center'}}>{record.active_status ? <CheckCircleTwoTone twoToneColor="#52c41a" /> :<CloseCircleTwoTone twoToneColor="red"/>} </div>),
        },
        {
          title: "Action",
          render: (_, record) => (
            <div
              style={{
                display: "flex",
                // justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              {/* { hasPermission('manage_batch_session') &&  */}
              <Tooltip title={!record.active_status ? "You can only manage the active batch session" : ""}>
                    <AButton
                        type="primary"
                        size="middle"
                        style={{
                            color: tokens.BG_LIGHT,
                            backgroundColor: tokens.COLOR_SUCCESS,
                        }}
                        icon={<DoubleRightOutlined />}
                        onClick={(e) => {
                            navigate(`/groupDetails/${record.batch.id}`)
                        }}
                        disabled={!record.active_status}
                    >
                        Manage
                    </AButton>
                </Tooltip>
                {/* } */}

            </div>
          ),
        },
      ];

    if(loading){
        return <Loader/>
    }

    return (
    <div>
      <Drawer
        title="Filter"
        placement={"right"}
        onClose={onClose}
        open={openDrawer}
      ></Drawer>
      <Space
        style={{
          width: "100%",
          display:'flex',
          marginBottom: "30px",
          flexDirection:'column',
          alignItems:'flex-end'
        }}
      >
        <div style={{display:'flex', flexDirection:'row'}}>
          
        <AInput
          suffix={<SearchOutlined />}
          placeholder="Search"
          name="search"
          size="large"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <AButton
          type="primary"
          icon={<FilterOutlined />}
          size="large"
          onClick={showDrawer}
          style={{ backgroundColor: tokens.COLOR_LIGHT_ORANGE, marginLeft: 10 }}
        >
          Filter
        </AButton>
        </div>
        <Switch onChange={onChange} checkedChildren={"All"}
      unCheckedChildren={"Active"}/>

      </Space>
      {batchSessions && batchSessions.length > 0 ? (
        <Space direction="vertical" style={{display:'flex'}}>

        <ATable
          columns={columns}
          dataSource={filterData(batchSessions,searchTerm)}
          onRow={(record, rowIndex) => {
            return {
              onClick: ({ event }) => {}, // click row
              onDoubleClick: (event) => {}, // double click row
            };
          }}
        />
        </Space>
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <ATitle level={4} content="You don't have any Batch yet." />
            <div
              style={{
                border: `1px dashed ${tokens.COLOR_PRIMARY}`,
                padding: "30px",
                borderRadius: "5px",
                //   width: "20%",
              }}
            >
              {dataType ? ' No active batch found.' : 'You do not have any Group yet.'}
            </div>
        </div>
    )}
    </div>
    )
}

export default ManageBatch