import React from "react";
import Icon from "@ant-design/icons";

export const EditIcon = (props) => {
  const editSvg = () => (
    <svg
      width="16"
      height="16"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      style={{
        verticalAlign: "baseline",
      }}
    >
      <g id="heroicons-outline/pencil-square">
        <path
          id="Vector"
          d="M15.4565 4.11278L17.0034 2.56591C17.6746 1.8947 18.7629 1.8947 19.4341 2.56591C20.1053 3.23712 20.1053 4.32538 19.4341 4.99659L9.70034 14.7303C9.21572 15.215 8.61798 15.5712 7.96115 15.7669L5.5 16.5L6.23313 14.0389C6.42879 13.382 6.78503 12.7843 7.26965 12.2997L15.4565 4.11278ZM15.4565 4.11278L17.875 6.53124M16.5 12.8333V17.1875C16.5 18.3266 15.5766 19.25 14.4375 19.25H4.8125C3.67341 19.25 2.75 18.3266 2.75 17.1875V7.56249C2.75 6.4234 3.67341 5.49999 4.8125 5.49999H9.16667"
          stroke="currentColor"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </g>
    </svg>
  );
  return <Icon component={editSvg} {...props} />;
};
