import RolesSaga from "./sagas";
import RolesReducer from "./reducers";
import rolesActions from "./actions";

export const rolesReducer = {
  rolesReducer: RolesReducer,
};

export const rolesSaga = [RolesSaga()];

export { rolesActions };
