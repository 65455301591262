import actions from "./actions";
import { fromJS } from "immutable";
import { combineReducers } from "redux";

const initialState = fromJS({
  students: [],
  groups: [],
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_STUDENTS_SUCCESS:
      return state.set("students", action.payload);

    case actions.SAVE_STUDENT_SUCCESS:
      return state.set("students", action.payload);

    case actions.EDIT_STUDENT_SUCCESS:
      return state.set("students", action.payload);

    case actions.UPLOAD_STUDENT_SUCCESS:
      return state.set("students", action.payload);

    case actions.DELETE_STUDENT_SUCCESS:
      return state.set("students", action.payload);

    case actions.FETCH_GROUPS_SUCCESS:
      return state.set("groups", action.payload);

    case actions.SAVE_GROUP_SUCCESS:
      return state.set("groups", action.payload);

    case actions.EDIT_GROUP_SUCCESS:
      return state.set("groups", action.payload);

    case actions.DELETE_GROUP_SUCCESS:
      return state.set("groups", action.payload);

    case actions.FETCH_STUDENTS_BY_COURSE_SUCCESS:
      return state.set("studentsByCourse", action.payload);

    default:
      return state;
  }
};

export default reducer;
