import SemestersSaga from "./sagas";
import SemestersReducer from "./reducers";
import semestersActions from "./actions";

export const semestersReducer = {
  semestersReducer: SemestersReducer,
};

export const semestersSaga = [SemestersSaga()];

export { semestersActions };
