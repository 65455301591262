import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { Space, Divider, Typography, Form, Row, Col, Select, Tooltip, message } from "antd";
import actions from "../../ManageBatch/store/actions";
import Loader from "../../../Components/Loader";
import ATitle from "../../../Components/Title";
import AText from "../../../Components/Text";
import { tokens } from "../../../utils/Constants";
import AButton from "../../../Components/Button";
import {
    EditOutlined,
    CheckSquareTwoTone,
    CloseSquareTwoTone,
    VerticalAlignTopOutlined,
    StopOutlined,
    CheckOutlined,
    WarningFilled
} from "@ant-design/icons";

import programActions from "../../Program/store/actions";
import { convertToRoman, validateEmail } from "../../../utils/Helper";
import AModal from "../../../Components/Model";
import AInput from "../../../Components/Input";
import ASelect from "../../../Components/Select";

const { Text } = Typography;

const GroupDetails = () => {
    const [loading, setLoading] = useState(true);
    const [openModel, setOpenModel] = useState(false);
    const [saving, setSaving] = useState(false);
    const [selectedSemester, setSelectedSemester] = useState(0)
    const [endBatch, setEndbatch] = useState(false);

    const { id } = useParams();
    const dispatch = useDispatch();
    const [form] = Form.useForm();
    const navigate = useNavigate();

    useEffect(() => {
        const getBatchDetails =  async () => {
            new Promise((resolve, reject) => {
                dispatch(actions.fetchBatchDetails(id, resolve, reject));
              })
                .then(async (result) => {
                    setLoading(false);
                    fetchSemesterYear(result[0].program.id)
                })
                .catch((error) => {
                  message.error(error.response ? error.response.data.error : "Error fetching batch details, Please try again!");
                })
                .finally(() => {
                    setLoading(false);
                });
        }

        const fetchSemesterYear =  async (programID) => {
            new Promise((resolve, reject) => {
                dispatch(programActions.fetchSemesters(programID, resolve, reject));
              })
                .then(async (result) => {
                    setLoading(false);
                })
                .catch((error) => {
                  message.error(error.response ? error.response.data.error : "Error fetching program details, Please try again!");
                })
                .finally(() => {
                    setLoading(false);
                });
        }

        new Promise((resolve, reject) => {
            dispatch(actions.fetchBatchInfo(id, resolve, reject));
          })
            .then(async (result) => {
                if(result.length > 0){
                    await fetchSemesterYear(result[0].program.id)
                }
                else{
                    await getBatchDetails()    
                }
                setLoading(false);

            })
            .catch((error) => {
              message.error(error.response ? error.response.data.error : "Error fetching batch details, Please try again!");
            })
            .finally(() => {
                setLoading(false);
            });
    }, []);

    const batchSessionInfo = useSelector(({ batchSessionReducer }) =>
        batchSessionReducer.get("batch_info")
    );

    const batchDetails = useSelector(({ batchSessionReducer }) =>
        batchSessionReducer.get("batch_details")
    );
    
    let semesters = useSelector(({ programsReducer }) =>
        programsReducer.get("semesters")
    );

    const handleEndBatch = () => {

        new Promise((resolve, reject) => {
            dispatch(actions.endBatch(id, resolve, reject));
          })
            .then(async (result) => {
                setEndbatch(false);
                message.success("Batch Ended Successfully!")
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
            })
            .catch((error) => {
              message.error(error.response ? error.response.data.error : "Error ending the batch!");
            })
            .finally(() => {
                setLoading(false);
            });
    }
    
    if (loading) {
        return <Loader />;
    }

    if (batchDetails && batchDetails.length > 0 && semesters && semesters.length>0) {

        return (
            <Space direction="vertical" style={{ width: "100%" }}>
            <Space direction="horizontal" style={{ display: 'flex', justifyContent: 'space-between' }}>
            <ATitle content={batchDetails[0].name ? batchDetails[0].name : ''} level={1} />
                <AButton
                    type="primary"
                    size="large"
                    style={{
                        color: tokens.BG_LIGHT,
                        backgroundColor: tokens.COLOR_LIGHT_ORANGE,
                    }}
                    icon={<EditOutlined />}
                    onClick={(e) => {
                        navigate(`/manageBatchrelation/${id}`)
                    }}
                >
                    Manage Batch
                </AButton>
            </Space>
            <Divider/>
            <Space direction="horizontal" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Space direction="vertical">
                    <Space direction="vertical">
                        <Text strong>Batch Name:</Text>
                        <Text>{batchDetails[0].name}</Text>
                    </Space>
                    <Space direction="vertical">
                        <Text strong>Session:</Text>
                        <Text>{"Not Found"}</Text>
                    </Space>
                    <Space direction="vertical">
                        <Text strong>Program:</Text>
                        <Text>{batchDetails[0].program.name}</Text>
                    </Space>
                    <Space direction="vertical">
                        <Text strong>Active Semester/Year:</Text>
                        <Text>{"Not Found"}</Text>
                    </Space>

                </Space>
                <div style={{ width: "1px", height: "200px", backgroundColor: "gray" }} />

                <Space direction="vertical">
                    <Text strong>Semesters/ Years List</Text>
                    {semesters.map((semesterData, index) => (
                        <div key={index} style={{ display: 'flex', justifyContent: 'space-between', padding: 6, border: tokens.BORDER, backgroundColor: tokens.COLOR_PRIMARY_P50, borderRadius:10 }}>
                            <div style={{ display: 'flex' }}>
                                <div>
                                    {convertToRoman(index + 1, 1)}.
                                </div>
                                <div>
                                    {semesterData.name}
                                </div>
                            </div>
                            <div style={{ marginLeft: 8 }}>
                                {<CloseSquareTwoTone twoToneColor={tokens.COLOR_DANGER} />}
                            </div>
                        </div>
                    ))}
                </Space>
                </Space>
            </Space>
        )
    }

    if (!batchSessionInfo || batchSessionInfo.length === 0) {
        return(
            <Space direction="vertical" style={{ width: "100%" }}>
            <Space direction="horizontal" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <ATitle content={'No Batch Details Found'} level={1} />
                <AButton
                    type="primary"
                    size="large"
                    style={{
                        color: tokens.BG_LIGHT,
                        backgroundColor: tokens.COLOR_LIGHT_ORANGE,
                    }}
                    icon={<EditOutlined />}
                    onClick={(e) => {
                        navigate(`/manageBatchrelation/${id}`)
                    }}
                >
                    Manage Batch
                </AButton>
            </Space>
            </Space>
        )
    }
    // Destructuring the batch Info
    const { batch, session, program, semester } = batchSessionInfo[0];

    //update batch
    const handleSave = (value) =>{
        setSaving(true)
        const data = {
            ...value,
            program : program.id,
            batch:batch.id,
            session:session.id
        }

        new Promise((resolve, reject) => {
            dispatch(actions.updateBatch(data, "ADD", resolve, reject));
          })
            .then(() => {
              message.success("Batch Updated Successfully!");
            //   navigate("/college");
            setOpenModel(false)
            })
            .catch((error) => {
              message.error(error.response ? error.response.data.error : "College add failed, Please try again");
            })
            .finally(() => {
              setSaving(false);
            });

    }


    return (
        <Space direction="vertical" style={{ width: "100%" }}>
            <Space direction="horizontal" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <ATitle content={batchSessionInfo[0].batch.name ? batchSessionInfo[0].batch.name : ''} level={1} />
                <AButton
                    type="primary"
                    size="middle"
                    style={{
                        color: tokens.BG_LIGHT,
                        backgroundColor: tokens.COLOR_SUCCESS,
                    }}
                    icon={<VerticalAlignTopOutlined />}
                    onClick={(e) => {
                        setOpenModel(true)
                    }}
                >
                    Update Batch
                </AButton>
            </Space>

            <Divider />
            {batchSessionInfo && batchSessionInfo[0] &&
            <Space direction="horizontal" style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Space direction="vertical">
                    <Space direction="vertical">
                        <Text strong>Batch Name:</Text>
                        <Text>{batch.name}</Text>
                    </Space>
                    <Space direction="vertical">
                        <Text strong>Session:</Text>
                        <Text>{session.name}</Text>
                    </Space>
                    <Space direction="vertical">
                        <Text strong>Program:</Text>
                        <Text>{program.name}</Text>
                    </Space>
                    <Space direction="vertical">
                        <Text strong>Active Semester/Year:</Text>
                        <Text>{semester.name}</Text>
                    </Space>

                </Space>

                <div style={{ width: "1px", height: "200px", backgroundColor: "gray" }} />

                <Space direction="vertical">
                    <Text strong>Semesters/ Years List</Text>
                    {semesters.map((semesterData, index) => (
                        <div key={index} style={{ display: 'flex', justifyContent: 'space-between', padding: 6, border: tokens.BORDER, backgroundColor: tokens.COLOR_PRIMARY_P50 , borderRadius:8}}>
                            <div style={{ display: 'flex' }}>
                                <div>
                                    {convertToRoman(index + 1, 1)}.
                                </div>
                                <div>
                                    {semesterData.name}
                                </div>
                            </div>
                            <div style={{ marginLeft: 8 }}>
                                {semesterData.id === semester.id ? <CheckSquareTwoTone twoToneColor={tokens.COLOR_SUCCESS}/> : <CloseSquareTwoTone twoToneColor={tokens.COLOR_DANGER} />}
                            </div>
                        </div>
                    ))}
                </Space>

            </Space>
            }

            <Space direction="horizontal" style={{ display: 'flex', justifyContent: 'space-between', marginTop:50 }}>
                <AButton
                    type="primary"
                    size="middle"
                    style={{
                        color: tokens.BG_LIGHT,
                        backgroundColor: tokens.COLOR_DARK_ORANGE,
                    }}
                    icon={<CheckOutlined />}
                    onClick={(e) => {
                        setEndbatch(true)
                    }}
                >   
                End Batch         
        </AButton>
        </Space>
            <Divider />

            <AModal 
                style={{
                padding: "50px",
                }}
                open={openModel}
                closable={true}
                onCancel={() => setOpenModel(false)}
                footer={null}
                className="css-dev-only-do-not-override-1r3vfrt-last"
            >
                <ATitle content="Update Batch" style={{ fontSize: "16px" }} />
                <Form
                size="large"
                form={form}
                name="add/editForm"
                onFinish={(values) => {
                    handleSave(values);
                }}
                layout="vertical"
                scrollToFirstError
                title="Add/EditCollege"
                initialValues={{semester : semester.id}}
                >
                <div>
                    <p
                    style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "19.6px",
                    }}
                    >
                    Select Semester/ Year
                    </p>
                    <Row gutter={24}>
                    <Col span={24}>
                        <Form.Item
                        name="semester"
                        rules={[
                            {
                            required: true,
                            message: "Please input the Email!",
                            },
                        ]}
                        >
                        <ASelect
                            customized={false}
                            onChange={(value) => setSelectedSemester(value)}
                            >
                            {semester && semesters.map((semester) => (
                            <Select.Option key={semester.id} value={semester.id}>
                                {semester.name}
                            </Select.Option>
                            ))}
                        </ASelect>
                        </Form.Item>
                    </Col>
                    </Row>
                </div>
                <Form.Item>
                    <div
                    style={{
                        display: "flex",
                        justifyContent: "space-between",
                    }}
                    >
                    <AButton
                        type="default"
                        size="middle"
                        onClick={() => setOpenModel(false)}
                        style={{
                        backgroundColor: tokens.COLOR_LIGHTBLACK_L50,
                        }}
                    >
                        Cancel
                    </AButton>
                    <Tooltip title={selectedSemester === semester.id && "Please update the Semester/ Year to save the changes"}>

                    <AButton
                        type="primary"
                        htmlType="submit"
                        style={{
                        color: tokens.BG_LIGHT,
                        backgroundColor: tokens.COLOR_LIGHT_ORANGE,
                        }}
                        loading={saving}
                        disabled={selectedSemester === semester.id}
                    >
                        Update Batch
                    </AButton>
                    </Tooltip>
                    </div>
                </Form.Item>
                </Form>
            </AModal>

            <AModal 
                style={{
                padding: "50px",
                }}
                open={endBatch}
                closable={true}
                onCancel={() => setEndbatch(false)}
                footer={null}
                className="css-dev-only-do-not-override-1r3vfrt-last"
            >
                <ATitle content="End Batch" style={{ fontSize: "16px" }} />
                <Space
          align="center"
          direction="vertical"
          style={{
            display: "flex",
            alignContent: "center",
          }}
        >
          <Space direction="vertical" size="small" align="center">
            <WarningFilled
              style={{
                fontSize: "65px",
                color: tokens.COLOR_DANGER,
              }}
            />
            <ATitle content="Are you sure ?" level={4} />
            <p
              style={{
                textAlign: "center",
                width: "100%",
                marginTop: "-5px",
              }}
            >
              Ending the batch will end all the semesters/years. This process cannot be undo.
            </p>
          </Space>
          <Space>
            <AButton
              type="default"
              size="default"
              onClick={() => setEndbatch(false)}
            >
              Cancel
            </AButton>
            <AButton
              type="primary"
              size="default"
              onClick={() => handleEndBatch()}
              mode="danger"
            >
              Confirm
            </AButton>
          </Space>
        </Space>    
            </AModal>
        </Space>
    );
};

export default GroupDetails;
