import actions from "./actions";
import { fromJS } from "immutable";
import { combineReducers } from "redux";

const initialState = fromJS({
  colleges: [],
  college_admins: [],
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_COLLEGES_SUCCESS:
      return state.set("colleges", action.payload);

    case actions.SAVE_COLLEGES_SUCCESS:
      return state.set("colleges", action.payload);

    case actions.EDIT_COLLEGES_SUCCESS:
      return state.set("colleges", action.payload);

    case actions.DELETE_COLLEGES_SUCCESS:
      return state.set("colleges", action.payload);

    case actions.FETCH_COLLEGE_ADMIN_SUCCESS:
      return state.set("college_admins", action.payload);

    case actions.SAVE_COLLEGE_ADMIN_SUCCESS:
      return state.set("college_admins", action.payload);

    case actions.DELETE_COLLEGE_ADMIN_SUCCESS:
      return state.set("college_admins", action.payload);

    default:
      return state;
  }
};

export default reducer;
