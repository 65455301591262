const actions = {
  FETCH_SEMESTER: "FETCH_SEMESTER",
  FETCH_SEMESTER_SUCCESS: "FETCH_SEMESTER_SUCCESS",

  fetchSemester: (semesterId, resolve, reject) => ({
    type: actions.FETCH_SEMESTER,
    semesterId,
    resolve,
    reject,
  }),

};

export default actions;
