import React, { useEffect, useState } from "react";
import ATable from "../../Components/Table";
import AButton from "../../Components/Button/index";
import { Roles, tokens } from "../../utils/Constants";
import { Col, Drawer, Form, Row, Select, Space, message, theme } from "antd";
import AInput from "../../Components/Input";
import { useDispatch, useSelector } from "react-redux";
import AModal from "../../Components/Model";
import ATitle from "../../Components/Title";
import { DeleteIcon } from "../../assets/icons/DeleteIcon";
import { EditIcon } from "../../assets/icons/EditIcon";
import actions from "../Students/store/actions";
import Loader from "../../Components/Loader";
import {
  SearchOutlined,
  PlusOutlined,
  WarningFilled,
  FilterOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import { getFormattedRoleName, hasPermission } from "../../utils/Helper";
import ASelect from "../../Components/Select";
import programActions from '../Program/store/actions';
import { useNavigate } from "react-router";

function Group() {
  const [openModel, setOpenModel] = useState(false);
  const [openEditModel, setOpenEditModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saving, setSaving] = useState(false);
  const [group, setgroup] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [initialValues, setInitialValues] = useState(null);
  const [selectedId, setSelectedId] = useState(0);
  const [warningModel, setWarningModel] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const navigate = useNavigate();
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { useToken } = theme;
  const { token } = useToken();
  //for drawer
  const [openDrawer, setOpenDrawer] = useState(false);
  const showDrawer = () => {
    setOpenDrawer(true);
  };

  const onClose = () => {
    setOpenDrawer(false);
  };
  //
  const handleSave = (value) => {
    setOpenModel(false);
    setSaving(true);
    const data = {
      ...value,
    };
    new Promise((resolve, reject) => {
      dispatch(actions.saveGroup(data, "ADD", resolve, reject));
    })
      .then(() => {
        message.success("Group added Sucessfully");
        form.resetFields();
      })
      .catch(() => {
        message.error("Group add failed");
      })
      .finally(() => {
        setSaving(false);
      });
  };
  const handleDelete = (id) => {
    setSaving(true);
    new Promise((resolve, reject) => {
      dispatch(actions.deleteGroup(id, resolve, reject));
    })
      .then(() => {
        setOpenModel(false);
        message.success("Group delete Success");
      })
      .catch(() => {
        message.error("Group delete fails ");
      })
      .finally(() => {
        setSaving(false);
        setWarningModel(false);
      });
  };
  const handleEdit = (value) => {
    const data = {
      ...value,
    };

    new Promise((resolve, reject) => {
      dispatch(actions.editGroup(data.id, data, "EDIT", resolve, reject));
    })
      .then(() => {
        message.success("Group edited Sucessfully");
        setOpenEditModel(false);
      })
      .catch(() => {
        message.error("Group edit failed");
      });
  };
  //for grpup
  let groups = useSelector(({ studentsReducer }) =>
    studentsReducer.get("groups")
  );

  let programs = useSelector(({ programsReducer }) =>
    programsReducer.get("programs")
  );


  const filteredGroups =
    groups &&
    groups.filter((group) => {
      return Object.keys(group).some((key) => {
        if (key !== "id") {
          const value = group[key];
          return (
            value &&
            value.toString().toLowerCase().includes(searchTerm.toLowerCase())
          );
        }
        return false;
      });
    });

  useEffect(() => {
    setLoading(true);
    new Promise((resolve, reject) => {
      dispatch(actions.fetchGroups(resolve, reject));
    }).finally(() => {
      setLoading(false);
    });
  }, [dispatch, saving]);
  //
  const openEditModal = (record) => {
    setgroup(record);
    setIsEditing(true);
    form.setFieldsValue({ name: record.name }); // Set initial values for the form
    setOpenEditModel(true);
  };
  const openAddModal = () => {
    setgroup(null);
    setIsEditing(false);
    form.setFieldsValue({ name: "" }); // Set initial values for the form
    setOpenModel(true);
  };

  useEffect(() => {
    setLoading(true);
    new Promise((resolve, reject) => {
      dispatch(programActions.fetchPrograms(resolve,reject))
    }).finally(() => {
      setLoading(false);
    });
  },[]);

  const columns = [
    {
      title: "S.N.",
      render: (_, __, index) => <span>{index + 1}</span>,
    },
    {
      title: "Batch Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Program",
      key: "program_name",
      render: (_, record) => record.program.name,
    },
    {
      title: (hasPermission('edit_group') || hasPermission('delete_group')) ? "Action" : null,
      render: (_, record) => (
         (hasPermission('edit_group') || hasPermission('delete_group')) && 
         <div
           style={{
             display: "flex",
             alignItems: "center",
           }}
         >
           { hasPermission('edit_group') && 
             <AButton
               type="primary"
               size="middle"
               style={{
                 color: tokens.BG_LIGHT,
                 backgroundColor: tokens.COLOR_SUCCESS,
               }}
               icon={<EditIcon />}
               onClick={(e) => {
                 e.stopPropagation();
                 openEditModal(record);
               }}
             >
               Edit
             </AButton>
           }
           { hasPermission('delete_group') &&
             <AButton
               type="default"
               style={{
                 color: tokens.COLOR_DANGER,
                 borderColor: tokens.COLOR_DANGER,
                 margin: "10px",
               }}
               icon={<DeleteIcon />}
               onClick={(e) => {
                 e.stopPropagation();
                 setSelectedId(record.id);
                 setWarningModel(true);
               }}
             >
               Delete
             </AButton>
           }
         </div>
      ),
     }     
  ];

  if (saving | loading | !programs) {
    return <Loader />;
  }
  return (
    <div>
      <Drawer
        title="Filter"
        placement={"right"}
        onClose={onClose}
        open={openDrawer}
      ></Drawer>
      <Space
        style={{
          width: "100%",
          justifyContent: "flex-end",
          marginBottom: "30px",
        }}
      >
        <AInput
          suffix={<SearchOutlined />}
          placeholder="Search"
          name="search"
          size="large"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <AButton
          type="primary"
          icon={<FilterOutlined />}
          size="large"
          onClick={showDrawer}
          style={{ backgroundColor: tokens.COLOR_LIGHT_ORANGE }}
        >
          Filter
        </AButton>
      </Space>

      {hasPermission("add_group") &&
        getFormattedRoleName() !== Roles.ROLE_SUPER_ADMIN && (
          <Space
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: "30px",
            }}
          >
            {/* <Link to={"/group/add"}> */}
            <AButton
              type="primary"
              icon={<PlusOutlined />}
              size="large"
              style={{ backgroundColor: tokens.COLOR_DARK_ORANGE }}
              onClick={(e) => {
                openAddModal();
              }}
            >
            Add New Student Batch
            </AButton>
            {/* </Link> */}
          </Space>
      )}
      {groups && groups.length > 0 ? (
        <ATable
          columns={columns}
          dataSource={filteredGroups}
          onRow={(record, rowIndex) => {
            return {
              onClick: ({ event }) => {navigate(`/groupDetails/${record.id}`)}, // click row
              onDoubleClick: (event) => {navigate(`/groupDetails/${record.id}`)}, // double click row
            };
          }}
        />
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <ATitle level={4} content="You don't have any Group yet." />
          {hasPermission("add_group") && (
            <div
              style={{
                border: `1px dashed ${tokens.COLOR_PRIMARY}`,
                padding: "30px",
                borderRadius: "5px",
                //   width: "20%",
              }}
            >
              <AButton
                type="default"
                icon={<PlusOutlined />}
                style={{
                  color: tokens.COLOR_PRIMARY,
                  borderColor: tokens.COLOR_PRIMARY,
                }}
                onClick={() => setOpenModel(true)}
              >
                Add New Group
              </AButton>
            </div>
          )}
        </div>
      )}
      {/* Model for adding program*/}
      <AModal
        style={{
          padding: "10px",
        }}
        open={openModel}
        closable={false}
        footer={null}
        className="css-dev-only-do-not-override-1r3vfrt-last"
      >
        <ATitle content="Add Group" style={{ fontSize: "16px" }} />
        <Form
          size="large"
          form={form}
          name="add/editForm"
          onFinish={(values) => {
            handleSave(values);
          }}
          layout="vertical"
          scrollToFirstError
          title="Add/EditCollege"
        >
          <div>
            <p
              style={{
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "19.6px",
              }}
            >
              Grpup Name
            </p>
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please input the Group Name!",
                    },
                  ]}
                >
                  <AInput placeholder={"Enter Group Name"} size="large" />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div>
            <p
              style={{
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "19.6px",
              }}
            >
              Select Program
            </p>
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name="program"
                  rules={[
                    {
                      required: true,
                      message: "Please select a Year/ Semester!",
                    },
                  ]}
                >
                  {/* <AInput placeholder={"Enter Group Name"} size="large" />
                   */}
                <ASelect
                  customized={false}
                  defaultValue="Choose Class/Semester"
                  onChange={() => {}}
                >
                {programs && programs.map((program) => (
                  <Select.Option key={program.id} value={program.id}>
                    {program.name}
                  </Select.Option>
                ))}
              </ASelect>
                </Form.Item>
              </Col>
            </Row>
          </div>
          <Form.Item>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <AButton
                type="default"
                size="middle"
                onClick={(e) => {
                  form.resetFields();
                  setgroup(null);
                  setOpenModel(false);
                }}
                style={{
                  backgroundColor: tokens.COLOR_LIGHTBLACK_L50,
                }}
              >
                Cancel
              </AButton>
              <AButton
                type="primary"
                htmlType="submit"
                style={{
                  color: tokens.BG_LIGHT,
                  backgroundColor: tokens.COLOR_LIGHT_ORANGE,
                }}
              >
                Create Group
              </AButton>
            </div>
          </Form.Item>
        </Form>
      </AModal>

      {/* Model for editing program*/}
      <AModal
        style={{
          padding: "10px",
        }}
        open={openEditModel}
        closable={false}
        footer={null}
        className="css-dev-only-do-not-override-1r3vfrt-last"
      >
        <ATitle content="Edit Group" style={{ fontSize: "16px" }} />
        <Form
          size="large"
          form={form}
          name="add/editForm"
          onFinish={(values) => {
            values.id = group.id;
            handleEdit(values);
          }}
          layout="vertical"
          scrollToFirstError
          title="Add/EditCollege"
          initialValues={group && { name: group.name,program:group.program.id }}
        >
          <div>
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name="name"
                  rules={[
                    {
                      required: true,
                      message: "Please input the Group Name!",
                    },
                  ]}
                  
                  label={
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "19.6px",
                      }}
                    >
                      Grpup Name
                    </p>
                  }
                >
                  <AInput placeholder={"Enter Group Name"} size="large" />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <div>
            <Row gutter={24}>
              <Col span={24}>
              <Form.Item
                  name="program"
                  label={
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "19.6px",
                      }}
                    >
                      Select Program
                    </p>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Please select a Program!",
                    },
                  ]}
                >
                  {/* <AInput placeholder={"Enter Program"} size="large" /> */}
                  <ASelect customized={false} defaultValue="Choose Program">
                    {programs && programs.map((element) => (
                      <Select.Option value={element.id}>
                        {element.name}
                      </Select.Option>
                    ))}
                  </ASelect>
                </Form.Item>
              </Col>
            </Row>
          </div>
          <Form.Item>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <AButton
                type="default"
                size="middle"
                onClick={(e) => {
                  form.resetFields();
                  setgroup(null);
                  setOpenEditModel(false);
                }}
                style={{
                  backgroundColor: tokens.COLOR_LIGHTBLACK_L50,
                }}
              >
                Cancel
              </AButton>
              <AButton
                type="primary"
                htmlType="submit"
                style={{
                  color: tokens.BG_LIGHT,
                  backgroundColor: tokens.COLOR_LIGHT_ORANGE,
                }}
              >
                Update
              </AButton>
            </div>
          </Form.Item>
        </Form>
      </AModal>
      {/* Waringin model */}
      <AModal
        style={{
          padding: "50px",
        }}
        open={warningModel}
        closable={false}
        footer={null}
        className="css-dev-only-do-not-override-1r3vfrt-last"
      >
        <Space
          align="center"
          direction="vertical"
          style={{
            display: "flex",
            alignContent: "center",
          }}
        >
          <Space direction="vertical" size="small" align="center">
            <WarningFilled
              style={{
                fontSize: "65px",
                color: token.colorError,
              }}
            />
            <ATitle content="Are you sure ?" level={4} />
            <p
              style={{
                textAlign: "center",
                width: "100%",
                marginTop: "-5px",
              }}
            >
              Deleting a Stuent Batch will delete all the students of this Batch/Group.
              <br /> This process cannot be undo.
            </p>
          </Space>
          <Space>
            <AButton
              type="default"
              size="small"
              onClick={() => setWarningModel(false)}
            >
              Cancel
            </AButton>
            <AButton
              type="primary"
              size="small"
              onClick={() => handleDelete(selectedId)}
              mode="danger"
            >
              Delete
            </AButton>
          </Space>
        </Space>
      </AModal>
    </div>
  );
}
export default Group;
