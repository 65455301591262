import React, { useEffect, useState } from "react";
import AButton from "../../../Components/Button";
import { tokens } from "../../../utils/Constants";
import { useNavigate, useParams } from "react-router";
import { WarningFilled, PlusOutlined } from "@ant-design/icons";
import { useDispatch, useSelector } from "react-redux";
import actions from "../store/actions";
import courseAction from "../../Course/store/actions";
import Loader from "../../../Components/Loader";
import AText from "../../../Components/Text";
import { Divider, Col, Row, Space, message } from "antd";
import QuillEditor from "../../../Components/Editor/index";
import CourseCard from "../../../Components/CourseCard";
import ATitle from "../../../Components/Title";
import AModal from "../../../Components/Model";
import { Link } from "react-router-dom";

function ViewSemester() {
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openModel, setOpenModel] = useState(false);
  const [selectedId, setSelectedId] = useState();

  const handleCourseDelete = (id) => {
    new Promise((resolve, reject) => {
      dispatch(courseAction.deleteCourses(id, resolve, reject));
    })
      .then(() => {
        message.success("Course delete Success");
        setOpenModel(false);
      })
      .catch(() => {
        message.error("Course delete fails ");
      })
      .finally(() => {});
  };

  let semesterDetail = useSelector(({ semestersReducer }) =>
    semestersReducer.get("semester")
  );

  useEffect(() => {
    new Promise((resolve, reject) => {
      dispatch(actions.fetchSemester(id, resolve, reject));
    })
      .then((response) => {})
      .finally(() => {});
  }, [id, openModel]);

  if (semesterDetail) {
    return (
      <div
        style={{
          backgroundColor: tokens.BG_LIGHT,
          borderRadius: "6px",
          padding: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            fontWeight: 500,
            margin: "10px 0",
          }}
        >
          <AText
            style={{
              fontSize: "14px",
              fontWeight: 600,
              margin: "0 10px 0 0",
              color: tokens.COLOR_NEUTRAL_N600,
            }}
          >
            {semesterDetail.type + " Name :"}
          </AText>
          <AText
            content={semesterDetail.name}
            style={{
              color: tokens.COLOR_NEUTRAL_N600,
              fontWeight: 500,
              fontSize: "14px",
            }}
          />
        </div>
        <div
          style={{ display: "flex", alignItems: "flex-end", margin: "10px 0" }}
        >
          <AText
            style={{
              fontSize: "14px",
              fontWeight: 600,
              margin: "0 10px 0 0",
              color: tokens.COLOR_NEUTRAL_N600,
            }}
          >
            Program Name :
          </AText>
          <AText
            content={
              semesterDetail.programCode +
              " (" +
              semesterDetail.programName +
              ")"
            }
            style={{
              color: tokens.COLOR_NEUTRAL_N600,
              fontWeight: 500,
              fontSize: "14px",
            }}
          />
        </div>

        <div style={{ margin: "10px 0", color: tokens.COLOR_NEUTRAL_N600 }}>
          <style>
            {`
        .ql-container.ql-snow {
          border: none;
        }
      `}
          </style>
          <AText
            style={{
              fontSize: "14px",
              fontWeight: 600,
              margin: "0 10px 0 0",
              color: tokens.COLOR_NEUTRAL_N600,
            }}
          >
            Description:
          </AText>

          <QuillEditor
            value={semesterDetail.description}
            readOnly={true}
            style={{
              fontSize: tokens.FONT_FAMILY,
              border: "none",
              padding: "0px",
            }}
            className="no-toolbar"
            onChange={() => {}}
            modules={{ toolbar: false }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        ></div>
        <Divider />

        <div
          style={{
            display: "flex",
            justifyContent: "end",
          }}
        >
          <Link
            to={{
              pathname: "/course/add",
              search: `?program=${semesterDetail.programName}&semester=${semesterDetail.name}&semesterId=${id}`,
            }}
          >
            <AButton
              type="primary"
              size="middle"
              icon={<PlusOutlined />}
              style={{
                color: tokens.BG_LIGHT,
                backgroundColor: tokens.COLOR_DARK_ORANGE,
              }}
            >
              Add New Course
            </AButton>
          </Link>
        </div>
        <div style={{ margin: "1.5em 1.5em 0 1.5em" }}>
          <Row wrap gutter={10}>
            {semesterDetail.courses &&
              semesterDetail.courses.map((course) => (
                <Col md={8} key={course.id}>
                  <CourseCard
                    course={course}
                    openPopUp={setOpenModel}
                    selectItemId={setSelectedId}
                  />
                </Col>
              ))}
          </Row>
        </div>
        <AModal
          style={{
            padding: "50px",
          }}
          open={openModel}
          closable={false}
          footer={null}
          className="css-dev-only-do-not-override-1r3vfrt-last"
        >
          <Space
            align="center"
            direction="vertical"
            style={{
              display: "flex",
              alignContent: "center",
            }}
          >
            <Space direction="vertical" size="small" align="center">
              <WarningFilled
                style={{
                  fontSize: "65px",
                  color: tokens.colorError,
                }}
              />
              <ATitle content="Are you sure ?" level={4} />
              <p
                style={{
                  textAlign: "center",
                  width: "100%",
                  marginTop: "-5px",
                }}
              >
                Deleting a course will also remove all associated batches. This
                process cannot be undo.
              </p>
            </Space>
            <Space>
              <AButton
                type="default"
                size="small"
                onClick={() => setOpenModel(false)}
              >
                Cancel
              </AButton>
              <AButton
                type="primary"
                size="small"
                onClick={() => handleCourseDelete(selectedId)}
                mode="danger"
              >
                Delete
              </AButton>
            </Space>
          </Space>
        </AModal>
      </div>
    );
  } else {
    return <Loader />;
  }
}
export default ViewSemester;
