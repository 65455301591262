import { collegesReducer } from "../pages/College/store";
import { combineReducers } from "@reduxjs/toolkit";
import { superadminsReducer } from "../pages/Superadmin/store";
import { semestersReducer } from "../pages/Semester/store";
import { programsReducer } from "../pages/Program/store";
import { instructorsReducer } from "../pages/Instructor/store";
import { studentsReducer } from "../pages/Students/store";
import { batchesReducer } from "../pages/Batch/store";
import { rolesReducer } from "../pages/Roles/store";
import { coursesReducer } from "../pages/Course/store";
import { noticeReducer } from "../pages/Notice/store";
import { assignCoursesReducer } from "../pages/AssignCourse/store";
import { timeSlotReducer } from "../pages/TimeSlot/store";
import { assignTimeSlotsReducer } from "../pages/AssignTimeSlot/store"
import { examsReducer } from "../pages/Exam/store";
import { batchSessionReducer } from "../pages/ManageBatch/store";

import { evaluationCriteriaReducer } from "../pages/EvaluationCriteria/store";
const appReducer = combineReducers({
  ...collegesReducer,
  ...superadminsReducer,
  ...semestersReducer,
  ...programsReducer,
  ...instructorsReducer,
  ...studentsReducer,
  ...batchesReducer,
  ...rolesReducer,
  ...coursesReducer,
  ...noticeReducer,
  ...assignCoursesReducer,
  ...batchesReducer,
  ...evaluationCriteriaReducer,
  ...timeSlotReducer,
  ...assignTimeSlotsReducer,
  ...examsReducer,
  ...batchSessionReducer,
});

export default (
  state = {
    collegesReducer: undefined,
    superadminsReducer: undefined,
    semestersReducer: undefined,
    programsReducer: undefined,
    instructorsReducer: undefined,
    studentsReducer: undefined,
    batchesReducer: undefined,
    rolesReducer: undefined,
    coursesReducer: undefined,
    noticeReducer: undefined,
    assignCoursesReducer: undefined,
    evaluationCriteriaReducer: undefined,
    timeSlotReducer: undefined,
    assignTimeSlotsReducer: undefined,
    examsReducer: undefined,
    batchSessionReducer: undefined,
  },
  action
) => appReducer(action.type === "LOGOUT" ? undefined : state, action);
