import { Typography } from 'antd';
import React from 'react';

const AText = ({ children, content, ...others }) => {
  const { Text } = Typography;
  return (
    <Text {...others}>
      {content}
      {children}
    </Text>
  );
};

export default AText;
