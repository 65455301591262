import { Col, Divider, Form, Row, Space, message, theme } from "antd";
import React, { useEffect, useState } from "react";
import { tokens } from "../../../utils/Constants";
import ATitle from "../../../Components/Title";
import AText from "../../../Components/Text";
import QuillEditor from "../../../Components/Editor";
import AButton from "../../../Components/Button";
import { DeleteIcon } from "../../../assets/icons/DeleteIcon";
import { EditIcon } from "../../../assets/icons/EditIcon";
import { PlusOutlined, WarningFilled, LoginOutlined } from "@ant-design/icons";

import { useNavigate, useParams } from "react-router";
import ATable from "../../../Components/Table";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Components/Loader";
import actions from "../store/actions";
import AModal from "../../../Components/Model";
import AInput from "../../../Components/Input";
import { validateEmail } from "../../../utils/Helper";
import axios from "axios";

function ViewCollege() {
  const [openModel, setOpenModel] = useState(false);
  const [addadminModel, setAddAdminModel] = useState(false);
  const [openAdminModel, setAdminModel] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const [loadingCreate, setLoadingCreate] = useState(false);


  const { id } = useParams();
  const [college, setCollege] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { useToken } = theme;
  const { token } = useToken();
  const [form] = Form.useForm();

  const handleSave = (value) => {
    // setSaving(true);
    setLoadingCreate(true);
    const data = {
      ...value,
    };
    new Promise((resolve, reject) => {
      dispatch(actions.saveCollegeAdmin(data, "ADD", resolve, reject));
    })
      .then(() => {
        message.success("College Admin added Sucessfully");
        form.resetFields();
        setAddAdminModel(false);
      })
      .catch(() => {
        message.error("College Admin add failed, please try again");
      })
    setLoadingCreate(false);
  };

  async function logInAsUser(id) {
    axios
      .get(process.env.REACT_APP_API_BASE_URL + `/loginas/${id}`)
      .then((response) => {
        if (response.data.token) {
          const userToken = response.data.token;
          const superAdminToken = localStorage.getItem("token");
          localStorage.setItem("superAdminToken", superAdminToken);
          localStorage.setItem("token", userToken);
          navigate("/dashboard");
          window.location.reload();
          message.success("Log in as User successfully");
        } else {
          message.error("Failed to generate jwt token");
        }
      })
      .catch((error) => {
        message.error(error.response.data);
      })
  }

  const handleCollegeAdminDelete = (admin_id) => {
    setLoadingDelete(true);
    setOpenModel(false);
    new Promise((resolve, reject) => {
      dispatch(actions.deleteCollegeAdmin(admin_id, resolve, reject));
    })
      .then(() => {
        message.success("College Admin delete Success");
        setAdminModel(false);
      })
      .catch(() => {
        message.error("College delete fails ");
      })
      setLoadingDelete(false);
  };

  //get all college list
  const collegesList = useSelector(({ collegesReducer }) =>
    collegesReducer.get("colleges")
  );
  //get all college adminslist
  let collegesAdminList = useSelector(({ collegesReducer }) =>
    collegesReducer.get("college_admins")
  );

  // Function to find an object by ID in the list
  const findObjectById = (id) => {
    return collegesList
      ? collegesList.find((item) => item.id === parseInt(id))
      : "";
  };

  const handleCollegeDelete = (id) => {
    new Promise((resolve, reject) => {
      dispatch(actions.deleteColleges(id, resolve, reject));
    })
      .then(() => {
        navigate("/college");
        message.success("College delete Success");
      })
      .catch(() => {
        message.error("College delete fails ");
      })
  };

  useEffect(() => {
    // Fetch the colleges list only if it hasn't been fetched yet
    if (!collegesList.length) {
      new Promise((resolve, reject) => {
        dispatch(actions.fetchColleges(resolve, reject));
      });
    }
    new Promise((resolve, reject) => {
      dispatch(actions.fetchCollegeAdmins(id, resolve, reject));
    });
  }, []);

  useEffect(() => {
    const foundCollege = findObjectById(id);
    setCollege(foundCollege);
  }, [collegesList]);

  const columns = [
    {
      title: "S.N.",
      render: (_, __, index) => <span>{index + 1}</span>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Action",
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <AButton
            type="default"
            style={{
              color: tokens.COLOR_DANGER,
              borderColor: tokens.COLOR_DANGER,
              margin: "10px",
            }}
            icon={<DeleteIcon />}
            onClick={(e) => {
              e.stopPropagation();
                setAdminModel(true);
                setSelectedId(record.id);
            }}
          >
            Delete
          </AButton>
          <AButton
            type="default"
            style={{
              color: tokens.COLOR_PRIMARY,
              borderColor: tokens.COLOR_PRIMARY,
              margin: "10px",
            }}
            icon={<LoginOutlined />}
            onClick={(e) => {
              e.stopPropagation(); logInAsUser(record.id);
            }}
          >
            Login-As-User
          </AButton>
        </div>
      ),
    },
  ];

  if (!college) {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Loader />
      </div>
    );
  }
  return (
    <div
      style={{
        backgroundColor: tokens.BG_LIGHT,
        borderRadius: "6px",
        padding: "20px",
      }}
    >
      <ATitle
        content={"College Info"}
        level={4}
        style={{ color: tokens.COLOR_NEUTRAL_N600, fontSize: "18px" }}
      />
      <div
        style={{
          display: "flex",
          alignItems: "flex-end",
          fontWeight: 500,
          margin: "10px 0",
        }}
      >
        <AText
          style={{
            fontSize: "14px",
            fontWeight: 600,
            margin: "0 10px 0 0",
            color: tokens.COLOR_NEUTRAL_N600,
          }}
        >
          College Name:
        </AText>
        <AText
          content={college.name}
          style={{
            color: tokens.COLOR_NEUTRAL_N600,
            fontWeight: 500,
            fontSize: "14px",
          }}
        />
      </div>
      <div
        style={{ display: "flex", alignItems: "flex-end", margin: "10px 0" }}
      >
        <AText
          style={{
            fontSize: "14px",
            fontWeight: 600,
            margin: "0 10px 0 0",
            color: tokens.COLOR_NEUTRAL_N600,
          }}
        >
          Address 1 :
        </AText>
        <AText
          content={college.street1}
          style={{
            color: tokens.COLOR_NEUTRAL_N600,
            fontWeight: 500,
            fontSize: "14px",
          }}
        />
      </div>
      <div
        style={{ display: "flex", alignItems: "flex-end", margin: "10px 0" }}
      >
        <AText
          style={{
            fontSize: "14px",
            fontWeight: 600,
            margin: "0 10px 0 0",
            color: tokens.COLOR_NEUTRAL_N600,
          }}
        >
          City :
        </AText>
        <AText
          content={college.city}
          style={{
            color: tokens.COLOR_NEUTRAL_N600,
            fontWeight: 500,
            fontSize: "14px",
          }}
        />
      </div>
      <div
        style={{ display: "flex", alignItems: "flex-end", margin: "10px 0" }}
      >
        <AText
          style={{
            fontSize: "14px",
            fontWeight: 600,
            margin: "0 10px 0 0",
            color: tokens.COLOR_NEUTRAL_N600,
          }}
        >
          State :
        </AText>
        <AText
          content={college.state}
          style={{
            color: tokens.COLOR_NEUTRAL_N600,
            fontWeight: 500,
            fontSize: "14px",
          }}
        />
      </div>
      <div
        style={{ display: "flex", alignItems: "flex-end", margin: "10px 0" }}
      >
        <AText
          style={{
            fontSize: "14px",
            fontWeight: 600,
            margin: "0 10px 0 0",
            color: tokens.COLOR_NEUTRAL_N600,
          }}
        >
          Contact Number :
        </AText>
        <AText
          content={college.contactNo}
          style={{
            color: tokens.COLOR_NEUTRAL_N600,
            fontWeight: 500,
            fontSize: "14px",
          }}
        />
      </div>
      <div style={{ margin: "10px 0", color: tokens.COLOR_NEUTRAL_N600 }}>
        <style>
          {`
          .ql-container.ql-snow {
            border: none;
          }
        `}
        </style>
        <AText
          style={{
            fontSize: "14px",
            fontWeight: 600,
            margin: "0 10px 0 0",
            color: tokens.COLOR_NEUTRAL_N600,
          }}
        >
          Description:
        </AText>

        <QuillEditor
          value={college.description}
          readOnly={true}
          style={{
            fontSize: tokens.FONT_FAMILY,
            border: "none",
            padding: "0px",
          }}
          className="no-toolbar"
          onChange={() => {}}
          modules={{ toolbar: false }}
        />
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <AButton
          type="default"
          size="middle"
          style={{
            color: tokens.COLOR_DANGER,
            borderColor: tokens.COLOR_DANGER,
            margin: "10px",
          }}
          icon={<DeleteIcon />}
          onClick={() => setOpenModel(true)}
        >
          Delete
        </AButton>
        <AButton
          type="primary"
          size="middle"
          icon={<EditIcon />}
          style={{
            color: tokens.BG_LIGHT,
            backgroundColor: tokens.COLOR_SUCCESS,
          }}
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/college/edit/${college.id}`);
          }}
        >
          Edit
        </AButton>
      </div>
      <Divider />
      <div
        style={{
          backgroundColor: tokens.BG_LIGHT,
          borderRadius: "6px",
          padding: "20px",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <AText
          style={{
            fontSize: "14px",
            fontWeight: 600,
            margin: "0 10px 0 0",
            color: tokens.COLOR_NEUTRAL_N600,
          }}
        >
          College Admin :
        </AText>
        <div
          style={{ display: "flex", alignItems: "flex-end", margin: "10px 0" }}
        >
          <AButton
            type="primary"
            size="middle"
            icon={<PlusOutlined />}
            style={{
              color: tokens.BG_LIGHT,
              backgroundColor: tokens.COLOR_DARK_ORANGE,
            }}
            onClick={(e) => {
              e.stopPropagation();
              setAddAdminModel(true);
            }}
          >
            Add New College Admin
          </AButton>
        </div>
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            margin: "10px 0",
            boxShadow: tokens.TABLE_BOX_SADOW,
          }}
        >
          <ATable
            columns={columns}
            dataSource={collegesAdminList}
            onRow={(record, rowIndex) => {
              return {
                onClick: ({ event }) => {
                  navigate(`/college/view/${record.id}`);
                }, // click row
                onDoubleClick: (event) => {
                  navigate(`/college/view/${record.id}`);
                }, // double click row
              };
            }}
          />
        </div>
      </div>
      {/* model to delete college */}
      <AModal
        style={{
          padding: "50px",
        }}
        open={openModel}
        closable={false}
        footer={null}
        className="css-dev-only-do-not-override-1r3vfrt-last"
      >
        <Space
          align="center"
          direction="vertical"
          style={{
            display: "flex",
            alignContent: "center",
          }}
        >
          <Space direction="vertical" size="small" align="center">
            <WarningFilled
              style={{
                fontSize: "65px",
                color: token.colorError,
              }}
            />
            <ATitle content="Are you sure ?" level={4} />
            <p
              style={{
                textAlign: "center",
                width: "100%",
                marginTop: "-5px",
              }}
            >
              Deleting a college will delete all the users of college. This
              process cannot be undo.
            </p>
          </Space>
          <Space>
            <AButton
              type="default"
              size="small"
              onClick={() => setOpenModel(false)}
            >
              Cancel
            </AButton>
            <AButton
              type="primary"
              size="small"
              onClick={() => handleCollegeDelete(college.id)}
              mode="danger"
            >
              Delete
            </AButton>
          </Space>
        </Space>
      </AModal>
      {/* model to add the college */}
      <AModal
        style={{
          padding: "10px",
        }}
        open={addadminModel}
        closable={false}
        footer={null}
        className="css-dev-only-do-not-override-1r3vfrt-last"
      >
        <ATitle content="College Admin Info" style={{ fontSize: "16px" }} />
        <Form
          size="large"
          form={form}
          name="add/editForm"
          onFinish={(values) => {
            handleSave(values);
          }}
          layout="vertical"
          scrollToFirstError
          title="Add/EditCollege"
        >
          <div>
            <p
              style={{
                fontSize: "14px",
                fontWeight: "600",
                lineHeight: "19.6px",
              }}
            >
              Email
            </p>
            <Row gutter={24}>
              <Col span={24}>
                <Form.Item
                  name="email"
                  rules={[
                    {
                      required: true,
                      message: "Please input the Email!",
                      validateTrigger: "onFinish",
                    },
                    {
                      validator: validateEmail,
                    },
                  ]}
                >
                  <AInput placeholder={"Enter Email"} size="large" />
                </Form.Item>
              </Col>
            </Row>
          </div>
          <Form.Item>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <AButton
                type="default"
                size="middle"
                onClick={() => setAddAdminModel(false)}
                style={{
                  backgroundColor: tokens.COLOR_LIGHTBLACK_L50,
                }}
              >
                Cancel
              </AButton>
              <AButton
                type="primary"
                htmlType="submit"
                style={{
                  color: tokens.BG_LIGHT,
                  backgroundColor: tokens.COLOR_LIGHT_ORANGE,
                }}
                loading={loadingCreate}
              >
                Create Admin
              </AButton>
            </div>
          </Form.Item>
        </Form>
      </AModal>
      {/* model to delete the college admin */}
      <AModal
        style={{
          padding: "50px",
        }}
        open={openAdminModel}
        closable={false}
        footer={null}
        className="css-dev-only-do-not-override-1r3vfrt-last"
      >
        <Space
          align="center"
          direction="vertical"
          style={{
            display: "flex",
            alignContent: "center",
          }}
        >
          <Space direction="vertical" size="small" align="center">
            <WarningFilled
              style={{
                fontSize: "65px",
                color: token.colorError,
              }}
            />
            <ATitle content="Are you sure ?" level={4} />
            <p
              style={{
                textAlign: "center",
                width: "100%",
                marginTop: "-5px",
              }}
            >
              Deleting items from this directory cannot be undo.
            </p>
          </Space>
          <Space>
            <AButton
              type="default"
              size="small"
              onClick={() => setAdminModel(false)}
            >
              Cancel
            </AButton>
            <AButton
              type="primary"
              size="small"
              onClick={() => handleCollegeAdminDelete(selectedId)}
              mode="danger"
              loading={loadingDelete}
            >
              Delete
            </AButton>
          </Space>
        </Space>
      </AModal>
    </div>
  );
}
export default ViewCollege;
