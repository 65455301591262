import { Checkbox, Form, message, theme } from "antd";
import React, { useEffect, useState } from "react";
import { tokens } from "../../../utils/Constants";
import AButton from "../../../Components/Button";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import actions from "../store/actions";
import Loader from "../../../Components/Loader";

function ViewRole() {
  const { id } = useParams();
  const [prevPermissions, setPrevPermissions] = useState([]);
  const [role, setRole] = useState(null);
  const [changedPermission, setChangedPermission] = useState([]);
  const [currentRole, setCurrentRole] = useState();
  const [permissionType, setPermissionType] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { useToken } = theme;
  const { token } = useToken();
  const [form] = Form.useForm();

  //get all roles list
  let rolesList = useSelector(({ rolesReducer }) =>
    rolesReducer.get("rolePermissions")
  );

  //get all roles list
  let permissionsList = useSelector(({ rolesReducer }) =>
    rolesReducer.get("permissions")
  );

  const findObjectById = (id) => {
    return rolesList ? rolesList.find((item) => item.id === parseInt(id)) : "";
  };

  useEffect(() => {
    new Promise((resolve, reject) => {
      dispatch(actions.fetchPermissions(resolve, reject));
    }).finally(() => {});

    new Promise((resolve, reject) => {
      dispatch(actions.fetchRolePermissions(resolve, reject));
    }).finally(() => {});
  }, []);

  useEffect(() => {
    const foundRole = findObjectById(id);
    setRole(foundRole);
    if (foundRole) {
      setCurrentRole(foundRole.name);
      const uniqueCodesSet = new Set(
        foundRole.permissions.map((item) => item.id)
      );
      setPrevPermissions(Array.from(uniqueCodesSet));
      setChangedPermission(Array.from(uniqueCodesSet));
    }
    if (permissionsList) {
      const uniqueCodesSet = new Set(permissionsList.map((item) => item.code));
      setPermissionType(Array.from(uniqueCodesSet));
    }
  }, [dispatch, rolesList, permissionsList]);

  const handleChange = (e) => {
    const permissionId = e.target.value;
    setChangedPermission((prevPermissions) =>
      prevPermissions.includes(permissionId)
        ? prevPermissions.filter((id) => id !== permissionId)
        : [...prevPermissions, permissionId]
    );
  };

  return (
    <div>
      <h1>{currentRole}</h1>
      <div style={{ display: "flex", flexWrap: "wrap", justifyContent: "left" }}>
        {permissionType &&
          permissionType.map((type, key) => {
            return (
              <div key={key} style={{border: "1px solid black", margin: "5px", padding: "5px", borderRadius: "5px"}}>
                <h4>{type}</h4>
                {permissionsList.map((e, index) => {
                  if (e.code == type) {
                    return (
                      <div key={index} style={{ margin: "10px 10px" }}>
                        <label
                          htmlFor={`permission-${e.id}`}
                          style={{
                            cursor: "pointer",
                            backgroundColor: "transparent",
                            transition: "background-color 0.3s, color 0.3s",
                            padding: "4px 8px",
                            borderRadius: "4px",
                          }}
                          onMouseEnter={(event) => {
                            event.currentTarget.style.backgroundColor =
                              "#C4C4C4";
                          }}
                          onMouseLeave={(event) => {
                            event.currentTarget.style.backgroundColor =
                              "transparent";
                          }}
                        >
                          <Checkbox
                            id={`permission-${e.id}`}
                            checked={changedPermission.includes(e.id)}
                            value={e.id}
                            onChange={handleChange}
                            name={e.name}
                          />
                          <span
                            style={{
                              marginLeft: "10px",
                              cursor: "pointer",
                              transition: "color 0.3s ease-in-out",
                            }}
                          >
                            {e.label}
                          </span>
                        </label>
                      </div>
                    );
                  }
                  <hr />;
                  return null;
                })}
              </div>
            );
          })}
      </div>
      <div className="buttons" style={{marginTop: "10px"}}>
        <AButton
          type="primary"
          size="middle"
          style={{
            color: tokens.BG_LIGHT,
            backgroundColor: tokens.COLOR_SUCCESS,
          }}
          onClick={() => {
            const data = {
              changePermission: changedPermission,
              prevPermission: prevPermissions,
              roleId: id,
            };
            new Promise((resolve, reject) => {
              dispatch(actions.editPermissions(id, data, resolve, reject));
            })
              .then(() => {
                message.success(
                  "Permissions of " + currentRole + " changed successfully"
                );
                navigate("/role");
              })
              .catch(() => {
                message.error("Permission edit failed");
                navigate("/role");
              });
          }}
        >
          Submit
        </AButton>
        <AButton
          type="primary"
          size="middle"
          style={{
            color: tokens.BG_LIGHT,
            backgroundColor: tokens.COLOR_SUCCESS,
            margin: "10px",
          }}
          onClick={(e) => {
            setChangedPermission(prevPermissions);
          }}
        >
          Reset
        </AButton>
        <AButton
          type="default"
          size="middle"
          style={{
            color: tokens.COLOR_DANGER,
            borderColor: tokens.COLOR_DANGER,
            margin: "10px",
          }}
          onClick={(e) => {
            navigate("/role");
          }}
        >
          Cancel
        </AButton>
      </div>
    </div>
  );
}
export default ViewRole;
