import { Select, SelectProps } from "antd";
import React from "react";

function ASelect({ customized, children, ...others }) {
  return customized ? (
    <Select
      bordered={false}
      style={{
        borderBottom: "1px solid black",
        fontSize: "14px",
      }}
      {...others}
    >
      {children}
    </Select>
  ) : (
    <Select bordered={true} {...others}>
      {children}
    </Select>
  );
}

export default ASelect;
