import React, { useEffect, useState } from "react";
import ViewTeacherStudent from "../../../Components/ViewInstructorStudent";
import actions from "../store/actions";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { message } from "antd";
import Loader from "../../../Components/Loader";
function ViewInstructor() {
  const [saving, setSaving] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const instructor = useSelector(({ instructorsReducer }) => {
    const instructors = instructorsReducer.get("instructors");
    try {
      return instructors.find((instructor) => {
        return instructor.id === parseInt(id);
      });
    } catch (e) {
      navigate("/instructor");
    }
  });

  useEffect(() => {
    if (!instructor) {
      new Promise((resolve, reject) => {
        dispatch(actions.fetchInstructors(resolve, reject));
      })
        .then((response) => {})
        .finally(() => {});
    }
  }, [instructor]);

  const handleDelete = (id) => {
    new Promise((resolve, reject) => {
      dispatch(actions.deleteInstructor(id, resolve, reject));
    })
      .then(() => {
        navigate("/instructor");
        message.success("Instructor delete Success");
      })
      .catch(() => {
        message.error("Instructor delete fails ");
      })
      .finally(() => {
        // setOpenModel(false);
      });
  };

  const handleEdit = (id) => {
    navigate(`/instructor/edit/${id}`);
  };
  if (!instructor) {
    return <Loader />;
  }

  return (
    <ViewTeacherStudent
      data={instructor}
      handleDelete={handleDelete}
      handleEdit={handleEdit}
    />
  );
}
export default ViewInstructor;
