import actions from "./actions";
import { fromJS } from "immutable";

const initialState = fromJS({
  timeSlots: [],
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_TIMESLOTS_SUCCESS:
      return state.set("timeSlots", action.payload);

    case actions.CREATE_TIMESLOT_SUCCESS:
      return state.set("timeSlots", action.payload);

    case actions.UPDATE_TIMESLOT_SUCCESS:
      return state.set("timeSlots", action.payload);

    case actions.DELETE_TIMESLOT_SUCCESS:
      return state.set("timeSlots", action.payload);
      
    default:
      return state;
  }
};

export default reducer;
