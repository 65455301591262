import React, { useEffect, useState } from "react";
import AButton from "../../../Components/Button";
import { tokens } from "../../../utils/Constants";
import { DeleteIcon } from "../../../assets/icons/DeleteIcon";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import actions from "../store/actions";
import Loader from "../../../Components/Loader";
import ATitle from "../../../Components/Title";
import AText from "../../../Components/Text";
import QuillEditor from "../../../Components/Editor";
import { EditIcon } from "../../../assets/icons/EditIcon";
import { Divider, Form, Row, Space, message, theme } from "antd";
import { PlusOutlined, WarningFilled } from "@ant-design/icons";
import ATable from "../../../Components/Table";
import AModal from "../../../Components/Model";
import AInput from "../../../Components/Input";
import { ActiveIcon } from "../../../assets/icons/ActiveIcon";

function ViewProgram() {
  const [visible, setVisible] = useState(false);
  const [visibleEdit, setVisibleEdit] = useState(false);
  const [editorContent, setEditorContent] = useState("");
  const [selectedSemester, setSelectedSemester] = useState();
  const [semester, setSemester] = useState();
  const [saving, setSaving] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const { id } = useParams();
  const [form] = Form.useForm();
  let navigate = useNavigate();
  const dispatch = useDispatch();

  const { useToken } = theme;
  const { token } = useToken();

  const program = useSelector(({ programsReducer }) => {
    const programs = programsReducer.get("programs");
    if (programs) {
      return programs.find((program) => {
        return program.id === parseInt(id);
      });
    }
  });

  let semesters = useSelector(({ programsReducer }) =>
    programsReducer.get("semesters")
  );

  useEffect(() => {
    if (!program) {
      new Promise((resolve, reject) => {
        dispatch(actions.fetchPrograms(resolve, reject));
      })
        .then((response) => {})
        .finally(() => {});
    }
  }, [program]);

  useEffect(() => {
    new Promise((resolve, reject) => {
      dispatch(actions.fetchSemesters(id, resolve, reject));
    }).finally(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, deleting]);

  const setSemesterById = (id) => {
    const semester = semesters.find((semester) => semester.id === id);
    setSelectedSemester(id);
    setSemester(semester);
  };

  const handleEditorChange = (content) => {
    setEditorContent(content);
  };

  const navigatePage = () => {};

  const handleSave = (value) => {
    setSaving(true);
    const data = {
      ...value,
      program_id: id,
    };
    new Promise((resolve, reject) => {
      dispatch(actions.saveSemester(data, "ADD", resolve, reject));
    })
      .then(() => {
        form.resetFields();
        message.success(program.programType + " added Sucessfully");
        setVisible(false);
      })
      .catch(() => {
        message.error(program.programType + " add failed");
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const handleEdit = (value) => {
    setSaving(true);
    const data = {
      ...value,
      program_id: id,
    };

    new Promise((resolve, reject) => {
      dispatch(
        actions.editSemester(semester.id, data, "EDIT", resolve, reject)
      );
    })
      .then(() => {
        form.resetFields();
        message.success(program.programType + " edited Sucessfully");
        setVisibleEdit(false);
      })
      .catch(() => {
        message.error(program.programType + " edit failed");
      })
      .finally(() => {
        setSaving(false);
      });
  };

  const hanldeSemesterDelete = (id) => {
    setDeleting(true);
    new Promise((resolve, reject) => {
      dispatch(actions.deleteSemester(id, resolve, reject));
    })
      .then(() => {
        message.success(program.programType + " deleted Sucessfully");
      })
      .catch(() => {
        message.error(program.programType + " delete failed");
      })
      .finally(() => {
        setDeleting(false);
      });
  };

  let columns = [];
  if (program) {
    columns = [
      {
        title: "S.N.",
        render: (_, __, index) => <span>{index + 1}</span>,
      },
      {
        title: `${program.programType} Name`,
        dataIndex: "name",
        key: "name",
      },
      {
        title: `${program.programType} Name`,
        dataIndex: "description",
        key: "description",
        render: (text) => <div dangerouslySetInnerHTML={{ __html: text }} />,
      },
      {
        title: "Action",
        render: (_, record) => (
          <div>
            <AButton
              type="primary"
              size="middle"
              //   icon={<EditIcon />}
              style={{
                color: tokens.BG_LIGHT,
                backgroundColor: tokens.COLOR_SUCCESS,
              }}
              onClick={(e) => {
                e.stopPropagation();
                setSemesterById(record.id);
                setVisibleEdit(true);
              }}
            >
              Edit
            </AButton>

            <AButton
              type="default"
              style={{
                color: tokens.COLOR_DANGER,
                borderColor: tokens.COLOR_DANGER,
                margin: "10px",
              }}
              //   icon={<DeleteIcon />}
              onClick={(e) => {
                e.stopPropagation(); hanldeSemesterDelete(record.id);
              }}
            >
              Delete
            </AButton>
          </div>
        ),
      },
    ];
  }
  if (saving || deleting) {
    return <Loader />;
  }
  if (program) {
    return (
      <div
        style={{
          backgroundColor: tokens.BG_LIGHT,
          borderRadius: "6px",
          padding: "20px",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            fontWeight: 500,
            margin: "10px 0",
          }}
        >
          <AText
            style={{
              fontSize: "14px",
              fontWeight: 600,
              margin: "0 10px 0 0",
              color: tokens.COLOR_NEUTRAL_N600,
            }}
          >
            Program Name:
          </AText>
          <AText
            content={program.name}
            style={{
              color: tokens.COLOR_NEUTRAL_N600,
              fontWeight: 500,
              fontSize: "14px",
            }}
          />
        </div>
        <div
          style={{ display: "flex", alignItems: "flex-end", margin: "10px 0" }}
        >
          <AText
            style={{
              fontSize: "14px",
              fontWeight: 600,
              margin: "0 10px 0 0",
              color: tokens.COLOR_NEUTRAL_N600,
            }}
          >
            Program Type :
          </AText>
          <AText
            content={program.programType}
            style={{
              color: tokens.COLOR_NEUTRAL_N600,
              fontWeight: 500,
              fontSize: "14px",
            }}
          />
        </div>
        <div
          style={{ display: "flex", alignItems: "flex-end", margin: "10px 0" }}
        >
          <AText
            style={{
              fontSize: "14px",
              fontWeight: 600,
              margin: "0 10px 0 0",
              color: tokens.COLOR_NEUTRAL_N600,
            }}
          >
            Program Code :
          </AText>
          <AText
            content={program.programCode}
            style={{
              color: tokens.COLOR_NEUTRAL_N600,
              fontWeight: 500,
              fontSize: "14px",
            }}
          />
        </div>

        <div style={{ margin: "10px 0", color: tokens.COLOR_NEUTRAL_N600 }}>
          <style>
            {`
        .ql-container.ql-snow {
          border: none;
        }
      `}
          </style>
          <AText
            style={{
              fontSize: "14px",
              fontWeight: 600,
              margin: "0 10px 0 0",
              color: tokens.COLOR_NEUTRAL_N600,
            }}
          >
            Program Description:
          </AText>

          <QuillEditor
            value={program.programDescription}
            readOnly={true}
            style={{
              fontSize: tokens.FONT_FAMILY,
              border: "none",
              padding: "0px",
            }}
            className="no-toolbar"
            onChange={() => {}}
            modules={{ toolbar: false }}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <AButton
            type="default"
            size="middle"
            style={{
              color: tokens.COLOR_DANGER,
              borderColor: tokens.COLOR_DANGER,
              margin: "10px",
            }}
            icon={<DeleteIcon />}
            //   onClick={() => setOpenModel(true)}
          >
            Delete
          </AButton>
          <AButton
            type="primary"
            size="middle"
            icon={<EditIcon />}
            style={{
              color: tokens.BG_LIGHT,
              backgroundColor: tokens.COLOR_SUCCESS,
            }}
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/program/edit/${program.id}`);
            }}
          >
            Edit
          </AButton>
        </div>
        <Divider />

        <div
          style={{
            backgroundColor: tokens.BG_LIGHT,
            borderRadius: "6px",
            padding: "20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <AText
            style={{
              fontSize: "14px",
              fontWeight: 600,
              margin: "0 10px 0 0",
              color: tokens.COLOR_NEUTRAL_N600,
            }}
          >
            {program.programType} Details
          </AText>
          <div
            style={{
              display: "flex",
              alignItems: "flex-end",
              margin: "10px 0",
            }}
          >
            <AButton
              type="primary"
              size="middle"
              icon={<PlusOutlined />}
              style={{
                color: tokens.BG_LIGHT,
                backgroundColor: tokens.COLOR_DARK_ORANGE,
              }}
              onClick={(e) => {
                e.stopPropagation();
                // setAddAdminModel(true);
                form.resetFields();
                setVisible(true);
              }}
            >
              Add New {program.programType}
            </AButton>
          </div>
          <ATable
            columns={columns}
            dataSource={semesters}
            onRow={(record, rowIndex) => {
              return {
                onClick: ({ event }) => {
                  navigate(`/semester/details/${record.id}`);
                }, // click row
                onDoubleClick: (event) => {
                  navigate(`/semester/details/${record.id}`);
                }, // double click row
              };
            }}
          />
        </div>
        {/* design for a model */}

        <AModal
          style={{
            padding: "50px",
          }}
          open={visible}
          closable={false}
          footer={null}
          className="css-dev-only-do-not-override-1r3vfrt-last"
        >
          <Form
            size="large"
            form={form}
            name="add/editForm"
            onFinish={(values) => {
              values.description = editorContent;
              handleSave(values);
            }}
            layout="vertical"
            scrollToFirstError
            title="Add/EditProgram"
          >
            <ATitle content={"Add "+program.programType} />
            <Form.Item
              name="name"
              label={
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "19.6px",
                  }}
                >
                 {program.programType == "Semester" ? "Semester Name" : "Year Name"}
                </p>
              }
              rules={[
                {
                  required: true,
                  message: "Please input the Name!",
                },
              ]}
            >
              <AInput placeholder={program.programType == "Semester" ? "Enter Semester Name" : "Enter Year Name"} size="large" />
            </Form.Item>
            <Form.Item
              name="description"
              label={
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "19.6px",
                  }}
                >
                  {" "}
                  {program.programType == "Semester" ? "Semester Description" : "Year Description"}
                </p>
              }
              rules={[
                {
                  required: true,
                  message: "Please input the Description!",
                },
              ]}
            >
              <QuillEditor
                className="toolbar"
                style={{
                  fontSize: tokens.FONT_FAMILY,
                  border: "0.4px solid black",
                  borderRadius: "6px",
                }}
                value={editorContent}
                onChange={handleEditorChange}
              />
            </Form.Item>
            <Form.Item>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <AButton
                  type="default"
                  size="middle"
                  onClick={() => {
                    form.resetFields();
                      setVisible(false);
                      setSelectedSemester();
                  }}
                  style={{
                    backgroundColor: tokens.COLOR_LIGHTBLACK_L50,
                  }}
                >
                  Cancel
                </AButton>

                <AButton
                  type="primary"
                  htmlType="submit"
                  size="large"
                  style={{
                    color: tokens.BG_LIGHT,
                    backgroundColor: tokens.COLOR_LIGHT_ORANGE,
                    padding: "0 30px",
                  }}
                  // loading={loading}
                >
                  {program.programType == "Semester" ? "Create Semester" : "Create Year"}
                </AButton>
              </div>
            </Form.Item>
          </Form>
        </AModal>

        {
          //model for edit
        }
        <AModal
          style={{
            padding: "50px",
          }}
          open={visibleEdit}
          closable={false}
          footer={null}
          className="css-dev-only-do-not-override-1r3vfrt-last"
        >
          <Form
            size="large"
            form={form}
            name="add/editForm"
            onFinish={(values) => {
              values.description = editorContent;
              handleEdit(values);
            }}
            layout="vertical"
            scrollToFirstError
            title="Add/EditProgram"
            initialValues={
              selectedSemester
                ? {
                    name: semester.name,
                    description: semester.description,
                  }
                : {}
            }
          >
            <ATitle content={"Edit "+program.programType} />

            <Form.Item
              name="name"
              label={
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "19.6px",
                  }}
                >
                  {program.programType == "Semester" ? "Semester Name" : "Year Name"}
                </p>
              }
              rules={[
                {
                  required: true,
                  message: "Please input the Name!",
                },
              ]}
            >
              <AInput placeholder={program.programType == "Semester" ? "Enter Semester Name" : "Enter Year Name"} size="large" />
            </Form.Item>
            <Form.Item
              name="description"
              label={
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "19.6px",
                  }}
                >
                  {program.programType == "Semester" ? "Semester Description" : "Year Description"}
                </p>
              }
              rules={[
                {
                  required: true,
                  message: "Please input the Description!",
                },
              ]}
            >
              <QuillEditor
                className="toolbar"
                style={{
                  fontSize: tokens.FONT_FAMILY,
                  border: "0.4px solid black",
                  borderRadius: "6px",
                }}
                value={editorContent}
                onChange={handleEditorChange}
              />
            </Form.Item>

            <Form.Item>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <AButton
                  type="default"
                  size="middle"
                  onClick={() => {
                    form.resetFields();
                      setVisibleEdit(false);
                      setSelectedSemester();
                  }}
                  style={{
                    backgroundColor: tokens.COLOR_LIGHTBLACK_L50,
                  }}
                >
                  Cancel
                </AButton>

                <AButton
                  type="primary"
                  htmlType="submit"
                  size="large"
                  style={{
                    color: tokens.BG_LIGHT,
                    backgroundColor: tokens.COLOR_LIGHT_ORANGE,
                    padding: "0 30px",
                  }}
                  // loading={loading}
                >
                  {program.programType == "Semester" ? "Update Semester" : "Update Year"}
                </AButton>
              </div>
            </Form.Item>
          </Form>
        </AModal>
      </div>
    );
  } else {
    return <Loader />;
  }
}
export default ViewProgram;
