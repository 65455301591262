import React, { ReactNode } from "react";
import { ConfigProvider } from "antd";
import { tokens } from "../utils/Constants";

const ThemeConfigProvider = ({ children }) => {
  return (
    <ConfigProvider
      theme={{
        // algorithm: theme.darkAlgorithm,
        token: {
          colorPrimary: tokens.COLOR_PRIMARY,
          fontFamily: tokens.FONT_FAMILY,
          colorBgBase: tokens.BG_LIGHT,
          colorFill: tokens.COLOR_PRIMARY_P50,
          colorSuccess: tokens.COLOR_SUCCESS,
          colorError: tokens.COLOR_DANGER,
          colorPrimaryHover: tokens.COLOR_PRIMARY_P100,
          fontSizeHeading1: parseInt(tokens.HEADING_1),
          fontSizeHeading2: parseInt(tokens.HEADING_2),
          fontSizeHeading3: parseInt(tokens.HEADING_3),
          fontSize: parseInt(tokens.PARAGRAPH),
        },
        components: {
          Radio: {
            colorPrimary: tokens.COLOR_PRIMARY,
          },
        },
      }}
    >
      {children}
    </ConfigProvider>
  );
};

export default ThemeConfigProvider;
