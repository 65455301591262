import actions from "./actions";
import { fromJS } from "immutable";
import { combineReducers } from "redux";

const initialState = fromJS({
  evaluationCriterias: [],
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_EVALUATION_CRITERIAS_SUCCESS:
      return state.set("evaluationCriterias", action.payload);

    case actions.SAVE_EVALUATION_CRITERIA_SUCCESS:
      return state.set("evaluationCriterias", action.payload);

    case actions.EDIT_EVALUATION_CRITERIA_SUCCESS:
      return state.set("evaluationCriterias", action.payload);

    case actions.DELETE_EVALUATION_CRITERIA_SUCCESS:
      return state.set("evaluationCriterias", action.payload);

    case actions.FETCH_EVALUATION_BY_TYPE_SUCCESS:
      return state.set("evaluations", action.payload);

    case actions.FETCH_EVALUATION_BY_TYPE_SUCCESS:
      return state.set("selectedCourse", action.courseId);

    case actions.SAVE_EVALUATION_SUCCESS:
      return state.set("evaluations", action.payload);

    case actions.EDIT_EVALUATION_SUCCESS:
      return state.set("evaluations", action.payload);

    case actions.DELETE_EVALUATION_SUCCESS:
      return state.set("evaluations", action.payload);

    case actions.FETCH_EVALUATION_BY_STUDENT_SUCCESS:
      return state.set("evaluationsByStudent", action.payload);

    case actions.SUBMIT_EVALUATION_BY_STUDENT_SUCCESS:
      return state.set("evaluationsByStudent", action.payload);

    case actions.UPDATE_EVALUATION_ANSWER_MARKS_SUCCESS:
      return state.set("evaluations", action.payload);

    case actions.UPDATE_EVALUATION_BY_STUDENT_SUCCESS:
      return state.set("evaluationsByStudent", action.payload);
    default:
      return state;
  }
};

export default reducer;
