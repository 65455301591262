import { Col, Form, Row, Select, Space } from "antd";
import ATitle from "../Title";
import AInput from "../Input";
import AButton from "../Button/index";
import { UploadOutlined } from "@ant-design/icons";
import { tokens } from "../../utils/Constants";
import ASelect from "../Select/index";
import { useState, useEffect } from "react";
import QuillEditor from "../Editor/index";
import React from "react";
import { useNavigate } from "react-router";
import AText from "../Text";
import { validateEmail, validateContactNumber } from "../../utils/Helper";
import Upload from "antd/es/upload/Upload";
import Uploads from "../Uploads";
import { DeleteIcon } from "../../assets/icons/DeleteIcon";
import { EditIcon } from "../../assets/icons/EditIcon";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../pages/Program/store/actions";
import groupActions from "../../pages/Students/store/actions";
import Loader from "../Loader";

const AddEditStudent = ({ data, handleSave, isStudent = true, loading = false }) => {
  const [mediaUrl, setMediaUrl] = useState("");
  const [mediaId, setMediaId] = useState();
  const [coursePhotoUrl, setCoursePhotoUrl] = useState(null);

  const [isHovered, setIsHovered] = useState(false);

  const [editorContent, setEditorContent] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedTempCountry, setSelectedTempCountry] = useState("");

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const handleEditorChange = (content) => {};

  let programs = useSelector(({ programsReducer }) =>
    programsReducer.get("programs")
  );

  useEffect(() => {
    new Promise((resolve, reject) => {
      dispatch(actions.fetchPrograms(resolve, reject));
    }).finally(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  //for grpup
  let groups = useSelector(({ studentsReducer }) =>
    studentsReducer.get("groups")
  );

  useEffect(() => {
    new Promise((resolve, reject) => {
      dispatch(groupActions.fetchGroups(resolve, reject));
    }).finally(() => {});
  }, [dispatch]);

  if (!programs || !groups) {
    return <Loader />;
  }
  return (
    <div
      style={{
        padding: "1%",
        backgroundColor: tokens.BG_LIGHT,
        borderRadius: "6px",
      }}
    >
      {/* {content ? <ATitle content={content} style={{ fontSize: "18px" }} /> : ""} */}
      <Form
        size="large"
        form={form}
        name="add/editForm"
        onFinish={(values) => {
          values.description = editorContent;
          values.image = mediaUrl || coursePhotoUrl;
          handleSave(values);
        }}
        layout="vertical"
        scrollToFirstError
        title="Add/EditStudent"
        initialValues={
          data
            ? isStudent
              ? {
                  first_name: data.first_name,
                  middle_name: data.middle_name,
                  last_name: data.last_name,
                  contactNumber: data.contactNumber,
                  email: data.email,
                  gender: data.gender,
                  address1: data.address1,
                  city: data.city,
                  state: data.state,
                  qualification: data.qualification,
                  father_first_name: data.father_first_name,
                  father_middle_name: data.father_middle_name,
                  father_last_name: data.father_last_name,
                  mother_first_name: data.mother_first_name,
                  mother_middle_name: data.mother_middle_name,
                  mother_last_name: data.mother_last_name,
                  program: data.program.id,
                  group: data.group.id,
                  father_contact_number: data.father_contact_number,
                  mother_contact_number: data.mother_contact_number,
                  emergency_contact_number: data.emergency_contact_number,
                }
              : {
                  first_name: data.first_name,
                  middle_name: data.middle_name,
                  last_name: data.last_name,
                  contactNumber: data.contactNumber,
                  email: data.email,
                  gender: data.gender,
                  address1: data.address1,
                  city: data.city,
                  state: data.state,
                  qualification: data.qualification,
                }
            : ""
        }
      >
        <Row gutter={20}>
          <Col span={8}>
            <Form.Item
              name="photo"
              label={
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "19.6px",
                  }}
                >
                  Upload Profile Photo
                </p>
              }
              // rules={[
              //   {
              //     required: true,
              //     message: "Please select a photo!",
              //   },
              // ]}
            >
              <Uploads
                setMediaUrl={setMediaUrl}
                // imagesUrl={profilePhotoUrl}
                setMediaId={setMediaId}
                id="uploadButton"
              />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={8}>
            <Form.Item
              name="first_name"
              label={
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "19.6px",
                  }}
                >
                  First Name
                </p>
              }
              rules={[
                {
                  required: true,
                  message: "Please input the First Name!",
                },
              ]}
            >
              <AInput
                placeholder={"Enter First Name"}
                size="large"
                // style={{ backgroundColor: tokens.COLOR_NEUTRAL_N700 }}
              />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="middle_name"
              label={
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "19.6px",
                  }}
                >
                  Middle Name
                </p>
              }
            >
              <AInput placeholder={"Enter Middle Name"} size="large" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="last_name"
              label={
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "19.6px",
                  }}
                >
                  Last Name
                </p>
              }
              rules={[
                {
                  required: true,
                  message: "Please input the Last Name!",
                },
              ]}
            >
              <AInput placeholder={"Enter Last Name"} size="large" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={8}>
            <Form.Item
              name="contactNumber"
              label={
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "19.6px",
                  }}
                >
                  Contact Number
                </p>
              }
              rules={[
                {
                  required: true,
                  message: "Please input the Contact Number!",
                  validateTrigger: "onFinish",
                },
                {
                  validator: validateContactNumber,
                },
              ]}
            >
              <AInput placeholder={"Enter Contact Number"} size="large" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="email"
              label={
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "19.6px",
                  }}
                >
                  Email
                </p>
              }
              rules={[
                {
                  required: true,
                  message: "Please input the Email!",
                  validateTrigger: "onFinish",
                },
                {
                  validator: validateEmail,
                },
              ]}
            >
              <AInput placeholder={"Enter Email"} size="large" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="gender"
              label={
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "19.6px",
                  }}
                >
                  Gender
                </p>
              }
              rules={[
                {
                  required: true,
                  message: "Please select a Gender!",
                },
              ]}
            >
              <ASelect customized={false} defaultValue="Choose Gender">
                <Select.Option value="Male">Male</Select.Option>
                <Select.Option value="Female">Female</Select.Option>
                <Select.Option value="others">Others</Select.Option>
              </ASelect>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={8}>
            <Form.Item
              name="address1"
              label={
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "19.6px",
                  }}
                >
                  Address 1
                </p>
              }
              rules={[
                {
                  required: true,
                  message: "Please input the Address1!",
                },
              ]}
            >
              <AInput placeholder={"Enter Address1"} size="large" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="city"
              label={
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "19.6px",
                  }}
                >
                  City
                </p>
              }
              rules={[
                {
                  required: true,
                  message: "Please input the City!",
                },
              ]}
            >
              <AInput placeholder={"Enter City"} size="large" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="state"
              label={
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "19.6px",
                  }}
                >
                  State
                </p>
              }
              rules={[
                {
                  required: true,
                  message: "Please select the State!",
                },
              ]}
            >
              <ASelect customized={false} defaultValue="Choose State">
                <Select.Option value="Koshi">Koshi</Select.Option>
                <Select.Option value="Madhesh">Madhesh</Select.Option>
                <Select.Option value="Bagmati">Bagmati</Select.Option>
                <Select.Option value="Gandaki">Gandaki</Select.Option>
                <Select.Option value="Lumbini">Lumbini</Select.Option>
                <Select.Option value="Karnali">Karnali</Select.Option>
                <Select.Option value="Sudurpashchim">
                  Sudurpashchim
                </Select.Option>
              </ASelect>
            </Form.Item>
          </Col>
        </Row>

        {isStudent ? (
          <div>
            <Row gutter={20}>
              <Col span={8}>
                <Form.Item
                  name="father_first_name"
                  label={
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "19.6px",
                      }}
                    >
                      Father First Name
                    </p>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Please input the Father First Name!",
                    },
                  ]}
                >
                  <AInput
                    placeholder={"Enter Father First Name"}
                    size="large"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="father_middle_name"
                  label={
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "19.6px",
                      }}
                    >
                      Father Middle Name
                    </p>
                  }
                >
                  <AInput
                    placeholder={"Enter Father Middle Name"}
                    size="large"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="father_last_name"
                  label={
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "19.6px",
                      }}
                    >
                      Father Last Name
                    </p>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Please input the Father Last Name!",
                    },
                  ]}
                >
                  <AInput placeholder={"Enter Father Last Name"} size="large" />
                </Form.Item>
              </Col>
            </Row>

            <Row gutter={20}>
              <Col span={8}>
                <Form.Item
                  name="mother_first_name"
                  label={
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "19.6px",
                      }}
                    >
                      Mother First Name
                    </p>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Please input the Mother First Name!",
                    },
                  ]}
                >
                  <AInput
                    placeholder={"Enter Mother First Name"}
                    size="large"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="mother_middle_name"
                  label={
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "19.6px",
                      }}
                    >
                      Mother Middle Name
                    </p>
                  }
                >
                  <AInput
                    placeholder={"Enter Mother Middle Name"}
                    size="large"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="mother_last_name"
                  label={
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "19.6px",
                      }}
                    >
                      Mother Last Name
                    </p>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Please input the Mother Last Name!",
                    },
                  ]}
                >
                  <AInput placeholder={"Enter Mother Last Name"} size="large" />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col span={8}>
                <Form.Item
                  name="father_contact_number"
                  label={
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "19.6px",
                      }}
                    >
                      Father Contact Number
                    </p>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Please input the Father Contact Number!",
                      validateTrigger: "onFinish",
                    },
                    {
                      validator: validateContactNumber,
                    },
                  ]}
                >
                  <AInput
                    placeholder={"Enter Father Contact Number"}
                    size="large"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="mother_contact_number"
                  label={
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "19.6px",
                      }}
                    >
                      Mother Contact Number
                    </p>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Please input the Mother Contact Number!",
                      validateTrigger: "onFinish",
                    },
                    {
                      validator: validateContactNumber,
                    },
                  ]}
                >
                  <AInput
                    placeholder={"Enter Mother Contact Number"}
                    size="large"
                  />
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="emergency_contact_number"
                  label={
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "19.6px",
                      }}
                    >
                      Emergency Contact Number
                    </p>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Please input the Emergency Contact Number! ",
                      validateTrigger: "onFinish",
                    },
                    {
                      validator: validateContactNumber,
                    },
                  ]}
                >
                  <AInput
                    placeholder={"Enter Emergency Contact Number"}
                    size="large"
                  />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={20}>
              <Col span={8}>
                <Form.Item
                  name="program"
                  label={
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "19.6px",
                      }}
                    >
                      Program
                    </p>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Please input the Program!",
                    },
                  ]}
                >
                  {/* <AInput placeholder={"Enter Program"} size="large" /> */}
                  <ASelect customized={false} defaultValue="Choose Program">
                    {programs.map((element) => (
                      <Select.Option value={element.id}>
                        {element.name}
                      </Select.Option>
                    ))}
                  </ASelect>
                </Form.Item>
              </Col>
              <Col span={8}>
                <Form.Item
                  name="group"
                  label={
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "19.6px",
                      }}
                    >
                      Stuednt Batch (Group)
                    </p>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Please input the Group!",
                    },
                  ]}
                >
                  {/* <AInput placeholder={"Enter Group"} size="large" /> */}
                  <ASelect customized={false} defaultValue="Choose Group">
                    {groups.map((element) => (
                      <Select.Option value={element.id}>
                        {element.name}
                      </Select.Option>
                    ))}
                  </ASelect>
                </Form.Item>
              </Col>
            </Row>
          </div>
        ) : (
          <div></div>
        )}

        {isStudent ? (
          " "
        ) : (
          <Row>
            <Col span={8}>
              <Form.Item
                name="qualification"
                label={
                  <p
                    style={{
                      fontSize: "14px",
                      fontWeight: "600",
                      lineHeight: "19.6px",
                    }}
                  >
                    Qualification
                  </p>
                }
                rules={[
                  {
                    required: true,
                    message: "Please input the qualification!",
                  },
                ]}
              >
                <AInput placeholder={"Enter Qualification"} size="large" />
              </Form.Item>
            </Col>
          </Row>
        )}

        {/* <Row style={{ display: "flex", justifyContent: "flex-end" }}>
          <Form.Item>
            <AButton
              type="primary"
              htmlType="submit"
              size="large"
              style={{
                color: tokens.BG_LIGHT,
                backgroundColor: tokens.COLOR_LIGHT_ORANGE,
                padding: "0 30px",
              }}
            >
              {isStudent ? "Create Student" : "Create Instructor"}
            </AButton>
          </Form.Item>
        </Row> */}

        <Form.Item>
          {data ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <AButton
                type="default"
                size="middle"
                style={{
                  color: tokens.COLOR_DANGER_D300,
                  borderColor: tokens.COLOR_DANGER_D300,
                  margin: "10px",
                }}
                icon={<DeleteIcon />}
                // onClick={() => handleDelete(college.id)}
              >
                Delete
              </AButton>
              <AButton
                type="default"
                htmlType="submit"
                size="middle"
                icon={<EditIcon />}
                style={{
                  color: tokens.COLOR_SUCCESS,
                  borderColor: tokens.COLOR_SUCCESS,
                }}
              >
                {isStudent ? "Update Student" : "Update Instructor"}
              </AButton>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <AButton
                type="default"
                size="middle"
                onClick={() => navigate(isStudent ? "/student" : "/instructor")}
                style={{
                  backgroundColor: tokens.COLOR_LIGHTBLACK_L50,
                }}
              >
                Cancel
              </AButton>
              <AButton
                type="primary"
                htmlType="submit"
                size="large"
                style={{
                  color: tokens.BG_LIGHT,
                  backgroundColor: tokens.COLOR_LIGHT_ORANGE,
                  padding: "0 30px",
                }}
                loading={loading}
              >
                {isStudent ? "Create Student" : "Create Instructor"}
              </AButton>
            </div>
          )}
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddEditStudent;
