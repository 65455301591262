import React, { useRef, useState } from "react";
import { Form, Row, Col, message } from "antd";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import AInput from "../../../Components/Input";
import AButton from "../../../Components/Button/index";
import ATitle from "../../../Components/Title";
import logo from "../../../assets/images/Logo.png";

const ResetPassword = () => {
  const [loading, setLoading] = useState(false);
  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const navigate = useNavigate();
  const { token } = useParams();
  const url = `${process.env.REACT_APP_API_BASE_URL}/resetpassword/${token}`;
  const disabled = !(newPassword === confirmPassword);
  const passwordPattern = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{7,}$/;

  async function handleSubmit(values) {
    setLoading(true);
    axios
      .post(url, { password: values.new_password })
      .then((response) => {
        setLoading(false);
        message.success("Password reset successfully");
        navigate("/login");
      })
      .catch((error) => {
        setLoading(false);
        message.error("Linked expired, please try again");
        navigate("/login");
      })
      .finally(() => {
        // Code to execute regardless of success or failure
      });
  }

  const isPasswordMatch = () => {
    return newPassword === confirmPassword;
  };

  const isPasswordValid = () => {
    return passwordPattern.test(newPassword);
  };

  return (
    <Row
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
      }}
    >
      <Col
        md={10}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          border: "2px solid black",
          borderRadius: "15px",
        }}
      >
        <img src={logo} alt="logo" width={170} height={170} />
        <Form
          name="basic"
          autoComplete="off"
          layout="vertical"
          onFinish={handleSubmit}
        >
          <ATitle
            content="Send Reset Link"
            level={5}
            style={{ fontSize: "32px", fontWeight: 600 }}
          />
          <Form.Item
            label="New Password"
            name="new_password"
            rules={[{ required: true, message: "Please enter new password!" },
            {
              pattern: passwordPattern,
              message:
                "Password requires: 1 uppercase, 1 lowercase, 1 digit, 1 special character, minimum length 7.",
            },]}
          >
            <AInput
              bordered={true}
              placeholder="New Password"
              size="large"
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </Form.Item>
          <Form.Item
            label="Confirm Password"
            name="confirm_password"
            rules={[
              { required: true, message: "Please enter confirm password!" },
            ]}
          >
            <AInput
              bordered={true}
              placeholder="Confirm Password"
              size="large"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
            />
          </Form.Item>
          {newPassword && confirmPassword && !isPasswordMatch() && (
            <p style={{ color: "red" }}>Passwords does not matched!</p>
          )}
          <div
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "15px",
            }}
          >
            <Form.Item>
              <AButton
                fullwidth={true}
                type="primary"
                htmlType="submit"
                size="large"
                loading={loading}
                disabled={disabled || !isPasswordValid()}
              >
                Reset Password
              </AButton>
            </Form.Item>
            <Form.Item>
              <Link to="/login">
                <AButton
                  fullwidth={true}
                  type="primary"
                  htmlType="cancel"
                  size="large"
                >
                  Cancel
                </AButton>
              </Link>
            </Form.Item>
          </div>
        </Form>
      </Col>
    </Row>
  );
};

export default ResetPassword;
