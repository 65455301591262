import BatchesSaga from "./sagas";
import BatchesReducer from "./reducers";
import batchesActions from "./actions";

export const batchesReducer = {
  batchesReducer: BatchesReducer,
};

export const batchesSaga = [BatchesSaga()];

export { batchesActions };
