import { useEffect, useState } from 'react';
import Sidebar from './Sidebar';
import { Layout, Space, Breadcrumb, Avatar, Badge, theme, message } from 'antd';
import { UserOutlined, DownOutlined } from '@ant-design/icons';
// import actions from "pages/login/store/actions";
import { useDispatch } from 'react-redux';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import './index.css';
// import { NotificationIcon } from "assets/icons/NotificationIcon";
import React from 'react';
import { Dropdown } from 'antd';
import ATitle from '../../Components/Title';
import AButton from '../../Components/Button';
import { tokens } from '../../utils/Constants';
import { getFormattedFullName, getFormattedRoleName, getOwnCollegeDetails } from '../../utils/Helper';
import { hydrate } from 'react-dom';

const MainLayout = ({ children }) => {
  const [isSidebarOpen, setSidebarOpen] = useState(true);
  const [isSA, setIsSA] = useState(false);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  // const { colorBgContainer } = theme.useToken();
  // const dispatch = useDispatch();
  const navigate = useNavigate();
  const formattedFullName = getFormattedFullName();
  const formattedRoleName = getFormattedRoleName();
  const [collegeName, setCollegeName] = useState('');

  if (!collegeName) {
    getOwnCollegeDetails()
      .then((collegeData) => {
        setCollegeName(collegeData.name);
      })
      .catch((error) => {
        console.error(error);
      });
  }

  const items = [
    {
      label: 'Profile',
      key: '0',
      onClick: () => {
        navigate('profile');
      },
    },
    {
      label: 'Settings',
      key: '1',
      onClick: () => {
        navigate('settings');
      },
    },
    {
      type: 'divider',
    },
    {
      label: 'Logout',
      key: '2',
      onClick: () => {
        new Promise((resolve, reject) => {
          navigate("/login");
          localStorage.removeItem("token");
          localStorage.removeItem("permission");
          localStorage.removeItem("role");
          localStorage.removeItem("selectedCourse");
          localStorage.removeItem("superAdminToken");
        });
      },
    },
  ];

  const location = useLocation();
  const pathSnippets = location.pathname.split('/').filter((i) => i);
  const lastPart = pathSnippets[pathSnippets.length - 1];
  if (!isNaN(Number(lastPart))) {
    pathSnippets.pop();
  }
  // Check if URL starts with "/evaluation"

  const firstSlice = location.pathname.slice(1).split('/')[0];

  const isEvaluationPage = firstSlice === 'evaluation';

  const getEvaluationName = () => {
    return decodeURIComponent(location.pathname.split('/').filter(Boolean).pop());
  };
  const getEvaluationNameWithID = () => {
    const combinedString = `${decodeURIComponent(
      location.pathname.split('/').filter(Boolean).slice(-2, -1)[0]
    )}/${decodeURIComponent(location.pathname.split('/').filter(Boolean).slice(-1)[0])}`;
    return combinedString;
  };

  const breadcrumbNameMap = {
    '/evaluation-test-requests': 'Evaluation Test Requests',
    '/enrollment': 'Enrollment',
    '/course': 'Course',
    '/course/add': 'Add Course',
    '/course/edit': 'Edit Course',
    '/course/details': 'Course Details',
    '/semester': 'Class',
    '/semester/details': 'Class Details',
    '/instructor': 'Instructor',
    '/instructor/add': 'Add Instructor',
    '/instructor/edit': 'Edit Instructor',
    '/instructor/details': 'Instructor Details',
    '/branch': 'Branch',
    '/branch/add': 'Add Branch',
    '/branch/edit': 'Edit Branch',
    '/batch': 'Batch',
    '/batch/add': 'Add Batch',
    '/batch/edit': 'Edit Batch',
    '/batch/details': 'Batch Details',
    '/timeSlot': 'Time Slot',
    '/learning-materials': 'Learning Materials',
    '/learning-materials/upload': 'Upload',
    '/student/edit': 'Edit Student',
    '/student/details': 'View Student',
    '/student': 'Student',
    '/student/add': 'Add Student',
    '/instructor': 'Instructor',
    '/instructor/add': 'Add Instructor',
    '/instructor/edit': 'Edit Instructor',
    '/instructor/details': 'View Instructor',
    '/college': 'College',
    '/college/add': 'Add College',
    '/college/edit': 'Edit College',
    '/college/view': 'View College',
    '/superadmin': 'Super Admin',
    '/superadmin/add': 'Add Super Admin',
    '/program': 'Program',
    '/program/add': 'Add Program',
    '/program/edit': 'Edit Program',
    '/program/details': 'View Program',
    '/group': 'Student Group',
    '/role': 'Roles',
    '/role/view': 'Assign Permissions',
    '/role/add': 'Add Role',
    '/role/edit': 'Edit Role',
    '/notice': 'Notice',
    '/notice/add': 'Add Notice',
    '/notice/edit': 'Edit Notice',
    '/notice/details': 'Notice Details',
    '/evaluation': 'Evaluation',
    '/assignInstructor': 'Assign Instructor',
    '/assignInstructor/add': 'Assign Course to Instructor',
    '/assignInstructor/edit': 'Change Assign Course',
    '/evaluationCriteria': 'Evaluation Criteria',
    '/evaluationCriteria/add': 'Add Evaluation Criteria',
    '/evaluationCriteria/edit': 'Edit Evaluation Criteria',
    '/timeSlot': 'Time Slot',
    '/courseTimeSlot': 'Assign Time Slot for Courses',
    '/exam': 'Examination',
    '/exam/add': 'Add Exam',
    '/exam/view': 'View Exam',
    '/exam/schedule': 'Examination Schedule',
    '/exam/mark': 'Examination Marks',
    "/manegeBatch": "Manage Batch",
    "/groupDetails": "Batch Details",
    "/manageBatchrelation": "Manage Batch Relation",
  };

  const extraBreadcrumbItems = isEvaluationPage
    ? [
      {
        key: getEvaluationName().toLowerCase(),
        title: <Link to={`evaluation/${getEvaluationNameWithID()}`}>{getEvaluationName()}</Link>,
      },
    ]
    : pathSnippets.map((_, index) => {
      const url = `/${pathSnippets.slice(0, index + 1).join('/')}`;
      return {
        key: url,
        title: <Link to={url}>{breadcrumbNameMap[url]}</Link>,
      };
    });

  const getBreadcrumbTitle = (breadcrumbItem) => {
    // Check if the URL starts with "/evaluation"

    if (isEvaluationPage) {
      return getEvaluationName();
    }

    // Extract the URL path
    if (breadcrumbItem) {
      const path = breadcrumbItem.key;

      // Split the path into parts
      const parts = path.split('/');

      // Check if the last part is a number (id)
      if (!isNaN(parts[parts.length - 1])) {
        // If it is, remove the last part
        parts.pop();
      }

      // Construct the new path without the id
      const newPath = parts.join('/');

      // Get the title from the breadcrumbNameMap using the new path
      const title = breadcrumbNameMap[newPath];

      // Return the title
      return title;
    }
    return;
  };

  // const breadcrumbItems = [
  //   {
  //     title: <Link to="/">Home</Link>,
  //     key: "home",
  //   },
  // ].concat(extraBreadcrumbItems);a
  const breadcrumbItems = extraBreadcrumbItems;
  const titleFromPath = breadcrumbNameMap[`/${pathSnippets.join('/')}`] || '';
  const contentCSS = {
    transition: 'ease-in-out',
    transitionDuration: '0.2s',
    minHeight: '100vh',
  };

  useEffect(() => {
    // check for login as user or not
    const superAdminToken = localStorage.getItem('superAdminToken');
    if (superAdminToken == null) {
      setIsSA(false);
    } else {
      setIsSA(true);
    }
  }, []);

  return (
    <Layout hasSider={true}>
      <Sidebar open={isSidebarOpen} onClose={setSidebarOpen} />
      <Layout.Content
        style={{
          transition: 'ease-in-out',
          transitionDuration: '0.2s',
          marginLeft: '275px',
          minHeight: '100vh',
        }}
      >
        <Layout.Header id='header'>
          <Space
            style={{
              justifyContent: 'space-between',
              width: '100%',
              alignContent: 'center',
            }}
          >
            <AButton
              style={
                isSA
                  ? {
                    display: 'block',
                    marginLeft: '10px',
                    color: tokens.COLOR_PRIMARY,
                  }
                  : { display: 'none' }
              }
              onClick={() => {
                const superAdminToken = localStorage.getItem('superAdminToken');
                localStorage.removeItem('token');
                localStorage.setItem('token', superAdminToken);
                localStorage.removeItem('superAdminToken');
                navigate('/dashboard');
                window.location.reload();
                message.success('Log in as Super Admin successfully');
              }}
            >
              Back to SuperAdmin
            </AButton>
            <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
              <Badge count={2}>
                <Avatar shape='circle' size={32} icon={<UserOutlined />} />
              </Badge>
              <Dropdown menu={{ items }}>
                <a href=' ' onClick={(e) => e.preventDefault()}>
                  <Space>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-end',
                        lineHeight: '0px',
                        gap: '5px',
                      }}
                    >
                      <p
                        style={{
                          textTransform: 'capitalize',
                          color: tokens.COLOR_USERNAME,
                          fontSize: '16px',
                          lineHeight: '0px',
                          marginBottom: '5px',
                          marginLeft: '16px',
                        }}
                      >
                        {formattedFullName.includes('undefined') ? collegeName : formattedFullName}
                      </p>
                      <p
                        style={{
                          textTransform: 'capitalize',
                          color: tokens.COLOR_NEUTRAL_N80,
                          fontSize: '13px',
                          lineHeight: '0px',
                        }}
                      >
                        {formattedRoleName}
                      </p>
                    </div>
                    <Avatar shape='circle' size={32} icon={<UserOutlined />} />
                    <DownOutlined />
                  </Space>
                </a>
              </Dropdown>
            </div>
          </Space>
        </Layout.Header>
        <div
          style={{
            paddingLeft: '16px',
          }}
        >
          <ATitle content={getBreadcrumbTitle(breadcrumbItems[breadcrumbItems.length - 1])} />
        </div>

        <div
          style={{
            paddingLeft: '16px',
          }}
        >
          <Breadcrumb separator='>' items={breadcrumbItems} />
        </div>
        <Layout.Content
          style={{
            margin: '4px 16px',
            padding: '16px',
            borderRadius: '6px',
            background: tokens.BG_LIGHT,
          }}
        >
          {children}
        </Layout.Content>
      </Layout.Content>
    </Layout>
  );
};

export default MainLayout;
