import { Col, Form, Row, Select, Space, Divider } from "antd";
import ATitle from "../Title";
import AInput from "../Input";
import AButton from "../Button/index";
import { UploadOutlined } from "@ant-design/icons";
import { stateName, tokens } from "../../utils/Constants";
import ASelect from "../Select/index";
import { useState, useEffect } from "react";
import QuillEditor from "../Editor/index";
import React from "react";
import { useNavigate } from "react-router";
import AText from "../Text";
import { DeleteIcon } from "../../assets/icons/DeleteIcon";
import { EditIcon } from "../../assets/icons/EditIcon";
import { validateContactNumber, validateEmail } from "../../utils/Helper";
const AddEditCollege = ({
  handleSave,
  college,
  handleDelete,
  saving = false,
  deleting = false,
  isStudent = true,
  loading
}) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [mediaUrl, setMediaUrl] = useState("");
  const [coursePhotoUrl, setCoursePhotoUrl] = useState(null);

  const [isHovered, setIsHovered] = useState(false);

  const [editorContent, setEditorContent] = useState(
    college ? college.description : ""
  );
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedTempCountry, setSelectedTempCountry] = useState("");

  const handleEditorChange = (content) => {
    setEditorContent(content);
  };

  const handleCountryChange = (value) => {
    setSelectedCountry(value);
  };
  const handleTempCountryChange = (value) => {
    setSelectedTempCountry(value);
  };

  return (
    <div style={{ padding: "1%" }}>
      {/* <ATitle
        content={college ? "Edit College" : "Add College"}
        style={{ fontSize: "18px" }}
      /> */}
      <Form
        size="large"
        form={form}
        name="add/editForm"
        onFinish={(values) => {
          values.description = editorContent;
          handleSave(values);
        }}
        layout="vertical"
        scrollToFirstError
        title="Add/EditCollege"
        initialValues={
          college
            ? {
                name: college.name,
                phone: college.contactNo,
                descriptions: college.description,
                street1: college.street1,
                city: college.city,
                state: college.state || "Koshi",
              }
            : {}
        }
      >
        <Row gutter={20}>
          <Col span={8}>
            <Form.Item
              name="name"
              label={
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "19.6px",
                  }}
                >
                  College Name
                </p>
              }
              rules={[
                {
                  required: true,
                  message: "Please input the Name!",
                },
              ]}
            >
              <AInput placeholder={"Enter Name"} size="large" />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="phone"
              label={
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "19.6px",
                  }}
                >
                  Contact Number
                </p>
              }
              rules={[
                {
                  required: true,
                  message: "Please input the contact number!",
                  validateTrigger: "onFinish"
                },
                {
                  validator: validateContactNumber,
                }
              ]}
            >
              <AInput placeholder={"Enter Contatact Number"} size="large" />
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Col span={24}>
            <Form.Item
              name="descriptions"
              label={
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "19.6px",
                  }}
                >
                  College Description
                </p>
              }
              rules={[
                {
                  required: true,
                  message: "Please input the Description!",
                },
              ]}
            >
              <QuillEditor
                className="toolbar"
                style={{
                  fontSize: tokens.FONT_FAMILY,
                  marginLeft: "-10px",
                }}
                value={editorContent}
                onChange={handleEditorChange}
              />
            </Form.Item>
          </Col>
        </Row>
        <p
          style={{
            fontSize: "16px",
            fontWeight: "600",
            lineHeight: "22.4px",
          }}
        >
          College Address
        </p>
        <Row gutter={20}>
          <Col span={8}>
            <Form.Item
              name="street1"
              label={
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "19.6px",
                  }}
                >
                  Address 1
                </p>
              }
              rules={[
                {
                  required: true,
                  message: "Please input the Address 1",
                },
              ]}
            >
              <AInput placeholder={"Enter Address 1"} size="large" />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="city"
              label={
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "19.6px",
                  }}
                >
                  City
                </p>
              }
              rules={[
                {
                  required: true,
                  message: "Please input the City",
                },
              ]}
            >
              <AInput placeholder={"Enter City"} size="large" />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="state"
              label={
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "19.6px",
                  }}
                >
                  State
                </p>
              }
              rules={[
                {
                  required: true,
                  message: "Please select the State!",
                },
              ]}
            >
              <ASelect customized={false} defaultValue="Choose State">
                {stateName.map((element) => (
                  <Select.Option value={element.value}>
                    {element.value}
                  </Select.Option>
                ))}
              </ASelect>
            </Form.Item>
          </Col>
        </Row>
        {!college ? (
          <div>
            <p
              style={{
                fontSize: "16px",
                fontWeight: "600",
                lineHeight: "22.4px",
              }}
            >
              College Admin
            </p>
            <Row gutter={20}>
              <Col span={8}>
                <Form.Item
                  name="email"
                  label={
                    <p
                      style={{
                        fontSize: "14px",
                        fontWeight: "600",
                        lineHeight: "19.6px",
                      }}
                    >
                      Email
                    </p>
                  }
                  rules={[
                    {
                      required: true,
                      message: "Please input the Email!",
                      validateTrigger: "onFinish",
                    },
                    {
                      validator: validateEmail,
                    },
                  ]}
                >
                  <AInput placeholder={"Enter Email"} size="large" />
                </Form.Item>
              </Col>
            </Row>
          </div>
        ) : (
          ""
        )}
        {/*buttom*/}
        <Form.Item>
          {college ? (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <AButton
                type="default"
                size="middle"
                style={{
                  color: tokens.COLOR_DANGER,
                  borderColor: tokens.COLOR_DANGER,
                  margin: "10px",
                }}
                icon={<DeleteIcon />}
                onClick={() => handleDelete(college.id)}
              >
                Delete
              </AButton>
              <AButton
                type="default"
                htmlType="submit"
                size="middle"
                icon={<EditIcon />}
                style={{
                  color: tokens.COLOR_SUCCESS,
                  borderColor: tokens.COLOR_SUCCESS,
                }}
              >
                Update
              </AButton>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <AButton
                type="default"
                size="middle"
                onClick={() => navigate("/college")}
                style={{
                  backgroundColor: tokens.COLOR_LIGHTBLACK_L50,
                }}
              >
                Cancel
              </AButton>
              <AButton
                type="primary"
                htmlType="submit"
                size="large"
                style={{
                  color: tokens.BG_LIGHT,
                  backgroundColor: tokens.COLOR_LIGHT_ORANGE,
                  padding: "0 30px",
                }}
                loading={loading}
              >
                Create College
              </AButton>
            </div>
          )}
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddEditCollege;
