import React, { useState, useEffect } from "react";
import { Form, Space, Input, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { AText, AButton } from "../../Components";
import actions from "../../pages/TimeSlot/store/actions";
import { PlusOutlined, DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import { tokens } from "../../utils/Constants";
import APopConfirm from "../../Components/PopConfirm";
import { hasPermission } from "../../utils/Helper";

const AddEditTimeSlot = ({ initialValue = null, disabled = false }) => {
  const [form] = useForm();
  const dispatch = useDispatch();
  const [isDisabled, setIsDisabled] = useState(disabled);

  const handleDelete = () => {
    initialValue &&
      new Promise((resolve, reject) => {
        dispatch(actions.deleteTimeSlot(initialValue.id, resolve, reject));
      })
        .then(() => {
          message.success("Deleted Timeslot succesfully");
        })
        .catch((error) => {
          message.error("Error occured while deleting TimeSlot");
        });
  };

  return (
    <Form
      form={form}
      title="Time Slots"
      onFinish={(values) => {
        if (initialValue) {
          new Promise((resolve, reject) => {
            values.id = initialValue.id;
            dispatch(actions.updateTimeSlot(values.id, values, resolve, reject));
          })
            .then(() => {
              message.success("Time slot updated successfully");
              setIsDisabled(true);
            })
            .catch(() => {message.error("Update Time Slot Failed "); }).
            finally(() => setIsDisabled(true));
        } else {
          new Promise((resolve, reject) => {
            dispatch(actions.createTimeSlot(values, resolve, reject));
          })
            .then((data) => {
              message.success("Time slot added successfully");
              setIsDisabled(true);
            })
            .catch(() => {message.error("Create Time Slot Failed "); }).
            finally(() => setIsDisabled(true));
        }
      }}
      initialValues={{
        start_time: initialValue && initialValue.start_time,
        end_time: initialValue && initialValue.end_time,
      }}
    >
      <Space
        style={{ width: "100%", justifyContent: "space-between" }}
        align="baseline"
        wrap={true}
      >
        <Space align="baseline">
          <Space>
            <Form.Item
              name="start_time"
              rules={[
                {
                  required: true,
                  message: "Please enter starting time",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (
                      !value ||
                      !getFieldValue("end_time") ||
                      getFieldValue("end_time") > value
                    ) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      "Start time cannot be later than End time!"
                    );
                  },
                }),
              ]}
            >
              <Input type="time" disabled={isDisabled} />
            </Form.Item>
          </Space>
          <AText strong content="TO" />
          <Space>
            <Form.Item
              name="end_time"
              rules={[
                {
                  required: true,
                  message: "Please enter ending time",
                },
                ({ getFieldValue }) => ({
                  validator(_, value) {
                    if (!value || getFieldValue("start_time") < value) {
                      return Promise.resolve();
                    }
                    return Promise.reject(
                      "End time cannot be before the Start time!"
                    );
                  },
                }),
              ]}
            >
              <Input type="time" disabled={isDisabled} />
            </Form.Item>
          </Space>
        </Space>
        {isDisabled ? (
          <Space>
            {hasPermission("edit_time_slot") && (
              <AButton
                type="default"
                style={{
                  borderColor: tokens.COLOR_PRIMARY,
                  color: tokens.COLOR_PRIMARY,
                }}
                onClick={() => {
                  setIsDisabled(false);
                }}
                icon={<EditOutlined />}
              >
                Edit
              </AButton>
            )}
            {hasPermission("delete_time_slot") && (
              <AButton
                type="default"
                style={{
                  borderColor: tokens.COLOR_DANGER_D300,
                  color: tokens.COLOR_DANGER_D300,
                }}
                icon={<DeleteOutlined />}
                onClick={handleDelete}
              >
                Delete
              </AButton>
            )}
          </Space>
        ) : initialValue ? (
          <Form.Item>
            <APopConfirm
              title="Update the Time Slot"
              description="Are you sure to update this time slot?"
              onConfirm={() => {
                form.submit();
              }}
              onCancel={() => { }}
              okText="Yes"
              cancelText="No"
            >
              <AButton
                type="default"
                style={{
                  borderColor: tokens.COLOR_PRIMARY,
                  color: tokens.COLOR_PRIMARY,
                }}
                icon={<EditOutlined />}
              >
                Update
              </AButton>
            </APopConfirm>
          </Form.Item>
        ) : (
          <Form.Item>
            {hasPermission("add_time_slot") && (
              <AButton
                type="default"
                style={{
                  borderColor: tokens.COLOR_PRIMARY,
                  color: tokens.COLOR_PRIMARY,
                }}
                icon={<PlusOutlined />}
                htmlType="submit"
              >
                Add
              </AButton>
            )}
          </Form.Item>
        )}
      </Space>
    </Form>
  );
};

export default AddEditTimeSlot;
