import { Avatar, Col, Form, Row, Select, Space } from "antd";
import ATitle from "../Title";
import AInput from "../Input";
import AButton from "../Button/index";
import { UploadOutlined, UserOutlined } from "@ant-design/icons";
import { tokens } from "../../utils/Constants";

import ASelect from "../Select/index";
import { useState, useEffect } from "react";
import QuillEditor from "../Editor/index";
import React from "react";
import { useNavigate } from "react-router";
import AText from "../Text";
import { validateEmail } from "../../utils/Helper";
import Upload from "antd/es/upload/Upload";
import Uploads from "../Uploads";
import Loader from "../Loader";
import { DeleteIcon } from "../../assets/icons/DeleteIcon";
import { EditIcon } from "../../assets/icons/EditIcon";
const ViewTeacherStudent = ({
  handleSave,
  handleDelete,
  saving = false,
  deleting = false,
  isStudent,
  content,
  handleEdit,
  data,
}) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [mediaUrl, setMediaUrl] = useState("");
  const [coursePhotoUrl, setCoursePhotoUrl] = useState(null);

  const [isHovered, setIsHovered] = useState(false);

  const [editorContent, setEditorContent] = useState("");
  const handleEditorChange = (content) => {};
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedTempCountry, setSelectedTempCountry] = useState("");

  if (!data) {
    return <Loader />;
  }
  return (
    <div
      style={{
        padding: "2%",
        backgroundColor: tokens.BG_LIGHT,
        borderRadius: "6px",
        height: "80vh",
      }}
    >
      <div style={{ display: "flex", flexDirection: "row" }}>
        <Avatar size={128} icon={<UserOutlined />} />
        <div
          style={{
            marginLeft: "20px",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <ATitle
            content={
              data.first_name + " " + data.middle_name + " " + data.last_name
            }
          />
          <AText content={data.contactNumber} />
          <AText content={data.email} />
          {isStudent&& 
            <AText content={`Group : ${data.group.group_name}`} />
          }
      </div>
      </div>
      {isStudent ? (
        <div
          style={{
            padding: "0 7% 0 2%",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                marginTop: "10%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <p style={{ fontSize: "14px", fontWeight: "600" }}>Address 1 :</p>
              <div style={{ marginLeft: "5px" }}>
                <p>{data.address1}</p>
              </div>
            </div>

            <div
              style={{
                marginTop: "10%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <p style={{ fontSize: "14px", fontWeight: "600" }}>
                Father First Name :
              </p>
              <div style={{ marginLeft: "5px" }}>
                <p>{data.father_first_name}</p>
              </div>
            </div>
            <div
              style={{
                marginTop: "10%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <p style={{ fontSize: "14px", fontWeight: "600" }}>
                Mother First Name :
              </p>
              <div style={{ marginLeft: "5px" }}>
                <p>{data.mother_first_name}</p>
              </div>
            </div>
            <div
              style={{
                marginTop: "10%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <p style={{ fontSize: "14px", fontWeight: "600" }}>
                Father Contact Number :
              </p>
              <div style={{ marginLeft: "5px" }}>
                <p>{data.father_contact_number}</p>
              </div>
            </div>
            <div
              style={{
                marginTop: "10%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <p style={{ fontSize: "14px", fontWeight: "600" }}>Gender :</p>
              <div style={{ marginLeft: "5px" }}>
                <p>{data.gender}</p>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                marginTop: "7%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <p style={{ fontSize: "14px", fontWeight: "600" }}>
                Father Middle Name :
              </p>
              <div style={{ marginLeft: "5px" }}>
                <p>{data.father_middle_name}</p>
              </div>
            </div>
            <div
              style={{
                marginTop: "7%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <p style={{ fontSize: "14px", fontWeight: "600" }}>
                Mother Middle Name :
              </p>
              <div style={{ marginLeft: "5px" }}>
                <p>{data.mother_middle_name}</p>
              </div>
            </div>
            <div
              style={{
                marginTop: "7%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <p style={{ fontSize: "14px", fontWeight: "600" }}>
                Mother Contact Number :
              </p>
              <div style={{ marginLeft: "5px" }}>
                <p>{data.mother_contact_number}</p>
              </div>
            </div>
            <div
              style={{
                marginTop: "7%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <p style={{ fontSize: "14px", fontWeight: "600" }}>Program : </p>
              <div style={{ marginLeft: "5px" }}>
                <p>{data.program.program_name}</p>
              </div>
            </div>
            <div
              style={{
                marginTop: "7%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <p style={{ fontSize: "14px", fontWeight: "600" }}>State :</p>
              <div style={{ marginLeft: "5px" }}>
                <p>{data.state}</p>
              </div>
            </div>
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
            }}
          >
            <div
              style={{
                marginTop: "10%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <p style={{ fontSize: "14px", fontWeight: "600" }}>City :</p>
              <div style={{ marginLeft: "5px" }}>
                <p>{data.city}</p>
              </div>
            </div>
            <div
              style={{
                marginTop: "10%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <p style={{ fontSize: "14px", fontWeight: "600" }}>
                Fatther Last Name :{" "}
              </p>
              <div style={{ marginLeft: "5px" }}>
                <p>{data.father_last_name}</p>
              </div>
            </div>
            <div
              style={{
                marginTop: "10%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <p style={{ fontSize: "14px", fontWeight: "600" }}>
                Mother Last Name :{" "}
              </p>
              <div style={{ marginLeft: "5px" }}>
                <p>{data.mother_last_name}</p>
              </div>
            </div>
            <div
              style={{
                marginTop: "10%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              <p style={{ fontSize: "14px", fontWeight: "600" }}>
                Emergency Number:
              </p>
              <div style={{ marginLeft: "5px" }}>
                <p>{data.emergency_contact_number}</p>
              </div>
            </div>
            <div
              style={{
                marginTop: "10%",
                display: "flex",
                flexDirection: "row",
              }}
            >
              {/* <p style={{ fontSize: "14px", fontWeight: "600" }}>Grpup :</p>
              <div style={{ marginLeft: "5px" }}>
                <p>{data.group.group_name}</p>
              </div> */}
            </div>
          </div>
        </div>
      ) : (
        <div
          style={{
            paddingLeft: "1%",
            marginTop: "1%",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            style={{
              marginTop: "1%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <p style={{ fontSize: "14px", fontWeight: "600" }}>Gender :</p>
            <div style={{ marginLeft: "5px" }}>
              <p>{data.gender}</p>
            </div>
          </div>
          <div
            style={{
              marginTop: "1%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <p style={{ fontSize: "14px", fontWeight: "600" }}>Address 1 :</p>
            <div style={{ marginLeft: "5px" }}>
              <p>{data.address1}</p>
            </div>
          </div>
          <div
            style={{
              marginTop: "1%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <p style={{ fontSize: "14px", fontWeight: "600" }}>City :</p>
            <div style={{ marginLeft: "5px" }}>
              <p>{data.city}</p>
            </div>
          </div>
          <div
            style={{
              marginTop: "1%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <p style={{ fontSize: "14px", fontWeight: "600" }}>State :</p>
            <div style={{ marginLeft: "5px" }}>
              <p>{data.state}</p>
            </div>
          </div>
          <div
            style={{
              marginTop: "1%",
              display: "flex",
              flexDirection: "row",
            }}
          >
            <p style={{ fontSize: "14px", fontWeight: "600" }}>
              Qualification :
            </p>
            <div style={{ marginLeft: "5px" }}>
              <p>{data.qualification}</p>
            </div>
          </div>
        </div>
      )}
      <div
        style={{
          marginTop: "1%",
          display: "flex",
          justifyContent: "flex-end",
          alignItems: "center",
        }}
      >
        <AButton
          type="default"
          size="middle"
          style={{
            color: tokens.COLOR_DANGER_D300,
            borderColor: tokens.COLOR_DANGER_D300,
            margin: "10px",
          }}
          icon={<DeleteIcon />}
          onClick={(e) => {
            e.stopPropagation();
            handleDelete(data.id);
          }}
        >
          Delete
        </AButton>
        <AButton
          type="primary"
          size="middle"
          icon={<EditIcon />}
          style={{
            color: tokens.BG_LIGHT,
            backgroundColor: tokens.COLOR_SUCCESS,
          }}
          onClick={(e) => {
            e.stopPropagation();
            handleEdit(data.id);
          }}
        >
          Edit
        </AButton>
      </div>
    </div>
  );
};

export default ViewTeacherStudent;
