import actions from "./actions";
import { fromJS } from "immutable";

const initialState = fromJS({
  exams: [],
  examSubjects: []
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_EXAMS_SUCCESS:
      return state.set("exams", action.payload);

    case actions.SAVE_EXAMS_SUCCESS:
      return state.set("exams", action.payload);

    case actions.EDIT_EXAMS_SUCCESS:
      return state.set("exams", action.payload);

    case actions.DELETE_EXAMS_SUCCESS:
      return state.set("exams", action.payload);

    case actions.FETCH_EXAMS_SUBJECT_BY_CLASS_SUCCESS:
      return state.set("examSubjects", action.payload);

    default:
      return state;
  }
};

export default reducer;
