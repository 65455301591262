import React, { useEffect, useState } from "react";
import {
  SearchOutlined,
  WarningFilled,
  PlusOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import { Col, Row, Space, message, Select } from "antd";
import AInput from "../../Components/Input";
import ASelect from "../../Components/Select/index";
import AButton from "../../Components/Button/index";
import { Link, useNavigate } from "react-router-dom";
import { Roles, tokens } from "../../utils/Constants";
import ATitle from "../../Components/Title";
import CourseCard from "../../Components/CourseCard";
import actions from "./store/actions";
import { useDispatch, useSelector } from "react-redux";
import AModal from "../../Components/Model";
import { getFormattedRoleName, hasPermission } from "../../utils/Helper";
import Loader from "../../Components/Loader";

function ExamMark() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openModel, setOpenModel] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [searchTerm, setSearchTerm] = useState("");

  const handleCourseDelete = (id) => {
    new Promise((resolve, reject) => {
      dispatch(actions.deleteCourses(id, resolve, reject));
    })
      .then(() => {
        message.success("Course delete Success");
        navigate("/course");
        setOpenModel(false);
      })
      .catch(() => {
        message.error("Course delete fails ");
      })
      .finally(() => {});
  };

  let courses = useSelector(({ coursesReducer }) =>
    hasPermission("view_course_as_instructor")
      ? getFormattedRoleName() !== Roles.ROLE_SUPER_ADMIN
        ? coursesReducer.get("coursesByInstructor")
        : coursesReducer.get("courses")
      : coursesReducer.get("courses")
  );

  const programCodes =
    courses && Array.from(new Set(courses.map((course) => course.programCode)));
  const [selectedProgramCode, setSelectedProgramCode] = useState(
    programCodes[0] || ""
  );
  const [selectedClass, setSelectedClass] = useState("");
  const [classes, setClasses] = useState([]);

  useEffect(() => {
    const programCodes = Array.from(new Set(courses.map((course) => course.programCode)));
    setSelectedProgramCode(programCodes[0] || "");
    setClasses(courses.filter(course => course.programCode === selectedProgramCode));
  }, [dispatch, courses]);

  const filteredCourses =
    courses &&
    courses.filter((course) => {
      return (
        (!selectedProgramCode || course.programCode === selectedProgramCode) &&
        (!selectedClass || course.class === selectedClass) &&
        Object.keys(course).some((key) => {
          if (key !== "id") {
            const value = course[key];
            return (
              value &&
              value.toString().toLowerCase().includes(searchTerm.toLowerCase())
            );
          }
          return false;
        })
      );
    });

  useEffect(() => {
    hasPermission("view_course_as_instructor")
      ? getFormattedRoleName() !== Roles.ROLE_SUPER_ADMIN
        ? new Promise((resolve, reject) => {
            dispatch(actions.fetchCoursesByInstructor(resolve, reject));
          }).finally(() => {})
        : new Promise((resolve, reject) => {
            dispatch(actions.fetchCourses(resolve, reject));
          }).finally(() => {})
      : new Promise((resolve, reject) => {
          dispatch(actions.fetchCourses(resolve, reject));
        }).finally(() => {});
  }, [dispatch, openModel]);

  useEffect(() => {
    const filteredClasses = courses
      .filter((course) => course.programCode === selectedProgramCode)
      .map((course) => course.class);
    setClasses(Array.from(new Set(filteredClasses)));
  }, [courses, selectedProgramCode]);
  useEffect(() => {
    const defaultClass = classes[0] || "";
    setSelectedClass(defaultClass);
  }, [classes, selectedProgramCode]);

  if (!courses) {
    return <Loader />;
  }
  return (
    <div>
      <Space
        style={{
          width: "100%",
          justifyContent: "flex-end",
          // margin: "30px",
          paddingRight: "1em",
        }}
      >
        <AInput
          suffix={<SearchOutlined />}
          placeholder="Search"
          name="search"
          size="large"
          type="primary"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Space>
      <Space
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "1.5em 1em",
        }}
      >
        <ASelect
          placeholder="Select Program Code"
          style={{ width: 120, marginRight: 10 }}
          onChange={(value) => {
            setSelectedProgramCode(value);
            setSelectedClass("");
          }}
          value={selectedProgramCode}
        >
          {programCodes.map((code) => (
            <Select.Option key={code} value={code}>
              {code}
            </Select.Option>
          ))}
        </ASelect>
        <ASelect
          placeholder="Select Class"
          style={{ width: 120, marginRight: 10 }}
          onChange={(value) => setSelectedClass(value)}
          value={selectedClass}
        >
          {classes.map((classItem) => (
            <Select.Option key={classItem} value={classItem}>
              {classItem}
            </Select.Option>
          ))}
        </ASelect>
        {hasPermission("add_course") && (
          <Link to={"/course/add"}>
            <AButton
              type="primary"
              icon={<PlusOutlined />}
              size="large"
              style={{ backgroundColor: tokens.COLOR_SUCCESS }}
            >
              Add New Course
            </AButton>
          </Link>
        )}
      </Space>
      {courses.length == 0 && searchTerm == "" ? (
        <Space
          style={{
            minWidth: "100%",
            minHeight: "80vh",
            justifyContent: "center",
            fontFamily: tokens.SECONDARY_FONT_FAMILY,
          }}
          align="center"
        >
          <Space direction="vertical" align="center">
            <ClockCircleOutlined style={{ fontSize: "24pt" }} />
            <ATitle level={4} content="You don't have any Course yet" />
            <div
              style={{
                border: `1px dashed ${tokens.COLOR_PRIMARY}`,
                padding: "30px",
                borderRadius: "5px",
              }}
            >
              {hasPermission("add_course") && (
                <AButton
                  type="default"
                  icon={<PlusOutlined />}
                  style={{
                    color: tokens.COLOR_PRIMARY,
                    borderColor: tokens.COLOR_PRIMARY,
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/course/add`);
                  }}
                >
                  Add New Course
                </AButton>
              )}
            </div>
          </Space>
        </Space>
      ) : (
        <div style={{ margin: "1.5em 1.5em 0 1.5em" }}>
          <Row wrap gutter={10}>
            {filteredCourses &&
              filteredCourses.map((course) => (
                <Col md={7} key={course.id}>
                  <CourseCard
                    course={course}
                    openPopUp={setOpenModel}
                    selectItemId={setSelectedId}
                  />
                </Col>
              ))}
          </Row>
        </div>
      )}
      <AModal
        style={{
          padding: "50px",
        }}
        open={openModel}
        closable={false}
        footer={null}
        className="css-dev-only-do-not-override-1r3vfrt-last"
      >
        <Space
          align="center"
          direction="vertical"
          style={{
            display: "flex",
            alignContent: "center",
          }}
        >
          <Space direction="vertical" size="small" align="center">
            <WarningFilled
              style={{
                fontSize: "65px",
                color: tokens.colorError,
              }}
            />
            <ATitle content="Are you sure ?" level={4} />
            <p
              style={{
                textAlign: "center",
                width: "100%",
                marginTop: "-5px",
              }}
            >
              Deleting a course will also remove all associated batches. This
              process cannot be undo.
            </p>
          </Space>
          <Space>
            <AButton
              type="default"
              size="small"
              onClick={() => setOpenModel(false)}
            >
              Cancel
            </AButton>
            <AButton
              type="primary"
              size="small"
              onClick={() => handleCourseDelete(selectedId)}
              mode="danger"
            >
              Delete
            </AButton>
          </Space>
        </Space>
      </AModal>
    </div>
  );
}

export default ExamMark;
