import React, { useEffect, useState } from 'react';
import { SearchOutlined, WarningFilled, PlusOutlined, ClockCircleOutlined } from '@ant-design/icons';
import { Col, Row, Space, message, Select } from 'antd';
import AInput from '../../Components/Input';
import ASelect from '../../Components/Select/index';
import AButton from '../../Components/Button/index';
import { Link, useNavigate } from 'react-router-dom';
import { tokens } from '../../utils/Constants';
import ATitle from '../../Components/Title';
import actions from './store/actions';
import { useDispatch, useSelector } from 'react-redux';
import AModal from '../../Components/Model';
import { hasPermission } from '../../utils/Helper';
import Loader from '../../Components/Loader';
import ExamListCard from '../../Components/ExamListCard';

function Exam() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [openModel, setOpenModel] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredExamList, setFilteredExamList] = useState([]);
  const [selectedProgramCode, setSelectedProgramCode] = useState('');

  const handleExamDelete = (id) => {
    new Promise((resolve, reject) => {
      dispatch(actions.deleteExams(id, resolve, reject));
    })
      .then(() => {
        message.success('Exam delete Success');
      })
      .catch(() => {
        message.error('Exam delete fails ');
      })
      .finally(() => {
        navigate('/exam');
        setOpenModel(false);
      });
  };

  let exams = useSelector(({ examsReducer }) => examsReducer.get('exams'));
  let programCodes = exams && Array.from(new Set(exams.map((exam) => exam.program)));

  useEffect(() => {
    if (exams) {
      const programCodes = Array.from(new Set(exams.map((exam) => exam.program)));
      setSelectedProgramCode(programCodes[0] || '');
    }
  }, [exams]);

  useEffect(() => {
    const filteredExams = exams.filter((exam) => exam.program === selectedProgramCode);
    const updatedArray = filteredExams.reduce((acc, exam) => {
      const existingProgram = acc.find((item) => item.program === exam.program && item.class === exam.class);

      if (existingProgram) {
        existingProgram.exam.push({ id: exam.id, name: exam.name });
      } else {
        acc.push({
          program: exam.program,
          class: exam.class,
          exam: [{ id: exam.id, name: exam.name }],
        });
      }

      return acc;
    }, []);

    setFilteredExamList(updatedArray);
  }, [exams, selectedProgramCode]);

  useEffect(() => {
    new Promise((resolve, reject) => {
      dispatch(actions.fetchExams(resolve, reject));
    }).finally(() => {});
  }, [dispatch, openModel]);

  if (!exams) {
    return <Loader />;
  }
  return (
    <div>
      <Space
        style={{
          width: '100%',
          justifyContent: 'flex-end',
          // margin: "30px",
          paddingRight: '1em',
        }}
      >
        <AInput
          suffix={<SearchOutlined />}
          placeholder='Search'
          name='search'
          size='large'
          type='primary'
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
      </Space>
      <Space
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
          margin: '1.5em 1em',
        }}
      >
        <ASelect
          placeholder='Select Program Code'
          style={{ width: 120, marginRight: 10 }}
          onChange={(value) => {
            setSelectedProgramCode(value);
          }}
          value={selectedProgramCode}
        >
          {programCodes.map((code) => (
            <Select.Option key={code} value={code}>
              {code}
            </Select.Option>
          ))}
        </ASelect>
        {hasPermission('add_exam') && (
          <Link to={'/exam/add'}>
            <AButton
              type='primary'
              icon={<PlusOutlined />}
              size='large'
              style={{ backgroundColor: tokens.COLOR_SUCCESS }}
            >
              Add New Exam
            </AButton>
          </Link>
        )}
      </Space>
      {exams.length == 0 && searchTerm == '' ? (
        <Space
          style={{
            minWidth: '100%',
            minHeight: '80vh',
            justifyContent: 'center',
            fontFamily: tokens.SECONDARY_FONT_FAMILY,
          }}
          align='center'
        >
          <Space direction='vertical' align='center'>
            <ClockCircleOutlined style={{ fontSize: '24pt' }} />
            <ATitle level={4} content="You don't have any Exam yet" />
            <div
              style={{
                border: `1px dashed ${tokens.COLOR_PRIMARY}`,
                padding: '30px',
                borderRadius: '5px',
              }}
            >
              {hasPermission('add_exam') && (
                <AButton
                  type='default'
                  icon={<PlusOutlined />}
                  style={{
                    color: tokens.COLOR_PRIMARY,
                    borderColor: tokens.COLOR_PRIMARY,
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/exam/add`);
                  }}
                >
                  Add New Exam
                </AButton>
              )}
            </div>
          </Space>
        </Space>
      ) : (
        <div style={{ margin: '1.5em 1.5em 0 1.5em' }}>
          <Row wrap gutter={10}>
            {filteredExamList &&
              filteredExamList.map((exam) => (
                <Col md={7} key={exam.id}>
                  <ExamListCard exam={exam} openPopUp={setOpenModel} selectItemId={setSelectedId} />
                </Col>
              ))}
          </Row>
        </div>
      )}
      <AModal
        style={{
          padding: '50px',
        }}
        open={openModel}
        closable={false}
        footer={null}
        className='css-dev-only-do-not-override-1r3vfrt-last'
      >
        <Space
          align='center'
          direction='vertical'
          style={{
            display: 'flex',
            alignContent: 'center',
          }}
        >
          <Space direction='vertical' size='small' align='center'>
            <WarningFilled
              style={{
                fontSize: '65px',
                color: tokens.colorError,
              }}
            />
            <ATitle content='Are you sure ?' level={4} />
            <p
              style={{
                textAlign: 'center',
                width: '100%',
                marginTop: '-5px',
              }}
            >
              Deleting a exam name will also remove all associated records. This process cannot be undo.
            </p>
          </Space>
          <Space>
            <AButton type='default' size='small' onClick={() => setOpenModel(false)}>
              Cancel
            </AButton>
            <AButton type='primary' size='small' onClick={() => handleExamDelete(selectedId)} mode='danger'>
              Delete
            </AButton>
          </Space>
        </Space>
      </AModal>
    </div>
  );
}

export default Exam;
