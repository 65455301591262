import { Col, Form, Row, Select, message } from "antd";
import ATitle from "../Title";
import AButton from "../Button/index";
import { tokens } from "../../utils/Constants";
import ASelect from "../Select/index";
import { useState, useEffect } from "react";
import React from "react";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../pages/Program/store/actions";
import courseAction from "../../pages/Course/store/actions";
import instructorAction from "../../pages/Instructor/store/actions";

const AddEditAssignCourse = ({ handleSave, assignCourse, saving = false }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const uniquePrograms = new Set();
  const [selectedProgram, setSelectedProgram] = useState(null);
  const [selectedClass, setSelectedClass] = useState(null);
  const [filteredClasses, setFilteredClasses] = useState([]);
  const [filteredCourses, setFilteredCourses] = useState([]);

  let courses = useSelector(({ coursesReducer }) =>
    coursesReducer.get("courses")
  );

  let instructors = useSelector(({ instructorsReducer }) =>
    instructorsReducer.get("instructors")
  );

  useEffect(() => {
    new Promise((resolve, reject) => {
      dispatch(courseAction.fetchCourses(resolve, reject));
    });
    new Promise((resolve, reject) => {
      dispatch(instructorAction.fetchInstructors(resolve, reject));
    });
  }, [dispatch]);

  courses.forEach((element) => {
    uniquePrograms.add(element.programCode);
  });

  const handleProgramChange = (value) => {
    setSelectedProgram(value);
    setSelectedClass(null);
    const filteredClasses = courses
      .filter((element) => element.programCode === value)
      .map((element) => element.class);
    setFilteredClasses([...new Set(filteredClasses)]);
    setFilteredCourses([]);
    form.setFieldsValue({ course_class: null, course_name: null });
  };

  const handleClassChange = (value) => {
    setSelectedClass(value);
    const filteredCourses = courses
      .filter((element) => element.programCode === selectedProgram && element.class === value);

    setFilteredCourses(filteredCourses);
    form.setFieldsValue({ course_name: null });
  };

  return (
    <div style={{ padding: "1%" }}>
      <ATitle
        content={assignCourse ? "Change Assign Course" : "Assign Course to Instructor"}
        style={{ fontSize: "18px" }}
      />
      <Form
        size="large"
        form={form}
        name="add/editForm"
        layout="vertical"
        scrollToFirstError
        title="Add/EditAssignCourse"
        onFinish={(values) => {
          handleSave(values);
        }}
        initialValues={{
          course_program: assignCourse && assignCourse.program_code,
          course_class: assignCourse && assignCourse.class,
          course_name: assignCourse && assignCourse.course_name,
          course_instructor: assignCourse && assignCourse.instructor_id,
        }}
      >
        <Row gutter={20}>
          <Col xs={24} md={8}>
            <Form.Item
              name="course_program"
              label={<p style={{ fontSize: "16px" }}>Program</p>}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Please select the program",
                },
              ]}
            >
              <ASelect
                customized={false}
                defaultValue="Choose Program"
                onChange={handleProgramChange}
                disabled={assignCourse}
              >
                {[...uniquePrograms].map((program) => (
                  <Select.Option key={program} value={program}>
                    {program}
                  </Select.Option>
                ))}
              </ASelect>
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name="course_class"
              label={<p style={{ fontSize: "16px" }}>Class/Semester</p>}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Please select the class",
                },
              ]}
            >
              <ASelect
                customized={false}
                defaultValue="Choose Class/Semester"
                onChange={handleClassChange}
                disabled={!selectedProgram}
              >
                {filteredClasses.map((className) => (
                  <Select.Option key={className} value={className}>
                    {className}
                  </Select.Option>
                ))}
              </ASelect>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col xs={24} md={8}>
            <Form.Item
              name="course_name"
              label={<p style={{ fontSize: "16px" }}>Course Name</p>}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Please select the course",
                },
              ]}
            >
              <ASelect
                customized={false}
                defaultValue="Choose Course"
                disabled={!selectedClass}
              >
                {filteredCourses.map((element) => (
                  <Select.Option key={element.id} value={element.id}>
                    {element.name}
                  </Select.Option>
                ))}
              </ASelect>
            </Form.Item>
          </Col>
          <Col xs={24} md={8}>
            <Form.Item
              name="course_instructor"
              label={<p style={{ fontSize: "16px" }}>Instructor Name</p>}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              rules={[
                {
                  required: true,
                  message: "Please select the instructor",
                },
              ]}
            >
              <ASelect
                customized={false}
                defaultValue="Choose Instructor"
              >
                {instructors &&
                  instructors.map((element) => (
                    <Select.Option value={element.id}>
                      {element.middle_name
                        ? `${element.first_name} ${element.middle_name} ${element.last_name}`
                        : `${element.first_name} ${element.last_name}`}
                    </Select.Option>
                  ))}
              </ASelect>
            </Form.Item>
          </Col>
        </Row>
        <Row>
          <Form.Item style={{ width: "100%" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <AButton
                type="default"
                size="middle"
                onClick={() => navigate("/assignInstructor")}
                style={{
                  backgroundColor: tokens.COLOR_LIGHTBLACK_L50,
                }}
              >
                Cancel
              </AButton>
              <AButton
                type="primary"
                htmlType="submit"
                size="large"
                style={{
                  color: tokens.BG_LIGHT,
                  backgroundColor: tokens.COLOR_PRIMARY,
                  padding: "0 30px",
                }}
                loading={saving}
              >
                {assignCourse ? "Update Assign Course" : "Assign Course"}
              </AButton>
            </div>
          </Form.Item>
        </Row>
      </Form>
    </div>
  );
};

export default AddEditAssignCourse;
