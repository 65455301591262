import { Button } from "antd";
import { tokens } from "../../utils/Constants";
import React from "react";

function AButton({ fullwidth = false, mode = "default", children, ...others }) {
  const style = {
    width: fullwidth ? "100%" : "auto",
    padding: "0 50px",
    color: mode === "success" || mode === "danger" ? "white" : "default",
    backgroundColor:
      mode === "success"
        ? tokens.COLOR_SUCCESS
        : mode === "danger"
        ? tokens.COLOR_DANGER
        : "default",
    borderColor:
      mode === "primaryoutlined"
        ? tokens.COLOR_PRIMARY
        : mode === "dangeroutlined"
        ? tokens.COLOR_DANGER
        : "default",
  };

  return (
    <Button style={style} {...others}>
      {children}
    </Button>
  );
}

export default AButton;
