import { takeLatest, put, call, all } from "redux-saga/effects";
import Api from "../../../services/api";
import actions from "./actions";
import { act } from "react-dom/test-utils";

const api = new Api();

const fetchCourses = takeLatest(actions.FETCH_COURSES, function* (action) {
  try {
    const response = yield call(api.get, "api/course");
    yield put({
      type: actions.FETCH_COURSES_SUCCESS,
      payload: response,
    });
    yield call(action.resolve, "success");
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

const addCourses = takeLatest(actions.SAVE_COURSES, function* (action) {
  try {
    const response = yield call(api.post, "api/course/add", action.data, null);
    action.data.id = response.id;
    yield put({
      type: actions.SAVE_COURSES_SUCCESS,
      payload: response,
    });

    yield call(action.resolve, "success");
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

const editCourses = takeLatest(actions.EDIT_COURSES, function* (action) {
  try {
    const response = yield call(
      api.put,
      `api/course/${action.courseId}`,
      action.data,
      null
    );
    yield call(action.resolve, "success");
    yield put({
      type: actions.EDIT_COURSES_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

const deleteCourses = takeLatest(actions.DELETE_COURSES, function* (action) {
  try {
    const responseId = yield call(api.delete, `api/course/${action.courseId}`);
    yield put({
      type: actions.DELETE_COURSES_SUCCESS,
      id: action.id,
    });

    yield call(action.resolve, responseId);
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

const fetchCoursesByInstructor = takeLatest(
  actions.FETCH_COURSES_BY_INSTRUCTOR,
  function* (action) {
    try {
      const response = yield call(api.get, "api/courseByInstructor");
      yield put({
        type: actions.FETCH_COURSES_BY_INSTRUCTOR_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, "success");
    } catch (error) {
      yield call(action.reject, "failed");
    }
  }
);

const fetchCoursesByBatch = takeLatest(
  actions.FETCH_COURSES_BY_BATCH,
  function* (action) {
    try {
      const response = yield call(
        api.get,
        `api/courseByBatch/${action.batchId}`
      );
      yield put({
        type: actions.FETCH_COURSES_BY_BATCH_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, "success");
    } catch (error) {
      yield call(action.reject, "failed");
    }
  }
);

const fetchCoursesByStudent = takeLatest(
  actions.FETCH_COURSES_BY_STUDENT,
  function* (action) {
    try {
      const response = yield call(api.get, "api/courseByStudent");
      yield put({
        type: actions.FETCH_COURSES_BY_STUDENT_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, "success");
    } catch (error) {
      yield call(action.reject, "failed");
    }
  }
);

const fetchCoursesByClass = takeLatest(
  actions.FETCH_COURSES_BY_CLASS,
  function* (action) {
    try {
      const response = yield call(api.get, `api/courseByClass/${action.classId}`);
      yield put({
        type: actions.FETCH_COURSES_BY_CLASS_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, "success");
    } catch (error) {
      yield call(action.reject, "failed");
    }
  }
);

const fetchNotes = takeLatest(actions.FETCH_NOTES, function* (action) {
  try {
    const response = yield call(api.get, `api/note/${action.courseId}`);
    yield put({
      type: actions.FETCH_NOTES_SUCCESS,
      payload: response,
    });
    yield call(action.resolve, response);
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

const addNotes = takeLatest(actions.SAVE_NOTES, function* (action) {
  try {
    const response = yield call(api.post, "api/note/add", action.data, null);
    action.data.id = response.id;
    yield put({
      type: actions.SAVE_NOTES_SUCCESS,
      payload: response,
    });

    yield call(action.resolve, "success");
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

const deleteNotes = takeLatest(actions.DELETE_NOTES, function* (action) {
  try {
    const responseId = yield call(api.delete, `api/note/${action.noteId}`);
    yield put({
      type: actions.DELETE_NOTES_SUCCESS,
      id: action.id,
    });

    yield call(action.resolve, responseId);
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

export default function* saga() {
  yield all([
    fetchCourses,
    addCourses,
    deleteCourses,
    editCourses,
    fetchCoursesByInstructor,
    fetchCoursesByBatch,
    fetchCoursesByStudent,
    fetchCoursesByClass,
    fetchNotes,
    addNotes,
    deleteNotes
  ]);
}
