import actions from "./actions";
import { fromJS } from "immutable";

const initialState = fromJS({
  batch_session: [],
  batch_info: {},
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_BATCH_SESSION_SUCCESS:
      return state.set("batch_session", action.payload);

    case actions.FETCH_BATCH_INFO_SUCCESS:
      return state.set("batch_info", action.payload);

    case actions.UPDATE_BATCH_SUCCESS:
      return state.set("batch_info", action.payload);

    case actions.FETCH_BATCH_DETAILS_SUCCESS:
      return state.set("batch_details", action.payload);

    case actions.END_BATCH_SUCCESS:
      return state;

    default:
      return state;
  }
};

export default reducer;
