import { Divider, Form, Row, Space, message, theme, Col } from "antd";
import React, { useEffect, useState } from "react";
import { tokens } from "../../../utils/Constants";
import ATitle from "../../../Components/Title";
import AText from "../../../Components/Text";
import QuillEditor from "../../../Components/Editor";
import AButton from "../../../Components/Button";
import ATable from "../../../Components/Table";
import { DeleteIcon } from "../../../assets/icons/DeleteIcon";
import { EditIcon } from "../../../assets/icons/EditIcon";
import { WarningFilled, BackwardOutlined, PlusOutlined } from "@ant-design/icons";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../../Components/Loader";
import actions from "../store/actions";
import AModal from "../../../Components/Model";
import { hasPermission } from "../../../utils/Helper";
import { AInput } from "../../../Components";
import Uploads from "../../../Components/Uploads";
import FileDownload from "../../../Components/Download";
import placeholderImage from "../../../assets/images/placeholderimage.webp";


function ViewCourse() {
  const [openModel, setOpenModel] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [mediaId, setMediaId] = useState();
  const [mediaUrl, setMediaUrl] = useState("");
  const [saving, setSaving] = useState(false);
  const [loading, setLoading] = useState(false);
  const { id } = useParams();
  const [course, setCourse] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { useToken } = theme;
  const { token } = useToken();

  //get all course list
  const coursesList = useSelector(({ coursesReducer }) =>
    coursesReducer.get("courses")
  );

  const notesList = useSelector(({ coursesReducer }) =>
    coursesReducer.get("notes")
  );

  // Function to find an object by ID in the list
  const findObjectById = (id) => {
    return coursesList
      ? coursesList.find((item) => item.id === parseInt(id))
      : "";
  };

  const handleCourseDelete = (id) => {
    new Promise((resolve, reject) => {
      dispatch(actions.deleteCourses(id, resolve, reject));
    })
      .then(() => {
        navigate("/course");
        message.success("Course delete Success");
      })
      .catch(() => {
        message.error("Course delete fails ");
      })
  };

  const handleNoteDelete = (id) => {
    new Promise((resolve, reject) => {
      dispatch(actions.deleteNotes(id, resolve, reject));
    })
      .then(() => {
        message.success("Note delete Success");
      })
      .catch(() => {
        message.error("Note delete fails ");
      })
  };

  useEffect(() => {
    if (!coursesList.length) {
      new Promise((resolve, reject) => {
        dispatch(actions.fetchCourses(resolve, reject));
      });
    }
  }, []);

  useEffect(() => {
    setLoading(true);
    id &&
      new Promise((resolve, reject) => {
        dispatch(actions.fetchNotes(id, resolve, reject));
      }).finally(() => {
        setLoading(false);
      });
  }, [id]);

  useEffect(() => {
    const foundCourse = findObjectById(id);
    setCourse(foundCourse);
  }, [coursesList]);

  const handleUploadSuccess = (mediaId) => {
    setMediaId(mediaId);
  };

  const handleSave = (value) => {
    setSaving(true)
    const data = {
      ...value,
    };
    new Promise((resolve, reject) => {
      dispatch(actions.saveNotes(data, resolve, reject));
    })
      .then(() => {
        message.success("Note added Sucessfully");
      })
      .catch(() => {
        message.error("Note add failed");
      })
      .finally(() => { setSaving(false); setUpdateSuccess(false) });
  };

  const columns = [
    {
      title: "S.N.",
      render: (_, __, index) => <span>{index + 1}</span>,
    },
    {
      title: "Title",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Short Details",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Action",
      render: (_, record) => (
        <div>
          <div
            style={{
              display: "flex",
              // justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <Space>
              <FileDownload fileName={record.media_name} url={record.fullUrl} />
            </Space>
            <AButton
              type="default"
              size="middle"
              style={{
                color: tokens.COLOR_DANGER,
                borderColor: tokens.COLOR_DANGER,
                margin: "10px",
              }}
              onClick={(e) => {
                e.stopPropagation();
                handleNoteDelete(record.id);
              }}
            >
              Delete
            </AButton>
          </div>
        </div>
      ),
    },
  ];
  if (!course || loading) {
    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <Loader />
      </div>
    );
  }

  return (
    <div
      style={{
        backgroundColor: tokens.BG_LIGHT,
        borderRadius: "6px",
        padding: "20px",
      }}
    >
      <ATitle
        content={"Course Info"}
        level={4}
        style={{ color: tokens.COLOR_NEUTRAL_N600, fontSize: "18px" }}
      />
      <div style={{display:'flex', justifyContent:'space-around'}}>
        <div>

        <div
          style={{
            display: "flex",
            alignItems: "flex-end",
            fontWeight: 500,
            margin: "10px 0",
          }}
        >
          <AText
            style={{
              fontSize: "14px",
              fontWeight: 600,
              margin: "0 10px 0 0",
              color: tokens.COLOR_NEUTRAL_N600,
            }}
          >
            Course Name:
          </AText>
          <AText
            content={course.name}
            style={{
              color: tokens.COLOR_NEUTRAL_N600,
              fontWeight: 500,
              fontSize: "14px",
            }}
          />
        </div>
        <div
          style={{ display: "flex", alignItems: "flex-end", margin: "10px 0" }}
        >
          <AText
            style={{
              fontSize: "14px",
              fontWeight: 600,
              margin: "0 10px 0 0",
              color: tokens.COLOR_NEUTRAL_N600,
            }}
          >
            Program :
          </AText>
          <AText
            content={course.program}
            style={{
              color: tokens.COLOR_NEUTRAL_N600,
              fontWeight: 500,
              fontSize: "14px",
            }}
          />
        </div>
        <div
          style={{ display: "flex", alignItems: "flex-end", margin: "10px 0" }}
        >
          <AText
            style={{
              fontSize: "14px",
              fontWeight: 600,
              margin: "0 10px 0 0",
              color: tokens.COLOR_NEUTRAL_N600,
            }}
          >
            Class :
          </AText>
          <AText
            content={course.class}
            style={{
              color: tokens.COLOR_NEUTRAL_N600,
              fontWeight: 500,
              fontSize: "14px",
            }}
          />
        </div>
        <div
          style={{ display: "flex", alignItems: "flex-end", margin: "10px 0" }}
        >
          <AText
            style={{
              fontSize: "14px",
              fontWeight: 600,
              margin: "0 10px 0 0",
              color: tokens.COLOR_NEUTRAL_N600,
            }}
          >
            Code :
          </AText>
          <AText
            content={course.code}
            style={{
              color: tokens.COLOR_NEUTRAL_N600,
              fontWeight: 500,
              fontSize: "14px",
            }}
          />
        </div>
        <div
          style={{ display: "flex", alignItems: "flex-end", margin: "10px 0" }}
        >
          <AText
            style={{
              fontSize: "14px",
              fontWeight: 600,
              margin: "0 10px 0 0",
              color: tokens.COLOR_NEUTRAL_N600,
            }}
          >
            Category :
          </AText>
          <AText
            content={course.category}
            style={{
              color: tokens.COLOR_NEUTRAL_N600,
              fontWeight: 500,
              fontSize: "14px",
            }}
          />
        </div>
        <div style={{ margin: "10px 0", color: tokens.COLOR_NEUTRAL_N600 }}>
          <style>
            {`
            .ql-container.ql-snow {
              border: none;
            }
          `}
          </style>
          <AText
            style={{
              fontSize: "14px",
              fontWeight: 600,
              margin: "0 10px 0 0",
              color: tokens.COLOR_NEUTRAL_N600,
            }}
          >
            Description :
          </AText>

          <QuillEditor
            value={course.description}
            readOnly={true}
            style={{
              fontSize: tokens.FONT_FAMILY,
              border: "none",
              padding: "0px",
            }}
            className="no-toolbar"
            modules={{ toolbar: false }}
          />
        </div>
      </div>
        <div>
          <img
                src={course && course.imageUrl ? course.imageUrl : placeholderImage}
                alt="card-list"
                style={{
                  width: "100%",
                  height: "25vh",
                  objectFit: "cover",
                  borderRadius: "0px",
                }}
              />
        </div>
      </div>

      <div
        style={{
          display: "flex",
          justifyContent: "flex-end",
          gap: "3%",
          alignItems: "center",
        }}
      >
        <AButton
          type="default"
          size="middle"
          style={{
            color: tokens.COLOR_PRIMARY,
            borderColor: tokens.COLOR_PRIMARY,
          }}
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/course`);
          }}
        >
          <BackwardOutlined style={{ color: tokens.COLOR_PRIMARY, fontSize: "15px", alignContent: "center" }} /> Back
        </AButton>
        <AButton
          type="default"
          size="middle"
          style={{
            color: tokens.COLOR_DANGER,
            borderColor: tokens.COLOR_DANGER,
          }}
          icon={<DeleteIcon />}
          onClick={() => setOpenModel(true)}
        >
          Delete
        </AButton>
        <AButton
          type="primary"
          size="middle"
          icon={<EditIcon />}
          style={{
            color: tokens.BG_LIGHT,
            backgroundColor: tokens.COLOR_SUCCESS,
          }}
          onClick={(e) => {
            e.stopPropagation();
            navigate(`/course/edit/${course.id}`);
          }}
        >
          Edit
        </AButton>
      </div>
      <Divider />
      {hasPermission("add_note") ? (
        <div style={{ marginTop: "20px" }}>
          <AButton
            type="primary"
            icon={<PlusOutlined />}
            size="large"
            style={{
              backgroundColor: tokens.COLOR_SUCCESS,
              margin: "20px 0",
            }}
            onClick={() => setUpdateSuccess(true)}
          >
            Add Notes
          </AButton>
        </div>
      ) : null}

      {notesList && notesList.length > 0 ? (
        <ATable columns={columns} dataSource={notesList} />
      ) : null}

      <AModal
        style={{
          width: "600px",
          padding: "50px",
        }}
        open={updateSuccess}
        closable={false}
        footer={null}
      >

        <ATitle
          content="Add Note"
          level={5}
          style={{ fontSize: "18px" }}
        />
        <Form
          size="large"
          name="addlearningmaterials"
          layout="vertical"
          scrollToFirstError
          title="Add/EditNote"
          onFinish={(values) => {
            values.media_id = mediaId;
            values.course_id = id;
            handleSave(values);
          }}
        >
          <Row wrap gutter={20}>
            <Col md={24}>
              <Form.Item
                name="title"
                label={<p style={{ fontSize: "16px" }}>Title</p>}
                rules={[
                  {
                    required: true,
                    message: "Please input the Note Title!",
                  },
                ]}
              >
                <AInput placeholder={"Enter Note Title"} size="large" />
              </Form.Item>
            </Col>
          </Row>
          <Row>
            <Col md={24}>
              <Form.Item
                name="description"
                label={<p style={{ fontSize: "16px" }}>Short Details</p>}
                rules={[
                  {
                    required: true,
                    message: "Please input the Short Details!",
                  },
                ]}
              >
                <AInput
                  placeholder={"Enter Short Details"}
                  size="large"
                  maxLength={90}
                />
              </Form.Item>
            </Col>
          </Row>
          <Row style={{ display: "flex", flexDirection: "column" }}>
            <Form.Item
              label={
                <p style={{ fontSize: "16px" }}>
                  Upload Note
                </p>
              }
            >
              <Uploads
                isDragger={true}
                setMediaUrl={setMediaUrl}
                setMediaId={setMediaId}
                customUploadText="Upload Note"
                imagesUrl={mediaUrl}
                id="uploadNote"
                onUploadSuccess={handleUploadSuccess}
              />
            </Form.Item>
          </Row>
          <Row
            style={{ display: "flex", justifyContent: "space-between" }}
          >
            <AButton
              mode="danger"
              size="large"
              style={{ margin: "15px 0" }}
              onClick={() => {
                setUpdateSuccess(false);
              }}
            >
              Cancel
            </AButton>
            <AButton
              type="primary"
              size="large"
              style={{ margin: "15px 0" }}
              htmlType="submit"
              loading={saving}
            >
              Submit
            </AButton>
          </Row>
        </Form>
      </AModal>

      <AModal
        style={{
          padding: "50px",
        }}
        open={openModel}
        closable={false}
        footer={null}
        className="css-dev-only-do-not-override-1r3vfrt-last"
      >
        <Space
          align="center"
          direction="vertical"
          style={{
            display: "flex",
            alignContent: "center",
          }}
        >
          <Space direction="vertical" size="small" align="center">
            <WarningFilled
              style={{
                fontSize: "65px",
                color: token.colorError,
              }}
            />
            <ATitle content="Are you sure ?" level={4} />
            <p
              style={{
                textAlign: "center",
                width: "100%",
                marginTop: "-5px",
              }}
            >
              Deleting a course will also remove all associated batches. This
              process cannot be undo.
            </p>
          </Space>
          <Space>
            <AButton
              type="default"
              size="small"
              onClick={() => setOpenModel(false)}
            >
              Cancel
            </AButton>
            <AButton
              type="primary"
              size="small"
              onClick={() => handleCourseDelete(course.id)}
              mode="danger"
            >
              Delete
            </AButton>
          </Space>
        </Space>
      </AModal>
    </div >
  );
}
export default ViewCourse;
