import React, { useEffect, useState } from "react";
import ATable from "../../Components/Table";
import AButton from "../../Components/Button/index";
import {
  EditTwoTone,
  PlusCircleTwoTone,
  SearchOutlined,
  PlusOutlined,
  FilterOutlined,
  InfoCircleOutlined,
  UploadOutlined,
  WarningFilled,
} from "@ant-design/icons";
import { Roles, tokens } from "../../utils/Constants";
import {
  Drawer,
  Col,
  Form,
  Row,
  Select,
  Space,
  message,
  Button,
  Upload,
} from "antd";
import AInput from "../../Components/Input";
import AModal from "../../Components/Model";
import ATitle from "../../Components/Title";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import actions from "./store/actions";
import programAction from "../Program/store/actions";
import Loader from "../../Components/Loader";
import { read, utils } from "xlsx";
import { getFormattedRoleName } from "../../utils/Helper";

function StudentDetails() {
  const [openModel, setOpenModel] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [openCSVModel, setOpenCSVModel] = useState(false);
  const [loadingStudentDelete, setLoadingStudentDelete] = useState(false);
  const [isInfoModalVisible, setIsInfoModalVisible] = useState(false);
  const [selectedId, setSelectedId] = useState();
  const [saving, setSaving] = useState(false);
  const [clickAdd, setClickAdd] = useState(false);
  const [fileData, setFileData] = useState([]);
  const [file, setFile] = useState(null);

  const [openDrawer, setOpenDrawer] = useState(false);
  const showDrawer = () => {
    setOpenDrawer(true);
  };

  const onClose = () => {
    setOpenDrawer(false);
  };

  const dispatch = useDispatch();
  const navigate = useNavigate();

  let students = useSelector(({ studentsReducer }) =>
    studentsReducer.get("students")
  );

  let programs = useSelector(({ programsReducer }) =>
    programsReducer.get("programs")
  );

  useEffect(() => {
    new Promise((resolve, reject) => {
      dispatch(programAction.fetchPrograms(resolve, reject));
    }).finally(() => {});
  }, [dispatch]);

  let groups = useSelector(({ studentsReducer }) =>
    studentsReducer.get("groups")
  );

  useEffect(() => {
    new Promise((resolve, reject) => {
      dispatch(actions.fetchGroups(resolve, reject));
    }).finally(() => {});
  }, [dispatch]);

  const filteredStudents =
    students &&
    students.filter((student) => {
      return Object.keys(student).some((key) => {
        if (key !== "id") {
          const value = student[key];
          return (
            value &&
            value.toString().toLowerCase().includes(searchTerm.toLowerCase())
          );
        }
        return false;
      });
    });

  const expectedColumns = [
    "S.N.",
    "First Name",
    "Middle Name",
    "Last Name",
    "Email",
    "Address",
    "City",
    "State",
    "Contact Number",
    "Gender",
    "Father First Name",
    "Father Middle Name",
    "Father Last Name",
    "Father Contact",
    "Mother First Name",
    "Mother Middle Name",
    "Mother Last Name",
    "Mother Contact",
    "Emergency Number",
    "Program",
    "Group",
  ];

  const beforeUpload = (file) => {
    const reader = new FileReader();
    reader.onload = (evt) => {
      const bstr = evt.target.result;
      const wb = read(bstr, { type: "binary" });
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      let data = utils.sheet_to_json(ws, { header: 1 });
      data = data.filter((item) => item.length > 0);

      const missingColumns = expectedColumns.filter(
        (col) => !data[0].includes(col)
      );

      if (missingColumns.length > 0) {
        message.error(
          `File is missing the following columns: ${missingColumns.join(
            ", "
          )}. Please verify and upload again.`
        );
        return false;
      }

      const excludedColumns = [
        "Middle Name",
        "Father Middle Name",
        "Mother Middle Name",
      ];

      const nonEmptyData = data.slice(1).filter((row) => row.some(Boolean));

      const emptyColumnRows = nonEmptyData.filter((row) => {
        const emptyColumns = expectedColumns.filter((col, index) => {
          const value = row[index];
          return !value && !excludedColumns.includes(col);
        });
        return emptyColumns.length > 0;
      });

      if (emptyColumnRows.length > 0) {
        const emptyColumnRowNumbers = emptyColumnRows.map((row) => row[0]);
        message.error(
          `Empty values found in S.N. : ${emptyColumnRowNumbers.join(
            ", "
          )}. Please fill in all columns values before uploading.`
        );
        return false;
      }

      const emailIndex = expectedColumns.indexOf("Email");
      const invalidEmails = data.slice(1).filter((row) => {
        const email = row[emailIndex];
        return email && !isValidEmail(email);
      });

      if (invalidEmails.length > 0) {
        const invalidEmailRowNumbers = invalidEmails.map((row) => row[0]);
        message.error(
          `Invalid email format found in S.N. : ${invalidEmailRowNumbers.join(
            ", "
          )}. Please provide valid email addresses.`
        );
        return false;
      }

      const contactNumberIndex = expectedColumns.indexOf("Contact Number");
      const fatherContactIndex = expectedColumns.indexOf("Father Contact");
      const motherContactIndex = expectedColumns.indexOf("Mother Contact");
      const emergencyNumberIndex = expectedColumns.indexOf("Emergency Number");

      const invalidContact =
        data.slice(1).filter((row) => {
          const contactNumber = row[contactNumberIndex];
          const fatherContact = row[fatherContactIndex];
          const motherContact = row[motherContactIndex];
          const emergencyNumber = row[emergencyNumberIndex];
          return (
            (contactNumber && !isValidContact(contactNumber)) ||
            (fatherContact && !isValidContact(fatherContact)) ||
            (motherContact && !isValidContact(motherContact)) ||
            (emergencyNumber && !isValidContact(emergencyNumber))
          );
        }) || [];

      if (invalidContact.length > 0) {
        const invalidContactRowNumbers = invalidContact.map((row) => row[0]);
        message.error(
          `Invalid contact numbers found in S.N. : ${invalidContactRowNumbers.join(
            ", "
          )}. Please provide valid contact numbers.`
        );
        return false;
      }

      const isValidGender = checkValidValues(
        data,
        ["Male", "Female", "Others"],
        "Gender"
      );

      if (!isValidGender) {
        return false;
      }

      const isValidState = checkValidValues(
        data,
        [
          "Koshi",
          "Madhesh",
          "Bagmati",
          "Gandaki",
          "Lumbini",
          "Karnali",
          "Sudurpashchim",
        ],
        "State"
      );

      if (!isValidState) {
        return false;
      }

      const programIndex = expectedColumns.indexOf("Program");
      const programCodes = programs.map((program) => program.programCode);

      const invalidPrograms = data.slice(1).filter((row) => {
        const programValue = row[programIndex];
        return !programCodes.includes(programValue);
      });

      if (invalidPrograms.length > 0) {
        message.error(
          `Invalid program names found in S.N. : ${invalidPrograms
            .map((row) => row[0])
            .join(", ")}. Please use valid program names.`
        );
        return false;
      }

      const groupIndex = expectedColumns.indexOf("Group");
      const groupNames = groups.map((group) => group.name);

      const invalidGroups = data.slice(1).filter((row) => {
        const groupValue = row[groupIndex];
        return !groupNames.includes(groupValue);
      });

      if (invalidGroups.length > 0) {
        message.error(
          `Invalid group names found in S.N. : ${invalidGroups
            .map((row) => row[0])
            .join(", ")}. Please use valid group names.`
        );
        return false;
      }

      const jsonData = data.slice(1).map((row) => {
        const obj = {};
        expectedColumns.forEach((col, index) => {
          obj[col] = row[index];
        });
        return obj;
      });
      setFile(jsonData);
      setFileData(jsonData);
    };
    reader.readAsBinaryString(file);
    return false; // do not automatically upload file
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const isValidContact = (contact) => {
    const contactRegex = /^(?:(98|97)\d{8}|0\d{8,10})$/;
    return contactRegex.test(contact);
  };

  const checkValidValues = (data, validValues, columnName) => {
    const columnIndex = expectedColumns.indexOf(columnName);
    const invalidRows = data.slice(1).filter((row, index) => {
      const value = row[columnIndex];
      return !validValues.includes(value);
    });
    if (invalidRows.length > 0) {
      const invalidRowNumbers = invalidRows.map((row) => row[0]);
      message.error(
        `Invalid ${columnName} values found in S.N. : ${invalidRowNumbers.join(
          ", "
        )}. Please use ${validValues
          .map((value) => `'${value}'`)
          .join(", ")} for the '${columnName}' column.`
      );
      return false;
    }
    return true;
  };

  const handleUpload = (values) => {
    setSaving(true);
    setClickAdd(false);
    const data = {
      ...values,
    };
    new Promise((resolve, reject) => {
      dispatch(actions.uploadStudent(data, resolve, reject));
    })
      .then(() => {
        message.success("Students added successfully");
        navigate("/student");
      })
      .catch((error) => {
        message.error("Issue while adding the students !!!");
      })
      .finally(() => {
        setSaving(false);
        setOpenCSVModel(false);
        setFile(null);
        setFileData([]);
      });
  };

  if (clickAdd) {
    handleUpload(fileData);
  }

  useEffect(() => {
    new Promise((resolve, reject) => {
      dispatch(actions.fetchStudents(resolve, reject));
    }).finally(() => {});
  }, [dispatch, openModel]);

  const handleStudentsDelete = (id) => {
    setLoadingStudentDelete(true);
    new Promise((resolve, reject) => {
      dispatch(actions.deleteStudent(id, resolve, reject));
    })
      .then(() => {
        message.success("Student delete Success");
      })
      .catch(() => {
        message.error("Student delete fails ");
      })
      .finally(() => {
        setOpenModel(false);
        setLoadingStudentDelete(false);
      });
  };

  const columns = [
    {
      title: "S.N.",
      render: (_, __, index) => <span>{index + 1}</span>,
    },
    {
      title: "First Name",
      dataIndex: "first_name",
      key: "first_name",
    },
    {
      title: "Middle Name",
      dataIndex: "middle_name",
      key: "middle_name",
    },
    {
      title: "Last Name",
      dataIndex: "last_name",
      key: "last_name",
    },
    {
      title: "Contact Number",
      dataIndex: "contactNumber",
      key: "contactNumber",
    },
    {
      title: "City ",
      dataIndex: "city",
      key: "city",
    },
    {
      title: "Student Batch (Group)",
      // dataIndex: "city",
      key: "group",
      render: (_, record) => (<div>{record.group.group_name}</div>)
    },
    {
      title: "Action",
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            // justifyContent: "flex-end",
            alignItems: "center",
          }}
        >
          <AButton
            type="primary"
            size="middle"
            style={{
              color: tokens.BG_LIGHT,
              backgroundColor: tokens.COLOR_SUCCESS,
            }}
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/student/edit/${record.id}`);
            }}
          >
            Edit
          </AButton>
          <AButton
            type="default"
            size="middle"
            style={{
              color: tokens.COLOR_DANGER_D300,
              borderColor: tokens.COLOR_DANGER_D300,
              margin: "10px",
            }}
            onClick={(e) => {
              e.stopPropagation();
              setOpenModel(true);
              setSelectedId(record.id);
            }}
          >
            Delete
          </AButton>
        </div>
      ),
    },
  ];

  if (!students) {
    return <Loader />;
  }
  return (
    <div>
      <Drawer
        title="Filter"
        placement={"right"}
        onClose={onClose}
        open={openDrawer}
      ></Drawer>
      <Space
        style={{
          width: "100%",
          justifyContent: "flex-end",
          marginBottom: "30px",
        }}
      >
        <AInput
          suffix={<SearchOutlined />}
          placeholder="Search"
          name="search"
          size="large"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <AButton
          type="primary"
          icon={<FilterOutlined />}
          size="large"
          onClick={showDrawer}
          style={{ backgroundColor: tokens.COLOR_LIGHT_ORANGE }}
        >
          Filter
        </AButton>
      </Space>
      {getFormattedRoleName() !== Roles.ROLE_SUPER_ADMIN && (
        <Space
          style={{
            display: "flex",
            justifyContent: "flex-end",
            margin: "1.5em 0",
          }}
        >
          <Link to={"/student/add"}>
            <AButton
              type="primary"
              icon={<PlusOutlined />}
              size="large"
              style={{
                backgroundColor: tokens.COLOR_SUCCESS,
                margin: "0px 10px",
              }}
            >
              Add New Student
            </AButton>
          </Link>
          <AButton
            type="primary"
            icon={<UploadOutlined />}
            size="large"
            style={{ backgroundColor: tokens.COLOR_SUCCESS }}
            onClick={(e) => {
              e.stopPropagation();
              setOpenCSVModel(true);
            }}
          >
            Upload Student List
          </AButton>
        </Space>
      )}
      <ATable
        columns={columns}
        dataSource={filteredStudents}
        onRow={(record, rowIndex) => {
          return {
            onClick: ({ event }) => {
              navigate(`/student/details/${record.id}`);
            }, // click row
            onDoubleClick: (event) => {
              navigate(`/student/details/${record.id}`);
            }, // double click row
          };
        }}
      />
      {/* model to delete the student */}
      <AModal
        style={{
          padding: "50px",
        }}
        open={openModel}
        closable={false}
        footer={null}
        className="css-dev-only-do-not-override-1r3vfrt-last"
      >
        <Space
          align="center"
          direction="vertical"
          style={{
            display: "flex",
            alignContent: "center",
          }}
        >
          <Space direction="vertical" size="small" align="center">
            <WarningFilled
              style={{
                fontSize: "65px",
                color: tokens.colorError,
              }}
            />
            <ATitle content="Are you sure ?" level={4} />
            <p
              style={{
                textAlign: "center",
                width: "100%",
                marginTop: "-5px",
              }}
            >
              Deleting items from this directory cannot be undo.
            </p>
          </Space>
          <Space>
            <AButton
              type="default"
              size="small"
              onClick={() => setOpenModel(false)}
            >
              Cancel
            </AButton>
            <AButton
              type="primary"
              size="small"
              onClick={() => handleStudentsDelete(selectedId)}
              mode="danger"
              loading={loadingStudentDelete}
            >
              Delete
            </AButton>
          </Space>
        </Space>
      </AModal>

      {/* Model for add CSV file for new student */}
      <AModal
        style={{
          padding: "50px",
        }}
        open={openCSVModel}
        closable={false}
        footer={null}
        className="css-dev-only-do-not-override-1r3vfrt-last"
      >
        <InfoCircleOutlined
          style={{
            position: "absolute",
            top: "20px",
            right: "20px",
            fontSize: "24px",
            color: "blue",
          }}
          onClick={(e) => {
            e.stopPropagation();
            setIsInfoModalVisible(true);
            setOpenCSVModel(false);
            setFile(null);
            setFileData([]);
          }}
        />
        <Space
          align="center"
          direction="vertical"
          style={{
            display: "flex",
            alignContent: "center",
          }}
        >
          <Space direction="vertical" size="small" align="center">
            <PlusOutlined
              style={{
                fontSize: "35px",
                background: tokens.COLOR_SUCCESS,
                color: tokens.BG_LIGHT,
                borderRadius: 50,
                padding: 10,
              }}
            />

            <ATitle content="Upload Your Spreadsheet file" level={4} />
            <Upload
              name="file"
              accept=".xlsx, .xls, .csv"
              beforeUpload={beforeUpload}
              fileList={
                file
                  ? [{ uid: "-1", name: "File Uploaded", status: "done" }]
                  : []
              }
              onRemove={() => setFile(null)}
            >
              <Button icon={<UploadOutlined />}>Upload Spreadsheet</Button>
            </Upload>
          </Space>
          <Space style={{ marginTop: "15px" }}>
            <AButton
              type="primary"
              size="small"
              mode="default"
              loading={saving}
              onClick={() => setClickAdd(true)}
            >
              Add Students
            </AButton>
            <AButton
              type="default"
              size="small"
              mode="danger"
              onClick={(e) => {
                e.stopPropagation();
                setFile(null);
                setFileData([]);
                setOpenCSVModel(false);
              }}
            >
              Cancel
            </AButton>
          </Space>
        </Space>
      </AModal>

      {/* model for info of spreadsheet columns details */}
      <AModal
        style={{
          padding: "5px",
        }}
        open={isInfoModalVisible}
        closable={false}
        footer={null}
        className="css-dev-only-do-not-override-1r3vfrt-last"
      >
        <Space
          align="center"
          direction="vertical"
          style={{
            display: "flex",
            alignContent: "center",
            padding: "20px",
          }}
        >
          <Space direction="vertical" size="small" align="center">
            <InfoCircleOutlined
              style={{
                fontSize: "35px",
                color: "blue",
                marginBottom: "10px",
              }}
            />
            <ATitle content="Info" level={4} />
            <p
              style={{
                textAlign: "justify",
                width: "100%",
              }}
            >
              Welcome to the student upload section! Here, you can effortlessly
              add multiple students by uploading a spreadsheet file. It's
              important to include specific columns in your file:
              <span style={{ fontWeight: 700 }}>
                S.N., First Name, Middle Name, Last Name, Email, Address, City,
                State, Contact Number, Gender, Father First Name, Father Middle
                Name, Father Last Name, Father Contact, Mother First Name,
                Mother Middle Name, Mother Last Name, Mother Contact, Emergency
                Number, Program , Group.
              </span>
              <br />
              Kindly take note of the following important details:
              <ul>
                <li>
                  For the 'Gender' column, use 'Male', 'Female', or 'Others'.
                </li>
                <li>
                  For the 'State' column, use one of the following: 'Bagmati',
                  'Koshi', 'Madhesh', 'Gandaki', 'Lumbini', 'Karnali', or
                  'Sudurpashchim'.
                </li>
                <li>
                  For the 'Program', use of the following:
                  {programs &&
                    programs
                      .map((program) => `'${program.programCode}' `)
                      .join(", ")}
                </li>
                <li>
                  For the 'Group', use of the following:
                  {groups &&
                    groups.map((group) => `'${group.name}' `).join(", ")}
                </li>
              </ul>
              Ensuring these details are present will help streamline the
              process. Thank you for your cooperation!
            </p>
          </Space>

          <Space>
            <AButton
              type="primary"
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                setOpenCSVModel(true);
                setIsInfoModalVisible(false);
              }}
            >
              Add Spreadsheet File
            </AButton>
            <AButton
              type="primary"
              size="small"
              onClick={(e) => {
                e.stopPropagation();
                setIsInfoModalVisible(false);
              }}
              mode="danger"
            >
              Close
            </AButton>
          </Space>
        </Space>
      </AModal>
    </div>
  );
}

export default StudentDetails;
