import { Modal, ModalProps } from "antd";
import React from "react";
function AModal({ children, open, onOk, onCancel, ...others }) {
  return (
    <Modal open={open} onOk={onOk} onCancel={onCancel} {...others}>
      {children}
    </Modal>
  );
}

export default AModal;
