import React, { useEffect, useState } from "react";
import AButton from "../../Components/Button/index";
import {
  SearchOutlined,
  PlusOutlined,
  FilterOutlined,
} from "@ant-design/icons";
import { tokens } from "../../utils/Constants";
import { Drawer, Space, message } from "antd";
import AInput from "../../Components/Input";
import { Link, useNavigate } from "react-router-dom";
import { DeleteIcon } from "../../assets/icons/DeleteIcon";
import ATable from "../../Components/Table";
import actions from "./store/actions";
import { useDispatch, useSelector } from "react-redux";
import Loader from "../../Components/Loader";

function SuperAdmin() {
  const [openDrawer, setOpenDrawer] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const [searchTerm, setSearchTerm] = useState('');

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const showDrawer = () => {
    setOpenDrawer(true);
  };

  const onClose = () => {
    setOpenDrawer(false);
  };

  let superAdminList = useSelector(({ superadminsReducer }) =>
    superadminsReducer.get("superadmins")
  );

  const filteredSuperAdmins = superAdminList && superAdminList.filter(superadmin => {
    return Object.keys(superadmin).some(key => {
      if (key !== 'id') {
        const value = superadmin[key];
        return value && value.toString().toLowerCase().includes(searchTerm.toLowerCase());
      }
      return false;
    });
  });

  useEffect(() => {
    new Promise((resolve, reject) => {
      dispatch(actions.fetchSuperAdmins(resolve, reject));
    }).finally(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  const handleSuperAdminDelete = (admin_id) => {
    new Promise((resolve, reject) => {
      dispatch(actions.deleteSuperAdmin(admin_id, resolve, reject));
    })
      .then(() => {
        message.success("Super Admin delete Success");
      })
      .catch(() => {
        message.error("Super Admin delete fails ");
      })
      .finally(() => {});
  };

  const columns = [
    {
      title: "S.N.",
      render: (_, __, index) => <span>{index + 1}</span>,
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Action",
      render: (_, record) => (
        <AButton
          type="default"
          style={{
            color: tokens.COLOR_DANGER,
            borderColor: tokens.COLOR_DANGER,
            margin: "10px",
          }}
          icon={<DeleteIcon />}
          onClick={(e) => {
            e.stopPropagation();
              handleSuperAdminDelete(record.id);
              setSelectedId(record.id);
          }}
        >
          Delete
        </AButton>
      ),
    },
  ];

  if (!superAdminList) {
    return <Loader />;
  }
  return (
    <div>
      <Drawer
        title="Filter"
        placement={"right"}
        onClose={onClose}
        open={openDrawer}
      ></Drawer>
      <Space
        style={{
          width: "100%",
          justifyContent: "flex-end",
          marginBottom: "30px",
        }}
      >
        <AInput
          suffix={<SearchOutlined />}
          placeholder="Search"
          name="search"
          size="large"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <AButton
          type="primary"
          icon={<FilterOutlined />}
          size="large"
          onClick={showDrawer}
          style={{ backgroundColor: tokens.COLOR_LIGHT_ORANGE }}
        >
          Filter
        </AButton>
      </Space>

      <Space
        style={{
          display: "flex",
          justifyContent: "flex-end",
          marginBottom: "30px",
        }}
      >
        <Link to={"/superadmin/add"}>
          <AButton
            type="primary"
            icon={<PlusOutlined />}
            size="large"
            style={{ backgroundColor: tokens.COLOR_DARK_ORANGE }}
          >
            Add New Super Admin
          </AButton>
        </Link>
      </Space>

      <ATable
        columns={columns}
        dataSource={filteredSuperAdmins}
        cursor="default"
        style={{
          margin: "10px 0",
          width: "100%",
          boxShadow: tokens.TABLE_BOX_SADOW,
          backgroundColor: tokens.BG_LIGHT,
        }}
      />
    </div>
  );
}
export default SuperAdmin;
