import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import actions from '../store/actions';
import { message } from 'antd';
import { useDispatch } from 'react-redux';
import AddEditExam from '../../../Components/AddEditExam';

function AddExam() {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const handleSave = (value) => {
    const data = {
      examName: value.exam_name,
      semesterClassId: value.exam_class,
    };

    new Promise((resolve, reject) => {
      dispatch(actions.saveExams(data, 'ADD', resolve, reject));
    })
      .then(() => {
        message.success('Exam added Sucessfully');
      })
      .catch(() => {
        message.error('Exam add failed');
      })
      .finally(() => {
        navigate('/exam');
      });
  };
  return (
    <div>
      <AddEditExam handleSave={handleSave} />
    </div>
  );
}

export default AddExam;
