import React from "react";
import Login from "../../pages/Login/index";
import Home from "../../pages/Home/index";
import PageNotFound from "../../pages/PageNotFound";
import ResetPassword from "../../pages/Login/ResetPassword/index";
import ForgotPassword from "../../pages/Login/ForgotPassword/index";
const PublicRoutes = [
  {
    path: "/login",
    Component: <Login />,
  },
  {
    path: "/home",
    Component: <Home />,
  },
  {
    path: "/forgotpassword",
    Component: <ForgotPassword />,
  },
  {
    path: "/resetpassword/:token",
    Component: <ResetPassword />,
  },
  {
    path: "*",
    Component: <PageNotFound />,
    // permission: ["course view"],
  },
];

export default PublicRoutes;
