import { Typography } from 'antd';
import React from 'react';
function ATitle({ content, ...others }) {
  const { Title } = Typography;
  return (
    <Title {...others}>
      {content}
    </Title>
  );
}

export default ATitle;
