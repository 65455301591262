const actions = {
  FETCH_SUPERADMINS: "FETCH_SUPERADMINS",
  FETCH_SUPERADMINS_SUCCESS: "FETCH_SUPERADMINS_SUCCESS",
  SAVE_SUPERADMIN: "SAVE_SUPERADMIN",
  SAVE_SUPERADMIN_SUCCESS: "SAVE_SUPERADMIN_SUCCESS",
  DELETE_SUPERADMIN: "DELETE_SUPERADMIN",
  DELETE_SUPERADMIN_SUCCESS: "DELETE_SUPERADMIN_SUCCESS",

  fetchSuperAdmins: (resolve, reject) => ({
    type: actions.FETCH_SUPERADMINS,
    resolve,
    reject,
  }),

  saveSuperAdmin: (data, event, resolve, reject) => ({
    type: actions.SAVE_SUPERADMIN,
    data,
    event,
    resolve,
    reject,
  }),
  deleteSuperAdmin: (id, resolve, reject) => ({
    type: actions.DELETE_SUPERADMIN,
    id,
    resolve,
    reject,
  }),
};

export default actions;
