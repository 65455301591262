const actions = {
  FETCH_COURSES: "FETCH_COURSES",
  FETCH_COURSES_SUCCESS: "FETCH_COURSES_SUCCESS",
  SAVE_COURSES: "SAVE_COURSES",
  SAVE_COURSES_SUCCESS: "SAVE_COURSES_SUCCESS",
  EDIT_COURSES: "EDIT_COURSES",
  EDIT_COURSES_SUCCESS: "EDIT_COURSES_SUCCESS",
  DELETE_COURSES: "DELETE_COURSES",
  DELETE_COURSES_SUCCESS: "DELETE_COURSES_SUCCESS",
  FETCH_COURSES_BY_INSTRUCTOR: "FETCH_COURSES_BY_INSTRUCTOR",
  FETCH_COURSES_BY_INSTRUCTOR_SUCCESS: "FETCH_COURSES_BY_INSTRUCTOR_SUCCESS",
  FETCH_COURSES_BY_BATCH: "FETCH_COURSES_BY_BATCH",
  FETCH_COURSES_BY_BATCH_SUCCESS: "FETCH_COURSES_BY_BATCH_SUCCESS",
  FETCH_COURSES_BY_STUDENT: "FETCH_COURSES_BY_STUDENT",
  FETCH_COURSES_BY_STUDENT_SUCCESS: "FETCH_COURSES_BY_STUDENT_SUCCESS",
  FETCH_NOTES: "FETCH_NOTES",
  FETCH_NOTES_SUCCESS: "FETCH_NOTES_SUCCESS",
  SAVE_NOTES: "SAVE_NOTES",
  SAVE_NOTES_SUCCESS: "SAVE_NOTES_SUCCESS",
  DELETE_NOTES: "DELETE_NOTES",
  DELETE_NOTES_SUCCESS: "DELETE_NOTES_SUCCESS",

  fetchCourses: (resolve, reject) => ({
    type: actions.FETCH_COURSES,
    resolve,
    reject,
  }),

  saveCourses: (data, event, resolve, reject) => ({
    type: actions.SAVE_COURSES,
    data,
    event,
    resolve,
    reject,
  }),

  editCourses: (courseId, data, event, resolve, reject) => ({
    type: actions.EDIT_COURSES,
    courseId,
    data,
    event,
    resolve,
    reject,
  }),
  deleteCourses: (courseId, resolve, reject) => ({
    type: actions.DELETE_COURSES,
    courseId: courseId,
    resolve: resolve,
    reject: reject,
  }),
  fetchCoursesByInstructor: (resolve, reject) => ({
    type: actions.FETCH_COURSES_BY_INSTRUCTOR,
    resolve,
    reject,
  }),
  fetchCoursesByBatch: (batchId, resolve, reject) => ({
    type: actions.FETCH_COURSES_BY_BATCH,
    batchId: batchId,
    resolve,
    reject,
  }),
  fetchCoursesByStudent: (resolve, reject) => ({
    type: actions.FETCH_COURSES_BY_STUDENT,
    resolve,
    reject,
  }),
  fetchNotes: (courseId, resolve, reject) => ({
    type: actions.FETCH_NOTES,
    courseId,
    resolve,
    reject,
  }),
  saveNotes: (data, resolve, reject) => ({
    type: actions.SAVE_NOTES,
    data,
    resolve,
    reject,
  }),
  deleteNotes: (noteId, resolve, reject) => ({
    type: actions.DELETE_NOTES,
    noteId: noteId,
    resolve: resolve,
    reject: reject,
  }),
};

export default actions;
