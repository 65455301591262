const actions = {
    FETCH_ASSIGN_TIMESLOTS: "FETCH_ASSIGN_TIMESLOTS",
    FETCH_ASSIGN_TIMESLOTS_SUCCESS: "FETCH_ASSIGN_TIMESLOTS_SUCCESS",
    SAVE_ASSIGN_TIMESLOT: "SAVE_ASSIGN_TIMESLOT",
    SAVE_ASSIGN_TIMESLOT_SUCCESS: "SAVE_ASSIGN_TIMESLOT_SUCCESS",
  
    fetchAssignTimeSlots: (resolve, reject) => ({
      type: actions.FETCH_ASSIGN_TIMESLOTS,
      resolve,
      reject,
    }),
  
    saveAssignTimeSlot: (data, resolve, reject) => ({
      type: actions.SAVE_ASSIGN_TIMESLOT,
      data,
      resolve,
      reject,
    }),  
  };
  
  export default actions;
  