import React, { useRef, useState } from "react";
import { Form, Row, Col, message } from "antd";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import AInput from "../../../Components/Input";
import AButton from "../../../Components/Button/index";
import ButtonLoader from "../../../Components/ButtonLoader/ButtonLoader";
import ATitle from "../../../Components/Title";
import logo from "../../../assets/images/Logo.png";

const ForgotPassword = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  async function handleSubmit(values) {
    setLoading(true);
    axios
      .post(process.env.REACT_APP_API_BASE_URL+"/forgotpassword", { email: values.email })
      .then((response) => {
        setLoading(false);
        message.success("Email send successfully, please check your email");
        navigate("/login");
      })
      .catch((error) => {
        setLoading(false);
        if (error.response.data.errors) {
          message.error(error.response.data.errors);
        }
      })
      .finally(() => {
        // Code to execute regardless of success or failure
      });
  }

  return (
    <Row
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "100vh",
      }}
    >
      <Col
        md={10}
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          flexDirection: "column",
          border: "2px solid black",
          borderRadius: "15px",
        }}
      >
        <img src={logo} alt="logo" width={170} height={170} />
        <Form
          name="basic"
          autoComplete="off"
          layout="vertical"
          onFinish={handleSubmit}
        >
          <ATitle
            content="Send Reset Link"
            level={5}
            style={{ fontSize: "32px", fontWeight: 600 }}
          />
          <Form.Item
            label="Email"
            name="email"
            rules={[{ required: true, message: "Please input your email!" }]}
          >
            <AInput bordered={true} placeholder="Email Address" size="large" />
          </Form.Item>
          <div 
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems:"center",
            gap: "15px",           
          }}
          >
            <Form.Item>
              <AButton
                fullwidth={false}
                type="primary"
                htmlType="submit"
                size="large"
                loading= {loading}
              >Reset Password
              </AButton>
            </Form.Item>
            <Form.Item>
              <Link to="/login">
                <AButton
                  fullwidth={false}
                  type="primary"
                  htmlType="cancel"
                  size="large"
                >
                  Cancel
                </AButton>
              </Link>
            </Form.Item>
          </div>
        </Form>
      </Col>
    </Row>
  );
};

export default ForgotPassword;
