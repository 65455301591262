import { takeLatest, put, call, all } from "redux-saga/effects";
import Api from "../../../services/api";
import actions from "./actions";

const api = new Api();

const fetchevaluationCriteria = takeLatest(
  actions.FETCH_EVALUATION_CRITERIAS,
  function* (action) {
    try {
      const response = yield call(api.get, "api/evaluationCriteria");
      yield put({
        type: actions.FETCH_EVALUATION_CRITERIAS_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, "success");
    } catch (error) {
      yield call(action.reject, "failed");
    }
  }
);

const addevaluationCriterias = takeLatest(
  actions.SAVE_EVALUATION_CRITERIA,
  function* (action) {
    try {
      const response = yield call(
        api.post,
        "api/evaluationCriteria",
        action.data,
        null
      );
      action.data.id = response.id;
      yield put({
        type: actions.SAVE_EVALUATION_CRITERIA_SUCCESS,
        payload: response,
      });

      yield call(action.resolve, "success");
    } catch (error) {
      yield call(action.reject, "failed");
    }
  }
);

const editevaluationCriterias = takeLatest(
  actions.EDIT_EVALUATION_CRITERIA,
  function* (action) {
    try {
      const response = yield call(
        api.put,
        `api/evaluationCriteria/${action.evaluationCriteriaId}`,
        action.data,
        null
      );
      yield call(action.resolve, "success");
      yield put({
        type: actions.EDIT_EVALUATION_CRITERIA_SUCCESS,
        payload: response,
      });
    } catch (error) {
      yield call(action.reject, "failed");
    }
  }
);

const deleteevaluationCriterias = takeLatest(
  actions.DELETE_EVALUATION_CRITERIA,
  function* (action) {
    try {
      const response = yield call(
        api.delete,
        `api/evaluationCriteria/${action.evaluationCriteriaId}`
      );
      yield put({
        type: actions.FETCH_EVALUATION_CRITERIAS_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, "success");
    } catch (error) {
      yield call(action.reject, "failed");
    }
  }
);

const fetchEvaluationByType = takeLatest(
  actions.FETCH_EVALUATION_BY_TYPE,
  function* (action) {
    try {
      const response = yield call(
        api.get,
        `api/evaluation?evaluatoinCriteria=${action.evaluationCriteriaType}&course=${action.courseId}`
      );
      yield put({
        type: actions.FETCH_EVALUATION_BY_TYPE_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, "success");
    } catch (error) {
      yield call(action.reject, "failed");
    }
  }
);
const saveEvaluation = takeLatest(actions.SAVE_EVALUATION, function* (action) {
  try {
    const response = yield call(
      api.post,
      `api/evaluation?evaluatoinCriteria=${action.evaluationId}&course=${action.courseId}`,
      action.data,
      null
    );
    action.data.id = response.id;
    yield put({
      type: actions.SAVE_EVALUATION_SUCCESS,
      payload: response,
    });

    yield call(action.resolve, "success");
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

const updateEvaluation = takeLatest(
  actions.EDIT_EVALUATION,
  function* (action) {
    try {
      const response = yield call(
        api.put,
        `api/evaluation/${action.id}?evaluatoinCriteria=${action.evaluationId}&course=${action.courseId}`,
        action.data,
        null
      );
      action.data.id = response.id;
      yield put({
        type: actions.EDIT_EVALUATION_SUCCESS,
        payload: response,
      });

      yield call(action.resolve, "success");
    } catch (error) {
      yield call(action.reject, "failed");
    }
  }
);

const deleteEvaluation = takeLatest(
  actions.DELETE_EVALUATION,
  function* (action) {
    try {
      const response = yield call(
        api.delete,
        `api/evaluation/${action.id}?evaluatoinCriteria=${action.evaluationId}&course=${action.courseId}`
      );
      yield put({
        type: actions.DELETE_EVALUATION_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, "success");
    } catch (error) {
      yield call(action.reject, "failed");
    }
  }
);

const fetchEvaluationByStudent = takeLatest(
  actions.FETCH_EVALUATION_BY_STUDENT,
  function* (action) {
    try {
      const response = yield call(
        api.get,
        `api/evaluationByStudent?evaluatoinCriteria=${action.evaluationCriteriaType}&course=${action.courseId}`
      );
      yield put({
        type: actions.FETCH_EVALUATION_BY_STUDENT_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, "success");
    } catch (error) {
      yield call(action.reject, "failed");
    }
  }
);
// /api/evaluationAnswer/submit

const submitEvaluationByStudent = takeLatest(
  actions.SUBMIT_EVALUATION_BY_STUDENT,
  function* (action) {
    try {
      const response = yield call(
        api.post,
        `/api/evaluationAnswer/submit?evaluatoinCriteria=${action.evaluationCriteriaType}&course=${action.courseId}`,
        action.data,
        null
      );
      action.data.id = response.id;
      yield put({
        type: actions.SUBMIT_EVALUATION_BY_STUDENT_SUCCESS,
        payload: response,
      });

      yield call(action.resolve, "success");
    } catch (error) {
      yield call(action.reject, "failed");
    }
  }
);

const updateEvaluationAnswerMarks = takeLatest(
  actions.UPDATE_EVALUATION_ANSWER_MARKS,
  function* (action) {
    try {
      const response = yield call(
        api.put,
        `api/evaluationAnswer/updatemarks/${action.evaluationAnswerID}?evaluatoinCriteria=${action.evaluationCriteriaType}&course=${action.courseId}`,
        action.data,
        null
      );
      action.data.id = response.id;
      yield put({
        type: actions.UPDATE_EVALUATION_ANSWER_MARKS_SUCCESS,
        payload: response,
      });

      yield call(action.resolve, "success");
    } catch (error) {
      yield call(action.reject, "failed");
    }
  }
);

const updateEvaluationByStudent = takeLatest(
  actions.UPDATE_EVALUATION_BY_STUDENT,
  function* (action) {
    try {
      const response = yield call(
        api.put,
        `/api/evaluationAnswer/${action.evaluationAnswerId}?evaluatoinCriteria=${action.evaluationCriteriaType}&course=${action.courseId}`,
        action.data,
        null
      );
      action.data.id = response.id;
      yield put({
        type: actions.UPDATE_EVALUATION_BY_STUDENT_SUCCESS,
        payload: response,
      });

      yield call(action.resolve, "success");
    } catch (error) {
      yield call(action.reject, "failed");
    }
  }
);
export default function* saga() {
  yield all([
    fetchevaluationCriteria,
    addevaluationCriterias,
    deleteevaluationCriterias,
    editevaluationCriterias,
    fetchEvaluationByType,
    saveEvaluation,
    updateEvaluation,
    deleteEvaluation,
    fetchEvaluationByStudent,
    submitEvaluationByStudent,
    updateEvaluationAnswerMarks,
    updateEvaluationByStudent,
  ]);
}
