import React, { useEffect, useState } from "react";
import {
  getEvaluationID,
  getEvaluationName,
  hasPermission,
} from "../../../../utils/Helper";
import InstuctorDetails from "./components/InstructorDetails";
import ChildDetails from "./components/ChildDetails";
import PageNotFound from "../../../PageNotFound";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import actions from "../../store/actions";
import Loader from "../../../../Components/Loader";

const EvaluationDetails = () => {
  const [loading, setLoading] = useState();

  const location = useLocation();
  const evaluationId = getEvaluationID(location);
  const evaluationName = getEvaluationName(location);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { recordId } = useParams();
  const courseId = localStorage.getItem("selectedCourse");

  const evaluationForInstructor = useSelector(
    ({ evaluationCriteriaReducer }) => {
      const evaluations = evaluationCriteriaReducer.get("evaluations");
      return (
        evaluations &&
        evaluations.find((evaluation) => {
          return evaluation.id === parseInt(recordId);
        })
      );
    }
  );

  const evaluationForChild = useSelector(({ evaluationCriteriaReducer }) => {
    const evaluations = evaluationCriteriaReducer.get("evaluationsByStudent");
    return (
      evaluations &&
      evaluations.find((evaluation) => {
        return evaluation.id === parseInt(recordId);
      })
    );
  });

  // useEffect(() => {
  //   courseId &&
  //     (hasPermission("view_course_as_instructor")
  //       ? new Promise((resolve, reject) => {
  //           dispatch(
  //             actions.fetchEvaluationByType(
  //               evaluationId,
  //               courseId,
  //               resolve,
  //               reject
  //             )
  //           );
  //         })
  //       : hasPermission("view_course_as_student")
  //       ? new Promise((resolve, reject) => {
  //           dispatch(
  //             actions.fetchEvaluationByStudent(
  //               evaluationId,
  //               courseId,
  //               resolve,
  //               reject
  //             )
  //           );
  //         })
  //       : "");
  // }, [dispatch]);
  useEffect(() => {
    if (courseId) {
       if (hasPermission("view_course_as_instructor")) {
         dispatch(actions.fetchEvaluationByType(evaluationId, courseId));
       } else if (hasPermission("view_course_as_student")) {
         dispatch(actions.fetchEvaluationByStudent(evaluationId, courseId));
       }
    }
   }, [dispatch, courseId, evaluationId]);

   
  if (evaluationForInstructor || evaluationForChild) {
    return hasPermission("view_course_as_instructor") ? (
      <InstuctorDetails evaluation={evaluationForInstructor} />
    ) : hasPermission("view_course_as_student") ? (
      <ChildDetails evaluation={evaluationForChild} />
    ) : (
      <PageNotFound />
    );
  } else {
    return <Loader />;
  }
};
export default EvaluationDetails;
