const actions = {
  FETCH_BATCH_SESSION: "FETCH_BATCH_SESSION",
  FETCH_BATCH_SESSION_SUCCESS: "FETCH_BATCH_SESSION_SUCCESS",
  FETCH_BATCH_INFO: "FETCH_BATCH_INFO",
  FETCH_BATCH_INFO_SUCCESS: "FETCH_BATCH_INFO_SUCCESS",
  UPDATE_BATCH: "UPDATE_BATCH",
  UPDATE_BATCH_SUCCESS: "UPDATE_BATCH_SUCCESS",
  FETCH_BATCH_DETAILS: "FETCH_BATCH_DETAILS",
  FETCH_BATCH_DETAILS_SUCCESS: "FETCH_BATCH_DETAILS_SUCCESS",
  END_BATCH: "END_BATCH",
  END_BATCH_SUCCESS: "END_BATCH_SUCCESS",

  fetchBatchSessions: (isActive, resolve, reject) => ({
    type: actions.FETCH_BATCH_SESSION,
    isActive,
    resolve,
    reject,
  }),
  fetchBatchInfo: (batchId, resolve, reject) => ({
    type: actions.FETCH_BATCH_INFO,
    batchId,
    resolve,
    reject,
  }),
  updateBatch: (data, event, resolve, reject) => ({
    type: actions.UPDATE_BATCH,
    data,
    event,
    resolve,
    reject,
  }),
  fetchBatchDetails: (id, resolve, reject) => ({
    type: actions.FETCH_BATCH_DETAILS,
    id,
    resolve,
    reject,
  }),
  endBatch: (batchId, resolve, reject) => ({
    type: actions.END_BATCH,
    batchId,
    resolve,
    reject,
  }),
};

export default actions;
