import { Col, Form, Row, Select, Space, Divider, InputNumber } from "antd";
import AInput from "../../../Components/Input/index";
import AButton from "../../../Components/Button/index";
import { stateName, tokens } from "../../../utils/Constants";

import ASelect from "../../../Components/Select/index";
import { useState, useEffect } from "react";
import QuillEditor from "../../../Components/Editor/index";
import React from "react";
import { useNavigate } from "react-router";
import { DeleteIcon } from "../../../assets/icons/DeleteIcon";
import { EditIcon } from "../../../assets/icons/EditIcon";
import { validateContactNumber, validateEmail } from "../../../utils/Helper";
import batchActions from "../../Batch/store/actions";
import { useDispatch, useSelector } from "react-redux";
import coruseActions from "../../Course/store/actions";

const AddEditEvaluationCriteria = ({
  handleSave,
  evaluationCriteria,
  handleDelete,
  saving = false,
  deleting = false,
  isStudent = true,
}) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [mediaUrl, setMediaUrl] = useState("");
  const [coursePhotoUrl, setCoursePhotoUrl] = useState(null);
  const [isLoading, setLoading] = useState(false);

  const [isHovered, setIsHovered] = useState(false);

  const [editorContent, setEditorContent] = useState(
    evaluationCriteria ? evaluationCriteria.description : ""
  );
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedTempCountry, setSelectedTempCountry] = useState("");
  const [batch, setBatch] = useState();

  const dispatch = useDispatch();

  const handleEditorChange = (content) => {
    setEditorContent(content);
  };

  const handleCountryChange = (value) => {
    setSelectedCountry(value);
  };
  const handleTempCountryChange = (value) => {
    setSelectedTempCountry(value);
  };
  let batches = useSelector(({ batchesReducer }) =>
    batchesReducer.get("batches")
  );

  const handleBatchChange = (value) => {
    setBatch(value);
  };

  let courses = useSelector(({ coursesReducer }) =>
    coursesReducer.get("coursesByBatch")
  );

  useEffect(() => {
    setLoading(true);
    new Promise((resolve, reject) => {
      dispatch(batchActions.fetchBatches(resolve, reject));
    }).finally(() => {
      setLoading(false);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    setLoading(true);
    batch &&
      new Promise((resolve, reject) => {
        dispatch(coruseActions.fetchCoursesByBatch(batch, resolve, reject));
      }).finally(() => {
        setLoading(false);
      });
  }, [batch, evaluationCriteria]);
  useEffect(() => {
    if (evaluationCriteria) {
      setBatch(evaluationCriteria.batch);
    }
  });

  useEffect(() => {
    if (evaluationCriteria && evaluationCriteria.courses) {
      form.setFieldsValue({
        courses: evaluationCriteria.courses.map((course) => course.id),
      });
    }
  }, [evaluationCriteria, form]);

  return (
    <div style={{ padding: "1%" }}>
      <Form
        size="large"
        form={form}
        name="add/editForm"
        onFinish={(values) => {
          //   values.description = editorContent;
          handleSave(values);
        }}
        layout="vertical"
        scrollToFirstError
        title="Add/EditevaluationCriteria"
        initialValues={
          evaluationCriteria
            ? {
                name: evaluationCriteria.name,
                total_points: evaluationCriteria.total_points,
                minimum_number: evaluationCriteria.minimum_number,
                batch: evaluationCriteria ? evaluationCriteria.batch : null,
                batch: evaluationCriteria ? evaluationCriteria.batch : [],
              }
            : {}
        }
      >
        <Row gutter={20}>
          <Col span={8}>
            <Form.Item
              name="name"
              label={
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "19.6px",
                  }}
                >
                  Evaluation Criteria Name
                </p>
              }
              rules={[
                {
                  required: true,
                  message: "Please input the Evaluation Criteria!",
                },
              ]}
            >
              <AInput placeholder={"Enter Name"} size="large" />
            </Form.Item>
          </Col>

          <Col span={8}>
            <Form.Item
              name="batch"
              label={
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "19.6px",
                  }}
                >
                  Batch
                </p>
              }
              rules={[
                {
                  required: true,
                  message: "Please select at least one batch",
                },
              ]}
            >
              <ASelect
                customized={false}
                placeholder="Choose Batch"
                onChange={handleBatchChange}
              >
                {batches &&
                  batches.map((element) => (
                    <Select.Option value={element.id}>
                      {element.name}
                    </Select.Option>
                  ))}
              </ASelect>
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="courses"
              label={
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "19.6px",
                  }}
                >
                  Courses
                </p>
              }
              rules={[
                {
                  required: true,
                  message: "Please select at least one course",
                },
              ]}
            >
              <ASelect
                customized={false}
                placeholder="Choose Course"
                mode="multiple"
                // onChange={handleBatchChange}
              >
                {courses &&
                  courses.map((element) => (
                    <Select.Option value={element.id}>
                      {element.name}
                    </Select.Option>
                  ))}
              </ASelect>
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={20}>
          <Col span={8}>
            <Form.Item
              name="total_points"
              label={
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "19.6px",
                  }}
                >
                  Total Points
                </p>
              }
              rules={[
                {
                  required: true,
                  message: "Please input the total points!",
                },
                {
                  pattern: new RegExp("^[0-9]+$"),
                  message: "Please enter numbers",
                },
              ]}
            >
              <AInput placeholder={"Enter Total Points"} size="large" />
            </Form.Item>
          </Col>
          <Col span={8}>
            <Form.Item
              name="minimum_number"
              label={
                <p
                  style={{
                    fontSize: "14px",
                    fontWeight: "600",
                    lineHeight: "19.6px",
                  }}
                >
                  Minimun Number
                </p>
              }
              rules={[
                {
                  required: true,
                  message: "Please input the minimun number!",
                },
                {
                  pattern: new RegExp("^[0-9]+$"),
                  message: "Please enter positive numbers",
                },
              ]}
            >
              <InputNumber
                placeholder={"Enter Minimun Number"}
                style={{ width: "96%" }}
              />
            </Form.Item>
          </Col>
        </Row>

        {/*buttom*/}
        <Form.Item>
          {evaluationCriteria ? (
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                marginTop: "20%",
              }}
            >
              <AButton
                type="default"
                size="middle"
                style={{
                  color: tokens.COLOR_DANGER,
                  borderColor: tokens.COLOR_DANGER,
                  margin: "10px",
                }}
                icon={<DeleteIcon />}
                onClick={() => handleDelete(evaluationCriteria.id)}
              >
                Delete
              </AButton>
              <AButton
                type="default"
                htmlType="submit"
                size="middle"
                icon={<EditIcon />}
                style={{
                  color: tokens.COLOR_SUCCESS,
                  borderColor: tokens.COLOR_SUCCESS,
                }}
              >
                Update
              </AButton>
            </div>
          ) : (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "20%",
              }}
            >
              <AButton
                type="default"
                size="middle"
                onClick={() => navigate("/evaluationCriteria")}
                style={{
                  backgroundColor: tokens.COLOR_LIGHTBLACK_L50,
                }}
              >
                Cancel
              </AButton>
              <AButton
                type="primary"
                htmlType="submit"
                size="large"
                style={{
                  color: tokens.BG_LIGHT,
                  backgroundColor: tokens.COLOR_LIGHT_ORANGE,
                  padding: "0 30px",
                }}
              >
                Create Evaluation Criteria
              </AButton>
            </div>
          )}
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddEditEvaluationCriteria;
