import React, { useState, useEffect } from "react";
import {
  SettingOutlined,
  HomeOutlined,
  UsergroupAddOutlined,
  CodepenOutlined,
  BankOutlined,
  SafetyCertificateOutlined,
  ProjectOutlined,
  AppstoreOutlined
} from "@ant-design/icons";
import { Divider, Menu } from "antd";
import Sider from "antd/es/layout/Sider";
import cardImage from "../../assets/images/LogoWhite.png";
import { Roles, tokens } from "../../utils/Constants";
import CircleIcon from "../../assets/icons/CircleIcon";
import { Navigate, useNavigate } from "react-router";
import { Link } from "react-router-dom";
import jwt_decode from "jwt-decode";
import { ChatIcon } from "../../assets/icons/ChatIcon";
import { getCollegeName, getFormattedRoleName, hasPermission } from "../../utils/Helper";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../pages/EvaluationCriteria/store/actions";

var keyLabelObject = {};

function getItem(label, key, icon, href, children, type) {
  keyLabelObject[key] = label;
  return {
    key,
    icon,
    href,
    children,
    label,
    type,
  };
}

const Sidebar = () => {
  const [role, setRole] = useState(null);
  const [collapsed, setCollapsed] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    // Fetch the role from localStorage and update the state
    const token = localStorage.getItem("token");
    if (!token) {
      return <Navigate to="/login" replace />;
    }
    const decodedToken = jwt_decode(token || "");
    setRole(decodedToken.rolename[0]);
  }, []);

  let evaluationCriteria = useSelector(({ evaluationCriteriaReducer }) =>
    evaluationCriteriaReducer.get("evaluationCriterias")
  );
  useEffect(() => {
    new Promise((resolve, reject) => {
      dispatch(actions.fetchevaluationCriterias(resolve, reject));
    }).finally(() => { });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  //sidebar content
  let items = [
    hasPermission("view_dashboard_overview") &&
    getItem("Dashboards", "1", <HomeOutlined />, "/dashboard"),
    (hasPermission("view_college") || hasPermission("view_program")) &&
    getItem("Manage  College", "15", <BankOutlined />, "/program", [
      hasPermission("view_college") &&
      getFormattedRoleName() === Roles.ROLE_SUPER_ADMIN &&
      getItem(
        "College",
        "16",
        <CircleIcon style={{ width: "12pt" }} />,
        "/college"
      ),
      getFormattedRoleName() !== Roles.ROLE_SUPER_ADMIN &&
      hasPermission("view_program") &&
      getItem(
        "Program",
        "3",
        <CircleIcon style={{ width: "12pt" }} />,
        "/program"
      ),
    ]),


    (hasPermission("view_course") || hasPermission("view_batch") ||
      hasPermission("view_group")) &&
    getItem("Manage  Class", "2", <CodepenOutlined />, "/program", [
      hasPermission("view_course") &&
      getItem(
        "Course",
        "4",
        <CircleIcon style={{ width: "12pt" }} />,
        "/course"
      ),
      hasPermission("view_group") &&
      getItem("Student Batch (Group)", "21",
        <CircleIcon style={{ width: "12pt" }} />,
        "/group"),
      hasPermission("view_assigned_course") &&
      getItem(
        "Assign Course",
        "24",
        <CircleIcon style={{ width: "12pt" }} />,
        "/assignInstructor"
      ),
      hasPermission("view_assign_timeSlot") &&
      getItem(
        "Assign Time Slot",
        "25",
        <CircleIcon style={{ width: "12pt" }} />,
        "/courseTimeSlot"
      ),
    ]),


    (hasPermission("view_exam") || hasPermission("add_exam")) &&
    getItem("Examination", "23", <CodepenOutlined />, "/exam", [
      hasPermission("view_exam") &&
      getItem(
        "Exam",
        "26",
        <CircleIcon style={{ width: "12pt" }} />,
        "/exam"
      ),
      hasPermission("add_exam") &&
      getItem(
        "Add Exam Schedule",
        "28",
        <CircleIcon style={{ width: "12pt" }} />,
        "/exam/schedule"
      ),
      hasPermission("exam_mark") &&
      getItem(
        "Exam Mark",
        "9",
        <CircleIcon style={{ width: "12pt" }} />,
        "/examMark"
      )
    ]),


    hasPermission("chat") && getItem("Chat", "18", <ChatIcon />, "/chat"),
    
    
      (hasPermission("view_super_admin") ||
        hasPermission("view_instructor") ||
        hasPermission("view_student") ||
        hasPermission("view_group")) &&

      getItem("User Management", "19", <UsergroupAddOutlined />, "/", [
        hasPermission("view_super_admin") &&
        getItem(
          "Super Admin",
          "17",
          <CircleIcon style={{ width: "12pt" }} />,
          "/superadmin"
        ),
        getFormattedRoleName() !== Roles.ROLE_SUPER_ADMIN &&
        hasPermission("view_instructor") &&
        getItem(
          "Instructor",
          "6",
          <CircleIcon style={{ width: "12pt" }} />,
          "/instructor"
        ),
        getFormattedRoleName() !== Roles.ROLE_SUPER_ADMIN &&
        (hasPermission("view_student") || hasPermission("view_group")) &&
        getItem("Student & Group", "20", <CircleIcon />, "/program", [
          hasPermission("view_student") &&
          getItem("Student", "7", "", "/student"),
          hasPermission("view_group") && getItem("Group", "21", "", "/group"),
        ]),
      ]),


      (hasPermission("assign_class") || hasPermission("assign_teacher") || hasPermission("view_notice")) &&
      getItem("Administration Panel", "8", <SettingOutlined />, "", [
        getFormattedRoleName() !== Roles.ROLE_SUPER_ADMIN &&
        (hasPermission("add_instructor") || hasPermission("edit_instructor")) &&
        getItem(
          "Assign Teacher",
          "10",
          <CircleIcon style={{ width: "12pt" }} />,
          "/assignInstructor"
        ),
        hasPermission("view_batch") &&
        getItem(
          "Session",
          "22",
          <CircleIcon style={{ width: "12pt" }} />,
          "/session"
        ),
        getFormattedRoleName() !== Roles.ROLE_SUPER_ADMIN &&
        hasPermission("view_time_slot") &&
        getItem(
          "Time Slot",
          "11",
          <CircleIcon style={{ width: "12pt" }} />,
          "/timeSlot"
        ),
        hasPermission("view_program") &&
        getItem(
          "Notice",
          "5",
          <CircleIcon style={{ width: "12pt" }} />,
          "/notice"
        ),
      ]),

      //add permisson to manage session and update here
      getFormattedRoleName() === Roles.ROLE_ADMIN &&
      getItem(
        "Manage Batch",
        "23",
        <AppstoreOutlined />,
        "/manegeBatch"
      ),

      // getFormattedRoleName() !== Roles.ROLE_SUPER_ADMIN &&
      // hasPermission("view_time_slot") &&
      // getItem(
      //   "Time Slot",
      //   "11",
      //   <CircleIcon style={{ width: "12pt" }} />,
      //   "/timeSlot"
      // ),
      // hasPermission("view_program") &&
      // getItem(
      //   "Notice",
      //   "5",
      //   <CircleIcon style={{ width: "12pt" }} />,
      //   "/notice"
      // ),
    
    hasPermission("manage_permission") &&
    getItem(
      "Role and Permissions",
      "12",
      <SafetyCertificateOutlined />,
      "/role"
    ),

    (hasPermission("view_evaluation_criteria_type_on_sidebar") ||
      hasPermission("view_evaluation")) &&
    getItem("Evaluation", "13", <ProjectOutlined />, "", [
      hasPermission("view_evaluation_criteria") &&
      getItem(
        "Evaluation Criteria",
        "14",
        <CircleIcon />,
        "/evaluationCriteria"
      ),
      ...evaluationCriteria.map(
        (criteria) =>
          getFormattedRoleName() !== Roles.ROLE_SUPER_ADMIN &&
          hasPermission("view_evaluation_criteria_type_on_sidebar") &&
          getItem(
            criteria.name,
            criteria.id + 100,
            <CircleIcon />,
            `/evaluation/${criteria.id}/${criteria.name}`
          )
      ),
    ]),
  ];


const onClick = ({ key, item }) => {
  const label = keyLabelObject[key];
  navigate(item.props.href, { state: { label: label } });
};
return (
  <Sider
    trigger={null}
    // collapsible
    // collapsed={collapsed}
    // onCollapse={(value) => setCollapsed(value)}
    style={{
      overflow: "auto",
      height: "100vh",
      position: "fixed",
      left: 0,
      top: 0,
      bottom: 0,
      borderRight: tokens.COLOR_LIGHTBLACK_L50 + " 1px solid",
    }}
  >
    <div style={{
      textAlign: "center",
    }}>
      <Link to="/dashboard">
        <img alt="logo" src={cardImage} width={"200px"} />
      </Link>
      <div
        style={{
          color: 'white',
          fontSize: "1.5em", // Adjust font size
          fontWeight: "bold", // Make it bold
          textAlign: "center", // Center the content

        }}

      >
        {getCollegeName()}
      </div>
      <Divider />
    </div>
    <Menu
      onClick={onClick}
      style={{
        width: 256,
        color: tokens.BG_LIGHT,
      }}
      defaultSelectedKeys={["1"]}
      defaultOpenKeys={["sub1"]}
      mode="inline"
      items={items}
    />
  </Sider>

);
}
export default Sidebar;
