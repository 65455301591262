import { takeLatest, put, call, all } from "redux-saga/effects";
import Api from "../../../services/api";
import actions from "./actions";
import { act } from "react-dom/test-utils";

const api = new Api();

const fetchInstructors = takeLatest(
  actions.FETCH_INSTRUCTORS,
  function* (action) {
    try {
      const response = yield call(api.get, "api/instructor");
      yield put({
        type: actions.FETCH_INSTRUCTORS_SUCCESS,
        payload: response,
      });
      yield call(action.resolve, "success");
    } catch (error) {
      yield call(action.reject, "failed");
    }
  }
);

const addInstructor = takeLatest(actions.SAVE_INSTRUCTOR, function* (action) {
  try {
    const response = yield call(api.post, "api/instructor", action.data, null);
    action.data.id = response.id;
    yield put({
      type: actions.SAVE_INSTRUCTOR_SUCCESS,
      payload: response,
    });

    yield call(action.resolve, "success");
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

const uploadInstructor = takeLatest(actions.UPLOAD_INSTRUCTOR, function* (action) {
  try{
    const response = yield call(api.post, "api/upload-instructors", action.data, null);
    yield put({
      type: actions.UPLOAD_INSTRUCTOR_SUCCESS,
      payload: response,
    });
    yield call(action.resolve, "success");
  }catch (error) {
    yield call(action.reject, "failed");
  }
});

const editInstructor = takeLatest(actions.EDIT_INSTRUCTOR, function* (action) {
  try {
    const response = yield call(
      api.put,
      `api/instructor/${action.userId}`,
      action.data,
      null
    );
    yield call(action.resolve, "success");
    yield put({
      type: actions.EDIT_INSTRUCTOR_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

const deleteInstructor = takeLatest(
  actions.DELETE_INSTRUCTOR,
  function* (action) {
    try {
      const responseId = yield call(
        api.delete,
        `api/instructor/${action.userId}`
      );
      yield put({
        type: actions.DELETE_INSTRUCTOR_SUCCESS,
        id: action.id,
      });

      yield call(action.resolve, responseId);
    } catch (error) {
      yield call(action.reject, "failed");
    }
  }
);

export default function* saga() {
  yield all([
    fetchInstructors,
    addInstructor,
    uploadInstructor,
    editInstructor,
    deleteInstructor,
  ]);
}
