import React from 'react';
import Dashboard from '../../pages/Dashboard/index';
import Program from '../../pages/Program/index';
import AddProgram from '../../pages/Program/AddProgram/index';
import PageNotFound from '../../pages/PageNotFound';
import StudentDetails from '../../pages/Students';
import Course from '../../pages/Course/index.';
import AddCourse from '../../pages/Course/AddCourse';
import EditCourse from '../../pages/Course/EditCourse';
import ViewCourse from '../../pages/Course/ViewCourse';
import AddStudent from '../../pages/Students/AddStudent';
import AddTeacher from '../../pages/Instructor/AddInstructor';
import College from '../../pages/College';
import AddCollege from '../../pages/College/AddCollege';
import EditCollege from '../../pages/College/EditCollege';
import ViewCollege from '../../pages/College/ViewCollege';
import ViewRole from '../../pages/Roles/ViewRole';
import SuperAdmin from '../../pages/Superadmin';
import Roles from '../../pages/Roles';
import AddRole from '../../pages/Roles/AddRole';
import EditRole from '../../pages/Roles/EditRole';
import AddSuperAdmin from '../../pages/Superadmin/add';
import EditProgram from '../../pages/Program/edit';
import ViewProgram from '../../pages/Program/details';
import ViewSemester from '../../pages/Semester/details';
import Instructor from '../../pages/Instructor';
import ViewInstructor from '../../pages/Instructor/Details';
import EditInstructor from '../../pages/Instructor/edit';
import EditStudent from '../../pages/Students/Edit';
import ViewStudent from '../../pages/Students/Details';
import Group from '../../pages/Group';
import AddBatch from '../../pages/Batch/Add';
import EditBatch from '../../pages/Batch/Edit';
import Batch from '../../pages/Batch';
import AddNotice from '../../pages/Notice/AddNotice';
import ViewNotice from '../../pages/Notice/details';
import EditNotice from '../../pages/Notice/edit';
import Notice from '../../pages/Notice';
import ViewBatch from '../../pages/Batch/Details';
import AssignCourse from '../../pages/AssignCourse';
import AddAssignCourse from '../../pages/AssignCourse/Add';
import EditAssignCourse from '../../pages/AssignCourse/Edit';
import EvaluationCriteria from '../../pages/EvaluationCriteria';
import AddEvaluationCriteria from '../../pages/EvaluationCriteria/Add';
import EditEvaluationCriteria from '../../pages/EvaluationCriteria/Edit';
import Evaluation from '../../pages/EvaluationCriteria/Evaluation';
import AddEvaluation from '../../pages/EvaluationCriteria/Evaluation/add';
import EditEvaluation from '../../pages/EvaluationCriteria/Evaluation/edit';
import EvaluationDetails from '../../pages/EvaluationCriteria/Evaluation/details';
import TimeSlot from '../../pages/TimeSlot';
import AssignTimeSlot from '../../pages/AssignTimeSlot';
import Exam from '../../pages/Exam';
import ExamMark from '../../pages/ExamMark';
import AddExam from '../../pages/Exam/AddExam';
import ExamSchedule from '../../pages/Exam/schedule';
import GroupDetails from "../../pages/Group/Details";
import ManageBatch from "../../pages/ManageBatch";
import ManageBatchRelation from "../../pages/Group/ManageGroup";

const RestrictedRoutes = [
  // {
  //   path: "/dashboard",
  //   Component: <Dashboard />,
  //   permission: ["view_dashboard_overview"],
  // },
  {
    path: '/program/add',
    Component: <AddProgram />,
    permission: ['add_program'],
  },
  {
    path: '/program/details/:id',
    Component: <ViewProgram />,
    permission: ['view_program'],
  },
  {
    path: '/program/edit/:id',
    Component: <EditProgram />,
    permission: ['edit_program'],
  },
  {
    path: '/program',
    Component: <Program />,
    permission: ['view_program'],
  },
  {
    path: '/student/edit/:id',
    Component: <EditStudent />,
    permission: ['edit_student'],
  },
  {
    path: '/student/details/:id',
    Component: <ViewStudent />,
    permission: ['view_student'],
  },
  {
    path: '/student/add',
    Component: <AddStudent />,
    permission: ['add_student'],
  },
  {
    path: '/student',
    Component: <StudentDetails />,
    permission: ['view_student'],
  },
  {
    path: '/instructor/edit/:id',
    Component: <EditInstructor />,
    permission: ['edit_instructor'],
  },
  {
    path: '/instructor/details/:id',
    Component: <ViewInstructor />,
    permission: ['view_instructor'],
  },
  {
    path: '/instructor/add',
    Component: <AddTeacher />,
    permission: ['add_instructor'],
  },
  {
    path: '/instructor',
    Component: <Instructor />,
    permission: ['view_instructor'],
  },
  {
    path: '/semester/details/:id',
    Component: <ViewSemester />,
    permission: ['view_semester'],
  },
  {
    path: '/course',
    Component: <Course />,
    permission: ['view_course', 'view_course_as_instructor'],
  },
  {
    path: '/course/add',
    Component: <AddCourse />,
    permission: ['add_course'],
  },
  {
    path: '/course/details/:id',
    Component: <ViewCourse />,
    permission: ['view_course', 'view_course_as_instructor'],
  },
  {
    path: '/course/edit/:id',
    Component: <EditCourse />,
    permission: ['edit_course'],
  },
  {
    path: '/college/view/:id',
    Component: <ViewCollege />,
    permission: ['view_college'],
  },
  {
    path: '/college/edit/:id',
    Component: <EditCollege />,
    permission: ['edit_college'],
  },
  {
    path: '/college/add',
    Component: <AddCollege />,
    permission: ['add_college'],
  },
  {
    path: '/college',
    Component: <College />,
    permission: ['view_college'],
  },
  {
    path: '/superadmin/add',
    Component: <AddSuperAdmin />,
    permission: ['add_super_admin'],
  },
  {
    path: '/superadmin',
    Component: <SuperAdmin />,
    permission: ['view_super_admin'],
  },
  {
    path: '/group',
    Component: <Group />,
    permission: ['view_group'],
  },
  {
    path: '/session/details/:id',
    Component: <ViewBatch />,
    permission: ['view_batch'],
  },
  {
    path: '/session/add',
    Component: <AddBatch />,
    permission: ['add_batch'],
  },
  {
    path: '/session/edit/:id',
    Component: <EditBatch />,
    permission: ['edit_batch'],
  },
  {
    path: '/session',
    Component: <Batch />,
    permission: ['view_batch'],
  },
  {
    path: '/role',
    Component: <Roles />,
    permission: ['manage_permission'],
  },
  {
    path: '/role/add',
    Component: <AddRole />,
    permission: ['add_role'],
  },
  {
    path: '/role/edit/:id',
    Component: <EditRole />,
    permission: ['edit_role'],
  },
  {
    path: '/role/view/:id',
    Component: <ViewRole />,
    permission: ['manage_permission'],
  },
  {
    path: '/notice/add',
    Component: <AddNotice />,
    permission: ['add_notice'],
  },
  {
    path: '/notice/details/:id',
    Component: <ViewNotice />,
    permission: ['view_notice'],
  },
  {
    path: '/notice/edit/:id',
    Component: <EditNotice />,
    permission: ['edit_notice'],
  },
  {
    path: '/notice',
    Component: <Notice />,
    permission: ['view_notice'],
  },
  {
    path: '/assignInstructor',
    Component: <AssignCourse />,
    permission: ['view_assigned_course'],
  },
  {
    path: '/assignInstructor/add',
    Component: <AddAssignCourse />,
    permission: ['assign_course_to_instructor'],
  },
  {
    path: '/assignInstructor/edit/:id',
    Component: <EditAssignCourse />,
    permission: ['change_assign_course'],
  },
  {
    path: '/evaluationCriteria/add',
    Component: <AddEvaluationCriteria />,
    permission: ['add_evaluation_criteria'],
  },
  {
    path: '/evaluationCriteria/edit/:id',
    Component: <EditEvaluationCriteria />,
    permission: ['edit_evaluation_criteria'],
  },
  {
    path: '/evaluationCriteria',
    Component: <EvaluationCriteria />,
    permission: ['view_evaluation_criteria'],
  },
  {
    path: '/evaluation/add/:id/:name',
    Component: <AddEvaluation />,
    permission: ['add_evaluation'],
  },
  {
    path: '/evaluation/edit/:recordId/:id/:name',
    Component: <EditEvaluation />,
    permission: ['add_evaluation'],
  },
  {
    path: '/evaluation/details/:recordId/:id/:name',
    Component: <EvaluationDetails />,
    permission: ['view_evaluation'],
  },
  {
    path: '/evaluation/:id/:name',
    Component: <Evaluation />,
    permission: ['view_evaluation'],
  },
  {
    path: '/timeSlot',
    Component: <TimeSlot />,
    permission: ['view_time_slot'],
  },
  {
    path: '/courseTimeSlot',
    Component: <AssignTimeSlot />,
    permission: ['view_assign_timeSlot'],
  },
  {
    path: '/exam',
    Component: <Exam />,
    permission: ['view_exam'],
  },
  {
    path: '/exam/add',
    Component: <AddExam />,
    permission: ['add_exam'],
  },
  {
    path: '/exam/schedule/:id',
    Component: <ExamSchedule />,
    permission: ['add_exam'],
  },
  {
    path: '/examMark',
    Component: <ExamMark />,
    permission: ['exam_mark'],
  },
  {
    path: "/manegeBatch",
    Component: <ManageBatch />,
    permission: ["view_group"],
  },
  {
    path: "/groupDetails/:id",
    Component: <GroupDetails />,
    permission: ["view_group"],
  },
  {
    path: "/manageBatchrelation/:id",
    Component: <ManageBatchRelation />,
    permission: ["view_group"],
  },
  {
    path: "*",
    Component: <PageNotFound />,
    permission: ['*'],
  },
  {
    path: '/dashboard',
    Component: <Dashboard />,
    permission: ['view_dashboard_overview'],
  },
];

export default RestrictedRoutes;
