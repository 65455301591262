const actions = {
    FETCH_ROLES: "FETCH_ROLES",
    FETCH_ROLES_SUCCESS: "FETCH_ROLES_SUCCESS",
    SAVE_ROLES: "SAVE_ROLES",
    SAVE_ROLES_SUCCESS: "SAVE_ROLES_SUCCESS",
    EDIT_ROLES: "EDIT_ROLES",
    EDIT_ROLES_SUCCESS: "EDIT_ROLES_SUCCESS",
    DELETE_ROLES: "DELETE_ROLES",
    DELETE_ROLES_SUCCESS: "DELETE_ROLES_SUCCESS",
    FETCH_PERMISSIONS: "FETCH_PERMISSIONS",
    FETCH_PERMISSIONS_SUCCESS: "FETCH_PERMISSIONS_SUCCESS",
    EDIT_PERMISSIONS: "EDIT_PERMISSIONS",
    EDIT_PERMISSIONS_SUCCESS: "EDIT_PERMISSIONS_SUCCESS",
    FETCH_ROLE_PERMISSIONS: "FETCH_ROLE_PERMISSIONS",
    FETCH_ROLE_PERMISSIONS_SUCCESS: "FETCH_ROLE_PERMISSIONS_SUCCESS",
  
    fetchRoles: (resolve, reject) => ({
      type: actions.FETCH_ROLES,
      resolve,
      reject,
    }),
  
    saveRoles: (data, event, resolve, reject) => ({
      type: actions.SAVE_ROLES,
      data,
      event,
      resolve,
      reject,
    }),
  
    editRoles: (roleId, data, event, resolve, reject) => ({
      type: actions.EDIT_ROLES,
      roleId,
      data,
      event,
      resolve,
      reject,
    }),

    editPermissions: (roleId, data, resolve, reject) => ({
      type: actions.EDIT_PERMISSIONS,
      roleId,
      data,
      resolve,
      reject,
    }),

    deleteRoles: (roleId, resolve, reject) => ({
      type: actions.DELETE_ROLES,
      roleId: roleId,
      resolve: resolve,
      reject: reject,
    }),

    fetchPermissions: (resolve, reject) => ({
      type: actions.FETCH_PERMISSIONS,
      resolve,
      reject,
    }),

    fetchRolePermissions: (resolve, reject) => ({
      type: actions.FETCH_ROLE_PERMISSIONS,
      resolve,
      reject,
    }),
  };
  
  export default actions;
  