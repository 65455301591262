import React, { useState } from 'react';
import { useNavigate } from 'react-router';
import actions from '../store/actions';
import { message } from 'antd';
import { useDispatch } from 'react-redux';
import AddEditCourse from '../../../Components/AddEditCourse';

function AddCourse() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [semesterId, setSemesterId] = useState();

  const handleSave = (value) => {
    const data = {
      ...value,
    };

    new Promise((resolve, reject) => {
      dispatch(actions.saveCourses(data, 'ADD', resolve, reject));
    })
      .then(() => {
        message.success('Course added Sucessfully');
        navigate(semesterId ? `/semester/details/${semesterId}` : '/course');
      })
      .catch(() => {
        message.error('Course add failed');
      })
      .finally(() => {
        setSemesterId();
      });
  };
  return (
    <div>
      <AddEditCourse handleSave={handleSave} handlePath={setSemesterId} />
    </div>
  );
}

export default AddCourse;
