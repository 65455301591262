import BatchSessionSaga from "./sagas";
import BatchSessionReducer from "./reducers";
import BatchSessionActions from "./actions";

export const batchSessionReducer = {
  batchSessionReducer: BatchSessionReducer,
};

export const batchSessionSaga = [BatchSessionSaga()];

export { BatchSessionActions };
