import React, { useEffect, useState } from "react";
import ViewTeacherStudent from "../../../Components/ViewInstructorStudent";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import actions from "../store/actions";
import Loader from "../../../Components/Loader";
import { message } from "antd";
function ViewStudent() {
  const data = {
    id: 1,
    firstName: "Yugesh",
    middleName: "",
    lastName: "Marahatta",
    contactNumber: "89749832",
    email: "yugeshmarahatta@gmail.com",
    gender: "Male",
    address1: "Lainchoue",
    city: "Kathmandu",
    state: "Bagmati",
    qualification: "Msc IT",
  };

  const [saving, setSaving] = useState(false);
  const { id } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const student = useSelector(({ studentsReducer }) => {
    const students = studentsReducer.get("students");
    try {
      return students.find((student) => {
        return student.id === parseInt(id);
      });
    } catch (e) {
      navigate("/student");
    }
  });
  useEffect(() => {
    if (!student) {
      new Promise((resolve, reject) => {
        dispatch(actions.fetchStudents(resolve, reject));
      })
        .then((response) => {})
        .finally(() => {});
    }
  }, [student]);

  const handleDelete = (id) => {
    new Promise((resolve, reject) => {
      dispatch(actions.deleteStudent(id, resolve, reject));
    })
      .then(() => {
        navigate("/student");
        message.success("Student delete Success");
      })
      .catch(() => {
        message.error("Student delete fails ");
      })
      .finally(() => {
        // setOpenModel(false);
      });
  };

  const handleEdit = (id) => {
    navigate(`/student/edit/${id}`);
  };
  if (!student) {
    return <Loader />;
  }
  return (
    <ViewTeacherStudent
      data={student}
      isStudent={true}
      handleDelete={handleDelete}
      handleEdit={handleEdit}
    />
  );
}
export default ViewStudent;
