import React from "react";
import { Tag, TagProps, theme } from "antd";
import { StopOutlined } from "@ant-design/icons";
import { ActiveIcon } from "../../assets/icons/ActiveIcon";
import { PendingIcon } from "../../assets/icons/PendingIcon";
import { tokens } from "../../utils/Constants";
const ATag = ({ icon = true, type, size = "medium", children, ...others }) => {
  const {
    token: { colorSuccess, colorError },
  } = theme.useToken();

  const contentStyle = {
    lineHeight: size === "large" ? "37px" : size === "medium" ? "34px" : "30px",
    height: size === "large" ? "37px" : size === "medium" ? "34px" : "30px",
    borderRadius:
      size === "large" ? "18.5px" : size === "medium" ? "17px" : "15px",
    borderColor:
      type === "active"
        ? colorSuccess
        : type === "pending"
        ? tokens.COLOR_SECONDARY
        : type === "primary"
        ? tokens.COLOR_PRIMARY
        : colorError,
    color:
      type === "active"
        ? colorSuccess
        : type === "pending"
        ? tokens.COLOR_SECONDARY
        : type === "primary"
        ? tokens.COLOR_PRIMARY
        : colorError,
  };

  switch (type) {
    case "active":
      return (
        <Tag
          style={{
            ...contentStyle,
          }}
          icon={icon && <ActiveIcon />}
          {...others}
        >
          {children}
        </Tag>
      );

    case "pending":
      return (
        <Tag
          style={{
            ...contentStyle,
          }}
          icon={icon && <PendingIcon />}
          {...others}
        >
          {children}
        </Tag>
      );

    case "reject":
      return (
        <Tag
          style={{
            ...contentStyle,
          }}
          icon={icon && <StopOutlined />}
          {...others}
        >
          {children}
        </Tag>
      );

    case "primary":
      return (
        <Tag
          style={{
            ...contentStyle,
          }}
          {...others}
        >
          {children}
        </Tag>
      );

    default:
      return <Tag {...others}>{children}</Tag>;
  }
};

export default ATag;
