import { takeLatest, put, call, all } from "redux-saga/effects";
import Api from "../../../services/api";
import actions from "./actions";
import { act } from "react-dom/test-utils";

const api = new Api();

const fetchBatches = takeLatest(actions.FETCH_BATCHES, function* (action) {
  try {
    const response = yield call(api.get, "api/batch");
    yield put({
      type: actions.FETCH_BATCHES_SUCCESS,
      payload: response,
    });
    yield call(action.resolve, "success");
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

const fetchBatch = takeLatest(actions.FETCH_BATCH, function* (action) {
  try {
    const response = yield call(api.get, `api/batch/${action.batchId}`);
    yield call(action.resolve, "success");
    yield put({
      type: actions.FETCH_BATCH_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

const addBatches = takeLatest(actions.SAVE_BATCHES, function* (action) {
  try {
    const response = yield call(api.post, "api/batch", action.data, null);
    action.data.id = response.id;
    yield put({
      type: actions.SAVE_BATCHES_SUCCESS,
      payload: response,
    });

    yield call(action.resolve, "success");
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

const editBatches = takeLatest(actions.EDIT_BATCHES, function* (action) {
  try {
    const response = yield call(
      api.put,
      `api/batch/${action.batchId}`,
      action.data,
      null
    );
    yield call(action.resolve, "success");
    yield put({
      type: actions.EDIT_BATCHES_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

const deleteBatches = takeLatest(actions.DELETE_BATCHES, function* (action) {
  try {
    const responseId = yield call(api.delete, `api/batch/${action.batchId}`);
    yield put({
      type: actions.DELETE_BATCHES_SUCCESS,
      id: action.id,
    });

    yield call(action.resolve, responseId);
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

export default function* saga() {
  yield all([fetchBatches, addBatches, editBatches, deleteBatches, fetchBatch]);
}
