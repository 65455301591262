import React, { useState } from "react";
import { useNavigate } from "react-router";
import { useDispatch } from "react-redux";
import actions from "../store/actions";
import { message } from "antd";
import AddEditBatch from "../Components/AddEditBatch";
import Loader from "../../../Components/Loader";
function AddBatch() {
  const dispatch = useDispatch();
  const [saving, setSaving] = useState(false);
  const [updateSuccess, setUpdateSuccess] = useState(false);
  const [addState, setAddState] = useState(1);

  let navigate = useNavigate();

  const transformData = (data) => {
    const transformedData = [];

    for (const programId in data) {
      if (data[programId]) {
        transformedData.push({
          program: programId,
          semesters: data[programId],
        });
      }
    }

    return transformedData;
  };

  const handleSave = (value, batchData) => {
    setSaving(true);

    const transformedValue = transformData(value);

    const data = {
      batchData,
      batchSemesterData: transformedValue,
    };

    new Promise((resolve, reject) => {
      dispatch(actions.saveBatches(data, "ADD", resolve, reject));
    })
      .then(() => {
        message.success("Session added Successfully");
        setUpdateSuccess(true);
        navigate("/session");
      })
      .catch(() => {
        message.error("Session add failed");
      })
      .finally(() => {
        setSaving(false);
        setAddState(2);
      });
  };

  if (saving) {
    return <Loader />;
  }
  return (
    <div>
      <AddEditBatch handleSave={handleSave} />
    </div>
  );
}

export default AddBatch;
