import { Navigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import React from "react";
import PageNotFound from "../../pages/PageNotFound";

const PermittedRoutes = ({ permission, Component }) => {
  //first get token from localstorage
  const token = localStorage.getItem("token");

  if (!token) {
    return <Navigate to="/login" replace />;
  }

  const decodedToken = jwt_decode(token || "");
  const permissionData = (decodedToken) => {
    let permissions = [];
    decodedToken.permissions.forEach((data) => {
      permissions.push(data);
    });
    return permissions;
  };

  const isAuthorized = () => {
    return permissionData(decodedToken).some((element) => {
      return permission.includes(element);
    });
    // return true;
  };

  //new method for suthorization
  // const isAuthorized = () => {
  //   // Retrieve the permission data from localStorage and convert to an array
  //   const storedPermissionsString = localStorage.getItem("permission");
  //   const storedPermissions = storedPermissionsString.split(",");

  //   // Check if any of the stored permissions are present in the user's permissions
  //   return storedPermissions.some((element) => {
  //     return permission.includes(element);
  //   });
  // };

  if (!isAuthorized()) {
    return <PageNotFound />;
  }

  return Component;
};
export default PermittedRoutes;
