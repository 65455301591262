import { takeLatest, put, call, all } from "redux-saga/effects";
import Api from "../../../services/api";
import actions from "./actions";

const api = new Api();

const fetchExams = takeLatest(actions.FETCH_EXAMS, function* (action) {
  try {
    const response = yield call(api.get, "api/exam");
    yield put({
      type: actions.FETCH_EXAMS_SUCCESS,
      payload: response,
    });
    yield call(action.resolve, "success");
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

const fetchExamSubjectByClass = takeLatest(actions.FETCH_EXAM_SUBJECT_BY_CLASS, function* (action) {
  try {
    const response = yield call(api.get, `api/exam-subject/${action.classId}`);
    yield put({
      type: actions.FETCH_EXAMS_SUBJECT_BY_CLASS_SUCCESS,
      payload: response,
    });
    yield call(action.resolve, "success");
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

const addExams = takeLatest(actions.SAVE_EXAMS, function* (action) {
  try {
    const response = yield call(api.post, "api/exam/add", action.data, null);
    action.data.id = response.id;
    yield put({
      type: actions.SAVE_EXAMS_SUCCESS,
      payload: response,
    });

    yield call(action.resolve, "success");
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

const editExams = takeLatest(actions.EDIT_EXAMS, function* (action) {
  try {
    const response = yield call(
      api.put,
      `api/exam/${action.examId}`,
      action.data,
      null
    );
    yield call(action.resolve, "success");
    yield put({
      type: actions.EDIT_EXAMS_SUCCESS,
      payload: response,
    });
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

const deleteExams = takeLatest(actions.DELETE_EXAMS, function* (action) {
  try {
    const responseId = yield call(api.delete, `api/exam/${action.examId}`);
    yield put({
      type: actions.DELETE_EXAMS_SUCCESS,
      id: action.id,
    });

    yield call(action.resolve, responseId);
  } catch (error) {
    yield call(action.reject, "failed");
  }
});

export default function* saga() {
  yield all([
    fetchExams,
    addExams,
    deleteExams,
    editExams,
    fetchExamSubjectByClass
  ]);
}
