import actions from "./actions";
import { fromJS } from "immutable";
import { combineReducers } from "redux";

const initialState = fromJS({
  courses: [],
  notes: [],
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_COURSES_SUCCESS:
      return state.set("courses", action.payload);

    case actions.SAVE_COURSES_SUCCESS:
      return state.set("courses", action.payload);

    case actions.EDIT_COURSES_SUCCESS:
      return state.set("courses", action.payload);

    case actions.DELETE_COURSES_SUCCESS:
      return state.set("courses", action.payload);

    case actions.FETCH_COURSES_BY_INSTRUCTOR_SUCCESS:
      return state.set("coursesByInstructor", action.payload);

    case actions.FETCH_COURSES_BY_BATCH_SUCCESS:
      return state.set("coursesByBatch", action.payload);

    case actions.FETCH_COURSES_BY_STUDENT_SUCCESS:
      return state.set("coursesByStudent", action.payload);

    case actions.FETCH_COURSES_BY_CLASS_SUCCESS:
      return state.set("coursesByClass", action.payload);

    case actions.FETCH_NOTES_SUCCESS:
      return state.set("notes", action.payload);

    case actions.SAVE_NOTES_SUCCESS:
      return state.set("notes", action.payload);

    case actions.DELETE_NOTES_SUCCESS:
      return state.set("notes", action.payload);

    default:
      return state;
  }
};

export default reducer;
