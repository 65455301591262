import React, { useState } from "react";
import AddEditEvaluationCriteria from "../Components/AddEditForm";
import { useDispatch } from "react-redux";
import Loader from "../../../Components/Loader";
import actions from "../store/actions";
import { message } from "antd";
import { useNavigate } from "react-router";
function AddEvaluationCriteria() {
  const [saving, setSaving] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const handleSave = (values) => {
    setSaving(true);
    new Promise((resolve, reject) => {
      dispatch(actions.saveevaluationCriterias(values, "ADD", resolve, reject));
    })
      .then(() => {
        message.success("Evaluation Criteria added Sucessfully");
        navigate("/evaluationCriteria");
      })
      .catch(() => {
        message.error("Evaluation Criteria add failed");
      })
      .finally(() => {
        setSaving(false);
      });
  };
  if (saving) {
    return <Loader />;
  }
  return <AddEditEvaluationCriteria handleSave={handleSave} />;
}
export default AddEvaluationCriteria;
