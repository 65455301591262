import React, { useEffect, useState } from "react";
import {
  SearchOutlined,
  WarningFilled,
  PlusOutlined,
  ClockCircleOutlined,
} from "@ant-design/icons";
import { Space, theme, message } from "antd";
import AInput from "../../Components/Input";
import AButton from "../../Components/Button/index";
import AModal from "../../Components/Model";
import ATitle from "../../Components/Title";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import ATable from "../../Components/Table";
import { tokens } from "../../utils/Constants";
import actions from "./store/actions";

function Roles() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [openModel, setOpenModel] = useState(false);
  const [selectedId, setSelectedId] = useState(0);
  const { useToken } = theme;
  const { token } = useToken();
  const [deleteRole, setDeleteRole] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');

  const handleRoleDelete = (id) => {
    new Promise((resolve, reject) => {
      dispatch(actions.deleteRoles(selectedId, resolve, reject));
    })
      .then(() => {
        setDeleteRole(true);
        message.success("Role delete Success");
      })
      .catch(() => {
        message.error("Role delete fails");
      })
      .finally(() => {
        setOpenModel(false);
      });
  };

  let roles = useSelector(({ rolesReducer }) => rolesReducer.get("rolePermissions"));
  const filteredRoles = roles.filter(role =>
    role.code.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleSearch = (e) => {
    setSearchTerm(e.target.value);
  };

  useEffect(() => {
    new Promise((resolve, reject) => {
      dispatch(actions.fetchRolePermissions(resolve, reject));
    }).finally(() => {});
  }, [dispatch, deleteRole]);

  const columns = [
    {
      title: "S.N.",
      render: (_, __, index) => <span>{index + 1}</span>,
    },
    {
      title: "Role",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Action",
      render: (_, record) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <AButton
            type="primary"
            size="middle"
            style={{
              color: tokens.BG_LIGHT,
              backgroundColor: tokens.COLOR_SUCCESS,
            }}
            onClick={(e) => {
              e.stopPropagation();
              navigate(`/role/edit/${record.id}`);
            }}
          >
            Edit
          </AButton>
          <AButton
            type="default"
            size="middle"
            style={{
              color: tokens.COLOR_DANGER,
              borderColor: tokens.COLOR_DANGER,
              margin: "10px",
            }}
            onClick={(e) => {
              e.stopPropagation();
              setOpenModel(true);
              setSelectedId(record.id);
            }}
          >
            Delete
          </AButton>
        </div>
      ),
    },
  ];
  return (
    <div>
      <Space
        style={{
          width: "100%",
          justifyContent: "flex-end",
          paddingRight: "1em",
        }}
      >
        <AInput
          suffix={<SearchOutlined />}
          placeholder="Search"
          name="search"
          size="large"
          type="primary"
          value={searchTerm}
          onChange={handleSearch}
        />
      </Space>
      <Space
        style={{
          display: "flex",
          justifyContent: "flex-end",
          margin: "1.5em 1em",
        }}
      >
        <Link to={"/role/add"}>
          <AButton
            type="primary"
            icon={<PlusOutlined />}
            size="large"
            style={{ backgroundColor: tokens.COLOR_SUCCESS }}
          >
            Add New Role
          </AButton>
        </Link>
      </Space>
      <ATable
        columns={columns}
        dataSource={filteredRoles}
        onRow={(record, rowIndex) => {
          return {
            onClick: ({ event }) => {
              navigate(`/role/view/${record.id}`);
            }, // click row
            onDoubleClick: (event) => {
              navigate(`/role/view/${record.id}`);
            }, // double click row
          };
        }}
      />
      <AModal
        style={{
          padding: "50px",
        }}
        open={openModel}
        closable={false}
        footer={null}
        className="css-dev-only-do-not-override-1r3vfrt-last"
      >
        <Space
          align="center"
          direction="vertical"
          style={{
            display: "flex",
            alignContent: "center",
          }}
        >
          <Space direction="vertical" size="small" align="center">
            <WarningFilled
              style={{
                fontSize: "65px",
                color: token.colorError,
              }}
            />
            <ATitle content="Are you sure ?" level={4} />
            <p
              style={{
                textAlign: "center",
                width: "100%",
                marginTop: "-5px",
              }}
            >
              Deleting a role will eliminate all permission associations with
              it. This action is irreversible.
            </p>
          </Space>
          <Space>
            <AButton
              type="default"
              size="small"
              onClick={() => setOpenModel(false)}
            >
              Cancel
            </AButton>
            <AButton
              type="primary"
              size="small"
              onClick={() => handleRoleDelete(selectedId)}
              mode="danger"
            >
              Delete
            </AButton>
          </Space>
        </Space>
      </AModal>
    </div>
  );
}

export default Roles;
