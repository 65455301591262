import actions from "./actions";
import { fromJS } from "immutable";
import { combineReducers } from "redux";

const initialState = fromJS({
  colleges: [],
  semesters: [],
});

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actions.FETCH_PROGRAMS_SUCCESS:
      return state.set("programs", action.payload);

    case actions.SAVE_PROGRAM_SUCCESS:
      return state.set("programs", action.payload);

    case actions.EDIT_PROGRAM_SUCCESS:
      return state.set("programs", action.payload);

    case actions.DELETE_PROGRAM_SUCCESS:
      return state.set("programs", action.payload);

    case actions.FETCH_SEMESTERS_SUCCESS:
      return state.set("semesters", action.payload);

    case actions.SAVE_SEMESTER_SUCCESS:
      return state.set("semesters", action.payload);

    case actions.EDIT_SEMESTER_SUCCESS:
      return state.set("semesters", action.payload);

    case actions.DELETE_SEMESTER_SUCCESS:
      return state.set("semesters", action.payload);

    default:
      return state;
  }
};

export default reducer;
