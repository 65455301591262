import React from "react";
import ReactQuill from "react-quill";
import 'react-quill/dist/quill.snow.css';

const QuillEditor = ({ value, onChange, className = "", ...rest }) => {
  const handleEditorChange = (content) => {
    onChange(content);
  };

  return (
    <ReactQuill
      className={`ql-editor ${className}`}
      value={value}
      onChange={handleEditorChange}
      {...rest}
    />
  );
};

export default QuillEditor;
