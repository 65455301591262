import React from "react";
import { Card } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { tokens } from "../../utils/Constants";
import AText from "../Text";
import {
  PushpinOutlined,
  DeleteOutlined,
  EditOutlined,
} from "@ant-design/icons";
import AButton from "../Button/index";
import placeholderImage from "../../assets/images/placeholderimage.webp";

function CourseCard({
  course,
  openPopUp,
  selectItemId,
  showDeleteButton = true,
  showEditButton = true,
}) {
  const navigate = useNavigate();

  const handleEditClick = (e) => {
    e.stopPropagation();
    navigate(`/course/edit/${course.id}`);
  };

  return (
    <Card
      style={{
        width: "100%",
        minWidth:240,
        margin: "10px",
        background: tokens.BG_LIGHT,
        boxShadow: "3px 2px 18px -4px rgba(0,0,0,0.61)",
        border: "1px solid #d9d9d9",
        borderRadius: "5px",
        padding: "10px",
      }}
      cover={
        <Link to={`/course/details/${course.id}`}>
          <img
            src={course && course.imageUrl ? course.imageUrl : placeholderImage}
            alt="card-list"
            style={{
              width: "100%",
              height: "25vh",
              objectFit: "cover",
              borderRadius: "0px",
            }}
          />
        </Link>
      }
    >
      <div
        style={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Link
          to={`/course/details/${course.id}`}
          onMouseOver={(e) => (e.target.style.color = "#ff5722")}
          onMouseOut={(e) => (e.target.style.color = tokens.COLOR_NEUTRAL_N600)}
        >
          <AText
            content={course.name}
            ellipsis
            strong
            style={{
              fontSize: "20px",
              fontWeight: 600,
              color: tokens.COLOR_NEUTRAL_N600,
              marginTop: "10px",
            }}
          />
        </Link>
        <AText
          content={`Course Code: ${course.code}`}
          ellipsis
          style={{
            fontSize: "13px",
            fontWeight: 600,
            color: tokens.COLOR_NEUTRAL_N600,
            margin: "5px 0",
          }}
        />
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <PushpinOutlined style={{ color: tokens.COLOR_NEUTRAL_N600 }} />
          <AText
            content={`Credit Hour: ${course.creditHour} hour`}
            ellipsis
            style={{
              marginLeft: "5px",
              color: tokens.COLOR_NEUTRAL_N600,
              fontSize: "13px",
            }}
          />
        </div>
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          margin: "20px 0 0 0",
        }}
      >
        {showDeleteButton && (
          <AButton
            type="primary"
            size="middle"
            icon={<DeleteOutlined />}
            style={{
              color: tokens.COLOR_DANGER,
              backgroundColor: tokens.BG_LIGHT,
              border: `1px solid ${tokens.COLOR_DANGER}`,
            }}
            onClick={(e) => {
              e.stopPropagation();
              openPopUp(true);
              selectItemId(course.id);
            }}
          >
            Delete
          </AButton>
        )}
        {showEditButton && (
          <AButton
            type="primary"
            icon={<EditOutlined />}
            size="middle"
            style={{
              color: tokens.COLOR_PRIMARY,
              backgroundColor: tokens.BG_LIGHT,
              border: `1px solid ${tokens.COLOR_PRIMARY}`,
            }}
            onClick={handleEditClick}
          >
            Edit
          </AButton>
        )}
      </div>
    </Card>
  );
}

export default CourseCard;
