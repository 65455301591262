const actions = {
  FETCH_BATCHES: "FETCH_BATCHES",
  FETCH_BATCHES_SUCCESS: "FETCH_BATCHES_SUCCESS",
  FETCH_BATCH: "FETCH_BATCH",
  FETCH_BATCH_SUCCESS: "FETCH_BATCH_SUCCESS",
  SAVE_BATCHES: "SAVE_BATCHES",
  SAVE_BATCHES_SUCCESS: "SAVE_BATCHES_SUCCESS",
  EDIT_BATCHES: "EDIT_BATCHES",
  EDIT_BATCHES_SUCCESS: "EDIT_BATCHES_SUCCESS",
  DELETE_BATCHES: "DELETE_BATCHES",
  DELETE_BATCHES_SUCCESS: "DELETE_BATCHES_SUCCESS",

  fetchBatches: (resolve, reject) => ({
    type: actions.FETCH_BATCHES,
    resolve,
    reject,
  }),

  fetchBatch: (batchId, resolve, reject) => ({
    type: actions.FETCH_BATCH,
    batchId,
    resolve,
    reject,
  }),

  saveBatches: (data, event, resolve, reject) => ({
    type: actions.SAVE_BATCHES,
    data,
    event,
    resolve,
    reject,
  }),

  editBatches: (batchId, data, event, resolve, reject) => ({
    type: actions.EDIT_BATCHES,
    batchId,
    data,
    event,
    resolve,
    reject,
  }),
  deleteBatches: (batchId, resolve, reject) => ({
    type: actions.DELETE_BATCHES,
    batchId: batchId,
    resolve: resolve,
    reject: reject,
  }),
};

export default actions;
