import NoticeSaga from "./sagas";
import NoticeReducer from "./reducers";
import noticeActions from "./actions";

export const noticeReducer = {
  noticeReducer: NoticeReducer,
};

export const noticeSaga = [NoticeSaga()];

export { noticeActions };
