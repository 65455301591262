import actions from "./actions";
import { fromJS } from "immutable";
import { combineReducers } from "redux";

const initialState = fromJS({
  semester: [],
});

const reducer = (state = initialState, action) => {
  switch (action.type) {

    case actions.FETCH_SEMESTER_SUCCESS:
      return state.set("semester", action.payload);

    default:
      return state;
  }
};

export default reducer;
