import { message, Card, Row, Col } from "antd";
import React, { useEffect, useState } from "react";
import { tokens, days } from "../../utils/Constants";
import { useDispatch, useSelector } from "react-redux";
import actions from "./store/actions";
import timeSlotActions from "../TimeSlot/store/actions";
import assignCourseActions from "../AssignCourse/store/actions"
import { formatTimeSlots } from "../../utils/Helper";
import AButton from "../../Components/Button";
import ASelect from "../../Components/Select";

function AssignTimeSlot() {
  const dispatch = useDispatch();
  const [editStatus, setEditStatus] = useState([]);
  const [saving, setSaving] = useState(false);
  const [selectedTimeSlots, setSelectedTimeSlots] = useState({});
  const [formattedTimeSlots, setFormattedTimeSlots] = useState();

  let assignTimeSlots = useSelector(({ assignTimeSlotsReducer }) =>
    assignTimeSlotsReducer.get("assignTimeSlots")
  );

  let assignCourses = useSelector(({ assignCoursesReducer }) =>
    assignCoursesReducer.get("assignCourses")
  );

  let timeSlots = useSelector(({ timeSlotReducer }) =>
    timeSlotReducer.get("timeSlots")
  );

  useEffect(() => {
    new Promise((resolve, reject) => {
      dispatch(actions.fetchAssignTimeSlots(resolve, reject));
    });

    new Promise((resolve, reject) => {
      dispatch(timeSlotActions.fetchTimeSlots(resolve, reject));
    });

    new Promise((resolve, reject) => {
      dispatch(assignCourseActions.fetchAssignCourses(resolve, reject));
    });
  }, [dispatch]);

  useEffect(() => {
    if (assignCourses) {
      setEditStatus(Array(assignCourses.length).fill(false));
    }
  }, [assignCourses]);

  useEffect(() => {
    if (timeSlots) {
      setFormattedTimeSlots(formatTimeSlots(timeSlots));
    }
  }, [timeSlots]);

  const handleEdit = (index) => {
    let newEditStatus = [...editStatus];
    newEditStatus[index] = true;
    setEditStatus(newEditStatus);
  }


  const handleSelectChange = (day, selectedOptions, courseId) => {
    setSelectedTimeSlots(prevSelectedTimeSlots => ({
      ...prevSelectedTimeSlots,
      [courseId]: {
        ...prevSelectedTimeSlots[courseId],
        [day]: selectedOptions,
      },
    }));
  };

  const handleSave = (index) => {
    setSaving(true);
    const courseId = assignCourses[index].id;
    const existingAssignTimeSlot = assignTimeSlots && assignTimeSlots.find(timeSlot => timeSlot.courseInstructorId === courseId) || {};
    if (existingAssignTimeSlot && existingAssignTimeSlot.timeSlot) {
      Object.keys(existingAssignTimeSlot.timeSlot).forEach(day => {
        if (typeof existingAssignTimeSlot.timeSlot[day] === 'string') {
          existingAssignTimeSlot.timeSlot[day] = existingAssignTimeSlot.timeSlot[day].split(',').map(Number);
        }
      });
    }

    const newAssignTimeSlot = {
      courseInstructorId: courseId,
      timeSlotIds: {},
    };

    days.forEach(day => {
      newAssignTimeSlot.timeSlotIds[day.value] = selectedTimeSlots[courseId] && selectedTimeSlots[courseId][day.value];
    });

    new Promise((resolve, reject) => {
      dispatch(actions.saveAssignTimeSlot(newAssignTimeSlot, resolve, reject));
    })
      .then(() => {
        message.success("Assign Time Slots Successfully");
      })
      .catch(() => {
        message.error("Assign Time Slot Failed");
      })
      .finally(() => {
        setSaving(false);
        let newEditStatus = [...editStatus];
        newEditStatus[index] = false;
        setEditStatus(newEditStatus);
      });
  };


  useEffect(() => {
    if (timeSlots) {
      setFormattedTimeSlots(formatTimeSlots(timeSlots));
    }
  }, [timeSlots]);

  return (
    <div>
      <h1>Assign Time Slots</h1>
      <div style={{ display: "flex", flexWrap: "wrap", gap: "10px", justifyContent: "left" }}>
        {assignCourses &&
          assignCourses.map((assignCourse, key) => (
            <Card key={key} style={{ width: "40%", marginTop: '16px' }}>
              <h3>{assignCourse.course_name}</h3>
              <p>{assignCourse.instructor_name}</p>
              {days.map((day, index) =>
              (
                <Row key={index} style={{ display: 'flex', justifyContent: 'space-between', border: '1px solid #ccc', marginBottom: '10px', padding: '5px 10px' }}>
                  <Col span={8}>{day.label}</Col>
                  <Col span={16}>
                    <ASelect
                      mode="multiple"
                      style={{ width: '100%' }}
                      placeholder="Please select"
                      options={formattedTimeSlots && formattedTimeSlots.map(timeSlot => ({
                        value: timeSlot.value,
                        label: timeSlot.show,
                      }))}
                      disabled={!editStatus[key]}
                      defaultValue={
                        assignTimeSlots &&
                        assignTimeSlots.find(assignTimeSlot => assignTimeSlot.courseInstructorId === assignCourse.id) &&
                        assignTimeSlots.find(assignTimeSlot => assignTimeSlot.courseInstructorId === assignCourse.id).timeSlot[day.value]
                      }
                      value={selectedTimeSlots[assignCourse.id] && selectedTimeSlots[assignCourse.id][day.value]}
                      onChange={(selectedOptions) => handleSelectChange(day.value, selectedOptions, assignCourse.id)}
                    />
                  </Col>
                </Row>
              ))}
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <AButton
                  type="primary"
                  size="middle"
                  style={{
                    color: tokens.BG_LIGHT,
                    backgroundColor: tokens.COLOR_SUCCESS,
                  }}
                  onClick={() => handleEdit(key)}
                  disabled={editStatus[key]}
                >
                  Edit
                </AButton>
                <AButton
                  type="default"
                  size="middle"
                  style={{
                    color: tokens.COLOR_DANGER,
                    borderColor: tokens.COLOR_DANGER,
                    margin: "10px",
                  }}
                  onClick={() => handleSave(key)}
                  disabled={!editStatus[key] || saving}
                  loading={saving}
                >
                  Save
                </AButton>
              </div>
            </Card>
          ))}
      </div>
    </div>
  );
}

export default AssignTimeSlot;
